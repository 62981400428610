import React, { useEffect, useState } from "react";
import AlertItem from "./AlertItem";
import { useAuth } from "../../contexts/AuthContext";
import "./AlertTray.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { db } from "../../firebase";

function AlertTray({ alerts = [], addAlert = () => {} }) {
  // State
  const [alertsToShow, setAlertsToShow] = useState([]);
  const [update, setUpdate] = useState(0);

  const { currentUser } = useAuth();

  // UE
  useEffect(() => {
    console.log(alerts);
    if (alerts.length > 5) {
      alerts.length = 5;
    }
    const filt = alerts.filter(
      (al) => al.read !== true && al.alertRead !== true
    );

    setAlertsToShow(filt);
  }, [alerts]);

  async function markAsRead(notificationID = "") {
    console.log("ALERT READ");
    console.log(notificationID);
    db.collection("users")
      .doc(currentUser.uid)
      .collection("portalNotifications")
      .doc(notificationID)
      .update({
        alertRead: true,
      });
  }

  return (
    <div className="alert-tray">
      <TransitionGroup>
        {alertsToShow.map((alert, i) => {
          if (i > 3 || alert.read) {
            return null;
          }
          // console.log(alert);
          return (
            <AlertItem
              key={`${alert.notificationID}alert`}
              alert={alert}
              markAsRead={() => {}}
              hide={() => {
                setAlertsToShow((c) => {
                  console.log(c);
                  const filt = c.filter((al) => {
                    return al.notificationID !== alert.notificationID;
                  });
                  return filt;
                });
                markAsRead(alert.notificationID);
                setUpdate((c) => c + 1);
              }}
            />
          );
        })}
      </TransitionGroup>
    </div>
  );
}

export default AlertTray;
