import React from "react";
import { Backdrop } from "@mui/material";
import "./Loaders.css";

import { ProgressBar } from "react-loader-spinner";
function BackdropProgLoader({ text = "" }) {
  return (
    <div className="bd-prog-loader">
      <Backdrop open>
        <div className="bdpl-pro">
          <ProgressBar
            // width={"300"}
            wrapperClass="bdpl-loader"
            barColor="#1e7a69"
            borderColor="white"
          />
          <div className="bdpl-txt">
            <p>{text}</p>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}

export default BackdropProgLoader;
