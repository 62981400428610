import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { AuthProvider } from "./contexts/AuthContext";
import { HashRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    {/* <React.StrictMode> */}
    <Router>
      <App />
    </Router>
    {/* </React.StrictMode> */}
  </AuthProvider>
);
