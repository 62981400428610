import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { monthNames } from "../HelperFunctions";
import { useNavigate, useParams } from "react-router-dom";
// Icons

// Components
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackRow from "../display/Rows/BackRow";
import OPRow from "./OPRow";
import ConfirmModal from "../ClientStats/ConfirmModal";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";

function OutstandingPayments() {
  // Admin
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const clubID = useParams().id;
  const payCall = admin.functions().httpsCallable("outstandingPayment");

  // State
  const [loading, setLoading] = useState(true);
  const [outstandingPayments, setOutstandingPayments] = useState([]);
  const [error, setError] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [toSettle, setToSettle] = useState("");

  // UE
  useEffect(() => {
    loadOps();
  }, []);

  // Funcs
  function goBack() {
    navigate(-1);
  }

  async function loadOps() {
    setLoading(true);
    const opDocs = await db
      .collection("clubs")
      .doc(clubID)
      .collection("outstandingPayments")
      .where("status", "==", "outstanding")
      .orderBy("date", "desc")
      .get();

    const opArr = [];

    for (let i = 0; i < opDocs.docs.length; i++) {
      const opDoc = opDocs.docs[i];
      const opData = opDoc.data();
      const opDocID = opDoc.id;
      const { date, month } = opData;
      const monthValue = monthNames[month - 1];
      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "medium",
      });
      opData.docID = opDocID;
      opData.monthValue = monthValue;
      opData.dateObject = dateObject;
      opData.dateString = dateString;

      opArr.push(opData);
    }

    setOutstandingPayments(opArr);
    setLoading(false);
  }

  async function settleOP() {
    setShowConfirm(false);
    setLoading(true);
    const res = await payCall({
      clubID,
      usageDocID: toSettle.usageDocID,
      docID: toSettle.docID,
    });

    console.log(res.data);

    const parsed = JSON.parse(res.data);

    console.log("RES:" + parsed);

    if (parsed.status === "Success") {
      toast.success("Outstanding Payment Successfully settled", {
        icon: successIco,
      });
      loadOps();
    } else {
      setError("Could not process payment - please update your card details.");
    }

    setLoading(false);
  }

  return (
    <div className="outstanding-payments">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <BackRow action={goBack} />
      <PageHeader text="Outstanding Payments" />

      {showConfirm && (
        <ConfirmModal
          text="Please confirm to process the payment"
          close={() => setShowConfirm(false)}
          confirm={settleOP}
        />
      )}

      <div className="acg-box">
        <div className="ac-graph bss-white">
          <div className="op-row opr-header">
            <div className="opr-date">
              <p>Date</p>
            </div>
            <div className="opr-mth">
              <p>Month</p>
            </div>
            <div className="opr-year">
              <p>Year</p>
            </div>
            <div className="opr-ref">
              <p>Reference</p>
            </div>
            <div className="opr-act">
              <p>Pay</p>
            </div>
          </div>
          {outstandingPayments.map((op, i) => {
            return (
              <OPRow
                key={`op${i}`}
                op={op}
                settleAct={() => {
                  setToSettle(op);
                  setShowConfirm(true);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OutstandingPayments;
