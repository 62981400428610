/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, createRef } from "react";
import "./Leaderboard.css";
import Dropdown from "react-dropdown";
import TeamScorecard from "./TeamScorecard/TeamScorecard";
import axios from "axios";
import fileDownload from "js-file-download";
import BackdropLoader from "../Loaders/BackdropLoader";
import "react-dropdown/style.css";
import { CSVDownload } from "react-csv";
import { db, admin, storage } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ScoreModal from "./ScoreModal/ScoreModal";
import PlayerScore from "../display/PlayerScore/PlayerScore";
import GroupPlayerScore from "../display/PlayerScore/GroupPlayerScore";
import PlayerRound from "../PlayerRound/PlayerRound";
import MultiplePlayerRound from "../PlayerRound/MultiplePlayerRound";
import MatchplayScore from "../display/MatchplayScore/MatchplayScore";
import CupScore from "./CupScore/CupScore";
import NassauScore from "../display/NassauScore/NassauScore";
import PlayerLoader from "../Loaders/PlayerLoader";
import MatchplayHoleSlider from "../display/MatchplayScore/MatchplayHoleSlider";
import { Oval } from "react-loader-spinner";
import ReactToPdf from "react-to-pdf";
import ErrorModal from "../ErrorModal/ErrorModal";

import { Checkbox, Dialog } from "@mui/material";
import PageHeader from "../display/Rows/PageHeader";
import PlayerItem from "../display/PlayerItem/PlayerItem";

function Leaderboard({
  eventID = "",
  customRound = false,
  leagueID,
  uid,
  cupID = "",
  live = true,
  roundID,
  eventDetails,
  hideName = false,
  limit = false,
  pointsArray = [],
}) {
  const options = [
    {
      label: "Front 9",
      value: "frontNine",
    },
    {
      label: "Back 9",
      value: "backNine",
    },
    {
      label: "Handicap",
      vallue: "handicap",
    },
    {
      label: "Even Holes",
      value: "evs",
    },
    {
      label: "Odd Holes",
      value: "odds",
    },
    {
      label: "Last 6 Holes",
      value: "lastSix",
    },
    {
      label: "Last 3 Holes",
      value: "lastThree",
    },
    {
      label: "Last Hole",
      value: "lastHole",
    },
    {
      label: "Stroke 1",
      value: "strokeOne",
    },
    {
      label: "Stroke 2",
      value: "strokeTwo",
    },
    {
      label: "Stroke 3",
      value: "strokeThree",
    },
  ];

  const csvVals = [
    { value: "handicap", label: "Handicap" },
    { value: "holesCompleted", label: "Thru" },
    { value: "totalGrossStrokes", label: "Gross Strokes" },
    { value: "totalNetStrokes", label: "Net Strokes" },
    { value: "currentGrossScore", label: "Gross Score" },
    { value: "currentScore", label: "Net Score" },
    { value: "totalPoints", label: "Stableford Points" },
    { value: "totalPutts", label: "Putts" },
    { value: "totalGIR", label: "GIR" },
    { value: "totalFIR", label: "FIR" },
    // { label: "", value: "" },
    // { label: "", value: "" },
  ];

  const teamCSVVals = [
    {
      value: "holesCompleted",
      label: "Thru",
    },
    { value: "currentScore", label: "Score" },
    // { value: "totalPoints", label: "Stableford Points" },
  ];

  const pdfHeaderOpts = [
    {
      value: "net",
      label: "Net Score",
    },
    {
      value: "gross",
      label: "Gross Score",
    },
    {
      value: "stableford",
      label: "Stableford",
    },
    {
      value: "netStrokes",
      label: "Net Strokes",
    },
    {
      value: "grossStrokes",
      label: "Gross Strokes",
    },
  ];

  const pdfVals = [
    {
      value: "putts",
      label: "Putts",
    },
    {
      value: "onePutts",
      label: "1 Putts",
    },
    {
      value: "gir",
      label: "GIR",
    },
    {
      value: "fir",
      label: "FIR",
    },
  ];

  const { currentUser, userDetails, clubFeatures } = useAuth();
  const [pdfHeaders, setPDFHeaders] = useState(
    pdfHeaderOpts.map((item) => item.value)
  );
  const [tableData, setTableData] = useState([]);
  const [scoreModalID, setScoreModalID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showRound, setShowRound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [eventFormat, setEventFormat] = useState("");
  const [roundToShow, setRoundToShow] = useState();
  const [update, setUpdate] = useState(0);
  const [selRound, setSelRound] = useState();
  const [selRoundID, setSelRoundID] = useState(roundID);
  const [error, setError] = useState("");
  const [enableTeams, setEnableTeams] = useState(false);
  const [csv, setCsv] = useState([]);
  const [dlCSV, setDLCSV] = useState(false);
  const [selCSVRd, setSelCSVRd] = useState(roundID);
  const [csvOpts, setCSVOpts] = useState([]);
  const [selPDFRd, setSelPDFRd] = useState(roundID);
  const [multPDFType, setMultPDFType] = useState("cumulative");
  const [multCSVType, setMultCSVType] = useState("cumulative");
  const [selCSVVals, setSelCSVVals] = useState(csvVals);
  const [selCSVBoard, setSelCSVBoard] = useState("individuals");
  const [submittedIDs, setSubmittedIDs] = useState([]);
  const [selTeamCSVVals, setSelTeamCSVVals] = useState(teamCSVVals);
  const [showExports, setShowExports] = useState(true);
  const [teamBoardArray, setTeamBoardArray] = useState([]);

  const [selPDFVals, setSelPDFVals] = useState(pdfVals);

  const [teamType, setTeamType] = useState("");

  const [format, setFormat] = useState("net-strokeplay");
  const [board, setBoard] = useState([]);

  const [clubID, setClubID] = useState("");

  const [roundFormat, setRoundFormat] = useState("");
  const [pdfs, setPDFs] = useState([]);
  const [showPDF, setShowPDF] = useState(false);
  const [showCSV, setShowCSV] = useState(false);
  const [coType, setCoType] = useState("");
  const [coType2, setCoType2] = useState("");
  const [selPDF, setSelPDF] = useState("");

  const [teamBoard, setTeamBoard] = useState([]);
  const [overallTeamScores, setOverallTeamScores] = useState(2);

  const ref = createRef();

  const callBoard = admin.functions().httpsCallable("leaderboardCall");

  const [og, setOG] = useState([]);
  const [ogFormat, setOGFormat] = useState("");

  const [enableDivisions, setEnableDivisions] = useState(false);
  const [divisionOptions, setDivisionOptions] = useState([]);

  const [divMap, setDivMap] = useState({});

  const [eventName, setEventName] = useState("");
  const [selFormat, setSelFormat] = useState("");
  const [leaderboardID, setLeaderboardID] = useState("");
  const lbIDRef = useRef();
  lbIDRef.current = leaderboardID;

  const [bd, setBD] = useState(false);

  const [rounds, setRounds] = useState([]);

  const [skinsIDs, setSkinsIDs] = useState([]);

  const [teamOneColor, setTeamOneColor] = useState("#1e7a69");
  const [teamTwoColor, setTeamTwoColor] = useState("#21c17c");

  const [pointsToWin, setPointsToWin] = useState(0);

  const [scratchMode, setScratchMode] = useState(false);

  const [matchData, setMatchData] = useState({
    players: [
      {
        playerName: "",
        handicap: "",
        profileImage: "",
        playerID: "",
      },
      {
        playerName: "",
        handicap: "",
        profileImage: "",
        playerID: "",
      },
    ],
    leaderboard: {
      leader: "",
      thru: "",
      by: "",
    },
    sortedIDs: [],
    nassau: false,
  });

  async function getPDF() {
    // return console.log(pdfHeaders);
    setShowPDF(false);
    setBD(true);
    const vals = selPDFVals.map((val) => val.value);
    const link = await callBoard({
      roundID: rounds.length > 1 ? selPDFRd : selRoundID,
      multPDFType,
      pdfHeaders,
      type: selPDF,
      clubID,
      coType,
      coType2,
      pdfVals: vals,
    }).catch((err) => {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there. Please try again."
      );
      setBD(false);
    });
    const url = link.data;
    const ref = storage.refFromURL(url);
    window.open(url, "_blank");
    setBD(false);
  }

  const dataRef = useRef();
  dataRef.current = matchData;

  useEffect(() => {
    if (clubFeatures) {
      // setShowExports(clubFeatures.eventExports ?? false);
    }
  }, [clubFeatures]);

  useEffect(() => {
    if (dlCSV) {
      setTimeout(() => {
        setDLCSV(false);
        // alert("set false");
      }, 4000);
    }
  }, [dlCSV]);

  useEffect(() => {
    if (currentUser) {
      if (cupID === "") {
        getPlayerScores(roundID);
      } else if (cupID === "custom") {
        loadCustomCupScores();
      } else {
        loadCupScores(roundID);
      }
    }
  }, [currentUser]);

  function compareCombined(a, b) {
    if (a.combinedScoreVal < b.combinedScoreVal) {
      return -1;
    }
    if (a.combinedScoreVal > b.combinedScoreVal) {
      return 1;
    }
    return 0;
  }

  function compare(a, b) {
    if (a.score < b.score) {
      return -1;
    }
    if (a.score > b.score) {
      return 1;
    }
    return 0;
  }

  function loadCustomCupScores() {
    setEventFormat("cup");

    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (roundDoc) => {
        if (!roundDoc.exists) {
          return;
        }
        const round = roundDoc.data();
        const { format, matches, score, teams } = round;
        setScratchMode(round.scratchMode ?? false);
        const nassau = round.nassau ?? false;
        setEventName("Custom Cup");

        setMatchData({
          matches,
          format,
          score,
          cupScore: "",
          teams,
          nassau,
        });
        setLoading(false);
      });
  }

  async function loadCupScores(roundID) {
    setEventFormat("cup");

    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (roundDoc) => {
        if (!roundDoc.exists) {
          return;
        }
        const round = roundDoc.data();
        const { format, matches, score, teams, roundIndex } = round;
        setPointsToWin(round.pointsToWin ?? 0);
        const cupDoc = await db.collection("cups").doc(cupID).get();
        const cupData = cupDoc.data();
        const teamOneColor = cupData.teamOneColor ?? "#21c17c";
        const teamTwoColor = cupData.teamTwoColor ?? "#e24955";
        setTeamOneColor(teamOneColor);
        setTeamTwoColor(teamTwoColor);
        const cupRounds = cupData.rounds;
        const cupScore = cupData.score ?? [];
        const nassau = cupData.nassau ?? false;
        setEventName(cupData.cupName);
        if (roundIndex > 0) {
          const arr = [];
          const { roundIDs } = cupData;
          for (let i = 0; i < roundIDs.length; i++) {
            if (i > roundIndex) {
              continue;
            }
            arr.push({
              label: `Round ${i + 1} - ${cupRounds[i].format}`,
              value: roundIDs[i],
            });
          }
          setRounds(arr);
          setSelRound(arr[arr.length - 1]);

          const csvRds = [...arr];
          // csvRds.unshift({ value: "overall", label: "Overall" });
          setCSVOpts(csvRds);
        }
        setMatchData({
          matches,
          format,
          score,
          cupScore,
          teams,
          nassau,
        });
        setUpdate((current) => current + 1);
        setLoading(false);
      });
  }

  function returnFormat(fmt) {
    if (fmt === "two") {
      return "Better Ball - Two to Count";
    }

    if (fmt === "bogey") {
      return "Bogey Plus";
    }

    if (fmt === "bogey++") {
      return "Bogey Double Plus";
    }

    if (fmt === "net-strokeplay") {
      return "Net Strokeplay";
    }
    if (fmt === "team-net-strokeplay") {
      return "Team Net Strokeplay";
    }
    if (fmt === "average-ball") {
      return "Average Ball";
    }
    if (fmt === "worst-ball") {
      return "Worst Ball";
    }
    if (fmt === "best-ball") {
      return "Best Ball";
    }
    if (fmt === "match-play") {
      return "Match Play";
    }
    if (fmt === "ab-match-play") {
      return "AB Match Play";
    }
    if (fmt === "bb-match-play") {
      return "BB Match Play";
    }
    if (fmt === "wb-match-play") {
      return "WB Match Play";
    }
    if (fmt === "stableford") {
      return "Stableford";
    }
    if (fmt === "mod-stableford") {
      return "Modified Stableford";
    }
    if (fmt === "skins-normal") {
      return "Normal Skins";
    }
    if (fmt === "skins-expo") {
      return "Expo Skins";
    }

    return fmt;
  }

  async function loadnewTeamBoard(roundID) {
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const { teamType, leaderboard } = round;
    setTeamType(teamType);
    setEnableTeams(true);
    setFormat(teamType ?? round.format);
    setRoundFormat(round.format);
    db.collection("rounds")
      .doc(roundID)
      .collection("teamLeaderboard")
      .onSnapshot(async (snap) => {
        const arr = [];

        for (let i = 0; i < snap.docs.length; i++) {
          const teamDoc = snap.docs[i];
          const team = teamDoc.data();
          let name = "";
          for (let j = 0; j < team.playerIDs.length; j++) {
            for (let x = 0; x < leaderboard.length; x++) {
              if (leaderboard[x].playerID === team.playerIDs[j]) {
                name += `${leaderboard[x].lastName ?? leaderboard[x].name} / `;
              }
            }
          }
          name = name.slice(0, -3);
          if (team.teamName) {
            name = team.teamName;
          }
          if (round.format === "stableford" && teamType !== "Overall") {
            team.score = team.stableScore ?? 0;
          }
          team.name = name;
          arr.push(team);
        }

        // console.log(arr);

        if (round.format.includes("stable")) {
          arr.sort((a, b) => b.score - a.score);
        } else {
          arr.sort((a, b) => a.score - b.score);
        }

        if (teamType.includes("bogey")) {
          arr.sort((a, b) => b.score - a.score);
        }

        let currentTies = 0;
        if (arr.length > 1) {
          for (let i = 0; i < arr.length; i++) {
            if (i === 0 && arr.length > 1) {
              if (arr[1].score === arr[0].score) {
                arr[0].tied = true;
              }
            }
            if (i > 0) {
              if (arr[i].score === arr[i - 1].score) {
                arr[i].tied = true;
              }
            }
            if (i > 0 && i < arr.length - 1) {
              if (arr[i].score === arr[i + 1].score) {
                arr[i].tied = true;
              }
            }

            if (i > 0) {
              if (arr[i - 1].score === arr[i].score) {
                arr[i].rank = i - currentTies;
                currentTies += 1;
              } else {
                currentTies = 0;
                arr[i].rank = i + 1;
              }
            }
          }
        }

        setBoard(arr);
      });
  }

  async function loadTeamBoard(roundID) {
    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (roundDoc) => {
        // const arr = [];
        const round = roundDoc.data();
        const { teamLeaderboard, leaderboard, teamType } = round;
        setTeamType(teamType);
        setEnableTeams(true);
        setFormat(teamType ?? round.format);
        setRoundFormat(round.format);
        if (teamType === "Overall") {
          setOverallTeamScores(round.overallTeamScores);
        }
        for (let i = 0; i < teamLeaderboard.length; i++) {
          const team = teamLeaderboard[i];
          let name = "";
          for (let j = 0; j < team.playerIDs.length; j++) {
            for (let x = 0; x < leaderboard.length; x++) {
              if (leaderboard[x].playerID === team.playerIDs[j]) {
                name += `${leaderboard[x].lastName ?? leaderboard[x].name} / `;
              }
            }
          }
          name = name.slice(0, -3);
          if (team.teamName) {
            name = team.teamName;
          }

          // if (round.roundIndex === 0) {
          //   for (let i = 0; i < teamLeaderboard.length; i++) {
          //     const tss = teamLeaderboard[i].startingScore ?? 0;
          //     teamLeaderboard[i].score = tss;
          //   }
          // }

          if (teamType === "Overall") {
            const ss = team.startingScore ?? 0;
            teamLeaderboard[i].today = team.score - ss;
          }

          teamLeaderboard[i].name = name;
        }
        if (round.format === "stableford") {
          if (teamType === "two") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];
              const { thru, score } = row;

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { lowest, secondLowest } = hole;
                if (lowest !== "") {
                  const lowestPoints = 2 - lowest;
                  totalPoints += lowestPoints;
                }
                if (secondLowest !== "") {
                  const secondLowestPoints = 2 - secondLowest;

                  totalPoints += secondLowestPoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          if (teamType === "scramble") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { best } = hole;
                if (best !== "") {
                  const lowestPoints = 2 - best;
                  totalPoints += lowestPoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          if (teamType === "custom") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { score, toCount, scores } = hole;
                let counter = toCount;
                if (counter > scores.length) {
                  counter = scores.length;
                }
                for (let g = 0; g < counter; g++) {
                  const score = scores[g];
                  if (score !== "") {
                    const points = 2 - score;
                    totalPoints += points;
                  }
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          if (teamType === "combined") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];
              const { playerIDs } = row;
              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                let holePoints = 0;

                for (let g = 0; g < playerIDs.length; g++) {
                  const scr = hole[playerIDs[g]];
                  if (scr !== "") {
                    holePoints += 2 - scr;
                  }
                }
                totalPoints += holePoints;
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }

          const sortedBoard = teamLeaderboard.sort((a, b) => b.score - a.score);
          setBoard(sortedBoard);
        } else if (round.format === "mod-stableford") {
          if (teamType === "two") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];
              const { thru, score } = row;

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { lowest, secondLowest } = hole;
                if (lowest !== "") {
                  const lowestPoints = 2 - lowest;
                  let scorePoints = 0;
                  switch (lowest) {
                    case lowest > 2:
                      scorePoints = -5;
                      break;
                    case 2:
                      scorePoints = -3;
                      break;
                    case 1:
                      scorePoints = -1;
                      break;
                    case 0:
                      break;
                    case -1:
                      scorePoints = 2;
                      break;
                    case -2:
                      scorePoints = 5;
                      break;
                    case lowest < -2:
                      scorePoints = 8;
                      break;
                    default:
                      break;
                  }
                  totalPoints += scorePoints;
                }
                if (secondLowest !== "") {
                  const secondLowestPoints = 2 - secondLowest;
                  let scorePoints = 0;
                  switch (secondLowest) {
                    case secondLowest > 2:
                      scorePoints = -5;
                      break;
                    case 2:
                      scorePoints = -3;
                      break;
                    case 1:
                      scorePoints = -1;
                      break;
                    case 0:
                      break;
                    case -1:
                      scorePoints = 2;
                      break;
                    case -2:
                      scorePoints = 5;
                      break;
                    case secondLowest < -2:
                      scorePoints = 8;
                      break;
                    default:
                      break;
                  }
                  totalPoints += scorePoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          if (teamType === "scramble") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { best } = hole;
                if (best !== "") {
                  const lowestPoints = 2 - best;
                  let scorePoints = 0;
                  switch (best) {
                    case best > 2:
                      scorePoints = -5;
                      break;
                    case 2:
                      scorePoints = -3;
                      break;
                    case 1:
                      scorePoints = -1;
                      break;
                    case 0:
                      break;
                    case -1:
                      scorePoints = 2;
                      break;
                    case -2:
                      scorePoints = 5;
                      break;
                    case best < -2:
                      scorePoints = 8;
                      break;
                    default:
                      break;
                  }
                  totalPoints += scorePoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          if (teamType === "custom") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { score } = hole;
                if (score !== "") {
                  let scorePoints = 0;
                  switch (score) {
                    case score > 2:
                      scorePoints = -5;
                      break;
                    case 2:
                      scorePoints = -3;
                      break;
                    case 1:
                      scorePoints = -1;
                      break;
                    case 0:
                      break;
                    case -1:
                      scorePoints = 2;
                      break;
                    case -2:
                      scorePoints = 5;
                      break;
                    case score < -2:
                      scorePoints = 8;
                      break;
                    default:
                      break;
                  }
                  totalPoints += scorePoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          const sortedBoard = teamLeaderboard.sort((a, b) => b.score - a.score);
          setBoard(sortedBoard);
        } else {
          if (format !== "bogey" && format !== "bogey++") {
            const sortedBoard = teamLeaderboard.sort(
              (a, b) => a.score - b.score
            );
            setBoard(sortedBoard);
          } else {
            const sortedBoard = teamLeaderboard.sort(
              (a, b) => b.score - a.score
            );
            setBoard(sortedBoard);
          }
        }

        setLoading(false);
      });
  }

  async function getPlayerScores(id) {
    setLoading(true);
    const roundDoc = await db.collection("rounds").doc(id).get();
    if (!roundDoc.exists) {
      return;
    }
    const round = roundDoc.data();
    // Extras
    setClubID(round.clubID);
    const extraArr = [];
    // console.log(round.twoClub);
    if (round.twoClub) {
      extraArr.push({
        value: "twoClub",
        label: "Two Club",
      });
    }
    const format = round.format ?? "net-strokeplay";
    if (round.enableTeams) {
      if (round.newTeamboard) {
        loadnewTeamBoard(id);
      } else {
        loadTeamBoard(id);
      }
    }
    // setSponsors(round.sponsors ?? []);
    const teams = round.teams ?? [];
    let divisions = round.divisions ?? [];
    const mep = {};
    setOGFormat(round.format);
    let end = round.enableDivisions;
    if (extraArr.length > 0) {
      end = true;
    }
    setEnableDivisions(end);
    for (let i = 0; i < divisions.length; i++) {
      divisions[i].label = divisions[i].divisionName;
      divisions[i].value = divisions[i].leaderboardID;
    }
    if (round.enableDivisions) {
      for (let i = 0; i < round.leaderboardIDs.length; i++) {
        const id = round.leaderboardIDs[i];
        const board = round[id];
        const leaderboard = round[id].leaderboard;

        const { format } = board;

        if (format === "stableford") {
          leaderboard.sort((a, b) => {
            return b.score - a.score;
          });
        } else {
          leaderboard.sort((a, b) => {
            // if (b.thru === 0) {
            //   return -1;
            // }
            return a.score - b.score;
          });
        } // console.log(leaderboard, id);

        leaderboard.sort((a, b) => {
          const awd = a.wd ?? false;
          const bwd = b.wd ?? false;
          return awd - bwd;
        });
        leaderboard.sort((a, b) => {
          const adq = a.dq ?? false;
          const bdq = b.dq ?? false;
          return adq - bdq;
        });
        if (leaderboard.length > 1) {
          let currentTies = 0;
          for (let i = 0; i < leaderboard.length; i++) {
            if (i === 0 && leaderboard.length > 1) {
              if (leaderboard[1].score === leaderboard[0].score) {
                leaderboard[0].tied = true;
              }
            }
            if (i > 0) {
              if (leaderboard[i].score === leaderboard[i - 1].score) {
                leaderboard[i].tied = true;
              }
            }
            if (i > 0 && i < leaderboard.length - 1) {
              if (leaderboard[i].score === leaderboard[i + 1].score) {
                leaderboard[i].tied = true;
              }
            }

            if (i > 0) {
              if (leaderboard[i - 1].score === leaderboard[i].score) {
                leaderboard[i].rank = i - currentTies;
                currentTies += 1;
              } else {
                currentTies = 0;
                leaderboard[i].rank = i + 1;
              }
            } else {
              leaderboard[i].rank = 1;
            }
          }
        }

        for (let i = 0; i < leaderboard.length; i++) {
          if (round[id].format !== "stableford") {
            if (leaderboard[i].score > 0) {
              // leaderboard[i].score = `+${leaderboard[i].score}`;
            }
          }
          if (leaderboard[i].tied === true) {
            // console.log(i);
            leaderboard[i].rank = `T${leaderboard[i].rank}`;
          } else {
            leaderboard[i].rank = i + 1;
          }
        }

        round[id].leaderboard = leaderboard;
        mep[divisions[i].leaderboardID] = round[id];
      }
    }
    if (round.enableTeams) {
      divisions.unshift({
        label: "Teams",
        value: "teams",
      });
    }
    divisions.unshift({
      label: "Overall",
      value: "overall",
    });
    if (extraArr.length > 0) {
      divisions = [...divisions, ...extraArr];
      for (let i = 0; i < extraArr.length; i++) {
        if (extraArr[i].value === "twoClub") {
          mep["twoClub"] = {
            leaderboard: round.twoClubBoard,
            divisionName: "Two Club",
          };
        }
      }
    }
    const newDivs = divisions.map((div) => {
      console.log(div);
      const newDiv = {
        label: div.label,
        value: div.value,
        teamLeaderboard: div.playerType === "teams",
      };
      return newDiv;
    });
    const pdfOpts = [...newDivs];
    // if (round.twoClub) {
    //   pdfOpts.push({
    //     label: "Two Club",
    //     value: "twoClub",
    //   });
    // }
    if (round.enableTeams && round.teamType === "Overall") {
      pdfOpts.push({
        label: "Team Summary",
        value: "team-summary",
      });
    }
    pdfOpts.push({
      label: "Prizes",
      value: "prizes",
    });
    if (round.jackpot) {
      pdfOpts.push({
        label: "Jackpot",
        value: "jackpot",
      });
    }

    if (round.roundIndex > 0) {
      pdfOpts.push({
        label: "Rounds",
        value: "rounds",
      });
    }

    if (round.completed) {
      pdfOpts.push({
        label: "Player Stats",
        value: "playerStats",
      });

      pdfOpts.push({
        label: "Event Stats",
        value: "eventStats",
      });
      pdfOpts.push({
        label: "Summary",
        value: "summary",
      });
    }
    // console.log(mep);
    console.log(newDivs);
    setPDFs(pdfOpts);
    setDivMap(mep);
    const tbArr = [];

    for (let i = 0; i < newDivs.length; i++) {
      const div = newDivs[i];
      if (div.teamLeaderboard) {
        tbArr.push(div.value);
      }
    }

    setTeamBoardArray(tbArr);

    setDivisionOptions(newDivs);

    setLeaderboardID("overall");
    setSelFormat(round.format);
    setEventFormat(format);
    if (
      format === "average-ball" ||
      format === "worst-ball" ||
      format === "best-ball"
    ) {
      db.collection("rounds")
        .doc(roundID)
        .onSnapshot(async (snap) => {
          if (!snap.exists) {
            return;
          }

          const round = snap.data();
          setScratchMode(round.scratchMode ?? false);
          const leaderboard = round.leaderboard;
          const arr = [];
          for (let i = 0; i < leaderboard.length; i++) {
            const group = leaderboard[i];
            const { groupName, holes, playerIDs } = group;
            let { score } = group;
            let thru = 0;
            for (let j = 0; j < holes.length; j++) {
              const hole = holes[j];
              const { lowest, highest } = hole;

              if (format === "average-ball") {
                if (lowest !== 0 || highest !== 0) {
                  thru = j + 1;
                }
              } else {
                if (lowest !== "") {
                  thru += 1;
                }
              }
            }

            // Format score
            if (score === 0) {
              score = "E";
            }
            if (score > 0) {
              score = `+${score}`;
            }

            arr.push({
              score,
              holes,
              groupName,
              playerIDs,
              thru,
            });
          }

          arr.sort(compare);
          setTableData(arr);
          setOG(arr);
          setLoading(false);
        });
    } else if (
      format === "bb-match-play" ||
      format === "wb-match-play" ||
      format === "ab-match-play"
    ) {
      db.collection("rounds")
        .doc(roundID)
        .onSnapshot(async (snap) => {
          if (!snap.exists) {
            return;
          }
          const round = snap.data();
          const { leaderboard, holes, teamNames, nassau } = round;
          setScratchMode(round.scratchMode ?? false);

          setMatchData({
            holes,
            leaderboard,
            teamNames,
            nassau,
          });
          setLoading(false);
        });
    } else if (format === "match-play") {
      db.collection("rounds")
        .doc(roundID)
        .onSnapshot(async (snap) => {
          if (!snap.exists) {
            return;
          }
          const round = snap.data();
          const { leaderboard, holes, playerIDs } = round;
          const nassau = round.nassau ?? false;
          const players = [];
          setScratchMode(round.scratchMode ?? false);

          // Players
          for (let i = 0; i < playerIDs.length; i++) {
            const playerID = playerIDs[i];
            const playerDoc = await db.collection("users").doc(playerID).get();
            const player = playerDoc.data();
            const initial = player.firstName.charAt(0);
            const playerName = `${initial} ${player.lastName}`;
            const { profileImage, handicap } = player;
            players.push({ playerName, profileImage, handicap, playerID });
          }

          setMatchData({
            players,
            holes,
            leaderboard,
            nassau,
          });
          setLoading(false);
        });
    } else if (format.includes("stableford")) {
      db.collection("rounds")
        .doc(id)
        .onSnapshot(async (snapshot) => {
          if (!snapshot.exists) {
            return;
          }
          const round = snapshot.data();
          // Get league standings
          const { leaderboard } = round;
          leaderboard.sort((a, b) => b.score - a.score);
          leaderboard.sort((a, b) => {
            const adq = a.dq ?? false;
            const bdq = b.dq ?? false;
            return adq - bdq;
          });
          leaderboard.sort((a, b) => {
            const adq = a.wd ?? false;
            const bdq = b.wd ?? false;
            return adq - bdq;
          });
          let currentTies = 0;
          if (leaderboard.length > 1) {
            for (let i = 0; i < leaderboard.length; i++) {
              if (i === 0 && leaderboard.length > 1) {
                if (leaderboard[1].score === leaderboard[0].score) {
                  leaderboard[0].tied = true;
                }
              }
              if (i > 0) {
                if (leaderboard[i].score === leaderboard[i - 1].score) {
                  leaderboard[i].tied = true;
                }
              }
              if (i > 0 && i < leaderboard.length - 1) {
                if (leaderboard[i].score === leaderboard[i + 1].score) {
                  leaderboard[i].tied = true;
                }
              }

              if (i > 0) {
                if (leaderboard[i - 1].score === leaderboard[i].score) {
                  leaderboard[i].rank = i - currentTies;
                  currentTies += 1;
                } else {
                  currentTies = 0;
                  leaderboard[i].rank = i + 1;
                }
              }
            }
          }

          for (let i = 0; i < leaderboard.length; i++) {
            if (leaderboard[i].score > 0) {
              // leaderboard[i].score = `+${leaderboard[i].score}`;
            }
            if (leaderboard[i].tied === true) {
              leaderboard[i].rank = `T${leaderboard[i].rank}`;
            } else {
              leaderboard[i].rank = i + 1;
            }
          }
          setSubmittedIDs(round.submittedIDs);
          setEventName(eventDetails.eventName);
          if (rounds.length === 0) {
            if (round.roundIndex > 0) {
              const arr = [];
              const roundIDs = round.roundIDs ?? [];
              for (let i = 0; i < roundIDs.length; i++) {
                if (i > round.roundIndex) {
                  continue;
                }
                arr.push({
                  label: `Round ${i + 1}`,
                  value: roundIDs[i],
                });
              }
              setRounds(arr);
              setSelRound(arr[arr.length - 1]);
              const csvRds = [...arr];
              // csvRds.unshift({ value: "overall", label: "Overall" });
              setCSVOpts(csvRds);
            }
          }
          // for (let i = 0; i < leaderboard.length; i++) {
          //   leaderboard[i].rank = leaderboard.length - leaderboard[i].rank + 1;
          // }
          const mep2 = {};
          if (round.enableDivisions) {
            for (let j = 0; j < round.leaderboardIDs.length; j++) {
              const id = round.leaderboardIDs[j];
              const board = round[id];
              const leaderboard = round[id].leaderboard;

              // const { format } = board;

              if (
                board.format === "stableford" ||
                (!board.format && format.includes("stable"))
              ) {
                leaderboard.sort((a, b) => {
                  return b.score - a.score;
                });
              } else {
                leaderboard.sort((a, b) => {
                  // if (b.thru === 0) {
                  //   return -1;
                  // }
                  return a.score - b.score;
                });
              } // console.log(leaderboard, id);

              leaderboard.sort((a, b) => {
                const awd = a.wd ?? false;
                const bwd = b.wd ?? false;
                return awd - bwd;
              });
              leaderboard.sort((a, b) => {
                const adq = a.dq ?? false;
                const bdq = b.dq ?? false;
                return adq - bdq;
              });
              let currentTies = 0;
              if (leaderboard.length > 1) {
                for (let i = 0; i < leaderboard.length; i++) {
                  if (i === 0 && leaderboard.length > 1) {
                    if (leaderboard[1].score === leaderboard[0].score) {
                      leaderboard[0].tied = true;
                    }
                  }
                  if (i > 0) {
                    if (leaderboard[i].score === leaderboard[i - 1].score) {
                      leaderboard[i].tied = true;
                    }
                  }
                  if (i > 0 && i < leaderboard.length - 1) {
                    if (leaderboard[i].score === leaderboard[i + 1].score) {
                      leaderboard[i].tied = true;
                    }
                  }

                  if (i > 0) {
                    if (leaderboard[i - 1].score === leaderboard[i].score) {
                      leaderboard[i].rank = i - currentTies;
                      currentTies += 1;
                    } else {
                      currentTies = 0;
                      leaderboard[i].rank = i + 1;
                    }
                  } else {
                    leaderboard[i].rank = 1;
                  }
                }
              }

              for (let i = 0; i < leaderboard.length; i++) {
                if (round[id].format !== "stableford") {
                  if (leaderboard[i].score > 0) {
                    // leaderboard[i].score = `+${leaderboard[i].score}`;
                  }
                }
                if (leaderboard[i].tied === true) {
                  // console.log(i);
                  leaderboard[i].rank = `T${leaderboard[i].rank}`;
                } else {
                  leaderboard[i].rank = i + 1;
                }
              }

              round[id].leaderboard = leaderboard;
              mep2[id] = round[id];
              if (lbIDRef.current === id) {
                setTableData(leaderboard);
              }
            }
          }
          if (round.twoClub) {
            mep2["twoClub"] = {
              leaderboard: round.twoClubBoard,
              divisionName: "Two Club",
            };
          }
          // console.log(mep2);
          setDivMap(mep2);
          // console.log(lbIDRef.current);
          if (lbIDRef.current === "overall") {
            setTableData(leaderboard);
          }
          setOG(leaderboard);
          setLoading(false);
        });
    } else if (format.includes("skins")) {
      db.collection("rounds")
        .doc(roundID)
        .onSnapshot(async (snap) => {
          if (!snap.exists) {
            return;
          }
          const round = snap.data();
          const players = {};
          setScratchMode(round.scratchMode ?? false);
          const { leaderboard, holes, groups } = round;
          for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            for (let j = 0; j < group.players.length; j++) {
              const player = group.players[j];
              const { playerID, handicap, name, profileImage } = player;
              players[playerID] = {
                playerID,
                handicap,
                name,
                profileImage,
              };
            }
          }

          const sorted = Object.fromEntries(
            Object.entries(leaderboard).sort(([, a], [, b]) => b - a)
          );
          const sortedIDs = [];
          for (let [key, value] of Object.entries(sorted)) {
            if (key === "thru") {
              continue;
            }
            sortedIDs.push(key);
          }
          setSkinsIDs(sortedIDs);
          setMatchData({ players, leaderboard, holes, groups, sortedIDs });
          setUpdate((current) => current + 1);
          setLoading(false);
        });
    } else if (format === "net-strokeplay" || format === "medal") {
      db.collection("rounds")
        .doc(id)
        .onSnapshot(async (snapshot) => {
          // alert("got here");
          // console.log("upd");
          if (!snapshot.exists) {
            return;
          }
          if (eventDetails) {
            setEventName(eventDetails.eventName);
          }
          const round = snapshot.data();
          setScratchMode(round.scratchMode ?? false);
          setSubmittedIDs(round.submittedIDs);
          // setDivisionOptions(round.divisions ?? []);
          if (rounds.length === 0) {
            if (round.roundIndex > 0) {
              const arr = [];
              const roundIDs = eventDetails.roundIDs ?? [];
              for (let i = 0; i < roundIDs.length; i++) {
                if (i > round.roundIndex) {
                  continue;
                }
                arr.push({
                  label: `Round ${i + 1}`,
                  value: roundIDs[i],
                });
              }
              setRounds(arr);

              setSelRound(arr[arr.length - 1]);
              const csvRds = [...arr];
              // csvRds.unshift({ value: "overall", label: "Overall" });
              setCSVOpts(csvRds);
            }
          }
          // Get league standings
          const leaderboard = round.leaderboard ?? [];

          leaderboard.sort((a, b) => a.score - b.score);

          leaderboard.sort((a, b) => {
            const adq = a.dq ?? false;
            const bdq = b.dq ?? false;
            return adq - bdq;
          });

          leaderboard.sort((a, b) => {
            const adq = a.wd ?? false;
            const bdq = b.wd ?? false;
            return adq - bdq;
          });
          let currentTies = 0;
          if (leaderboard.length > 1) {
            for (let i = 0; i < leaderboard.length; i++) {
              if (i === 0 && leaderboard.length > 1) {
                if (leaderboard[1].score === leaderboard[0].score) {
                  leaderboard[0].tied = true;
                }
              }
              if (i > 0) {
                if (leaderboard[i].score === leaderboard[i - 1].score) {
                  leaderboard[i].tied = true;
                }
              }
              if (i > 0 && i < leaderboard.length - 1) {
                if (leaderboard[i].score === leaderboard[i + 1].score) {
                  leaderboard[i].tied = true;
                }
              }

              if (i > 0) {
                if (leaderboard[i - 1].score === leaderboard[i].score) {
                  leaderboard[i].rank = i - currentTies;
                  currentTies += 1;
                } else {
                  currentTies = 0;
                  leaderboard[i].rank = i + 1;
                }
              }
            }
          }
          for (let i = 0; i < leaderboard.length; i++) {
            if (leaderboard[i].score > 0) {
              // leaderboard[i].score = `+${leaderboard[i].score}`;
            }
            if (leaderboard[i].tied === true) {
              leaderboard[i].rank = `T${leaderboard[i].rank}`;
            } else {
              leaderboard[i].rank = i + 1;
            }
          }
          const mep2 = {};
          if (round.enableDivisions) {
            for (let j = 0; j < round.leaderboardIDs.length; j++) {
              const id = round.leaderboardIDs[j];
              const board = round[id];
              const leaderboard = round[id].leaderboard;

              const { format } = board;

              if (format === "stableford") {
                leaderboard.sort((a, b) => {
                  return b.score - a.score;
                });
              } else {
                leaderboard.sort((a, b) => {
                  // if (b.thru === 0) {
                  //   return -1;
                  // }
                  return a.score - b.score;
                });
              } // console.log(leaderboard, id);

              leaderboard.sort((a, b) => {
                const awd = a.wd ?? false;
                const bwd = b.wd ?? false;
                return awd - bwd;
              });
              leaderboard.sort((a, b) => {
                const adq = a.dq ?? false;
                const bdq = b.dq ?? false;
                return adq - bdq;
              });
              let currentTies = 0;
              if (leaderboard.length > 1) {
                for (let i = 0; i < leaderboard.length; i++) {
                  if (i === 0 && leaderboard.length > 1) {
                    if (leaderboard[1].score === leaderboard[0].score) {
                      leaderboard[0].tied = true;
                    }
                  }
                  if (i > 0) {
                    if (leaderboard[i].score === leaderboard[i - 1].score) {
                      leaderboard[i].tied = true;
                    }
                  }
                  if (i > 0 && i < leaderboard.length - 1) {
                    if (leaderboard[i].score === leaderboard[i + 1].score) {
                      leaderboard[i].tied = true;
                    }
                  }

                  if (i > 0) {
                    if (leaderboard[i - 1].score === leaderboard[i].score) {
                      leaderboard[i].rank = i - currentTies;
                      currentTies += 1;
                    } else {
                      currentTies = 0;
                      leaderboard[i].rank = i + 1;
                    }
                  } else {
                    leaderboard[i].rank = 1;
                  }
                }
              }

              for (let i = 0; i < leaderboard.length; i++) {
                if (round[id].format !== "stableford") {
                  if (leaderboard[i].score > 0) {
                    // leaderboard[i].score = `+${leaderboard[i].score}`;
                  }
                }
                if (leaderboard[i].tied === true) {
                  // console.log(i);
                  leaderboard[i].rank = `T${leaderboard[i].rank}`;
                } else {
                  leaderboard[i].rank = i + 1;
                }
              }

              round[id].leaderboard = leaderboard;
              mep2[id] = round[id];

              if (lbIDRef.current === id) {
                setTableData(leaderboard);
              }
            }
          }
          if (round.twoClub) {
            mep2["twoClub"] = {
              leaderboard: round.twoClubBoard,
              divisionName: "Two Club",
            };
          }
          setDivMap(mep2);
          // console.log(leaderboardID);
          // if (leaderboardID === "overall" || leaderboardID === "") {
          if (lbIDRef.current === "overall") {
            setTableData(leaderboard);
          }
          // }

          setOG(leaderboard);
          setLoading(false);
        });
    } else if (format === "team-net-strokeplay") {
      db.collection("rounds")
        .doc(roundID)
        .onSnapshot(async (snapshot) => {
          if (!snapshot.exists) {
            return;
          }
          const round = snapshot.data();
          // Get league standings
          const { leaderboard } = round;
          setScratchMode(round.scratchMode ?? false);

          setTableData(leaderboard);
          setOG(leaderboard);
          setLoading(false);
        });
    } else {
      db.collection("rounds")
        .doc(roundID)
        .collection("scores")
        .orderBy("currentScore", "asc")
        .onSnapshot(async (snapshot) => {
          // alert('got here')
          if (!snapshot.exists) {
            return;
          }
          const playerArray = [];
          const scores = snapshot.docs;
          // Get league standings

          let currentTies = 0;

          // Net Strokeplay Format (default)
          if (format === "net-strokeplay" || format === "medal") {
            for (let i = 0; i < scores.length; i++) {
              const playerID = scores[i].id;
              const playerDoc = await db
                .collection("users")
                .doc(playerID)
                .get();
              const player = playerDoc.data();
              const initial = player.firstName.charAt(0);
              const playerName = `${initial} ${player.lastName}`;
              const { profileImage } = player;
              const score = scores[i].data();
              const { holesCompleted, eventPoints, handicap } = score;
              let { currentScore } = score;
              if (currentScore === 0) {
                currentScore = "E";
              }
              if (currentScore > 0) {
                currentScore = `+${currentScore}`;
              }
              let ranking = i + 1;

              if (i > 0) {
                if (scores[i - 1].data().currentScore === score.currentScore) {
                  ranking = i - currentTies;
                  currentTies += 1;
                } else {
                  currentTies = 0;
                }
              }

              let leagueRank = 0;
              // League rankings
              // for (let j = 0; j < leagueDocs.docs.length; j++) {
              //     if (leagueDocs.docs[j].id === playerID) {
              //         leagueRank = j + 1;
              //     }
              // }
              playerArray.push({
                playerID,
                currentScore,
                holesCompleted,
                ranking,
                playerName,
                leagueRank,
                eventPoints,
                handicap,
                profileImage,
                score,
              });
            }
          }

          // Team Net Strokeplay
          if (format === "team-net-strokeplay") {
            const playerScoreMap = {};

            for (let j = 0; j < scores.length; j++) {
              const scoreDoc = scores[j];
              const playerID = scoreDoc.id;
              const scoreData = scoreDoc.data();
              const { currentScore, holesCompleted } = scoreData;
              playerScoreMap[playerID] = {
                currentScore,
                holesCompleted,
              };
            }

            // const teamArray = [];

            for (let i = 0; i < teams.length; i++) {
              const team = teams[i];
              const player1ID = team.playerIDs[0];
              const player2ID = team.playerIDs[1];
              const player1Doc = await db
                .collection("users")
                .doc(player1ID)
                .get();
              const player2Doc = await db
                .collection("users")
                .doc(player2ID)
                .get();
              const player1 = player1Doc.data();
              const player2 = player2Doc.data();
              const hcpOne = player1.handicap;
              const hcpTwo = player2.handicap;
              const initial1 = player1.firstName.charAt(0);
              const player1Name = `${initial1} ${player1.lastName}`;
              const initial2 = player2.firstName.charAt(0);
              const player2Name = `${initial2} ${player2.lastName}`;
              const combinedName = `${player1Name} / ${player2Name}`;
              const currentScore1 = playerScoreMap[player1ID].currentScore;
              const currentScore2 = playerScoreMap[player2ID].currentScore;
              let holesCompleted = 0;
              if (
                playerScoreMap[player1ID].holesCompleted <
                playerScoreMap[player2ID].holesCompleted
              ) {
                holesCompleted = playerScoreMap[player2ID].holesCompleted;
              } else {
                holesCompleted = playerScoreMap[player1ID].holesCompleted;
              }

              let combinedScore = currentScore1 + currentScore2;
              const combinedScoreVal = currentScore1 + currentScore2;

              if (combinedScore === 0) {
                combinedScore = "E";
              }
              if (combinedScore > 0) {
                // combinedScore = `+${combinedScore}`;
              }

              playerArray.push({
                playerIDs: team.playerIDs,
                currentScore: combinedScore,
                combinedScoreVal,
                playerName: combinedName,
                holesCompleted,
                nameOne: player1Name,
                nameTwo: player2Name,
                hcpOne,
                hcpTwo,
              });
            }
            playerArray.sort(compareCombined);

            for (let k = 0; k < playerArray.length; k++) {
              playerArray[k].ranking = k + 1;
            }
          }

          setTableData(playerArray);
          setOG(playerArray);
          setLoading(false);
        });
    }
  }

  async function loadPlayerRound(roundData, img, name, playerID = "") {
    setLoading(true);
    if (eventDetails.roundIDs.length === 1 || eventDetails.eventID === "") {
      const roundDoc = await db
        .collection("rounds")
        .doc(roundID)
        .collection("scores")
        .doc(playerID)
        .get();
      const round = roundDoc.data();

      let totalPutts = 0;

      for (let i = 0; i < round.holes.length; i++) {
        totalPutts += round.holes[i].putts;
      }

      setRoundToShow(
        <PlayerRound
          playerName={name}
          eventName={eventDetails.eventName}
          leagueName={eventDetails.leagueName}
          // courseName={name}
          dateString={eventDetails.eventDate}
          goBack={() => setShowRound(false)}
          handicap={round.handicap}
          imageLink={img}
          scoreCard={round.holes}
          playerID={playerID}
          roundID={roundID}
          frontNinePar={round.frontNinePar}
          frontNineGross={round.frontNineGross}
          frontNineNet={round.frontNineNet}
          backNineNet={round.backNineNet}
          backNineGross={round.backNineGross}
          backNinePar={round.backNinePar}
          coursePar={round.coursePar}
          totalGrossStrokes={round.totalGrossStrokes}
          totalNetStrokes={round.totalNetStrokes}
          frontNineFIR={round.frontNineFIR}
          backNineFIR={round.backNineFIR}
          frontNineGIR={round.frontNineGIR}
          backNineGIR={round.backNineGIR}
          backNinePutts={round.backNinePutts}
          frontNinePutts={round.frontNinePutts}
          totalPutts={totalPutts}
          liveEvent={live}
          stats={round.stats}
          grossStats={round.grossStats}
        />
      );
      setShowRound(true);
    } else {
      const arr = [];
      const { roundIDs } = eventDetails;
      let activeRound = 0;

      for (let i = 0; i < roundIDs.length; i++) {
        const roundID = roundIDs[i];
        if (selRound === roundID) {
          activeRound = i;
        }
        const roundDoc = await db
          .collection("rounds")
          .doc(roundID)
          .collection("scores")
          .doc(playerID)
          .get();
        const round = roundDoc.data();
        arr.push(round);
      }
      if (!playerID.includes("guest")) {
        const playerDoc = await db.collection("users").doc(playerID).get();
        const player = playerDoc.data();

        setRoundToShow(
          <MultiplePlayerRound
            goBack={() => setShowRound(false)}
            eventDetails={eventDetails}
            rounds={arr}
            playerDetails={player ?? {}}
            activeRound={activeRound}
          />
        );
        setShowRound(true);
      } else {
        setRoundToShow(
          <MultiplePlayerRound
            goBack={() => setShowRound(false)}
            eventDetails={eventDetails}
            rounds={arr}
            playerDetails={{}}
            activeRound={activeRound}
          />
        );
        setShowRound(true);
      }
    }
    setLoading(false);
  }

  async function generateTeamCSV() {
    setShowCSV(false);
    setDLCSV(false);
    setLoading(true);

    const labArr = teamCSVVals.map((val) => val.label);

    const nameMap = {};

    for (let i = 0; i < og.length; i++) {
      const row = og[i];
      const { name, playerID } = row;
      nameMap[playerID] = name;
    }

    labArr.unshift("Name");

    const arr = [labArr];

    for (let i = 0; i < board.length; i++) {
      const team = board[i];
      const { playerIDs } = team;
      const tarr = [];
      if (team.teamName) {
        tarr.push(team.teamName);
      } else {
        let name = "";
        for (let j = 0; j < playerIDs.length; j++) {
          const playerID = playerIDs[j];
          const plname = nameMap[playerID];
          if (j === playerIDs.length - 1) {
            name += plname;
          } else {
            name += `${plname} / `;
          }
        }
        tarr.push(name);
      }
      tarr.push(team.thru);
      tarr.push(team.score);

      arr.push(tarr);
    }

    setCsv(arr);
    setUpdate((c) => c + 1);
    setDLCSV(true);

    setLoading(false);
  }
  async function generateTeamDivCSV() {
    setShowCSV(false);
    setDLCSV(false);
    setLoading(true);

    const labArr = teamCSVVals.map((val) => val.label);

    const nameMap = {};

    for (let i = 0; i < og.length; i++) {
      const row = og[i];
      const { name, playerID } = row;
      nameMap[playerID] = name;
    }

    labArr.unshift("Name");

    const arr = [labArr];

    const board = divMap[selCSVBoard].leaderboard;

    for (let i = 0; i < board.length; i++) {
      const team = board[i];
      const { playerIDs } = team;
      const tarr = [];
      if (team.teamName) {
        tarr.push(team.teamName);
      } else {
        let name = "";
        for (let j = 0; j < playerIDs.length; j++) {
          const playerID = playerIDs[j];
          const plname = nameMap[playerID];
          if (j === playerIDs.length - 1) {
            name += plname;
          } else {
            name += `${plname} / `;
          }
        }
        tarr.push(name);
      }
      tarr.push(team.thru);
      tarr.push(team.score);

      arr.push(tarr);
    }

    setCsv(arr);
    setUpdate((c) => c + 1);
    setDLCSV(true);

    setLoading(false);
  }

  async function rdByRdCSV() {
    setLoading(true);
    setDLCSV(false);
    setShowCSV(false);
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();

    const { roundIDs, activeRoundID, playerIDs } = event;

    const playerMap = {};

    const activeRound = await db.collection("rounds").doc(activeRoundID).get();
    const arData = activeRound.data();
    const { leaderboard } = arData;

    const nameMap = {};

    for (let i = 0; i < leaderboard.length; i++) {
      const player = leaderboard[i];
      const { name, playerID } = player;
      nameMap[playerID] = name;
    }

    for (let i = 0; i < playerIDs.length; i++) {
      const playerID = playerIDs[i];
      playerMap[playerID] = {
        playerID,
        rounds: [],
        total: 0,
        name: nameMap[playerID],
      };
    }

    const roundIndex = roundIDs.indexOf(activeRoundID);

    const rds = [];
    const headerArr = ["pos", "name"];

    for (let i = 0; i < roundIndex + 1; i++) {
      headerArr.push(`Round ${i + 1}`);
      const roundID = roundIDs[i];
      rds.push("");
      const scoreDocs = await db
        .collection("rounds")
        .doc(roundID)
        .collection("scores")
        .get();

      for (let j = 0; j < scoreDocs.docs.length; j++) {
        const scoreDoc = scoreDocs.docs[j];
        const playerID = scoreDoc.id;
        const scoreData = scoreDoc.data();
        const { format } = scoreData;
        if (scoreData.wd) {
          playerMap[playerID].rounds.push("WD");
          playerMap[playerID].wd = true;
        } else {
          if (format === "medal") {
            playerMap[playerID].rounds.push(scoreData.totalGrossStrokes);
          } else if (format === "stableford") {
            playerMap[playerID].rounds.push(scoreData.totalNetStrokes);
          } else {
            const startingPoints = scoreData.startingPoints ?? 0;
            playerMap[playerID].rounds.push(
              scoreData.totalPoints - startingPoints
            );
          }
        }
      }
    }

    headerArr.push("total");

    const tempArr = Object.values(playerMap);

    for (let i = 0; i < tempArr.length; i++) {
      const player = tempArr[i];
      const { rounds } = player;
      if (player.wd) {
        tempArr[i].total = "WD";
      } else {
        const total = rounds.reduce((a, b) => a + b);
        tempArr[i].total = total;
      }
    }

    tempArr.sort((a, b) => a.total - b.total);
    tempArr.sort((a, b) => {
      const awd = a.wd ?? false;
      const bwd = b.wd ?? false;
      return awd - bwd;
    });
    let currentTies = 0;

    for (let k = 0; k < tempArr.length; k++) {
      let rank = k + 1;
      if (k !== 0) {
        if (tempArr[k].total === tempArr[k - 1].total) {
          currentTies += 1;
          rank -= currentTies;
        } else {
          currentTies = 0;
        }
      }
      tempArr[k].rank = rank;
    }

    const csvArr = [headerArr];

    for (let i = 0; i < tempArr.length; i++) {
      const player = tempArr[i];
      const { rank, name, rounds, total } = player;
      const row = [rank, name];
      for (let j = 0; j < rounds.length; j++) {
        row.push(rounds[j]);
      }
      row.push(total);
      csvArr.push(row);
    }
    setCsv(csvArr);
    setDLCSV(true);
    setLoading(false);
  }

  async function generateCSV() {
    // alert("TRIGGERED");
    setShowCSV(false);
    setDLCSV(false);
    setLoading(true);
    const roundIDs = rounds.map((r) => r.value);
    const cumArr = ["totalPoints", "currentScore", "currentGrossScore"];
    const ri = roundIDs.indexOf(selCSVRd);
    // const arr = [["name", "handicap", "thru", "gross", "net", "points"]];
    const valArr = selCSVVals.map((val) => val.value);
    const filtered = csvVals.filter((val) => valArr.includes(val.value));
    const labArr = filtered.map((val) => val.label);
    labArr.unshift("name");

    let boardToUse = [...og];

    if (selCSVBoard !== "individuals") {
      boardToUse = divMap[selCSVBoard].leaderboard;
    }

    const arr = [labArr];

    const mep = {};

    for (let i = 0; i < boardToUse.length; i++) {
      const row = boardToUse[i];
      mep[row.playerID] = row.name;
    }

    if (multCSVType === "cumulative") {
      const playerMap = {};

      for (let i = 0; i < boardToUse.length; i++) {
        const row = boardToUse[i];
        const { playerID, name, handicap } = row;
        playerMap[playerID] = {
          name,
          // handicap,
          // thru: 0,
          // gross: 0,
          // net: 0,
          // points: 0,
        };
        for (let j = 0; j < filtered.length; j++) {
          const val = filtered[j].value;
          playerMap[playerID][val] = 0;
        }
      }

      for (let i = 0; i < ri + 1; i++) {
        const roundID = rounds[i].value;
        const scores = await db
          .collection("rounds")
          .doc(roundID)
          .collection("scores")
          .get();

        for (let j = 0; j < scores.docs.length; j++) {
          const scoreDoc = scores.docs[j];
          const playerID = scoreDoc.id;

          if (!mep[playerID]) {
            continue;
          }
          const scoreData = scoreDoc.data();
          const {
            totalPutts,
            totalGrossStrokes,
            totalNetStrokes,
            holesCompleted,
          } = scoreData;
          if (holesCompleted === 0) {
            continue;
          }

          const startingPoints = scoreData.startingPoints ?? 0;
          const totalPoints = scoreData.totalPoints ?? 0;

          // playerMap[playerID].gross += totalGrossStrokes;
          // playerMap[playerID].net += totalNetStrokes;
          // playerMap[playerID].thru += holesCompleted;
          // arr.push({
          //   name,
          //   totalFIR,
          //   totalGIR,
          //   totalPutts,
          //   totalGrossStrokes,
          //   totalNetStrokes,
          //   playerID,
          //   holesCompleted,
          //   points: totalPoints ?? 0,
          // });

          for (let x = 0; x < filtered.length; x++) {
            const val = filtered[x].value;
            if (cumArr.includes(val)) {
              playerMap[playerID][val] = scoreData[val];
            } else {
              playerMap[playerID][val] += scoreData[val];
            }
          }
        }
      }

      for (const [key, value] of Object.entries(playerMap)) {
        const parr = [];
        for (const [k2, v2] of Object.entries(value)) {
          parr.push(v2);
        }
        arr.push(parr);
      }
      setCsv(arr);
      setUpdate((c) => c + 1);
      setDLCSV(true);

      setLoading(false);
    } else {
      const docs = await db
        .collection("rounds")
        .doc(selCSVRd)
        .collection("scores")
        .orderBy("currentGrossScore")
        .get();

      for (let i = 0; i < docs.docs.length; i++) {
        const row = docs.docs[i].data();
        const playerID = docs.docs[i].id;

        if (mep[playerID] === undefined) {
          continue;
        }

        const tp = [mep[playerID]];

        for (let j = 0; j < filtered.length; j++) {
          const val = filtered[j].value;
          if (val === "totalPoints") {
            const startingPoints = row.startingPoints ?? 0;
            const pts = row.totalPoints - startingPoints;
            tp.push(pts);
          } else {
            tp.push(row[val]);
          }
        }

        arr.push(tp);

        // arr.push([
        //   mep[playerID],
        //   row.handicap,
        //   row.holesCompleted,
        //   row.totalGrossStrokes,
        //   row.totalNetStrokes,
        //   row.startingPoints
        //     ? row.totalPoints - row.startingPoints
        //     : row.totalPoints,
        // ]);
      }
      setCsv(arr);
      setUpdate((c) => c + 1);
      setDLCSV(true);

      setLoading(false);

      setUpdate((c) => c + 1);
    }

    // setDLCSV(false);
  }

  return (
    <div
      className={eventFormat === "cup" ? "leaderboard mt-20" : "leaderboard "}
    >
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog onClose={() => setShowCSV(false)} open={showCSV}>
        <div className="lb-pdfs">
          <PageHeader
            close={() => setShowCSV(false)}
            text="Select Export Type"
            showClose
          />
          {rounds.length > 1 && false && (
            <div className="leaderboard-rounds">
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={selCSVRd}
                options={rounds}
                onChange={(e) => {
                  setSelCSVRd(e.value);
                }}
              />
            </div>
          )}
          <div className="ec-box">
            {rounds.length > 1 && selCSVRd !== "rounds" && (
              <div className="input-group">
                <div className="ig-header">
                  <p>Cumulative vs Round</p>
                </div>
                <div className="selector-group">
                  <button
                    onClick={() => setMultCSVType("cumulative")}
                    className={
                      multCSVType === "cumulative"
                        ? "sg-active sg-right"
                        : "sg-right"
                    }
                  >
                    Cumulative
                  </button>
                  <button
                    onClick={() => setMultCSVType("round")}
                    className={
                      multCSVType === "round"
                        ? "sg-middle sg-active"
                        : "sg-middle"
                    }
                  >
                    Round
                  </button>
                </div>
              </div>
            )}

            {false && (
              <div className="input-group ">
                <div className="ig-header">
                  <p>Leaderboard Type</p>
                </div>
                <div className="selector-group">
                  <button
                    onClick={() => setSelCSVBoard("individuals")}
                    className={
                      selCSVBoard === "individuals"
                        ? "sg-active sg-right"
                        : "sg-right"
                    }
                  >
                    Individuals
                  </button>
                  <button
                    onClick={() => setSelCSVBoard("teams")}
                    className={
                      selCSVBoard === "teams"
                        ? "sg-middle sg-active"
                        : "sg-middle"
                    }
                  >
                    Teams
                  </button>
                </div>
              </div>
            )}
            <div className="input-group">
              <p>Select Leaderboard</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={selCSVBoard}
                options={divisionOptions}
                onChange={(e) => {
                  console.log(e);
                  setSelCSVBoard(e.value);
                }}
              />
            </div>
          </div>

          {csvOpts.length > 0 && (
            <div className="pdf-opts pd-20">
              {csvOpts.map((opt, i) => {
                console.log(opt);
                return (
                  <PlayerItem
                    key={i}
                    name={opt.label}
                    hideIcons
                    hideImage
                    hideHCP
                    noLink
                    showBg={selCSVRd === opt.value}
                    showProfile={() => {
                      // console.log(opt);
                      setSelCSVRd(opt.value);
                      setUpdate((c) => c + 1);
                    }}
                  />
                );
              })}
              {rounds.length > 1 && selCSVBoard !== "teams" && (
                <PlayerItem
                  name={"Rounds"}
                  hideIcons
                  hideImage
                  hideHCP
                  noLink
                  showBg={selCSVRd === "rounds"}
                  showProfile={() => {
                    setSelCSVRd("rounds");
                    setUpdate((c) => c + 1);
                  }}
                />
              )}
            </div>
          )}
          {selCSVBoard !== "teams" &&
            selCSVRd !== "rounds" &&
            !teamBoardArray.includes(selCSVBoard) && (
              <div className="pl-20 input-group">
                <p>Select Values</p>
              </div>
            )}
          <div className="eve-rds csv-vals">
            {selCSVBoard !== "teams" &&
              selCSVRd !== "rounds" &&
              !teamBoardArray.includes(selCSVBoard) &&
              csvVals.map((value, i) => {
                const selOpts = selCSVVals.map((val) => val.value);

                return (
                  <div key={`${i}csv`} className="eve-rd-item">
                    <Checkbox
                      checked={selOpts.includes(value.value)}
                      onChange={(e) => {
                        setSelCSVVals((c) => {
                          if (selOpts.includes(value.value)) {
                            const filt = c.filter(
                              (val) => val.value !== value.value
                            );
                            return filt;
                          } else {
                            c.push(value);
                            return c;
                          }
                        });
                        setUpdate((c) => c + 1);
                      }}
                    />
                    <p>{value.label}</p>
                  </div>
                );
              })}
            {false &&
              selCSVBoard === "teams" &&
              teamCSVVals.map((value, i) => {
                const selOpts = selTeamCSVVals.map((val) => val.value);

                return (
                  <div className="eve-rd-item">
                    <Checkbox
                      checked={selOpts.includes(value.value)}
                      onChange={(e) => {
                        setSelTeamCSVVals((c) => {
                          if (selOpts.includes(value.value)) {
                            const filt = c.filter(
                              (val) => val.value !== value.value
                            );
                            return filt;
                          } else {
                            c.push(value);
                            return c;
                          }
                        });
                        setUpdate((c) => c + 1);
                      }}
                    />
                    <p>{value.label}</p>
                  </div>
                );
              })}
          </div>
          <div className="flex-center mt-20 mb-20">
            <button
              onClick={
                teamBoardArray.includes(selCSVBoard)
                  ? generateTeamDivCSV
                  : selCSVBoard === "teams"
                  ? generateTeamCSV
                  : selCSVRd === "rounds"
                  ? rdByRdCSV
                  : generateCSV
              }
              className="default-button"
            >
              Generate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showPDF} onClose={() => setShowPDF(false)}>
        <div className="lb-pdfs">
          <PageHeader
            close={() => setShowPDF(false)}
            text="Select Export Type"
            showClose
          />

          {rounds.length > 0 &&
            selPDF.value !== "rounds" &&
            selPDF.value !== "team-summary" && (
              <div className="ec-box">
                <div className="input-group">
                  <p>Select Round</p>
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    value={selPDFRd}
                    options={rounds}
                    onChange={(e) => {
                      setSelPDFRd(e.value);
                    }}
                  />
                </div>
                <div className="input-group  mt-10">
                  <div className="ig-header">
                    <p>Cumulative vs Round</p>
                  </div>
                  <div className="selector-group">
                    <button
                      onClick={() => setMultPDFType("cumulative")}
                      className={
                        multPDFType === "cumulative"
                          ? "sg-active sg-right"
                          : "sg-right"
                      }
                    >
                      Cumulative
                    </button>
                    <button
                      onClick={() => setMultPDFType("round")}
                      className={
                        multPDFType === "round"
                          ? "sg-middle sg-active"
                          : "sg-middle"
                      }
                    >
                      Round
                    </button>
                  </div>
                </div>
              </div>
            )}

          {!["rounds", "team-summary"].includes(selPDF.value) && (
            <div className="ec-box">
              <div className="input-group ">
                <p>Countout Type</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  value={coType}
                  options={options}
                  onChange={(e) => {
                    setCoType(e.value);
                  }}
                />
              </div>
              <div className="input-group ">
                <p>Countout Type (2)</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  value={coType2}
                  options={options}
                  onChange={(e) => {
                    setCoType2(e.value);
                  }}
                />
              </div>
            </div>
          )}
          {![
            "prizes",
            "playerStats",
            "eventStats",
            "summary",
            "jackpot",
            "rounds",
            "team-summary",
          ].includes(selPDF.value) &&
            !teamBoardArray.includes(selPDF.value) && (
              <>
                <div className="pl-20 input-group">
                  <p>Select Headers</p>
                </div>
                <div className="eve-rds csv-vals">
                  {pdfHeaderOpts.map((value, i) => {
                    if (
                      selPDF.value === "teams" &&
                      value.value === "gross" &&
                      roundFormat !== "medal"
                    ) {
                      return null;
                    }
                    if (
                      selPDF.value === "teams" &&
                      value.value !== "gross" &&
                      roundFormat === "medal"
                    ) {
                      return null;
                    }

                    return (
                      <div key={`${i}pdfhead`} className="eve-rd-item">
                        <Checkbox
                          checked={pdfHeaders.includes(value.value)}
                          onChange={(e) => {
                            setPDFHeaders((c) => {
                              if (pdfHeaders.includes(value.value)) {
                                const filt = c.filter(
                                  (val) => val !== value.value
                                );
                                return filt;
                              } else {
                                c.push(value.value);
                                return c;
                              }
                            });
                            setUpdate((c) => c + 1);
                          }}
                        />
                        <p>{value.label}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

          {selPDF.value === "playerStats" && (
            <>
              <div className="pl-20 input-group">
                <p>Select Values</p>
              </div>
              <div className="eve-rds csv-vals">
                {pdfVals.map((value, i) => {
                  const selOpts = selPDFVals.map((val) => val.value);

                  return (
                    <div key={`${i}pdfVal`} className="eve-rd-item">
                      <Checkbox
                        checked={selOpts.includes(value.value)}
                        onChange={(e) => {
                          setSelPDFVals((c) => {
                            if (selOpts.includes(value.value)) {
                              const filt = c.filter(
                                (val) => val.value !== value.value
                              );
                              return filt;
                            } else {
                              c.push(value);
                              return c;
                            }
                          });
                          setUpdate((c) => c + 1);
                        }}
                      />
                      <p>{value.label}</p>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <div className="pdf-opts pd-20">
            {pdfs.map((item, index) => (
              <PlayerItem
                key={item.value}
                noLink
                hideHCP
                hideIcons
                hideImage
                name={item.label}
                showProfile={() => setSelPDF(item)}
                showBg={item.value === selPDF.value}
              />
            ))}
          </div>

          <div className="flex-center mb-20 mt-20">
            <button
              onClick={getPDF}
              disabled={selPDF === ""}
              className="default-button"
            >
              Generate
            </button>
          </div>
        </div>
      </Dialog>

      {bd && <BackdropLoader />}
      {loading ? (
        <div className="flex-center loading">
          <Oval
            color="#1e7a69"
            secondaryColor="#ffffff"
            height={40}
            width={40}
          />
        </div>
      ) : (
        <>
          {showModal && (
            <ScoreModal
              closeModal={() => setShowModal(false)}
              eventID={eventID}
              playerID={scoreModalID}
            />
          )}

          {/* <div className="lb-export-box">
          <div className="lb-export" ref={ref}>
            <p>Dingaling</p>
          </div>
          </div> */}

          {dlCSV && (
            <CSVDownload
              filename={`${eventName} Export`}
              data={csv}
              target="_blank"
            />
          )}

          <div
            style={{ width: showRound ? "100%" : "95%" }}
            className="leaderboard-players"
          >
            {userDetails &&
              userDetails.accountType !== "scorer" &&
              showExports &&
              cupID === "" && (
                <div className="lbp-buts">
                  <button
                    className="default-button mb-20"
                    onClick={() => setShowPDF(true)}
                  >
                    Generate pdf
                  </button>
                  <button
                    onClick={() => {
                      // if (rounds.length > 1) {
                      setShowCSV(true);
                      // }
                    }}
                    className="default-button mb-20"
                  >
                    Generate CSV
                  </button>
                </div>
              )}

            {!showRound && (
              <>
                {rounds.length > 1 && (
                  <div className="leaderboard-rounds">
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      value={selRound}
                      options={rounds}
                      onChange={(e) => {
                        setDLCSV(false);
                        if (eventFormat === "cup") {
                          setLoading(true);
                          loadCupScores(e.value);
                        } else {
                          // alert("boob");
                          setLoading(true);
                          getPlayerScores(e.value);
                        }
                        setSelRound(e);
                        setSelRoundID(e.value);
                      }}
                    />
                  </div>
                )}
                {(enableDivisions || enableTeams) && (
                  <div className="leaderboard-rounds">
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      value={leaderboardID}
                      options={divisionOptions}
                      onChange={(e) => {
                        setLeaderboardID(e.value);

                        if (e.value === "teams") {
                        } else {
                          if (e.value === "overall") {
                            setEventFormat(ogFormat);
                            setTableData(og);
                            return;
                          }
                          if (e.value === "twoClub") {
                            console.log(divMap);
                            setEventFormat("twoClub");
                            setTableData(divMap[e.value].leaderboard);
                            return;
                          }
                          const divFormat = divMap[e.value].format;
                          console.log(divFormat);
                          if (divFormat) {
                            setEventFormat(divMap[e.value].format);
                          } else {
                            setEventFormat(ogFormat);
                          }

                          const lb = divMap[e.value].leaderboard;
                          lb.sort((a, b) => {
                            const adq = a.dq ?? false;
                            const bdq = b.dq ?? false;
                            return adq - bdq;
                          });

                          // console.log(lb);

                          // if (!divFormat) {
                          //   if (ogFormat.includes("stable")) {
                          //     lb.sort((a, b) => b.score - a.score);
                          //   }
                          // }

                          setTableData(lb);

                          // console.log(divMap[e.value].format);

                          // setSelBoard(divMap[e.value].leaderboard);
                          const fmt = divMap[e.value].format;
                          if (fmt) {
                            setSelFormat(fmt);
                          } else {
                            setSelFormat(ogFormat);
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {showRound && roundToShow}

            {eventFormat === "twoClub" && (
              <>
                {tableData.map((player, index) => {
                  return (
                    <PlayerScore
                      key={player.playerID}
                      blackScore
                      hideImage
                      score={player.score}
                      rank={player.rank}
                      format="stable"
                      thru={player.thru}
                      hcp={player.handicap}
                      showRound={() => {}}
                      name={player.name}
                    />
                  );
                })}
              </>
            )}

            {leaderboardID === "teams" ? (
              <>
                {/* <div className="tl-header">
                  <PlayerScore
                    hideImage
                    name="Name"
                    rank={"Pos"}
                    header
                    // format={"medal"}
                    thru={"Thru"}
                    score={format.includes("stable") ? "Points" : "Score"}
                  />
                </div> */}

                <div className="team-board-box">
                  {!showRound &&
                    board.map((team, index) => (
                      <PlayerScore
                        key={index}
                        // showBadge
                        team
                        hideHcp
                        bogey={format === "bogey" || format === "bogey++"}
                        showRound={() => {
                          setRoundToShow(
                            <TeamScorecard
                              eventName={eventName}
                              goBack={() => setShowRound(false)}
                              group={team}
                              format={teamType}
                              roundID={selRoundID}
                              roundFormat={ogFormat}
                            />
                          );
                          setShowRound(true);
                        }}
                        showBg={team.playerIDs.includes(currentUser.uid)}
                        score={
                          roundFormat.includes("stableford") ||
                          format === "bogey" ||
                          format === "bogey++"
                            ? team.score
                            : team.score > 0
                            ? `${team.score}`
                            : team.score
                        }
                        rank={index + 1}
                        format={roundFormat}
                        // hideThru
                        thru={team.thru}
                        hideImage
                        name={team.name}
                        points={format.includes("stable")}
                      />
                    ))}
                </div>
              </>
            ) : (
              <>
                {!showRound && (
                  <div
                    ref={ref}
                    style={{ width: "100%" }}
                    className="flex-center-col"
                  >
                    {(eventFormat === "net-strokeplay" ||
                      eventFormat === "medal") &&
                      !showRound &&
                      tableData.map((player, index) => {
                        if (limit) {
                          if (index > 7) {
                            return null;
                          }
                        }
                        // console.log(player);
                        return (
                          <PlayerScore
                            submitted={submittedIDs.includes(player.playerID)}
                            dq={player.dq}
                            wd={player.wd}
                            key={player.playerID}
                            hideImage
                            showBg={player.playerID === currentUser.uid}
                            notFirst={
                              eventDetails && eventDetails.roundIndex > 0
                            }
                            format={eventFormat}
                            today={player.dq || player.wd ? "-" : player.today}
                            img={player.profileImage}
                            rank={player.dq || player.wd ? "-" : player.rank}
                            hcp={scratchMode ? 0 : player.handicap}
                            name={player.name}
                            animate
                            thru={player.dq || player.wd ? "-" : player.thru}
                            score={player.score}
                            roundID={roundID}
                            eventID={eventID}
                            playerID={player.playerID}
                            pointsScored={
                              pointsArray.length > 0
                                ? pointsArray[index].points
                                : 0
                            }
                            showRound={() => {
                              loadPlayerRound(
                                player,
                                player.profileImage,
                                player.playerName,
                                player.playerID
                              );
                            }}
                          />
                        );
                      })}
                  </div>
                )}
                {eventFormat === "team-net-strokeplay" &&
                  !showRound &&
                  tableData.map((player, index) => (
                    <PlayerScore
                      key={`${index}group`}
                      rank={player.rank}
                      name={player.name}
                      thru={player.thru}
                      format={eventFormat}
                      score={
                        player.score > 0 ? `+${player.score}` : player.score
                      }
                      hideImage
                      roundID={roundID}
                      eventID={eventID}
                    />
                  ))}
                {eventFormat.includes("stable") &&
                  !showRound &&
                  tableData.map((player, index) => {
                    let teamDiv = false;
                    if (player.teamID !== undefined) {
                      teamDiv = true;
                    }
                    // console.log(player);
                    return (
                      <PlayerScore
                        submitted={submittedIDs.includes(player.playerID)}
                        dq={player.dq}
                        wd={player.wd}
                        key={player.playerID ?? player.teamID}
                        hideImage
                        points={true}
                        img={player.profileImage}
                        rank={
                          player.dq || player.wd
                            ? "-"
                            : player.rank
                            ? player.rank
                            : index + 1
                        }
                        today={player.dq || player.wd ? "-" : player.today}
                        hideHcp={teamDiv}
                        hcp={scratchMode ? 0 : player.handicap}
                        name={player.name}
                        animate
                        format={eventFormat}
                        thru={player.dq || player.wd ? "-" : player.thru}
                        score={player.score}
                        roundID={roundID}
                        eventID={eventID}
                        playerID={player.playerID}
                        pointsScored={
                          pointsArray.length > 0 ? pointsArray[index].points : 0
                        }
                        showRound={() => {
                          if (teamDiv) {
                            setRoundToShow(
                              <TeamScorecard
                                eventName={eventName}
                                goBack={() => setShowRound(false)}
                                group={player}
                                format={teamType}
                                roundID={roundID}
                                roundFormat={ogFormat}
                              />
                            );
                            setShowRound(true);
                          } else {
                            loadPlayerRound(
                              player.score,
                              player.profileImage,
                              player.name,
                              player.playerID
                            );
                          }
                        }}
                      />
                    );
                  })}
                {["average-ball", "worst-ball", "best-ball"].includes(
                  eventFormat
                ) &&
                  tableData.map((group, index) => {
                    return (
                      <PlayerScore
                        key={`${index}group`}
                        hideImage
                        showRound={() => {}}
                        hideHcp={true}
                        img=""
                        name={group.groupName}
                        score={group.score}
                        thru={group.thru}
                        animate
                        roundID={roundID}
                        format={"average-ball"}
                        rank={index + 1}
                      />
                    );
                  })}
                {eventFormat === "match-play" && !matchData.nassau && (
                  <>
                    <div className="mp-container">
                      <MatchplayScore
                        nameOne={matchData.players[0].playerName}
                        nameTwo={matchData.players[1].playerName}
                        idOne={matchData.players[0].playerID}
                        idTwo={matchData.players[1].playerID}
                        score={matchData.leaderboard.by}
                        leader={matchData.leaderboard.leader}
                        holes={matchData.leaderboard.thru}
                      />
                    </div>
                    <div className="mp-slider-box">
                      <MatchplayHoleSlider
                        teamOneColor={teamOneColor}
                        teamTwoColor={teamTwoColor}
                        idOne={matchData.players[0].playerID}
                        idTwo={matchData.players[1].playerID}
                        holes={matchData.holes}
                      />
                    </div>
                  </>
                )}
                {eventFormat === "match-play" && matchData.nassau && (
                  <div className="mp-container">
                    <NassauScore
                      nameOne={matchData.players[0].playerName}
                      nameTwo={matchData.players[1].playerName}
                      idOne={matchData.players[0].playerID}
                      idTwo={matchData.players[1].playerID}
                      score={matchData.leaderboard.by}
                      leader={matchData.leaderboard.leader}
                      holes={matchData.leaderboard.thru}
                      leaderboard={matchData.leaderboard}
                      players={matchData.players}
                      roundID={roundID}
                    />
                  </div>
                )}
                {(eventFormat === "bb-match-play" ||
                  eventFormat === "wb-match-play" ||
                  eventFormat === "ab-match-play") &&
                  matchData.nassau && (
                    <div className="mp-container">
                      <NassauScore
                        nameOne={matchData.teamNames.teamOne}
                        nameTwo={matchData.teamNames.teamTwo}
                        score={matchData.leaderboard.score ?? 0}
                        leader={matchData.leaderboard.leader}
                        holes={matchData.leaderboard.thru}
                        idOne="teamOne"
                        idTwo={"teamTwo"}
                        leaderboard={matchData.leaderboard}
                        players={matchData.players}
                        roundID={roundID}
                      />
                    </div>
                  )}
                {(eventFormat === "bb-match-play" ||
                  eventFormat === "wb-match-play" ||
                  eventFormat === "ab-match-play") &&
                  !matchData.nassau && (
                    <div className="mp-container">
                      <MatchplayScore
                        nameOne={matchData.teamNames.teamOne}
                        nameTwo={matchData.teamNames.teamTwo}
                        score={matchData.leaderboard.score ?? 0}
                        leader={matchData.leaderboard.leader}
                        holes={matchData.leaderboard.thru}
                        idOne="teamOne"
                        idTwo={"teamTwo"}
                      />
                    </div>
                  )}
                {eventFormat.includes("skins") &&
                  !loading &&
                  skinsIDs.map((playerID, index) => {
                    const player = matchData.players[playerID];

                    return (
                      <PlayerScore
                        key={player.playerID}
                        img={player.profileImage}
                        playerID={playerID}
                        hcp={scratchMode ? 0 : player.handicap}
                        rank={index + 1}
                        score={matchData.leaderboard[playerID]}
                        thru={matchData.leaderboard.thru ?? 0}
                        name={player.name}
                        roundID={roundID}
                        skins={true}
                      />
                    );
                  })}
                {cupID !== "" && (
                  // <div>
                  <CupScore
                    pointsToWin={pointsToWin}
                    teams={matchData.teams}
                    matches={matchData.matches}
                    cupScore={matchData.cupScore}
                    score={matchData.score}
                    format={matchData.format}
                    nassau={matchData.nassau}
                    roundID={selRoundID}
                    teamOneColor={teamOneColor}
                    teamTwoColor={teamTwoColor}
                  />
                  // </div>
                )}
              </>
            )}
          </div>

          {/* <table>
            <thead>
            <tr>
            <th>#</th>
            <th>Player</th>
            <th>Score</th>
            <th>Thru</th>
            <th>Cup</th>
            {live && <th>Proj</th>}
            {!live && <th>Pts</th>}
            </tr>
            </thead>
            <tbody>
            {tableData.map(row => {
                return (
                    <tr onClick={() => {
                        setScoreModalID(row.playerID)
                        setShowModal(true)
                    }}>
                    <td>{row.ranking}</td>
                    <td className='start-td'>{row.playerName}</td>
                    <td className={row.currentScore > 0 ? 'over-par' : row.currentScore < 0 ? 'under-par' : ''}>{row.currentScore}</td>
                    <td>{row.holesCompleted}</td>
                    <td>{row.leagueRank}</td>
                    <td>{row.eventPoints}</td>
                    </tr>
                    )
                })}
                </tbody>
            </table> */}
        </>
      )}
    </div>
  );
}

export default Leaderboard;
