import React, { useState, useEffect } from "react";

// Icons

// Components
import LogItem from "../display/LeagueLogs/LogItem/LogItem";
import LogHeader from "../display/LeagueLogs/LogItem/LogHeader";
import RadioGroupTwo from "../Buttons/RadioGroup/RadioGroupTwo";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CSVLink } from "react-csv";

function TeamLeagueLog({
  pointsTable = [],
  teamTable = [],
  bbs = false,
  leagueTitle = "",
}) {
  const [active, setActive] = useState("teams");
  const [csv, setCSV] = useState([]);
  const [singlesCSV, setSinglesCSV] = useState([]);

  useEffect(() => {
    if (bbs) {
      console.log(teamTable);
      const teamArr = [
        [
          "team",
          "played",
          "points",
          "wins",
          "stablefordFor",
          "stablefordAgainst",
        ],
      ];
      for (let i = 0; i < teamTable.length; i++) {
        const team = teamTable[i];
        teamArr.push([
          team.teamName,
          team.played,
          team.leaguePoints,
          team.won,
          team.stableFor,
          team.stableAg,
        ]);
      }
      console.log(teamArr);
      setCSV(teamArr);
    } else {
      const teamArr = [["team", "played", "points", "wins", "ties"]];

      for (let i = 0; i < teamTable.length; i++) {
        const team = teamTable[i];
        teamArr.push([
          team.teamName,
          team.played,
          team.leaguePoints,
          team.won,
          team.tied,
        ]);
      }
      setCSV(teamArr);
    }
    const sigArr = [["name", "played", "points", "wins"]];

    for (let i = 0; i < pointsTable.length; i++) {
      const player = pointsTable[i];
      sigArr.push([player.name, player.played, player.points, player.won]);
    }
    setSinglesCSV(sigArr);
  }, []);

  // console.log(teamTable);
  return (
    <div className="team-league-log">
      <div className="sub-header">
        <h3 className="">Points Table</h3>
      </div>
      <div className="flex-ac">
        <RadioGroupTwo
          buttons={[
            { text: "Teams", value: "teams" },
            { text: "Singles", value: "singles" },
          ]}
          active={active}
          setActive={setActive}
        />
        {csv.length > 0 && (
          <CSVLink
            filename={
              active === "teams"
                ? `${leagueTitle} Logs ${new Date().toLocaleDateString()}`
                : `${leagueTitle} Singles ${new Date().toLocaleDateString()}`
            }
            data={active === "teams" ? csv : singlesCSV}
            className="pill-button small-but ml-20"
          >
            <p>Export CSV</p>
          </CSVLink>
        )}
      </div>

      {active === "singles" && (
        <div className="pd-20">
          <LogHeader />
          {pointsTable.map((row, index) => {
            return (
              <LogItem
                key={index}
                name={row.name}
                played={row.played}
                wins={row.won}
                rank={index + 1}
                round={false}
                ave={row.played === 0 ? 0 : Math.round(row.points / row.played)}
                points={row.points}
              />
            );
          })}
        </div>
      )}
      {active === "teams" && (
        <div className="pd-20 tl-accord tlt-pad">
          <Accordion>
            <AccordionSummary>
              <LogHeader showTies bbs={bbs} />
            </AccordionSummary>
          </Accordion>
          {teamTable.map((row, index) => {
            const results = row.results ?? [];
            const lps = row.leaguePoints ?? 0;

            return (
              <Accordion key={row.teamID}>
                <AccordionSummary
                  className="tl-row-acc"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <LogItem
                    ties={bbs ? row.won : row.tied}
                    noLink
                    rank={index + 1}
                    key={index}
                    name={row.teamName}
                    played={row.played}
                    wins={
                      bbs && isNaN(row.stableFor)
                        ? 0
                        : bbs
                        ? row.stableFor
                        : row.won
                    }
                    points={row.leaguePoints ?? 0}
                    ave={
                      bbs && isNaN(row.stableAg)
                        ? 0
                        : bbs
                        ? row.stableAg
                        : row.played === 0
                        ? 0
                        : Math.round((lps / row.played) * 100) / 100
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div className="tra-results">
                    {results.map((res, j) => {
                      const { score, oppoScore, oppo } = res;

                      const type =
                        score === oppoScore
                          ? "Drew"
                          : score > oppoScore
                          ? "Beat"
                          : "Lost";
                      const fillter =
                        score === oppoScore
                          ? "with"
                          : score > oppoScore
                          ? ""
                          : "to";

                      return (
                        <div key={j} className="tra-res">
                          <h3>{res.dateString}</h3>
                          <p>
                            {type} {fillter} {oppo}{" "}
                            <span>
                              {score} : {oppoScore}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default TeamLeagueLog;
