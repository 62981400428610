import React from "react";

function HomeLandingItem({ item = {} }) {
  return (
    <div onClick={item.action} className="hl-item">
      <div className="hli-ctt">
        <div className="hli-top">
          <h2>{item.title}</h2>
          <h5>{item.subtitle}</h5>
        </div>

        {item.text}
        <div className="hl-ib">{item.ico}</div>
      </div>
    </div>
  );
}

export default HomeLandingItem;
