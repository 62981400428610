import React, { useState, useEffect, useRef } from "react";
import { admin, db } from "../../../../../firebase";
import { successIco } from "../../../HelperFunctions";

// Icons

// Components
import CustomDrop from "../../../../CustomDrop/CustomDrop";
import { Dialog } from "@mui/material";
import PageHeader from "../../../../display/Rows/PageHeader";
import CPLoader from "../../../CPLoader";
import Pill from "../../../../Buttons/Pill";
import { toast } from "react-toastify";
import FairwayFinderRow from "./FairwayFinderRow";

function FairwayFinderInput({
  close = () => {},
  clubID = "",
  playerID = "",
  scenarioID = "",
  reload = () => {},
}) {
  // Admin
  const attProp = {
    club: "",
    points: 0,
    result: "",
  };

  // State
  const [pastAttemptOpts, setPastAttemptOpts] = useState([]);
  const [attempts, setAttempts] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Functions
  function sortTotalPoints(arr = []) {
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      total += arr[i].points;
    }
    setTotalPoints(total);
    setUpdate((c) => c + 1);
  }

  function addRow() {
    setAttempts((c) => {
      c.push(attProp);
      return c;
    });
    setUpdate((c) => c + 1);
  }

  async function saveDrillAttempt() {
    setLoading(true);

    for (let i = 0; i < attempts.length; i++) {
      const att = attempts[i];
      const { club, distance } = att;
      if (club === "" || distance === 0) {
        setError("Please ensure all fields are filled in");
        return setLoading(false);
      }
    }

    await db.collection("clubs").doc(clubID).collection("playerScenarios").add({
      playerID,
      dateAdded: new Date(),
      attempts,
      scenarioID,
      totalPoints,
    });

    const toAdd = {
      playerID,
      dateAdded: new Date(),
      attempts,
      totalPoints,
    };

    await db
      .collection("admin")
      .doc("academy")
      .collection("scenarios")
      .doc(scenarioID)
      .update({
        attempts: admin.firestore.FieldValue.arrayUnion(toAdd),
      });

    toast.success("Scenario successfully saved", {
      icon: successIco,
    });
    reload();
    setLoading(false);
    close();
  }

  return (
    <Dialog open onClose={close} className="accuracy-drill">
      <div className="acc-drill-ctt">
        <PageHeader text="Fairway Finder" showClose close={close} />
        {loading ? (
          <div className="cpl-box">
            <CPLoader />
          </div>
        ) : (
          <>
            <div className="flex-ac pl-20">
              {" "}
              <Pill
                text={"Add Shot"}
                onClick={addRow}
                disabled={attempts.length === 10}
              />
            </div>

            <div className="input-group pl-20">
              <p>Total Points</p>
              <h3>{totalPoints}</h3>
            </div>

            <div className="adc-shots">
              {attempts.map((att, i) => {
                return (
                  <FairwayFinderRow
                    row={att}
                    key={`attt${i}`}
                    removeRow={() => {
                      setAttempts((c) => {
                        c.splice(i, 1);
                        console.log(i, c);
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                    index={i + 1}
                    saveRow={(e) => {
                      setAttempts((c) => {
                        c[i] = e;
                        sortTotalPoints(c);
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                  />
                );
              })}
            </div>

            <div className="error-message">
              <p>{error}</p>
            </div>

            <div className="mt-20 pl-20 pb-20">
              <button
                onClick={saveDrillAttempt}
                disabled={attempts.length === 0}
                className="default-button"
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default FairwayFinderInput;
