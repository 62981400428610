import { CheckCircle } from "@mui/icons-material";
import { db, storage, admin } from "../../firebase";

export function makeID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export const monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function generateShiftedMonthArray(yearStart) {
  const sliced1 = monthArray.slice(yearStart - 1, 12);
  const sliced2 = monthArray.slice(0, yearStart - 1);
  const comb = [...sliced1, ...sliced2];
  return comb;
}

export const monthValues = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export function generateYearsArray() {
  const currentYear = new Date().getFullYear();
  const yearsArray = [];

  for (let year = 2023; year <= currentYear; year++) {
    yearsArray.push({
      label: `${year}`,
      value: `${year}`,
    });
  }

  return yearsArray;
}

export function shuffleArray(array) {
  const shuffledArray = array.slice(); // Create a copy of the original array

  // Fisher-Yates shuffle algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
}

export function yearsSince2022() {
  const dt = new Date();
  const yr = dt.getFullYear();
  const diff = yr - 2022;
  const arr = [];
  for (let i = 0; i < diff + 1; i++) {
    arr.push(2022 + i);
  }
  return arr;
}

export function areDatesOnSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function extractIdFromUrl(url, keyword = "") {
  const regex = new RegExp(`\\/academies\\/([^/]+)\\/${keyword}`);
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}

export function extractAcademyIdFromUrl(url, knownId) {
  // console.log(url);
  const regex = new RegExp(`\\/academies\\/([^/]+)\\/clients\\/${knownId}`);
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}

export function isDateBetween(dateA, dateB, dateC) {
  return dateA >= dateB && dateA <= dateC;
}

const url =
  "/academies/m2xFsTMklidBuxDaqSxh/clients/cOu7x0kME5NWjzmjD6z8LD053r82";
const knownId = "m2xFsTMklidBuxDaqSxh"; // Replace this with your known id
const extractedId = extractIdFromUrl(url, knownId);

export const coachAttDefaults = [
  "Juniors",
  "Seniors",
  "Low Handicaps",
  "High Handicaps",
  "Short Game",
  "Long Game",
  "Strength & Conditioning",
  "Nutrition",
];

export const typeDefaults = [
  {
    title: "Default Type",
    colour: "#000000",
    rate: "Default",
    id: "default",
  },
];

export const genderOpts = ["Male", "Female", "Other"];

export const rateDefaults = [
  {
    15: 250,
    30: 400,
    45: 550,
    60: 750,
    75: 850,
    90: 900,
    120: 1400,
    name: "Default",
    rateID: "default",
  },
];

export function getRandomHex(length) {
  var result = "#";
  var characters = "0123456789ABCDEF";
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function isImage(file) {
  const validImageTypes = [
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/bmp",
    "image/webp",
  ]; // Add more image MIME types if necessary
  return validImageTypes.includes(file.type);
}

export function isVideo(file) {
  const validVideoTypes = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/quicktime",
  ]; // Add more video MIME types if necessary
  return validVideoTypes.includes(file.type);
}

export function uploadAcademyImage({ img, acID = "", sel = false }) {
  return new Promise((res, rej) => {
    const ref = storage.ref(`${acID}/${img.name}`);

    const task = ref.put(img);

    task.on(
      "state_changed",
      (progSnap) => {},
      (err) => {},
      () => {
        task.snapshot.ref.getDownloadURL().then((url) => {
          if (sel) {
            db.collection("academies")
              .doc(acID)
              .update({
                imageLinks: admin.firestore.FieldValue.arrayUnion(url),
              });
          } else {
            db.collection("academies")
              .doc(acID)
              .update({
                imageLinks: admin.firestore.FieldValue.arrayUnion(url),
                imageLink: url,
              });
          }
        });
      }
    );
  });
}
export function uploadUserImage({ img, userID = "", coach = false }) {
  return new Promise((res, rej) => {
    const ref = storage.ref(`${userID}/${img.name}`);

    const task = ref.put(img);

    task.on(
      "state_changed",
      (progSnap) => {},
      (err) => {},
      () => {
        task.snapshot.ref.getDownloadURL().then((url) => {
          db.collection("users").doc(userID).update({
            profileImage: url,
          });
          if (coach) {
            db.collection("coaches").doc(userID).update({
              profileImage: url,
            });
          }
        });
      }
    );
  });
}
export function uploadUserVid({ vid, userID = "", coach = false }) {
  return new Promise((res, rej) => {
    const ref = storage.ref(`${userID}/${vid.name}`);

    const task = ref.put(vid);

    task.on(
      "state_changed",
      (progSnap) => {},
      (err) => {},
      () => {
        task.snapshot.ref.getDownloadURL().then((url) => {
          db.collection("users").doc(userID).update({
            profileVideo: url,
          });
          if (coach) {
            db.collection("coaches").doc(userID).update({
              profileVideo: url,
            });
          }
        });
      }
    );
  });
}

export function checkTimeOverlapsAndUpdate(objects) {
  // Loop through each object in the array
  for (let i = 0; i < objects.length; i++) {
    const currentObject = objects[i];
    const currentStart = currentObject.dateObj;
    const currentEnd = currentObject.sessionEnd;
    let count = 0;
    let position = 1;

    // Compare the current object with the rest of the objects
    for (let j = 0; j < objects.length; j++) {
      if (i !== j) {
        const otherObject = objects[j];
        const otherStart = otherObject.dateObj;
        const otherEnd = otherObject.sessionEnd;

        // Check for overlaps between the current and other objects
        if (
          (currentStart >= otherStart && currentStart < otherEnd) ||
          (currentEnd > otherStart && currentEnd <= otherEnd) ||
          (currentStart <= otherStart && currentEnd >= otherEnd)
        ) {
          count++; // Increment the overlap count
          if (otherStart <= currentStart && i > j) {
            position++; // Increment the position if other object starts earlier
          }
        }
      }
    }

    // Update the current object with overlaps and position information
    currentObject.overlaps = count;
    currentObject.position = position;
  }

  return objects;
}

export const successIco = (
  <>
    <CheckCircle className="toast-success-ico" />
  </>
);

export const drillCategories = [
  "General",
  "Short Game",
  "Approach",
  "Long Game",
  "Putting",
  "Physical",
  "Situational",
  "Other",
];

export const errorDefault =
  "Sorry, we encountered a technical difficulty there - please try again.";

export const drillDifficulties = ["Easy", "Medium", "Hard"];

export function sortDrillsByDifficulty(arr = [], dir = "up") {
  arr.sort((a, b) => {
    const aVal = drillDifficulties.indexOf(a.difficulty);
    const bVal = drillDifficulties.indexOf(b.difficulty);
    // console.log(aVal, bVal);
    if (dir === "up") {
      return aVal - bVal;
    } else {
      return bVal - aVal;
    }
  });

  return arr;
}

export function sortTiedArray(arr = []) {
  if (arr.length === 0) {
    return arr;
  }
  if (arr.length === 1) {
    arr[0].rank = 1;
    return arr;
  }
  let currentTies = 0;
  arr[0].rank = 1;
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      if (arr[1].score === arr[0].score) {
        arr[0].tied = true;
      }
    }
    if (i > 0) {
      if (arr[i].score === arr[i - 1].score) {
        arr[i].tied = true;
      }
    }
    if (i > 0 && i < arr.length - 1) {
      if (arr[i].score === arr[i + 1].score) {
        arr[i].tied = true;
      }
    }

    if (i > 0) {
      if (arr[i - 1].score === arr[i].score) {
        arr[i].rank = i - currentTies;
        currentTies += 1;
      } else {
        currentTies = 0;
        arr[i].rank = i + 1;
      }
    }
  }
  return arr;
}

export const clubArray = [
  "L-Wedge",
  "S-Wedge",
  "G-Wedge",
  "P-Wedge",
  "9 Iron",
  "8 Iron",
  "7 Iron",
  "6 Iron",
  "5 Iron",
  "4 Iron",
  "3 Iron",
  "2 Iron",
  "1 Iron",
  "Hybrid",
  "7-Wood",
  "5-Wood",
  "3-Wood",
  "Driver",
];
