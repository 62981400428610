import React from "react";

import { Dialog } from "@mui/material";
import PageHeader from "../../../../display/Rows/PageHeader";

function ShotRandomizerDisplay({ details = {}, close = () => {} }) {
  return (
    <Dialog open onClose={close}>
      <div className="amd-dia-ctt">
        <PageHeader showClose close={close} text="Attempt Details" />

        <div className="adc-ctt pd-20">
          <div className="sub-header">
            <h3>Details</h3>
          </div>
          <div className="details-item mt-10">
            <h3>Date:</h3>
            <p>{details.dateString}</p>
          </div>
          <div className="details-item mt-10">
            <h3>Total Rating:</h3>
            <p>{details.totalRating}</p>
          </div>

          <div className="sub-header mb-10 mt-20">
            <h3>Attempts</h3>
          </div>
          <div className="adc-atts">
            <div className="amd-dia-row adr-header">
              <div className="adr-ite">
                <p>Distance</p>
              </div>
              <div className="adr-ite">
                <p>Trajectory</p>
              </div>
              <div className="adr-ite">
                <p>Shape</p>
              </div>
              <div className="adr-ite">
                <p>Rating</p>
              </div>
            </div>
            {details.attempts.map((att, i) => {
              return (
                <div key={`attdis${i}`} className="amd-dia-row">
                  <div className="adr-ite">
                    <p>{att.distance}</p>
                  </div>
                  <div className="adr-ite">
                    <p>{att.trajectory}</p>
                  </div>
                  <div className="adr-ite">
                    <p>{att.shape}</p>
                  </div>
                  <div className="adr-ite adr-perc">
                    <p>{att.rating}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ShotRandomizerDisplay;
