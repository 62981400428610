import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";

// Components
import BackRow from "../../display/Rows/BackRow";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { Dialog } from "@mui/material";
import PageHeader from "../../display/Rows/PageHeader";
import PTSPlayer from "../EventCreator/PTSPlayer";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import { round } from "lodash";
import { Checkbox } from "@mui/material";

function LiveTeeChange({
  hide = () => {},
  defaultPlayers = [],
  eventID = "",
  roundID = "",
  defaultPlayer = {},
  progress = () => {},
  portal = false,
  cupID = "",
  cup = false,
  rounds = [],
  teamEvt = false,
}) {
  const { userSettings } = useAuth();
  useEffect(() => {
    if (userSettings) {
      setYards(userSettings.units === "yards");
    }
  }, [userSettings]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [players, setPlayers] = useState([]);
  const [coursePar, setCoursePar] = useState({});

  const [upd, setUpd] = useState(0);
  function updat() {
    setUpd((c) => c + 1);
  }

  const [selRds, setSelRds] = useState([]);

  const [selected, setSelected] = useState({
    name: "",
    handicap: "",
    playerID: "",
    tee: "",
    profileImage: "",
  });

  const [showEdit, setShowEdit] = useState(false);

  const [initialTee, setInitialTee] = useState(defaultPlayer.tee);

  const [searchInput, setSearchInput] = useState("");
  const [yards, setYards] = useState(false);

  const [teamAllowance, setTeamAllowance] = useState(100);
  const [sgAllowance, setSGAllowance] = useState(100);

  const [teeArray, setTeeArray] = useState([]);
  const [femTeeArray, setFemTeeArray] = useState([]);
  const [lengths, setLengths] = useState({});
  const [courseName, setCourseName] = useState("");
  const [update, setUpdate] = useState(0);
  const [finished, setFinished] = useState(false);

  const [slope, setSlope] = useState({});
  const [ratings, setRatings] = useState();

  const [femSlope, setFemSlope] = useState({});
  const [femRatings, setFemRatings] = useState();

  const [courseID, setCourseID] = useState("");

  const [sr, setSR] = useState(false);

  const [first, setFirst] = useState(false);

  useEffect(() => {
    loadDetails();
    setSelRds(rounds);
  }, []);

  useEffect(() => {
    if (defaultPlayer.playerID) {
      setSelected(defaultPlayer);
      loadPlayerHandicap({ player: defaultPlayer });
      setShowEdit(true);
    }
  }, []);

  async function loadDetails() {
    // const eventDoc = await db.collection("events").doc(eventID).get();
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const { courseID, teeMap, guests, genderMap } = round;
    const sr = round.sr || teamEvt;
    setCourseID(courseID);
    const leaderboard = round.leaderboard ?? [];
    if (defaultPlayer.playerID && defaultPlayer.playerID.includes("guest")) {
      const player = defaultPlayer;
      for (let i = 0; i < guests.length; i++) {
        if (guests[i].playerID === player.playerID) {
          player.originalHandicap = guests[i].handicap;
        }
      }
      setSelected(defaultPlayer);
    }

    if (round.eventID && !teamEvt) {
      const evtDoc = await db.collection("events").doc(round.eventID).get();
      const evtData = evtDoc.data();
      const sga = evtData.singlesAllowance ?? 100;
      setSGAllowance(sga);
    }

    setSR(sr);
    setTeamAllowance(round.teamAllowance ?? 100);

    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const { slope, ratings, teeArray, length, par } = course;
    const femTees = [];

    const femRatings = course.femRatings ?? {};
    const femSlope = course.femSlope ?? {};

    for (const [key, value] of Object.entries(femRatings)) {
      femTees.push(key);
    }
    const filt = teeArray.filter((r) => femTees.includes(r));
    console.log(filt);

    setFemTeeArray(filt);
    setCoursePar(par);
    // console.log(courseID);

    for (let i = 0; i < leaderboard.length; i++) {
      const id = leaderboard[i].playerID;
      leaderboard[i].teamHandicap = Math.round(
        (leaderboard[i].handicap * round.teamAllowance) / 100
      );
      // console.log(teeMap[id]);
      leaderboard[i].tee = teeMap[id] ?? teeArray[0];
      leaderboard[i].gender = teeMap[id] ?? "male";
    }

    setPlayers(leaderboard);
    setLengths(length);
    setSlope(slope);
    setRatings(ratings);
    setFemSlope(femSlope);
    setFemRatings(femRatings);
    setTeeArray(teeArray);
    setLoading(false);
  }

  async function loadPlayerHandicap({ player }) {
    console.log(player);
    setLoading(true);
    if (player.playerID.includes("guest")) {
      if (first === false) {
        const roundDoc = await db.collection("rounds").doc(roundID).get();
        const round = roundDoc.data();
        const genderMap = round.genderMap ?? {};
        player.gender = genderMap[player.playerID];
        console.log(genderMap[player.playerID]);
        setFirst(true);
      }
      return;
    }
    const userDoc = await db.collection("users").doc(player.playerID).get();
    const user = userDoc.data();
    console.log(first);
    if (first === false) {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const genderMap = round.genderMap ?? {};
      player.gender = genderMap[player.playerID];
      if (player.tee === undefined) {
        if (round.teeMap) {
          player.tee = round.teeMap[player.playerID];
        } else {
          player.tee = "white";
        }
      }
      // console.log(genderMap[player.playerID]);
      setFirst(true);
    }

    const { handicap } = user;
    const gender = user.gender ?? "male";
    player.originalHandicap = handicap;
    if (user.gender) {
      player.gender = user.gender;
    }
    console.log(player);

    setSelected(player);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      // if (!teamEvt) {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const teeMap = round.teeMap ?? {};
      teeMap[selected.playerID] = selected.tee;
      await roundDoc.ref.update({
        teeMap,
      });
      // }
      if (cup) {
        await db.collection("triggers").add({
          dateAdded: new Date(),
          type: "cup-hcp-change",
          roundID,
          roundIDs: rounds,
          cupID,
          playerID: selected.playerID,
          handicap:
            typeof selected.handicap === "string"
              ? parseInt(selected.handicap)
              : selected.handicap,
        });
      } else if (teamEvt) {
        await db.collection("triggers").add({
          dateAdded: new Date(),
          // tee: selected.tee,
          type: "team-evt-hcp",
          roundID,
          eventID,
          courseID,
          playerID: selected.playerID,
          roundIDs: rounds,
          handicap:
            typeof selected.handicap === "string"
              ? parseInt(selected.handicap)
              : selected.handicap,
        });
      } else {
        await db.collection("triggers").add({
          dateAdded: new Date(),
          type: "event-hcp-change",
          roundID,
          eventID,
          roundIDs: rounds,
          playerID: selected.playerID,
          handicap:
            typeof selected.handicap === "string"
              ? parseInt(selected.handicap)
              : selected.handicap,
        });
      }

      if (portal) {
        progress();
      }
      setTimeout(() => {
        setFinished(true);
        setLoading(false);
      }, 1500);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again"
      );
      setLoading(false);
    }
  }

  return (
    <Dialog open onClose={hide} className="ltc-dia">
      <div className="live-tee-change ehel">
        {portal && <BackRow action={hide} />}
        {loading && <BackdropLoader />}

        <PageHeader
          text={finished ? "Successfully Changed" : "Event Tee Edit"}
          showClose
          close={hide}
        />
        {finished ? (
          <>
            <div className="im-msg mb-40">
              <p>
                {selected.name}'s tee has been changed. Changes will reflect in
                a few moments
              </p>
            </div>
          </>
        ) : showEdit ? (
          <>
            <div className="ltc-player pd-20">
              <PTSPlayer
                player={selected}
                tees={selected.gender === "female" ? femTeeArray : teeArray}
                lengths={lengths}
                yards={yards}
                selected={selected.tee}
                setSelected={(e) => {
                  if (selected.gender === "female") {
                    setSelected((current) => {
                      let ps = femSlope[e];
                      if (typeof ps === "string") {
                        ps = parseFloat(ps);
                      }
                      let pr = femRatings[e];
                      if (typeof pr === "string") {
                        pr = parseFloat(pr);
                      }
                      let newhcp =
                        (current.originalHandicap * ps) / 113 +
                        pr -
                        coursePar[e];
                      console.log(newhcp);
                      newhcp = Math.round(newhcp);
                      const newteamhcp = Math.round(
                        (newhcp * teamAllowance) / 100
                      );
                      const sgaHcp = (newhcp * sgAllowance) / 100;
                      console.log(sgaHcp);
                      current.handicap = Math.round(sgaHcp);
                      current.teamHandicap = newteamhcp;
                      current.tee = e;
                      return current;
                    });
                  } else {
                    setSelected((current) => {
                      let ps = slope[e];
                      if (typeof ps === "string") {
                        ps = parseFloat(ps);
                      }
                      let pr = ratings[e];
                      if (typeof pr === "string") {
                        pr = parseFloat(pr);
                      }
                      let newhcp =
                        (current.originalHandicap * ps) / 113 +
                        pr -
                        coursePar[e];
                      console.log(sgAllowance);
                      console.log(newhcp);
                      newhcp = Math.round(newhcp);
                      const newteamhcp = Math.round(
                        (newhcp * teamAllowance) / 100
                      );
                      const sgaHcp = (newhcp * sgAllowance) / 100;
                      console.log(sgaHcp);

                      current.handicap = Math.round(sgaHcp);
                      current.teamHandicap = newteamhcp;
                      current.tee = e;
                      return current;
                    });
                  }

                  setUpdate((current) => current + 1);
                }}
              />
            </div>
            {sr && (
              <>
                <div className="ltc-handicaps pd-20">
                  <p>
                    Handicap Index: <strong>{selected.originalHandicap}</strong>
                  </p>
                  <p>
                    {selected.name}'s <strong>new handicap</strong> will be{" "}
                    <strong>{selected.handicap}</strong>
                  </p>
                  {teamAllowance !== 100 && (
                    <>
                      <p>
                        {selected.name}'s <strong>new team handicap</strong>{" "}
                        will be <strong>{selected.teamHandicap}</strong>
                      </p>
                    </>
                  )}
                </div>
              </>
            )}

            {rounds.length > 1 &&
              rounds.indexOf(roundID) !== rounds.length - 1 && (
                <>
                  <div className="eve-rds">
                    {rounds.map((rd, i) => {
                      const idx = rounds.indexOf(roundID);

                      if (idx > i) {
                        return null;
                      }

                      return (
                        <div className="eve-rd-item" key={`${i}evrd`}>
                          <Checkbox
                            checked={selRds.includes(rd)}
                            onChange={(e) => {
                              setSelRds((c) => {
                                if (c.includes(rd)) {
                                  const filt = c.filter((id) => rd !== id);
                                  return filt;
                                } else {
                                  c.push(rd);
                                  return c;
                                }
                              });
                              updat();
                            }}
                          />
                          <p>Round {i + 1}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            {error !== "" && (
              <div className="error-message-row">
                <p>{error}</p>
              </div>
            )}
            {!portal && (
              <div className={"flex-center portal-prog-row mb-40"}>
                <button onClick={saveChanges} className="default-button">
                  Save Changes
                </button>
              </div>
            )}
            {portal && (
              <div className={"flex-center portal-prog-row mb-40"}>
                <button
                  onClick={() => {
                    if (selected.tee === initialTee) {
                      progress();
                    } else {
                      saveChanges();
                    }
                  }}
                  className="default-button"
                >
                  Score Input
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="ec-box">
              <div className="input-group si-search">
                <p>Search</p>
                <input
                  type="text"
                  placeholder="Search for player"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input"
                />
              </div>
            </div>
            <div className="ehe-players ehel-players pl-20 pb-20">
              {players.map((player) => {
                if (searchInput !== "") {
                  const sl = searchInput.toLowerCase();
                  if (!player.name.toLowerCase().includes(sl)) {
                    return null;
                  }
                }

                return (
                  <PlayerItem
                    key={player.playerID}
                    hideIcons
                    img={player.profileImage}
                    noLink
                    name={player.name}
                    hcp={player.handicap}
                    showProfile={() => {
                      console.log(player);
                      setSelected(player);
                      loadPlayerHandicap({ player });
                      setShowEdit(true);
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default LiveTeeChange;
