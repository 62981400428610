import React, { useEffect, useState } from "react";
import "./FixtureRow.css";
import FixtureRowHeader from "./FixtureRowHeader";
import FixtureRow from "./FixtureRow";

function FixtureList({ eventArr = [], leagueID = "", search = "" }) {
  const sgOpts = ["Past", "Future", "Active"];

  const [events, setEvents] = useState(eventArr);

  // const [search, setSearch] = useState("");
  const [currentSorter, setCurrentSorter] = useState("dateObject");
  const [dir, setDir] = useState("down");
  const [update, setUpdate] = useState(0);

  // useEffect(() => {
  //   // console.log(search);
  // }, [search]);

  function sortEvents({ sorter }) {
    if (sorter === currentSorter) {
      if (dir === "up") {
        if (sorter === "eventName") {
          setEvents((c) => {
            c.sort((a, b) => b[sorter].localeCompare(a[sorter]));
            return c;
          });
        } else {
          setEvents((c) => {
            c.sort((a, b) => b[sorter] - a[sorter]);
            return c;
          });
        }
        setCurrentSorter(sorter);

        setDir("down");
      } else {
        if (sorter === "eventName") {
          setEvents((c) => {
            c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
            return c;
          });
        } else {
          setEvents((c) => {
            c.sort((a, b) => a[sorter] - b[sorter]);
            return c;
          });
        }
        setCurrentSorter(sorter);

        setDir("up");
      }
    } else {
      if (sorter === "eventName") {
        setEvents((c) => {
          c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
          return c;
        });

        setCurrentSorter(sorter);
        setDir("up");
      } else {
        setEvents((c) => {
          c.sort((a, b) => a[sorter] - b[sorter]);
          return c;
        });

        setCurrentSorter(sorter);
        setDir("up");
      }
    }
    setUpdate((c) => c + 1);
  }

  useEffect(() => {
    setEvents(eventArr);
    setUpdate((c) => c + 1);
  }, [eventArr]);

  return (
    <div className="fplb-box mt-20 mb-40">
      <div className="fixture-list bs-subtle bss-white fp-list-box">
        <FixtureRowHeader
          currentSorter={currentSorter}
          dir={dir}
          sortByHeader={(e) => {
            sortEvents({ sorter: e });
          }}
        />

        {events.map((evt, i) => {
          // if (search !== "") {
          //   const sl = search.toLowerCase();
          //   const el = evt.eventName.toLowerCase();

          //   if (!el.includes(sl)) {
          //     return null;
          //   }
          // }

          // console.log(evt.roundIDs);

          // console.log(search);

          if (search !== "") {
            const { eventName } = evt;
            const sl = search.toLowerCase();
            const el = eventName.toLowerCase();
            if (!el.includes(sl)) {
              return null;
            }
          }

          if (leagueID !== "") {
            if (leagueID !== evt.leagueID) {
              return null;
            }
          }

          let courseString = evt.courseName;

          if (evt.courses !== undefined) {
            courseString = "";
            for (let j = 0; j < evt.courses.length; j++) {
              if (j === evt.courses.length - 1) {
                courseString += evt.courses[j].name ?? evt.courses[j].label;
              } else {
                courseString += `${
                  evt.courses[j].name ?? evt.courses[j].label
                }, `;
              }
            }
          }

          const rids = evt.roundIDs ?? [];

          return (
            <FixtureRow
              cup={evt.cup}
              team={evt.teamEvent}
              key={evt.eventID}
              imageLink={evt.imageLink}
              date={evt.dateString}
              players={evt.playerIDs.length}
              title={evt.eventName}
              courses={courseString}
              eventID={evt.eventID}
              winner={evt.winnerName}
              season={evt.season}
              league={evt.leagueName}
              active={rids.length > 0}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FixtureList;
