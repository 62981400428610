import React, { useEffect, useState } from "react";
import CustomDrop from "../../../../CustomDrop/CustomDrop";
import { DeleteForever, Save } from "@mui/icons-material";
import Switch from "react-ios-switch/lib/Switch";

function FairwayFinderRow({
  row = {},
  saveRow = () => {},
  removeRow = () => {},
  index = 1,
}) {
  const clubOpts = ["Driver", "Wood", "Iron"];
  const resultOpts = ["Hit", "Miss", "Hazard"];

  const [selClub, setSelClub] = useState(row.club ?? "");
  const [result, setResult] = useState(row.hit ?? false);
  const [points, setPoints] = useState(row.points ?? 0);

  useEffect(() => {
    let pts = 0;
    if (result === "Hazard") {
      pts = -1;
    } else if (result === "Hit") {
      if (selClub === "Driver") {
        pts = 3;
      } else if (selClub === "Wood") {
        pts = 2;
      } else {
        pts = 1;
      }
    } else {
      pts = 0;
    }

    setPoints(pts);
    const att = { club: selClub, result, points: pts };
    saveRow(att);
  }, [selClub, result]);

  return (
    <div className="acc-drill-row">
      <div className="adr-idx">
        <p>Shot {index}:</p>
      </div>
      <div className="flex-as">
        <div className="adr-item input-group">
          <p>Club</p>
          <CustomDrop
            value={selClub}
            onChange={(e) => setSelClub(e.value)}
            options={clubOpts}
          />
        </div>
        <div className="adr-item input-group">
          <p>Result</p>
          <CustomDrop
            value={result}
            onChange={(e) => setResult(e.value)}
            options={resultOpts}
          />
        </div>
        <div className="adr-item input-group">
          <p>Points</p>
          <h3>{points}</h3>
        </div>
        <div className="adr-del adr-ico">
          <DeleteForever
            onClick={removeRow}
            className="red-icon-bg icon-cursor"
          />
        </div>
      </div>
    </div>
  );
}

export default FairwayFinderRow;
