import React, { useState, useEffect } from "react";

// Icons

// Components
import Chart from "react-apexcharts";

import DrillAttempt from "./DrillAttempt";
import { Tabs, Tab } from "@mui/material";
import BackRow from "../../display/Rows/BackRow";
import DrillScoreInput from "./DrillScoreInput";

function DrillScores({
  drill = {},
  attempts = [],
  client = {},
  back = () => {},
  clientID = "",
  clubID = "",
  reload = () => {},
}) {
  const [selPage, setSelPage] = useState("scores");

  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const proptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    colors: ["#6ba390", "#232c2e", "#A9363F"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      type: "date",
      categories: labels,
    },
    yaxis: {
      title: {
        text: "Score",
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  useEffect(() => {
    console.log(clubID);
    sortSeries();
  }, [attempts]);

  async function sortSeries() {
    const srs = [
      {
        name: "Scores",
        data: [],
      },
    ];
    const lbls = [];

    console.log(attempts);

    for (let i = 0; i < attempts.length; i++) {
      const att = attempts[i];

      const date = new Date(att.date.toDate());
      attempts[i].dateObject = date;
    }

    attempts.sort((a, b) => b.dateObject - a.dateObject);

    for (let i = 0; i < attempts.length; i++) {
      const att = attempts[i];
      const score = parseInt(att.score);
      srs[0].data.push(score);
      const date = new Date(att.date.toDate());
      const dateString = date.toLocaleDateString("en-us", {
        dateStyle: "short",
      });
      lbls.push(dateString);
    }

    console.log(srs, lbls);
    setSeries(srs);
    setLabels(lbls);
  }

  return (
    <div className="drill-scores wbg">
      {/* <PageSubheader
        text={`${client}'s Scores - ${drill.title}`}
        showBack
        back={back}
      /> */}
      <BackRow action={back} />
      {show && toShow}

      <div className="dash-tab gre-meu ml-20 mt-20 mb-10">
        <Tabs
          className="dt-tabs shadow-md"
          value={selPage}
          onChange={(e, v) => setSelPage(v)}
        >
          <Tab value={"scores"} label="Scores" />
          <Tab value={"graph"} label="Graph" />
        </Tabs>
      </div>

      {selPage === "scores" && (
        <div className="dss-box">
          <div className="mt-20 bs-subtle bss-white pd-20">
            <div className="drill-attempt da-header flex flex-align-center">
              <div>
                <p>Date</p>
              </div>
              <div>
                <p>Drill</p>
              </div>
              <div>
                <p>Category</p>
              </div>
              <div>
                <p>Difficulty</p>
              </div>
              <div>
                <p>Score</p>
              </div>
              <div>
                <p></p>
              </div>
            </div>
            {attempts.map((att, i) => {
              return (
                <DrillAttempt
                  editAct={() => {
                    setToShow(
                      <DrillScoreInput
                        clientName={client}
                        clubID={clubID}
                        clientID={clientID}
                        close={() => setShow(false)}
                        defaults={att}
                        reload={reload}
                        edit
                        drill={att}
                      />
                    );
                    setShow(true);
                  }}
                  attempt={att}
                  key={i}
                />
              );
            })}
            {attempts.length === 0 && (
              <div className="zer-reqs mt-40 mb-40">
                <p>No attempts have been added to this client's profile yet.</p>
              </div>
            )}
          </div>
        </div>
      )}
      {selPage === "graph" && (
        <>
          <Chart
            options={proptions}
            series={series}
            type={"line"}
            height={320}
          />
        </>
      )}
    </div>
  );
}

export default DrillScores;
