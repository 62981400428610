import React, { useState, useEffect } from "react";
import "./Notifications.css";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

// Icons

// Components
import NotificationItem from "./NotificationItem";

function NotificationDrop({ notifications = [], hide = () => {} }) {
  const { currentUser } = useAuth();

  async function markAllRead() {
    const docs = await db
      .collection("users")
      .doc(currentUser.uid)
      .collection("portalNotifications")
      .where("read", "==", false)
      .get();

    docs.docs.forEach((doc) => doc.ref.update({ read: true }));
    hide();
  }

  return (
    <div className="notification-drop">
      <div className="np-header">
        <div className="nph-left">
          <h3 className="ne-header" style={{ marginLeft: "0px" }}>
            Notifications
          </h3>
        </div>
        <div className="nph-right">
          <p onClick={markAllRead} className="green-link">
            Mark all as read
          </p>
        </div>
      </div>
      <div className="nd-nots">
        {notifications.map((not) => (
          <NotificationItem
            key={`${not.notificationID}drops`}
            notification={not}
            hide={hide}
            userID={currentUser.uid}
          />
        ))}
        {notifications.length === 0 && (
          <div className="no-msg">
            <p>No items to display</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationDrop;
