import React from "react";

function BPCHeader() {
  return (
    <div className="bpc-row bpc-header">
      <div className="bpc-item">
        <h3>First Name</h3>
      </div>
      <div className="bpc-item">
        <h3>Last Name</h3>
      </div>
      <div className="bpc-item">
        <h3>Handicap</h3>
      </div>
      <div className="bpc-item">
        <h3>Email</h3>
      </div>
      <div className="bpc-item">
        <h3>Password</h3>
      </div>

      <div className="bpc-item">
        <h3>Date of Birth</h3>
      </div>
      <div className="bpc-item">
        <h3>Gender</h3>
      </div>
    </div>
  );
}

export default BPCHeader;
