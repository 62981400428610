import React, { useState, useEffect } from "react";
import "./PayDispute.css";
import { db } from "../../firebase";

import { Oval } from "react-loader-spinner";
import DisputeRowHeader from "./DisputeRowHeader";
import DisputeRow from "./DisputeRow";

function PayDisputes({ clubID = "" }) {
  // State
  const [loading, setLoading] = useState(true);
  const [disputes, setDisputes] = useState([]);
  const [toShow, setToShow] = useState();
  const [show, setShow] = useState(false);

  // UE
  useEffect(() => {
    loadDisputes();
  }, []);

  // Funcs
  async function loadDisputes() {
    console.log(clubID);
    const disputeDocs = await db
      .collection("payDisputes")
      .where("clubID", "==", clubID)
      .orderBy("date", "desc")
      .get();
    const arr = [];

    for (let i = 0; i < disputeDocs.docs.length; i++) {
      const disputeDoc = disputeDocs.docs[i];
      const dispute = disputeDoc.data();
      const dateObject = new Date(dispute.date.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "short",
      });
      dispute.dateObject = dateObject;
      dispute.dateString = dateString;
      arr.push(dispute);
    }
    setDisputes(arr);
    setLoading(false);
  }
  return (
    <div className="pay-disputes">
      <div className="sub-header">
        <h3>Payment Disputes</h3>
      </div>
      <div className="acg-box">
        <div className="ac-graph bss-white">
          {loading ? (
            <>
              {" "}
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : show ? (
            toShow
          ) : (
            <>
              <DisputeRowHeader />
              {disputes.map((dispute, i) => {
                return <DisputeRow row={dispute} key={`dis${i}`} />;
              })}
              {!loading && disputes.length === 0 && (
                <div>
                  {" "}
                  <div className="no-items mt-20">
                    <p>No items to be displayed</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PayDisputes;
