import React from "react";

function PayHistoryHeader() {
  return (
    <div className="pay-history-row phr-header">
      <div className="phr-item">
        <p>Date</p>
      </div>
      <div className="phr-item">
        <p>Amount</p>
      </div>
      <div className="phr-item">
        <p>Card Type</p>
      </div>
      <div className="phr-item">
        <p>Last 4</p>
      </div>
      <div className="phr-item">
        <p>Reference</p>
      </div>
      <div className="phr-item">
        <p>Status</p>
      </div>
      <div className="phr-item phr-opt">
        <p>Options</p>
      </div>
      <div className="phr-item phr-opt">
        <p>Dispute</p>
      </div>
    </div>
  );
}

export default PayHistoryHeader;
