import React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { ArrowDropUp } from "@mui/icons-material";
function FixtureRowHeader({
  currentSorter = "",
  dir = "",
  sortByHeader = () => {},
}) {
  return (
    <div className="fixture-row fixture-row-header row-header">
      <div className="fr-item fri-ava">
        <p>Image</p>
      </div>
      <div
        onClick={() => sortByHeader("dateObject")}
        className="fr-item fri-15    cursor-ico"
      >
        <p>Date</p>
        {currentSorter === "dateObject" && (
          <>
            {dir === "up" ? (
              <ArrowDropUp className="header-arrow" />
            ) : (
              <ArrowDropDown className="header-arrow" />
            )}
          </>
        )}
      </div>
      <div
        onClick={() => sortByHeader("eventName")}
        className="fr-item fri-2 cursor-ico"
      >
        <p>Event</p>
        {currentSorter === "eventName" && (
          <>
            {dir === "up" ? (
              <ArrowDropUp className="header-arrow" />
            ) : (
              <ArrowDropDown className="header-arrow" />
            )}
          </>
        )}
      </div>
      <div className="fr-item fri-15 fri-course">
        <p>Course(s)</p>
      </div>
      <div className="fr-item fri-1">
        <p>League</p>
      </div>
      <div className="fr-item fri-1">
        <p>Season</p>
      </div>
      {true && (
        <div className="fr-item  fri-1">
          <p>Winner</p>
        </div>
      )}
      <div className="fr-item  fri-link">
        <p>Players</p>
      </div>

      <div className="fri-item fri-link">
        <p>View</p>
      </div>
      <div className="fri-item fri-link">
        <p>Live</p>
      </div>
    </div>
  );
}

export default FixtureRowHeader;
