import React, { useState, useEffect } from "react";
import "./LeagueEdit.css";
import { useHelperClickTheme } from "../../../contexts/HelperContext";
// Admin
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { useClickTheme } from "../../../contexts/MenuContext";
import moment from "moment/moment";
import { useParams, useNavigate } from "react-router-dom";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Close from "@mui/icons-material/Close";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useCookies } from "react-cookie";
import { Tabs, Tab, Avatar } from "@mui/material";
import LeagueEvents from "./LeagueEvents";
import LeagueSeasons from "./LeagueSeasons";
import LeagueMembers from "../LeagueMembers";
import LogPage from "../../display/LeagueLogs/LogPage";
import Pill from "../../Buttons/Pill";
import BroadcastModal from "../../BroadcastModal/BroadcastModal";
import InviteModal from "../../Events/InviteModal/InviteModal";
import LeagueAddPlayer from "./LeagueAddPlayer";
import EventCreator from "../../Events/EventCreator/EventCreator";
import SeasonEditor from "../SeasonEditor/SeasonEditor";
import LeagueEditHome from "./LeagueEditHome";
import LeagueStats from "./LeagueStats";
import LeagueCreator from "../LeagueCreator/LeagueCreator";
import Dialog from "@mui/material/Dialog";
import ClubCourse from "../../../pages/Clubs/ClubCourse";
import LeagueSponsorManager from "./LeagueSponsorManager";
import LeaguePlayerUpload from "./LeaguePlayerUpload";
import EventTeamManager from "../../Events/EventCreator/EventTeamManager";
import EventTeamUpload from "../../Events/EventCreator/EventTeamUpload";
import MenuSwitcher from "../../MenuSwitcher/MenuSwitcher";
import { KeyboardArrowDown } from "@mui/icons-material";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import PageHeader from "../../display/Rows/PageHeader";
function LeagueEdit({
  // leagueID,
  // goBack = () => {},
  // clubID = "",
  defaultCourses = [],
}) {
  const navigate = useNavigate();
  function goBack() {
    // alert("back");
    navigate(-1);
  }

  const leagueID = useParams().id;

  // Admin
  const showClick = useClickTheme();
  const { currentUser, userDetails, portalSettings } = useAuth();
  const showHelperClick = useHelperClickTheme();

  // State
  const [loading, setLoading] = useState(true);
  const [clubID, setClubID] = useState("");
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingSeasons, setLoadingSeasons] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [showInvite, setShowInvite] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showBC, setShowBC] = useState(false);

  const [active, setActive] = useState("overview");

  const [table, setTable] = useState([]);
  const [details, setDetails] = useState();
  const [seasons, setSeasons] = useState([]);
  const [events, setEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [players, setPlayers] = useState([]);
  const [toInvite, setToInvite] = useState([]);
  const [toAdd, setToAdd] = useState([]);
  const [invited, setInvited] = useState([]);
  const [joinedArray, setJoinedArray] = useState([]);
  const [shortBoard, setShortBoard] = useState([]);

  const [teamPoints, setTeamPoints] = useState(false);

  const [eclectic, setEclectic] = useState(false);

  const [courses, setCourses] = useState(defaultCourses);

  const [showCourseSelect, setShowCourseSelect] = useState(false);

  const [selCourse, setSelCourse] = useState({
    courseID: "",
    name: "",
  });

  // UE
  useEffect(() => {
    if (userDetails) {
      loadDetails();
    }
  }, [userDetails]);

  useEffect(() => {
    if (portalSettings && userDetails) {
      if (
        portalSettings.clubID &&
        defaultCourses.length === 0 &&
        userDetails.accountType === "club"
      ) {
        loadClubCourses(portalSettings.clubID);
        setClubID(portalSettings.clubID);
      }
    }
  }, [portalSettings, userDetails]);

  // Functions
  async function loadDetails() {
    setLoading(true);
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const events = await db
      .collection("events")
      .where("leagueID", "==", leagueID)
      .orderBy("eventDate")
      .get();
    const divEvents = await db
      .collection("events")
      .where("divLeagueIDs", "array-contains", leagueID)
      .orderBy("eventDate", "asc")
      .get();
    const seasons = await db
      .collection("seasons")
      .where("leagueID", "==", leagueID)
      .orderBy("dateCreated")
      .get();

    const arr = [];
    const parr = [];
    const farr = [];
    const djarr = [];

    const invArr = [];
    const toInvArr = [];

    const addArr = [];

    const playArr = [];

    const sarr = [];

    // League Details
    const league = leagueDoc.data();
    const {
      leagueName,
      playerIDs,
      pointsTable,
      city,
      country,
      imageLink,
      seasonName,
      shortName,
      stats,
      type,
      chatID,
      invitedIDs,
      seasonID,
    } = league;
    const eclectic = league.leagueType === "eclectic";
    setEclectic(eclectic);
    setDetails({
      leagueName,
      playerIDs,
      pointsTable,
      teams: league.teams ?? [],
      city,
      leagueType: league.leagueType ?? "normal",
      country,
      imageLink,
      seasonName,
      shortName,
      stats,
      type,
      seasonID,
      chatID,
    });
    setTeamPoints(league.teamPoints ?? false);

    const datesJoined = league.datesJoined ?? {};

    const djIDs = [];
    for (let [key, value] of Object.entries(datesJoined)) {
      djIDs.push(key);
    }

    const now = new Date();
    // Players

    let ids = [...playerIDs];

    for (let i = 0; i < invitedIDs.length; i++) {
      if (!ids.includes(invitedIDs[i])) {
        ids.push(invitedIDs[i]);
      }
    }

    const { friends } = userDetails;

    for (let i = 0; i < friends.length; i++) {
      if (!ids.includes(friends[i])) {
        ids.push(friends[i]);
      }
    }

    const clubID = league.clubID ?? "";
    if (clubID) {
      setClubID(clubID);
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();
      const { memberIDs } = club;
      for (let x = 0; x < memberIDs.length; x++) {
        const id = memberIDs[x];
        if (!ids.includes(id)) {
          ids.push(id);
        }
      }
    }

    ids = ids.filter((id) => id !== null);

    const refs = ids.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );

    const playerDocs = await Promise.all(refs);
    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const playerID = playerDoc.id;
      if (!playerDoc.exists) {
        continue;
      }
      const player = playerDoc.data();
      const { firstName, lastName, profileImage, handicap } = player;
      if (playerIDs.includes(playerID)) {
        playArr.push({
          name: `${firstName} ${lastName}`,
          handicap,
          playerID,
          profileImage,
          firstName,
          lastName,
        });
      } else {
        addArr.push({
          name: `${firstName} ${lastName}`,
          handicap,
          playerID,
          profileImage,
          firstName,
          lastName,
        });
      }
      if (invitedIDs.includes(playerID) && !playerIDs.includes(playerID)) {
        invArr.push({
          name: `${firstName} ${lastName}`,
          handicap,
          playerID,
          profileImage,
          firstName,
          lastName,
        });
      }
      if (!invitedIDs.includes(playerID) && !playerIDs.includes(playerID)) {
        toInvArr.push({
          name: `${firstName} ${lastName}`,
          handicap,
          playerID,
          profileImage,
        });
      }

      if (djIDs.includes(playerID)) {
        const dateJoined = new Date(datesJoined[playerID].toDate());
        const dateString = dateJoined.toLocaleDateString("en-us", {
          dateStyle: "full",
        });
        const djMoment = moment(dateJoined);
        const timeSince = djMoment.fromNow();
        djarr.push({
          name: `${firstName} ${lastName}`,
          handicap,
          playerID,
          profileImage,
          dateJoined,
          dateString,
          timeSince,
          firstName,
          lastName,
        });
      }
    }

    const sorted = djarr.sort((a, b) => (a.dateJoined > b.dateJoined ? -1 : 1));
    setJoinedArray(sorted);
    setToAdd(addArr);
    setInvited(invArr);
    setToInvite(toInvArr);
    setPlayers(playArr);
    setLoading(false);

    const seasonMap = {};

    for (let i = 0; i < seasons.docs.length; i++) {
      const seasonDoc = seasons.docs[i];
      const seasonData = seasonDoc.data();
      const seasonID = seasonDoc.id;
      const { seasonName } = seasonData;
      seasonMap[seasonID] = seasonName;
    }

    // Events
    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      const season = seasonMap[seasonID] ?? "";
      event.season = season;
      event.eventID = eventID;
      const dateObject = new Date(event.eventDate.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      event.dateObject = dateObject;
      event.dateString = dateString;

      arr.push(event);

      if (dateObject > now) {
        farr.push(event);
      } else {
        parr.push(event);
      }
    }
    for (let i = 0; i < divEvents.docs.length; i++) {
      const eventDoc = divEvents.docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      event.eventID = eventID;
      const dateObject = new Date(event.eventDate.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      event.dateObject = dateObject;
      event.dateString = dateString;

      arr.push(event);

      if (dateObject > now) {
        farr.push(event);
      } else {
        parr.push(event);
      }
    }
    parr.sort((a, b) => b.dateObject - a.dateObject);
    farr.sort((a, b) => a.dateObject - b.dateObject);
    setPastEvents(parr);
    setFutureEvents(farr);
    setLoadingEvents(false);

    // Seasons
    for (let i = 0; i < seasons.docs.length; i++) {
      const seasonDoc = seasons.docs[i];
      const seasonID = seasonDoc.id;
      const season = seasonDoc.data();
      const dcObject = new Date(season.dateCreated.toDate());
      const dcString = dcObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      season.dcObject = dcObject;
      season.dcString = dcString;
      season.seasonID = seasonID;
      if (
        seasonID === league.seasonID ||
        (league.seasonID === "" && i === seasons.docs.length - 1)
      ) {
        const board = season.pointsTable ?? [];
        const sorted = board.sort((a, b) => (a.points > b.points ? -1 : 1));
        // const shortboard = sorted.slice(0, 8);
        if (eclectic) {
          sorted.sort((a, b) => a.net - b.net);
        }
        if (sorted.length > 0 && sorted[0].finalRank !== undefined) {
          sorted.sort((a, b) => a.finalRank - b.finalRank);
        }
        setShortBoard(sorted);
      }

      sarr.push(season);
    }
    // console.log(sarr);
    setSeasons(sarr);
    setLoadingSeasons(false);
  }

  async function loadClubCourses(id) {
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { leagueType } = league;
    if (leagueType === "eclectic") {
      const { seasonID } = league;
      const seasonDoc = await db.collection("seasons").doc(seasonID).get();
      const season = seasonDoc.data();
      const { courseName, courseID, courseImage, city, country } = season;

      const course = {
        name: courseName,
        country,
        city,
        imageLink: courseImage,
        courseID,
      };

      setCourses([course]);
    } else {
      const clubDoc = await db.collection("clubs").doc(id).get();
      const club = clubDoc.data();
      const { courseIDs } = club;

      const courseRefs = courseIDs.map((courseID) =>
        db.collection("courses").doc(courseID).get()
      );

      const courseDocs = await Promise.all(courseRefs);
      const carr = [];

      for (let i = 0; i < courseDocs.length; i++) {
        const courseDoc = courseDocs[i];
        const courseID = courseDoc.id;
        const course = courseDoc.data();
        const { name, country, city, imageLink } = course;
        carr.push({
          name,
          country,
          city,
          imageLink,
          courseID,
        });
        setCourses(carr);
      }
    }
  }

  return (
    <div className="league-edit">
      {loading && <BackdropLoader />}
      {showAdd && (
        <LeagueAddPlayer
          hide={() => setShowAdd(false)}
          leagueID={leagueID}
          players={toAdd}
          reload={loadDetails}
        />
      )}
      {showBC && (
        <BroadcastModal
          close={() => setShowBC(false)}
          leagueID={leagueID}
          name={details.leagueName}
        />
      )}
      {showInvite && (
        <InviteModal
          leagueID={leagueID}
          defaultPlayers={toInvite}
          hide={() => setShowInvite(false)}
          reload={loadDetails}
        />
      )}
      {!loading && (
        <>
          {show ? (
            toShow
          ) : (
            <>
              <Dialog
                open={showCourseSelect}
                onClose={() => setShowCourseSelect(false)}
              >
                <div className="ch-cs pd-20">
                  <div className="page-header">
                    <h2>Course Selection</h2>
                    <Close
                      className="icon-cursor"
                      onClick={() => setShowCourseSelect(false)}
                    />
                  </div>
                  <div className="ph-msg">
                    <p>Select a course for this round</p>
                  </div>
                  <div className="pd-20 flex-wrapper">
                    {courses.map((course, index) => {
                      return (
                        <ClubCourse
                          select
                          setSelected={() => {
                            setSelCourse(course);
                          }}
                          course={course}
                          key={course.courseID}
                          hideRight
                          selected={selCourse.courseID === course.courseID}
                        />
                      );
                    })}
                  </div>
                  <div className="flex-center mb-20">
                    <button
                      onClick={() => {
                        setToShow(
                          <EventCreator
                            edit={false}
                            defaultClubID={clubID}
                            defaultCourseData={selCourse}
                            goBack={() => setShow(false)}
                          />
                        );
                        setShowCourseSelect(false);
                        setShow(true);
                      }}
                      className="default-button"
                    >
                      Select
                    </button>
                  </div>
                </div>
              </Dialog>
              <div className="le-top">
                <div className="let-left">
                  {userDetails && userDetails.accountType !== "society" && (
                    <div onClick={goBack} className="back-row">
                      <ArrowBackIcon />
                      <p>Back</p>
                    </div>
                  )}
                  <div className="flex-align-center pl-20">
                    <Avatar src={details.imageLink} alt={"league"} />
                    <div className="ph-me-opt">
                      <PageHeader text={details.leagueName} />
                      {false && (
                        <div className="page-header">
                          <h2>{details.leagueName}</h2>
                        </div>
                      )}
                      <MenuSwitcher />
                    </div>
                  </div>

                  <div className="ph-msg">
                    <p>
                      {details.city}, {details.country}
                    </p>
                    {/* <p>{details.type} League</p> */}
                  </div>
                </div>
                <div className="let-right">
                  {cookies["menu-type"] === "drop" && (
                    <>
                      <div className="flex flex-align-center mb-20 mt-10">
                        <div className="ddm-box pl-20">
                          <DropdownMenu.Root>
                            <DropdownMenu.Trigger asChild>
                              <button className="ddm-but">
                                <span>General</span>
                                <KeyboardArrowDown className="ddm-ico" />
                              </button>
                            </DropdownMenu.Trigger>

                            <DropdownMenu.Portal>
                              <DropdownMenu.Content
                                className="DropdownMenuContent"
                                sideOffset={5}
                              >
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setToShow(
                                      <LeagueCreator
                                        goBack={() => {
                                          setShow(false);
                                          showHelperClick("league-home");
                                        }}
                                        reload={() => loadDetails()}
                                        defaultLeagueID={leagueID}
                                        edit
                                      />
                                    );
                                    setShow(true);
                                    showHelperClick("league-edit");
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Edit Details
                                </DropdownMenu.Item>
                                <DropdownMenu.Item
                                  onClick={() => {
                                    window.open(
                                      `https://www.theugatour.com/leagues/${leagueID}`,
                                      "_blank"
                                    );
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Public Link
                                </DropdownMenu.Item>
                              </DropdownMenu.Content>
                            </DropdownMenu.Portal>
                          </DropdownMenu.Root>
                        </div>
                        <div className="ddm-box">
                          <DropdownMenu.Root>
                            <DropdownMenu.Trigger asChild>
                              <button className="ddm-but">
                                <span>Admin</span>
                                <KeyboardArrowDown className="ddm-ico" />
                              </button>
                            </DropdownMenu.Trigger>

                            <DropdownMenu.Portal>
                              <DropdownMenu.Content
                                className="DropdownMenuContent"
                                sideOffset={5}
                              >
                                <DropdownMenu.Item
                                  onClick={() => {
                                    if (clubID !== "") {
                                      if (courses.length === 0) {
                                        setToShow(
                                          <EventCreator
                                            edit={false}
                                            defaultLeagueName={
                                              details.leagueName
                                            }
                                            defaultLeagueID={leagueID}
                                            defaultClubID={clubID}
                                            goBack={() => setShow(false)}
                                          />
                                        );
                                        setShow(true);
                                      } else if (courses.length === 1) {
                                        setToShow(
                                          <EventCreator
                                            edit={false}
                                            defaultEclectic={eclectic}
                                            noCoursEdit={eclectic}
                                            defaultCourseData={courses[0]}
                                            defaultLeagueName={
                                              details.leagueName
                                            }
                                            defaultLeagueID={leagueID}
                                            defaultClubID={clubID}
                                            goBack={() => setShow(false)}
                                          />
                                        );
                                        setShow(true);
                                      } else {
                                        setShowCourseSelect(true);
                                      }
                                    } else {
                                      setToShow(
                                        <EventCreator
                                          edit={false}
                                          defaultLeagueName={details.leagueName}
                                          defaultLeagueID={leagueID}
                                          goBack={() => setShow(false)}
                                        />
                                      );
                                      setShow(true);
                                    }
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  New Event
                                </DropdownMenu.Item>
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setToShow(
                                      <SeasonEditor
                                        edit={false}
                                        eclectic={eclectic}
                                        goBack={() => {
                                          setShow(false);
                                          showHelperClick("league-home");
                                        }}
                                        reload={loadDetails}
                                        seasons={seasons}
                                        leagueID={leagueID}
                                        defaultSeasonIndex={seasons.length + 1}
                                      />
                                    );
                                    setShow(true);
                                    showHelperClick("season-edit");
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  New Season
                                </DropdownMenu.Item>
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setToShow(
                                      <LeagueSponsorManager
                                        goBack={() => {
                                          setShow(false);
                                          showHelperClick("league-home");
                                        }}
                                        leagueID={leagueID}
                                      />
                                    );
                                    setShow(true);
                                    showHelperClick("league-sponsors");
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Manage Sponsors
                                </DropdownMenu.Item>
                              </DropdownMenu.Content>
                            </DropdownMenu.Portal>
                          </DropdownMenu.Root>
                        </div>
                        <div className="ddm-box">
                          <DropdownMenu.Root>
                            <DropdownMenu.Trigger asChild>
                              <button className="ddm-but">
                                <span>Players</span>
                                <KeyboardArrowDown className="ddm-ico" />
                              </button>
                            </DropdownMenu.Trigger>

                            <DropdownMenu.Portal>
                              <DropdownMenu.Content
                                className="DropdownMenuContent"
                                sideOffset={5}
                              >
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setShowInvite(true);
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Invite Players
                                </DropdownMenu.Item>
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setShowAdd(true);
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Add Players
                                </DropdownMenu.Item>
                                {!isMobile && (
                                  <DropdownMenu.Item
                                    onClick={() => {
                                      setToShow(
                                        <LeaguePlayerUpload
                                          clubID={clubID}
                                          goBack={() => {
                                            setShow(false);
                                            showHelperClick("league-home");
                                          }}
                                          leagueID={leagueID}
                                          reload={loadDetails}
                                        />
                                      );
                                      setShow(true);
                                      showHelperClick("league-player-upload");
                                    }}
                                    className="DropdownMenuItem"
                                  >
                                    Upload Players
                                  </DropdownMenu.Item>
                                )}
                                {teamPoints && (
                                  <DropdownMenu.Item
                                    onClick={() => {
                                      setToShow(
                                        <EventTeamManager
                                          goBack={() => {
                                            setShow(false);
                                            showHelperClick("league-home");
                                          }}
                                          leagueID={leagueID}
                                          league
                                        />
                                      );
                                      setShow(true);
                                      showHelperClick("league-teams");
                                    }}
                                    className="DropdownMenuItem"
                                  >
                                    Edit Teams
                                  </DropdownMenu.Item>
                                )}
                                {teamPoints && (
                                  <DropdownMenu.Item
                                    onClick={() => {
                                      setToShow(
                                        <EventTeamUpload
                                          close={() => setShow(false)}
                                          league
                                          leagueID={leagueID}
                                          clubID={clubID}
                                        />
                                      );
                                      setShow(true);
                                    }}
                                    className="DropdownMenuItem"
                                  >
                                    Upload Teams
                                  </DropdownMenu.Item>
                                )}
                                <DropdownMenu.Item
                                  onClick={() => {
                                    if (details.chatID) {
                                      showClick({
                                        type: "chat",
                                        chatID: details.chatID,
                                      });
                                    } else {
                                      setShowBC(true);
                                    }
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Group Message
                                </DropdownMenu.Item>
                              </DropdownMenu.Content>
                            </DropdownMenu.Portal>
                          </DropdownMenu.Root>
                        </div>
                      </div>
                    </>
                  )}
                  {cookies["menu-type"] !== "drop" && (
                    <div className="ch-links">
                      <div className="ch-link">
                        <div className="sub-header">
                          <h5>General</h5>
                        </div>
                        <div className="quick-links">
                          <Pill
                            text="Edit Details"
                            onClick={() => {
                              setToShow(
                                <LeagueCreator
                                  goBack={() => {
                                    setShow(false);
                                    showHelperClick("league-home");
                                  }}
                                  reload={() => loadDetails()}
                                  defaultLeagueID={leagueID}
                                  edit
                                />
                              );
                              setShow(true);
                              showHelperClick("league-edit");
                            }}
                          />

                          <Pill
                            text={"Public Page"}
                            onClick={() =>
                              window.open(
                                `https://www.theugatour.com/leagues/${leagueID}`,
                                "_blank"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="ch-link">
                        <div className="sub-header">
                          <h5>Admin</h5>
                        </div>
                        <div className="quick-links">
                          <Pill
                            text={"New Event"}
                            onClick={() => {
                              if (clubID !== "") {
                                if (courses.length === 0) {
                                  setToShow(
                                    <EventCreator
                                      edit={false}
                                      defaultLeagueName={details.leagueName}
                                      defaultLeagueID={leagueID}
                                      defaultClubID={clubID}
                                      goBack={() => setShow(false)}
                                    />
                                  );
                                  setShow(true);
                                } else if (courses.length === 1) {
                                  setToShow(
                                    <EventCreator
                                      edit={false}
                                      defaultEclectic={eclectic}
                                      noCoursEdit={eclectic}
                                      defaultCourseData={courses[0]}
                                      defaultLeagueName={details.leagueName}
                                      defaultLeagueID={leagueID}
                                      defaultClubID={clubID}
                                      goBack={() => setShow(false)}
                                    />
                                  );
                                  setShow(true);
                                } else {
                                  setShowCourseSelect(true);
                                }
                              } else {
                                setToShow(
                                  <EventCreator
                                    edit={false}
                                    defaultLeagueName={details.leagueName}
                                    defaultLeagueID={leagueID}
                                    goBack={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              }
                            }}
                          />
                          <Pill
                            text={"New Season"}
                            onClick={() => {
                              setToShow(
                                <SeasonEditor
                                  edit={false}
                                  eclectic={eclectic}
                                  goBack={() => setShow(false)}
                                  reload={loadDetails}
                                  seasons={seasons}
                                  leagueID={leagueID}
                                  defaultSeasonIndex={seasons.length + 1}
                                />
                              );
                              setShow(true);
                            }}
                          />

                          <Pill
                            text={"Manage Sponsors"}
                            onClick={() => {
                              setToShow(
                                <LeagueSponsorManager
                                  goBack={() => setShow(false)}
                                  leagueID={leagueID}
                                />
                              );
                              setShow(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className="ch-link">
                        <div className="sub-header">
                          <h5>Players</h5>
                        </div>
                        <div className="quick-links">
                          <Pill
                            text={"Invite Player"}
                            onClick={() => setShowInvite(true)}
                          />
                          <Pill
                            text={"Add Player"}
                            onClick={() => setShowAdd(true)}
                          />
                          {!isMobile && (
                            <Pill
                              text={"Upload Players"}
                              onClick={() => {
                                setToShow(
                                  <LeaguePlayerUpload
                                    clubID={clubID}
                                    goBack={() => setShow(false)}
                                    leagueID={leagueID}
                                    reload={loadDetails}
                                  />
                                );
                                setShow(true);
                              }}
                            />
                          )}
                          {teamPoints && (
                            <Pill
                              text={"Edit Teams"}
                              onClick={() => {
                                setToShow(
                                  <EventTeamManager
                                    goBack={() => {
                                      setShow(false);
                                      showHelperClick("league-home");
                                    }}
                                    leagueID={leagueID}
                                    league
                                  />
                                );
                                setShow(true);
                                showHelperClick("league-teams");
                              }}
                            />
                          )}

                          {teamPoints && (
                            <Pill
                              text={"Upload Teams"}
                              onClick={() => {
                                setToShow(
                                  <EventTeamUpload
                                    close={() => setShow(false)}
                                    league
                                    leagueID={leagueID}
                                    clubID={clubID}
                                  />
                                );
                                setShow(true);
                              }}
                            />
                          )}
                          <Pill
                            text={"Group Message"}
                            onClick={() => {
                              if (details.chatID) {
                                showClick({
                                  type: "chat",
                                  chatID: details.chatID,
                                });
                              } else {
                                setShowBC(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={classNames("tabs-container", {
                    "mt-20": !isMobile,
                  })}
                >
                  <Tabs
                    value={active}
                    onChange={(e, newValue) => {
                      setActive(newValue);
                    }}
                  >
                    <Tab label="Overview" value={"overview"} />
                    <Tab label="Events" value={"events"} />
                    <Tab label="Seasons" value={"seasons"} />
                    <Tab label="Members" value={"members"} />
                    <Tab label="Logs" value={"logs"} />
                    <Tab label="Stats" value={"stats"} />
                  </Tabs>
                </div>
              </div>

              {active === "overview" && (
                <LeagueEditHome
                  teams={details.teams}
                  leaderboard={shortBoard}
                  datesJoined={joinedArray}
                  eclectic={eclectic}
                />
              )}

              {active === "stats" && <LeagueStats leagueID={leagueID} />}

              {active === "events" && (
                <LeagueEvents
                  pastEvents={pastEvents}
                  loading={loading}
                  setShow={setShow}
                  setToShow={setToShow}
                  futureEvents={futureEvents}
                />
              )}
              {active === "logs" && (
                <LogPage
                  teams={details.teams}
                  leagueID={leagueID}
                  leagueName={details.leagueName}
                />
              )}
              {active === "seasons" && (
                <LeagueSeasons
                  eclectic={eclectic}
                  setShow={setShow}
                  reload={loadDetails}
                  setToShow={setToShow}
                  activeSeasonID={details.seasonID}
                  loading={loadingSeasons}
                  seasons={seasons}
                />
              )}
              {active === "members" && (
                <LeagueMembers
                  leagueID={leagueID}
                  reload={loadDetails}
                  players={players}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default LeagueEdit;
