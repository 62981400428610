import React from "react";
import "./Refunds.css";
import RefundRow from "./RefundRow";

function Refunds({ refunds = [] }) {
  return (
    <div className="refunds-page">
      <div className="acg-box">
        <div className="ac-graph bss-white">
          <div className="refund-row rr-header">
            <div className="rr-date">
              <p>Date</p>
            </div>

            <div className="rr-date">
              <p>Amount</p>
            </div>
            <div className="rr-date">
              <p>Reference</p>
            </div>
          </div>
          {refunds.map((refund, i) => {
            return <RefundRow key={refund.reference} refund={refund} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Refunds;
