import React, { useState, useEffect } from "react";
import { db, admin } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import "./Account.css";
import PaystackPop from "@paystack/inline-js";

// Icons
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
// Components
import BackdropLoader from "../Loaders/BackdropLoader";
import PageHeader from "../display/Rows/PageHeader";
import ErrorModal from "../ErrorModal/ErrorModal";
import { Tabs, Tab } from "@mui/material";
import AccountCosts from "./AccountCosts";
import FeaturePicker from "./FeaturePicker";
import SubscriptionCreator from "../PayFast/SubscriptionCreator";
import PayfastDetails from "./PayfastDetails";
import { daysBetween, errDefault } from "../HelperFunctions";
import Pill from "../Buttons/Pill";
import ConfirmModal from "../ClientStats/ConfirmModal";
import PaymentHistory from "./PaymentHistory";
import PayDisputes from "../PayDispute/PayDisputes";
import ClubCredits from "../ClubCredits/ClubCredits";
import IconButton from "../Buttons/IconButton";
import { AddCard } from "@mui/icons-material";
import OutstandingPayments from "./OutstandingPayments";
import Refunds from "../Refunds/Refunds";

function Account() {
  // Admin
  const generatePSSub = admin.functions().httpsCallable("paystackSig");
  const getPFInfo = admin.functions().httpsCallable("paystackInfo");
  const { currentUser, portalSettings, userDetails } = useAuth();
  const moduleOptions = [
    {
      label: "Events",
      value: "events",
    },
    {
      label: "Leagues",
      value: "leagues",
    },
    {
      label: "Matchplay Leaguues",
      value: "mpLeagues",
    },
    {
      label: "Matchplay Tournaments",
      value: "mpTournaments",
    },
    {
      label: "Cups",
      value: "cups",
    },
    {
      label: "Contests",
      value: "contests",
    },
  ];

  const addOnOptions = [
    {
      label: "User Uploads",
      value: "userUploads",
    },
    {
      label: "Handicap Uploads",
      value: "hcpUploads",
    },
  ];

  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selTab, setSelTab] = useState("details");

  const [memberLimit, setMemberLimit] = useState(0);
  const [eventLimit, setEventLimit] = useState(0);
  const [enabledModules, setEnabledModules] = useState(["events", "leagues"]);

  const [enabledAddOns, setEnabledAddOns] = useState([]);
  const [pfInfo, setPFInfo] = useState({});

  const [pastPayments, setPastPayments] = useState([]);

  const [currentFee, setCurrentFee] = useState(0);
  const [clubID, setClubID] = useState("");

  const [credits, setCredits] = useState(0);

  const [accountType, setAccountType] = useState("subscription");

  // Trial
  const [trialActive, setTrialActive] = useState(false);
  const [trialEnd, setTrialEnd] = useState("");
  const [trialDaysLeft, setTrialDaysLeft] = useState("");
  const [trialUsed, setTrialUsed] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showDeactDialog, setShowDeactDialog] = useState(false);

  const [outstandingPayments, setOutstandingPayments] = useState([]);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [refunds, setRefunds] = useState([]);

  // UE
  useEffect(() => {
    if (currentUser) {
      loadDetails();
    }
  }, [currentUser]);

  useEffect(() => {
    if (portalSettings) {
      setClubID(portalSettings.clubID);
    }
  }, [portalSettings]);

  // Funcs
  async function loadDetails() {
    const userDoc = await db.collection("users").doc(currentUser.uid).get();
    const user = userDoc.data();

    const { portalSettings, portalType } = user;
    const { clubID } = portalSettings;

    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();

    const trial = club.trial ?? false;
    const trialUsed = club.trialUsed ?? false;
    const trialEnd = club.trialEnd ?? "";

    if (club.credit) {
      const fixedCredit = (club.credit / 100).toFixed(2);
      setCredits(fixedCredit);
    }

    setTrialActive(trial);
    setTrialUsed(trialUsed);

    if (trialEnd !== "") {
      const teDate = new Date(trialEnd.toDate());
      const today = new Date();
      const days = daysBetween(today, teDate);
      const teDateString = teDate.toLocaleDateString("default", {
        dateStyle: "short",
      });
      setTrialDaysLeft(days);
      setTrialEnd(teDateString);
    }

    if (user.accountSettings) {
      const { memberLimit, eventLimit, enabledModules, enabledAddOns } = user;
      setMemberLimit(memberLimit);
      setEventLimit(eventLimit);
      setEnabledAddOns(enabledAddOns);
      setEnabledModules(enabledModules);
    } else {
    }

    const pfInfo = await getPFInfo({ clubID });
    if (pfInfo.data !== "Data does not exist") {
      setPFInfo(pfInfo.data);
    }

    const outstandingPaymentDocs = await clubDoc.ref
      .collection("outstandingPayments")
      .where("status", "==", "outstanding")
      .orderBy("date", "desc")
      .get();

    console.log(outstandingPaymentDocs.docs.length);

    const opArr = [];

    for (let i = 0; i < outstandingPaymentDocs.docs.length; i++) {
      const opDoc = outstandingPaymentDocs.docs[i];
      const opData = opDoc.data();

      const { date } = opData;
      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "medium",
      });
      opData.dateObject = dateObject;
      opData.dateString = dateString;
      opArr.push(opData);
    }

    setOutstandingPayments(opArr);

    const refundDocs = await db
      .collection("refunds")
      .where("clubID", "==", clubID)
      .get();
    const refArr = [];

    for (let i = 0; i < refundDocs.docs.length; i++) {
      const refundDoc = refundDocs.docs[i];
      const refund = refundDoc.data();
      const dateObject = new Date(refund.date.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "medium",
      });
      refund.amount = (refund.amount / 100).toFixed(2);
      refund.dateString = dateString;
      refArr.push(refund);
    }

    setRefunds(refArr);

    setLoading(false);
  }

  async function callPaystack() {
    setShowUpdateDialog(false);
    setLoading(true);
    const { firstName, lastName, emailAddress } = userDetails;

    const res = await generatePSSub({
      emailAddress,
      clubID: portalSettings.clubID,
    });
    const { data } = res;
    const access_code = data.data.access_code;
    // const popup = new Paystack();
    // popup.
    const popup = new PaystackPop();
    popup.resumeTransaction(access_code);
    setLoading(false);
  }

  async function deactivateAccount() {
    setShowDeactDialog(false);
    try {
      await db.collection("clubs").doc(portalSettings.clubID).update({
        status: "inactive",
        cancelNext: true,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
      setError(errDefault);
    }
  }

  return (
    <div className="account-page">
      {show ? (
        toShow
      ) : (
        <>
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          {loading && <BackdropLoader />}
          {<PageHeader text="Account Settings" />}

          {showUpdateDialog && (
            <ConfirmModal
              confirm={callPaystack}
              close={() => setShowUpdateDialog(false)}
              text="Your card will be charged with a small fee, which will be added to your Tour account as credit for future payments."
            />
          )}

          {showDeactDialog && (
            <ConfirmModal
              confirm={deactivateAccount}
              close={() => setShowDeactDialog(false)}
              text="Are you sure you want to deactivate your account? Your account will still be charged for this month's usage. You can re-activate your account at any time."
            />
          )}

          <div className="ec-tabs">
            <Tabs value={selTab} onChange={(e, v) => setSelTab(v)}>
              <Tab label={"Details"} value={"details"} />
              <Tab label={"Features"} value={"features"} />
              <Tab label={"Usage"} value={"charges"} />
              <Tab label={"Payments"} value={"payments"} />
              <Tab label={"Disputes"} value={"disputes"} />
              <Tab label={"Credits"} value={"credits"} />
              {refunds.length > 0 && (
                <Tab label={"Refunds"} value={"refunds"} />
              )}
            </Tabs>
          </div>

          {selTab === "details" && (
            <div className="acc-subs">
              {" "}
              {outstandingPayments.length > 0 && (
                <>
                  <div className="opm-box">
                    <div className="op-msg">
                      <p>
                        You have outstanding payments. Please{" "}
                        <a href={`/#/outstanding/${clubID}`}>click here</a> to
                        ensure your account does not get disabled.
                      </p>
                    </div>{" "}
                  </div>
                </>
              )}
              <div className="sub-header">
                <h3>Quick Links</h3>
              </div>
              <div className="quick-links mt-20">
                <IconButton
                  colour="green"
                  onClick={() => setShowUpdateDialog(true)}
                  ico={<AddCard />}
                  text="Update Payment Details"
                />
                <IconButton
                  colour="black"
                  onClick={() => setShowDeactDialog(true)}
                  ico={<NoAccountsIcon />}
                  text="Deactivate Account"
                />
                {/* <Pill
              onClick={() => setShowUpdateDialog(true)}
              text={"Update Payment Details"}
            />
            <Pill
              onClick={() => setShowDeactDialog(true)}
              text={"Deactive Account"}
            /> */}
              </div>
              <div className="sub-header">
                <h3>Account Details</h3>
              </div>
              <div className="acc-details mt-20 mb-40">
                <div className="bs-subtle pd-20 bss-white">
                  <div className="acc-det-box">
                    <div className="acc-group">
                      <p>Account Type</p>
                      <h3>{accountType}</h3>
                    </div>
                    <div className="acc-group">
                      <p>Credit</p>
                      <h3>${credits}</h3>
                    </div>
                    {trialActive && (
                      <div className="acc-group">
                        <p>Trial Expiry</p>
                        <h3>
                          {trialEnd} ({trialDaysLeft} days left)
                        </h3>
                      </div>
                    )}
                    {accountType === "subscription" && (
                      <>
                        <div className="acc-group">
                          <p>Card Type</p>
                          <h3>{pfInfo.card_type}</h3>
                        </div>

                        <div className="acc-group">
                          <p>Expiry </p>
                          <h3>
                            {pfInfo.exp_month} / {pfInfo.exp_year}
                          </h3>
                        </div>
                        <div className="acc-group">
                          <p>Last 4 Digits</p>
                          <h3>{pfInfo.last4}</h3>
                        </div>

                        <div className="acc-group">
                          <p>Billing ID</p>
                          <h3>{pfInfo.customer_code}</h3>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="sub-box"></div>
              <SubscriptionCreator />
              <PayfastDetails />
            </div>
          )}
          {selTab === "charges" && <AccountCosts clubID={clubID} />}
          {selTab === "features" && <FeaturePicker clubID={clubID} />}
          {selTab === "payments" && <PaymentHistory clubID={clubID} />}
          {selTab === "disputes" && <PayDisputes clubID={clubID} />}
          {selTab === "credits" && <ClubCredits clubID={clubID} />}
          {selTab === "refunds" && <Refunds refunds={refunds} />}
        </>
      )}
    </div>
  );
}

export default Account;
