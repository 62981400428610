import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

// Icons

// Components
import BackdropLoader from "../Loaders/BackdropLoader";

function PayfastDetails() {
  // Admin

  const { portalSettings } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [clubID, setClubID] = useState("");

  // UE
  useEffect(() => {
    if (portalSettings) {
      setClubID(portalSettings.clubID);
      loadDetails({ clubID: portalSettings.clubID });
    }
  }, [portalSettings]);

  // Funcs
  async function loadDetails({ clubID }) {
    const clubRef = db.collection("clubs").doc(clubID);
    const clubDoc = await clubRef.get();

    const pfDoc = await clubRef
      .collection("paystack")
      .orderBy("dateAdded", "desc")
      .limit(1)
      .get();

    if (pfDoc.docs.length === 0) {
      return console.log("no token docs");
    }

    const pfData = pfDoc.docs[0].data();
    console.log(pfData);
  }

  return <div className="payfast-details"></div>;
}

export default PayfastDetails;
