import { DeleteForever } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

function PuttMissDrillRow({
  row = {},
  saveRow = () => {},
  removeRow = () => {},
  index = 1,
}) {
  const [distance, setDistance] = useState(row.distance ?? 0);
  const [miss, setMiss] = useState(0);
  const [missPerc, setMissPerc] = useState(0);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    let missPercentage = ((miss / distance) * 100).toFixed(2);

    if (isNaN(missPercentage)) {
      setMissPerc(0);
      missPercentage = 0;
    } else {
      setMissPerc(missPercentage);
    }

    const att = { distance, miss, missPercentage };

    saveRow(att);
    // console.log("saved");
  }, [distance, miss]);

  return (
    <div className="acc-drill-row putt-drill-row">
      <div className="adr-idx">
        <p>Shot {index}:</p>
      </div>
      <div className="flex-as">
        <div className="adr-item input-group">
          <p>Distance</p>
          <input
            className="default-input"
            type="number"
            value={distance}
            onChange={(e) => setDistance(e.target.valueAsNumber)}
          />
        </div>
        <div className="adr-item input-group">
          <p>Miss</p>
          <input
            className="default-input"
            type="number"
            value={miss}
            onChange={(e) => setMiss(e.target.value)}
          />
        </div>
        <div className="adr-item input-group">
          <p>Percentage</p>
          <h3>{missPerc}%</h3>
        </div>
        <div className="adr-del adr-ico">
          <DeleteForever
            onClick={removeRow}
            className="red-icon-bg icon-cursor"
          />
        </div>{" "}
      </div>
    </div>
  );
}

export default PuttMissDrillRow;
