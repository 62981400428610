import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import "./ClientStats.css";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// Icons

// Components
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { yearsSince2022 } from "../HelperFunctions";
import StatsItem from "./StatsItem/StatsItem";
import { Tabs, Tab, Dialog } from "@mui/material";
import ClientStatsGraphs from "./ClientStatsGraphs";
import StatsCorrelation from "./StatsCorrelations/StatsCorrelation";
import ClientCourseProfile from "./ClientCourseProfile/ClientCourseProfile";
import SearchInput from "./SearchInput";
import CSVStatsSelector from "./CSVStatsSelector";
import { CSVDownload } from "react-csv";
import PageHeader from "../display/Rows/PageHeader";
import { DateRangePicker } from "react-date-range";
import BackdropLoader from "../Loaders/BackdropLoader";
import { useParams } from "react-router-dom";
import Pill from "../Buttons/Pill";
import StatsRoundInput from "./StatsRoundInput/StatsRoundInput";
import ClientDrills from "./ClientDrills/ClientDrills";
import BackRow from "../display/Rows/BackRow";
import ClientDrillLoader from "./ClientDrills/ClientDrillLoader";
import ClientRounds from "./ClientRounds";
import RoundCard from "./RoundCard/RoundCard";
import ClientSeasonSummary from "./ClientSeasonSummary";
import StandardDeviation from "./StandardDeviation/StandardDeviation";

const lengthStatsProp = {
  parFives: [],
  parThrees: [],
  parFours: [],
};

function ClientStats({}) {
  // Admin
  const navigate = useNavigate();
  const { portalSettings, userDetails } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [dateRge, setDateRge] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const userID = useParams().id;
  const [rdOpts, setRDOpts] = useState([]);
  const [drillIDs, setDrillIDs] = useState([]);
  const [rdAmt1, setRdAmt1] = useState("");
  const [rdAmt2, setRdAmt2] = useState("");
  // const [dates, setDates] = useState([]);
  const [gross, setGross] = useState(true);
  const [dropts, setDropts] = useState([]);
  const [statsMap, setStatsMap] = useState({});
  const [ogStats, setOGStats] = useState({});
  const [ogGrossStats, setOGGrossStats] = useState({});
  const [stats, setStats] = useState([]);
  const [grossStats, setGrossStats] = useState([]);
  const [selStat, setSelStat] = useState("overall");
  const [courses, setCourses] = useState([]);
  const [selCourse, setSelCourse] = useState("");
  const [period, setPeriod] = useState("rds");
  const [selStatType, setSelStatType] = useState("stats");

  const [hideTop, setHideTop] = useState(false);

  const [playerDetails, setPlayerDetails] = useState({
    name: "",
    handicap: "",
    profileImage: "",
  });

  const [showCustomPeriod, setShowCustomPeriod] = useState(false);

  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  const [showExport, setShowExport] = useState(false);
  const [toDL, setToDL] = useState([]);
  const [showDL, setShowDL] = useState(false);

  const [sel, setSel] = useState("numbers");

  const [sg, setSG] = useState();

  const [comps, setComps] = useState({});
  const [grossComps, setGrossComps] = useState({});

  const [snaps, setSnaps] = useState([]);

  const [lengthStats, setLengthStats] = useState([]);

  const [rds, setRds] = useState([]);

  // UE
  useEffect(() => {
    if (portalSettings) {
      loadStats();
      loadDrills();
    }
  }, [portalSettings]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    if (showDL) {
      setTimeout(() => {
        setShowDL(false);
        // console.log("set dl false");
      });
    }
  }, [showDL]);

  // Funcs
  async function loadDrills() {
    const userDoc = await db.collection("users").doc(userID).get();
    const user = userDoc.data();
    const drillIDs = user.drillIDs ?? {};
    const { clubID } = portalSettings;

    const clubDrillIDs = drillIDs[clubID] ?? [];
    // console.log(clubDrillIDs);
    setDrillIDs(clubDrillIDs);
  }

  async function loadStats() {
    const userDoc = await db.collection("users").doc(userID).get();
    const user = userDoc.data();
    const { firstName, lastName, handicap, profileImage } = user;

    setPlayerDetails({
      handicap,
      profileImage,
      name: `${firstName} ${lastName}`,
    });
    const useStats = user.stats;

    useStats.parThreesToPar =
      Math.round((user.grossStats.averageParThrees - 3) * 100) / 100;
    useStats.parFoursToPar =
      Math.round((user.grossStats.averageParFours - 4) * 100) / 100;
    useStats.parFivesToPar =
      Math.round((user.grossStats.averageParFives - 5) * 100) / 100;
    useStats.parThreesToParGIR =
      Math.round((user.stats.parThreeGrossGIRScoringAverage - 3) * 100) / 100;
    useStats.parFoursToParGIR =
      Math.round((user.stats.parFourGrossGIRScoringAverage - 4) * 100) / 100;
    useStats.parFivesToParGIR =
      Math.round((user.stats.parFiveGrossGIRScoringAverage - 5) * 100) / 100;
    // console.log(useStats);
    setOGStats(useStats);
    setOGGrossStats(user.grossStats);
    if (user.smartStats) {
      setSG(user.smartStats);
    }

    setLengthStats(user.lengthStats ?? lengthStatsProp);

    const snapDocs = await userDoc.ref
      .collection("snapshots")
      .orderBy("date", "asc")
      .get();

    const snapArr = [];

    const rdIDs = [];

    for (let i = 0; i < snapDocs.docs.length; i++) {
      const doc = snapDocs.docs[i];
      const data = doc.data();
      const dateObj = new Date(data.date.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });

      const { stats, grossStats } = data;

      if (stats === undefined || grossStats === undefined) {
        console.log(data);
        continue;
      }

      const { averageParThrees, averageParFives, averageParFours } = grossStats;

      const parThreesToPar = Math.round((averageParThrees - 3) * 100) / 100;
      const parFoursToPar = Math.round((averageParFours - 4) * 100) / 100;
      const parFivesToPar = Math.round((averageParFives - 5) * 100) / 100;

      const grossParThreeAve =
        grossStats.averageParThrees * stats.totalParThrees;
      const grossParFourAve = grossStats.averageParFours * stats.totalParFours;
      const grossParFiveAve = grossStats.averageParFives * stats.totalParFives;

      const parThreeTotal = stats.totalParThrees * 3;
      const parFourTotal = stats.totalParFours * 4;
      const parFiveTotal = stats.totalParFives * 5;
      const totalGrossShots =
        grossParThreeAve + grossParFourAve + grossParFiveAve;
      const totalPar = parThreeTotal + parFourTotal + parFiveTotal;
      const totalHoles =
        stats.totalParThrees + stats.totalParFours + stats.totalParFives;

      const averageThreePutts =
        Math.round((stats.threePutts / stats.totalRounds) * 100) / 100;
      const averageOnePutts =
        Math.round((stats.onePutts / stats.totalRounds) * 100) / 100;

      stats.averageThreePutts = averageThreePutts;
      stats.averageOnePutts = averageOnePutts;

      let grossAveToPar =
        Math.round(((totalGrossShots - totalPar) / totalHoles) * 100) / 100;
      if (isNaN(grossAveToPar)) {
        grossAveToPar = 0;
      }
      //   console.log(grossAveToPar);
      //   let averageToPar =
      //     averageParThrees + averageParFours + averageParFives - 12;
      //   averageToPar = Math.round(averageToPar * 100) / 100;
      stats.scramblePerc = Math.round(
        (stats.totalGrossScrambles / stats.totalGrossScrambleAttempts) * 100
      );
      stats.birdieConversion = Math.round(
        (grossStats.totalBirdies / stats.totalGIR) * 100
      );
      stats.averageToPar = grossAveToPar;
      stats.parThreesToPar = parThreesToPar;
      stats.parFoursToPar = parFoursToPar;
      stats.parFivesToPar = parFivesToPar;
      data.stats = stats;
      snapArr.push(data);
      rdIDs.push(data.roundID);
    }

    const rdRefs = rdIDs.map((id) =>
      db.collection("rounds").doc(id).collection("scores").doc(userID).get()
    );
    const rdDocs = await Promise.all(rdRefs);

    const rdArr = [];
    const rdOptArr = [];

    for (let i = 0; i < rdDocs.length; i++) {
      const rdDoc = rdDocs[i].data();
      const roundID = rdIDs[i];
      if (!rdDoc) {
        continue;
      }

      rdOptArr.push({
        value: `${i + 1}`,
        label: `Round ${i + 1}`,
      });

      const {
        totalGrossStrokes,
        totalNetStrokes,
        totalPoints,
        totalFIR,
        totalPutts,
        totalGIR,
        stats,
        grossStats,
      } = rdDoc;

      const { averageParThrees, averageParFives, averageParFours } = grossStats;
      let averageToPar =
        averageParThrees + averageParFours + averageParFives - 12;
      averageToPar = Math.round(averageToPar * 100) / 100;

      const parThreesToPar = Math.round((averageParThrees - 3) * 100) / 100;
      const parFoursToPar = Math.round((averageParFours - 4) * 100) / 100;
      const parFivesToPar = Math.round((averageParFives - 5) * 100) / 100;
      const {
        parFourGrossGIRScoringAverage,
        parThreeGrossGIRScoringAverage,
        parFiveGrossGIRScoringAverage,
        parFiveGrossFIRScoringAverage,
        parFourGrossFIRScoringAverage,
        threePutts,
        onePutts,
        totalGrossScrambleAttempts,
        totalGrossScrambles,
      } = stats;

      const {
        totalBirdies,
        totalEagles,
        totalPars,
        totalBogies,
        totalDoubles,
        totalTriples,
      } = grossStats;

      const birdieConversion = Math.round((totalBirdies / totalGIR) * 100);

      const scramblePerc = Math.round(
        (totalGrossScrambles / totalGrossScrambleAttempts) * 100
      );

      const rdDate = new Date(rdDoc.stats.roundDate.toDate());
      rdArr.push({
        roundID,
        totalBirdies,
        totalEagles,
        totalPars,
        totalBogies,
        totalDoubles,
        totalTriples,
        scramblePerc,
        birdieConversion,
        totalGrossStrokes,
        totalNetStrokes,
        totalPoints,
        totalFIR: totalFIR ?? stats.totalFIR,
        totalPutts,
        totalGIR: totalGIR ?? stats.totalGIR,
        date: rdDate,
        averageParThrees: grossStats.averageParThrees,
        averageParFours: grossStats.averageParFours,
        averageParFives: grossStats.averageParFives,
        parThreeGrossGIRScoringAverage,
        parFourGrossGIRScoringAverage,
        parFiveGrossGIRScoringAverage,
        parFiveGrossFIRScoringAverage,
        parFourGrossFIRScoringAverage,
        threePutts,
        onePutts,
        // averageToPar,
        parThreesToPar,
        parFoursToPar,
        parFivesToPar,
      });
    }

    setRds(rdArr);

    setSnaps(snapArr);

    // console.log(rdArr, snapArr);

    const docs = await userDoc.ref.collection("courseStats").get();

    const courseIDs = docs.docs.map((doc) =>
      db.collection("courses").doc(doc.id).get()
    );

    const courseDocs = await Promise.all(courseIDs);

    const courseArr = [];
    const dropts = [
      {
        label: "Overall",
        value: "overall",
      },
    ];

    const mep = {};

    const yarr = yearsSince2022();

    const { stats, grossStats } = user;

    mep["overall"] = {
      stats,
      grossStats,
    };

    if (user.ugaStats) {
      mep["events"] = {
        stats: user.ugaStats,
        grossStats: user.ugaGrossStats,
      };
      dropts.push({
        label: "Events",
        value: "events",
      });
    }

    for (let i = 0; i < yarr.length; i++) {
      const year = yarr[i];
      if (user[`${year}stats`] === undefined) {
        continue;
      }
      const st = user[`${year}stats`] ?? [];
      const gst = user[`${year}GrossStats`] ?? [];
      mep[`${year}`] = {
        stats: st,
        grossStats: gst,
      };
      dropts.push({
        label: `${year}`,
        value: `${year}`,
      });
    }

    // docs.docs.sort((a, b) => a.data().name.localeCompare(b.data().name));

    for (let i = 0; i < docs.docs.length; i++) {
      const doc = docs.docs[i];
      const data = doc.data();
      const courseDoc = courseDocs[i];
      const course = courseDoc.data();

      const { name, teeArray, tees, holeStats } = course;
      data.name = name;
      data.courseID = doc.id;
      data.teeArray = teeArray;
      data.tees = tees;
      data.courseHoleStats = holeStats;

      courseArr.push(data);

      dropts.push({
        label: name,
        value: doc.id,
      });

      mep[`${doc.id}`] = {
        stats: data.stats,
        grossStats: data.grossStats,
      };
    }
    courseArr.sort((a, b) => a.name.localeCompare(b.name));
    setRDOpts(rdOptArr);
    setDropts(dropts);
    setCourses(courseArr);
    if (courseArr.length > 0) {
      setSelCourse(courseArr[0]);
    }
    console.log(mep);
    setStatsMap(mep);
    setStats(stats);
    setGrossStats(stats);
    setLoading(false);
  }

  function sortTimePeriod() {}

  return (
    <div className="client-stats">
      {loading ? (
        <div className="cs-loader">
          {" "}
          <div className="cp-loader">
            <BackdropLoader />
          </div>
        </div>
      ) : show ? (
        toShow
      ) : (
        <>
          <Dialog
            open={showCustomPeriod}
            onClose={() => setShowCustomPeriod(false)}
          >
            <div className="scp-ctt pd-20">
              <PageHeader
                text="Select period"
                close={() => setShowCustomPeriod(false)}
                showClose
              />{" "}
              <div className="input-group">
                <p>Period</p>
                <div className="flex">
                  <div className="selector-group sg-small mr-20">
                    <button
                      onClick={() => {
                        setPeriod("rds");
                      }}
                      className={
                        period === "rds" ? "sg-left sg-active" : "sg-left"
                      }
                    >
                      Rounds
                    </button>
                    <button
                      onClick={() => {
                        setPeriod("mths");
                      }}
                      className={
                        period === "mths" ? "sg-right sg-active" : "sg-right"
                      }
                    >
                      Dates
                    </button>
                  </div>
                </div>
              </div>
              {period === "mths" && (
                <>
                  <div className="drp-container">
                    <DateRangePicker
                      className="aca-drp"
                      color="#6ba390"
                      minDate={minDate}
                      maxDate={maxDate}
                      onChange={(e) => {
                        console.log(e);
                        setDateRge(e.selection);
                      }}
                      ranges={[dateRge]}
                    />
                  </div>
                </>
              )}
              {period === "rds" && (
                <div className="flex flex-wrap ec-box">
                  <div className="input-group">
                    <p>From</p>
                    <Dropdown
                      options={rdOpts}
                      value={rdAmt1}
                      onChange={(e) => setRdAmt1(e.value)}
                    />
                  </div>
                  <div className="input-group">
                    <p>To</p>
                    <Dropdown
                      options={rdOpts}
                      value={rdAmt2}
                      onChange={(e) => setRdAmt2(e.value)}
                    />
                  </div>
                </div>
              )}
              <div className="flex-center mt-40">
                <button className="default-button">Generate</button>
              </div>
            </div>
          </Dialog>

          {showDL && <CSVDownload data={toDL} />}

          {showExport && (
            <CSVStatsSelector
              stats={stats}
              grossStats={grossStats}
              close={() => setShowExport(false)}
              dl={(arr) => {
                setToDL(arr);
                update();
                setShowDL(true);
              }}
            />
          )}
          {!hideTop && (
            <>
              <BackRow action={() => navigate(-1)} />
              <PageHeader text={`Pro Stats - ${playerDetails.name}`} />

              <div className="pl-20 quick-links">
                <Pill
                  text={"Add Round"}
                  onClick={() => {
                    setToShow(
                      <StatsRoundInput
                        id={userID}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                />
                {false && (
                  <Pill
                    text={" "}
                    onClick={() => {
                      setToShow(
                        <ClientDrillLoader close={() => setShow(false)} />
                      );
                      setShow(true);
                    }}
                  />
                )}
              </div>

              <div className="gre-meu ec-tabs mb-10 ml-20">
                <Tabs value={sel} onChange={(e, v) => setSel(v)}>
                  <Tab label="Numbers" value={"numbers"} />
                  <Tab label="Graphs" value={"graphs"} />
                  <Tab label="Correlation" value={"correl"} />
                  {userDetails && (userDetails.admin || userDetails.test) && (
                    <Tab value={"deviation"} label="Deviation" />
                  )}
                  <Tab label="Courses" value={"courses"} />
                  <Tab label="Rounds" value={"rounds"} />

                  {userDetails && (userDetails.admin || userDetails.test) && (
                    <Tab value={"drills"} label="Drills" />
                  )}
                </Tabs>
              </div>
            </>
          )}
          {sel === "numbers" && (
            <>
              <div className="clista-box flex">
                <div className="clista-left">
                  <div className="cr-search">
                    <SearchInput setVal={setSearch} val={search} />
                  </div>
                  {dropts.map((item, i) => {
                    let c = item.label ?? "";
                    if (search !== "") {
                      if (!c.toLowerCase().includes(search.toLowerCase())) {
                        return null;
                      }
                    }

                    return (
                      <div
                        key={`${i}cli-item`}
                        onClick={() => {
                          if (item.value === "overall") {
                            setSelStatType("stats");
                          }
                          setSelStat(item.value);
                          const st = statsMap[item.value];
                          console.log(st.grossStats);
                          setStats(st.stats);
                          setGrossStats(st.grossStats);
                        }}
                        className={
                          selStat === item.value
                            ? "csl-item csli-active"
                            : "csl-item"
                        }
                      >
                        <p>{item.label}</p>
                      </div>
                    );
                  })}
                </div>

                <div className="clista-right">
                  {selStat !== "overall" && (
                    <div className="ec-tabs gre-meu mb-20">
                      <Tabs
                        value={selStatType}
                        onChange={(e, v) => setSelStatType(v)}
                      >
                        <Tab label="Statistics" value={"stats"} />
                        <Tab label="Summary" value={"summary"} />
                      </Tabs>
                    </div>
                  )}

                  {selStatType === "stats" && (
                    <>
                      {" "}
                      <div className="bs-subtle wmc bss-white pd-10">
                        <div className="flex wmc flex-align-center">
                          <div className="flex flex-align-center">
                            <div className="selector-group sg-small mr-20">
                              <button
                                onClick={() => {
                                  setGross(true);
                                }}
                                className={
                                  gross ? "sg-left sg-active" : "sg-left"
                                }
                              >
                                Gross
                              </button>
                              <button
                                onClick={() => {
                                  setGross(false);
                                }}
                                className={
                                  !gross ? "sg-right sg-active" : "sg-right"
                                }
                              >
                                Net
                              </button>
                            </div>
                          </div>
                          {false && (
                            <div
                              onClick={() => setShowCustomPeriod(true)}
                              className="pill-button shadow-md mr-20"
                            >
                              <p>Custom Period</p>
                            </div>
                          )}
                          <div
                            onClick={() => setShowExport(true)}
                            className="pill-button shadow-md"
                          >
                            <p>Export</p>
                          </div>
                        </div>
                      </div>
                      <div className="bs-subtle bss-white pb-20">
                        <div className="neh-box mt-20">
                          <h2 className="ne-header">Scoring</h2>
                        </div>
                        <div className="rs-box flex flex-wrap">
                          <StatsItem
                            title="Scoring Average"
                            stat={gross ? stats.averageGross : stats.averageNet}
                            hideComp={selStat === "overall"}
                            comp={
                              gross ? ogStats.averageGross : ogStats.averageNet
                            }
                            better={
                              (gross &&
                                ogStats.averageGross > stats.averageGross) ||
                              (!gross && ogStats.averageNet > stats.averageNet)
                            }
                          />
                          <StatsItem
                            grey
                            title="Total Rounds"
                            stat={stats.totalRounds}
                            hideComp={selStat === "overall"}
                            comp={ogStats.totalRounds}
                          />
                          <StatsItem
                            grey
                            title={"Par 3 Average"}
                            stat={
                              !gross
                                ? stats.averageParThrees
                                : grossStats.averageParThrees
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? ogGrossStats.averageParThrees
                                : ogStats.averageParThrees
                            }
                            better={
                              (gross &&
                                ogGrossStats.averageParThrees >
                                  grossStats.averageParThrees) ||
                              (!gross &&
                                ogStats.averageParThrees >
                                  stats.averageParThrees)
                            }
                          />
                          <StatsItem
                            hideComp={selStat === "overall"}
                            title={"Par 4 Average"}
                            stat={
                              !gross
                                ? stats.averageParFours
                                : grossStats.averageParFours
                            }
                            comp={
                              gross
                                ? ogGrossStats.averageParFours
                                : ogStats.averageParFours
                            }
                            better={
                              (gross &&
                                ogGrossStats.averageParFours >
                                  grossStats.averageParFours) ||
                              (!gross &&
                                ogStats.averageParFours > stats.averageParFours)
                            }
                          />
                          <StatsItem
                            comp={
                              gross
                                ? ogGrossStats.averageParFives
                                : ogStats.averageParFives
                            }
                            better={
                              (gross &&
                                ogGrossStats.averageParFives >
                                  grossStats.averageParFives) ||
                              (!gross &&
                                ogStats.averageParFives > stats.averageParFives)
                            }
                            hideComp={selStat === "overall"}
                            title={"Par 5 Average"}
                            stat={
                              !gross
                                ? stats.averageParFives
                                : grossStats.averageParFives
                            }
                          />
                          <StatsItem
                            grey
                            title={"Birdie Conversion"}
                            stat={
                              !(
                                (stats.netBirdieAttempts > 0 ||
                                  stats.totalNetGir > 0) &&
                                stats.totalGIR > 0
                              )
                                ? "0"
                                : !gross
                                ? `${Math.round(
                                    (stats.totalBirdies /
                                      stats.netBirdieAttempts ??
                                      stats.totalNetGir) * 100
                                  )}%`
                                : `${Math.round(
                                    (grossStats.totalBirdies / stats.totalGIR) *
                                      100
                                  )}%`
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              !(
                                (ogStats.netBirdieAttempts > 0 ||
                                  ogStats.totalNetGir > 0) &&
                                ogStats.totalGIR > 0
                              )
                                ? "0"
                                : !gross
                                ? `${Math.round(
                                    (ogStats.totalBirdies /
                                      ogStats.netBirdieAttempts ??
                                      ogStats.totalNetGir) * 100
                                  )}%`
                                : `${Math.round(
                                    (ogGrossStats.totalBirdies /
                                      ogStats.totalGIR) *
                                      100
                                  )}%`
                            }
                            better={
                              (!gross &&
                                ogStats.totalBirdies / ogStats.totalNetGir <
                                  stats.totalBirdies / stats.totalNetGir) ||
                              ogGrossStats.totalBirdies / ogStats.totalGIR <
                                grossStats.totalBirdies / stats.totalGIR
                            }
                          />
                          <StatsItem
                            title={"Bounce Back Birdies"}
                            stat={
                              !gross
                                ? stats.netBounceBackBirdies
                                : stats.grossBounceBackBirdies
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              !gross
                                ? ogStats.netBounceBackBirdies
                                : ogStats.grossBounceBackBirdies
                            }
                            better={false}
                            //   better={gross && stats.grossBounceBackBirdies > }
                          />
                        </div>
                        <div className="neh-box mt-20">
                          <h2 className="ne-header">Holes</h2>
                        </div>
                        <div className="rs-box flex flex-wrap">
                          <StatsItem
                            title={"Average Eagles"}
                            stat={
                              !gross
                                ? stats.averageEagles
                                : grossStats.averageEagles
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? ogGrossStats.averageEagles
                                : ogStats.averageEagles
                            }
                            better={
                              (gross &&
                                ogGrossStats.averageEagles <
                                  grossStats.averageEagles) ||
                              (!gross &&
                                ogStats.averageEagles < stats.averageEagles)
                            }
                          />
                          <StatsItem
                            grey
                            title={"Average Birdies"}
                            stat={
                              !gross
                                ? stats.averageBirdies
                                : grossStats.averageBirdies
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? ogGrossStats.averageBirdies
                                : ogStats.averageBirdies
                            }
                            better={
                              (gross &&
                                ogGrossStats.averageBirdies <
                                  grossStats.averageBirdies) ||
                              (!gross &&
                                ogStats.averageBirdies < stats.averageBirdies)
                            }
                          />
                          <StatsItem
                            grey
                            title={"Average Pars"}
                            stat={
                              !gross
                                ? stats.averagePars
                                : grossStats.averagePars
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? ogGrossStats.averagePars
                                : ogStats.averagePars
                            }
                            better={
                              (gross &&
                                ogGrossStats.averagePars <
                                  grossStats.averagePars) ||
                              (!gross &&
                                ogStats.averagePars < stats.averagePars)
                            }
                          />
                          <StatsItem
                            title={"Average Bogies"}
                            stat={
                              !gross
                                ? stats.averageBogies
                                : grossStats.averageBogies
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? ogGrossStats.averageBogies
                                : ogStats.averageBogies
                            }
                            better={
                              (gross &&
                                ogGrossStats.averageBogies >
                                  grossStats.averageBogies) ||
                              (!gross &&
                                ogStats.averageBogies > stats.averageBogies)
                            }
                          />
                          <StatsItem
                            title={"Average Doubles"}
                            stat={
                              !gross
                                ? stats.averageDoubles
                                : grossStats.averageDoubles
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? ogGrossStats.averageDoubles
                                : ogStats.averageDoubles
                            }
                            better={
                              (gross &&
                                ogGrossStats.averageDoubles >
                                  grossStats.averageDoubles) ||
                              (!gross &&
                                ogStats.averageDoubles > stats.averageDoubles)
                            }
                          />
                          <StatsItem
                            title={"Average Other"}
                            grey
                            stat={
                              !gross
                                ? stats.averageOthers
                                : grossStats.averageOthers
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? ogGrossStats.averageOthers
                                : ogStats.averageOthers
                            }
                            better={
                              (gross &&
                                ogGrossStats.averageOthers >
                                  grossStats.averageOthers) ||
                              (!gross &&
                                ogStats.averageOthers > stats.averageOthers)
                            }
                          />
                        </div>
                        <div className="neh-box mt-20">
                          <h2 className="ne-header">Approach</h2>
                        </div>
                        <div className="rs-box flex flex-wrap">
                          <StatsItem
                            title={"Greens in Regulation"}
                            stat={
                              gross
                                ? `${Math.round(
                                    (stats.averageGIR / 18) * 100
                                  )}%`
                                : `${Math.round(
                                    (stats.averageNetGIR / 18) * 100
                                  )}%`
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? `${Math.round(
                                    (ogStats.averageGIR / 18) * 100
                                  )}%`
                                : `${Math.round(
                                    (ogStats.averageNetGIR / 18) * 100
                                  )}%`
                            }
                            better={
                              (gross &&
                                ogStats.averageGIR < stats.averageGIR) ||
                              (!gross &&
                                ogStats.averageNetGIR < stats.averageNetGIR)
                            }
                          />
                          <StatsItem
                            grey
                            title={"Greens in Regulation - Par 3s"}
                            stat={
                              gross
                                ? `${Math.round(
                                    (stats.parThreeGrossGIRs /
                                      stats.totalParThrees) *
                                      100
                                  )}%`
                                : `${Math.round(
                                    (stats.parThreeNetGIRs /
                                      stats.totalParThrees) *
                                      100
                                  )}%`
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? `${Math.round(
                                    (ogStats.parThreeGrossGIRs /
                                      ogStats.totalParThrees) *
                                      100
                                  )}%`
                                : `${Math.round(
                                    (ogStats.parThreeNetGIRs /
                                      ogStats.totalParThrees) *
                                      100
                                  )}%`
                            }
                            better={
                              (gross &&
                                ogStats.parThreeGrossGIRs /
                                  ogStats.totalParThrees <
                                  stats.parThreeGrossGIRs /
                                    stats.totalParThrees) ||
                              (!gross &&
                                ogStats.parThreeNetGIRs /
                                  ogStats.totalParThrees <
                                  stats.parThreeNetGIRs /
                                    stats.parThreeGrossGIRs)
                            }
                          />
                          <StatsItem
                            grey
                            title={"Greens in Regulation - Par 4s"}
                            stat={
                              gross
                                ? `${Math.round(
                                    (stats.parFourGrossGIRs /
                                      stats.totalParFours) *
                                      100
                                  )}%`
                                : `${Math.round(
                                    (stats.parFourNetGIRs /
                                      stats.totalParFours) *
                                      100
                                  )}%`
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? `${Math.round(
                                    (ogStats.parFourGrossGIRs /
                                      ogStats.totalParFours) *
                                      100
                                  )}%`
                                : `${Math.round(
                                    (ogStats.parFourNetGIRs /
                                      ogStats.totalParFours) *
                                      100
                                  )}%`
                            }
                            better={
                              (gross &&
                                ogStats.parFourGrossGIRs /
                                  ogStats.totalParFours <
                                  stats.parFourGrossGIRs /
                                    stats.totalParFours) ||
                              (!gross &&
                                ogStats.parFourNetGIRs / ogStats.totalParFours <
                                  stats.parFourNetGIRs / stats.parFourGrossGIRs)
                            }
                          />
                          <StatsItem
                            title={"Greens in Regulation - Par 5s"}
                            stat={
                              gross
                                ? `${Math.round(
                                    (stats.parFiveGrossGIRs /
                                      stats.totalParFives) *
                                      100
                                  )}%`
                                : `${Math.round(
                                    (stats.parFiveNetGIRs /
                                      stats.totalParFives) *
                                      100
                                  )}%`
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              gross
                                ? `${Math.round(
                                    (ogStats.parFiveGrossGIRs /
                                      ogStats.totalParFives) *
                                      100
                                  )}%`
                                : `${Math.round(
                                    (ogStats.parFiveNetGIRs /
                                      ogStats.totalParFives) *
                                      100
                                  )}%`
                            }
                            better={
                              (gross &&
                                ogStats.parFiveGrossGIRs /
                                  ogStats.totalParFives <
                                  stats.parFiveGrossGIRs /
                                    stats.totalParFives) ||
                              (!gross &&
                                ogStats.parFiveNetGIRs / ogStats.totalParFives <
                                  stats.parFiveNetGIRs / stats.parFiveGrossGIRs)
                            }
                          />
                          {selStat === "overall" && (
                            <StatsItem
                              title={"Par 3 Average (GIR)"}
                              stat={
                                !gross
                                  ? stats.parThreeNetGIRScoringAverage
                                  : stats.parThreeGrossGIRScoringAverage
                              }
                            />
                          )}
                          {selStat === "overall" && (
                            <StatsItem
                              grey
                              title={"Par 4 Average (GIR)"}
                              stat={
                                !gross
                                  ? stats.parFourNetGIRScoringAverage
                                  : stats.parFourGrossGIRScoringAverage
                              }
                            />
                          )}
                          {selStat === "overall" && (
                            <StatsItem
                              grey
                              title={"Par 5 Average (GIR)"}
                              stat={
                                !gross
                                  ? stats.parFiveNetGIRScoringAverage
                                  : stats.parFiveGrossGIRScoringAverage
                              }
                            />
                          )}
                          <StatsItem
                            title={"Scrambling"}
                            stat={
                              !(
                                stats.totalNetScrambleAttempts > 0 &&
                                stats.totalGrossScrambleAttempts > 0
                              )
                                ? "0%"
                                : !gross
                                ? `${Math.round(
                                    (stats.totalNetScrambles /
                                      stats.totalNetScrambleAttempts) *
                                      100
                                  )}%`
                                : `${Math.round(
                                    (stats.totalGrossScrambles /
                                      stats.totalGrossScrambleAttempts) *
                                      100
                                  )}%`
                            }
                            comp={
                              !(
                                ogStats.totalNetScrambleAttempts > 0 &&
                                ogStats.totalGrossScrambleAttempts > 0
                              )
                                ? "0%"
                                : !gross
                                ? `${Math.round(
                                    (ogStats.totalNetScrambles /
                                      ogStats.totalNetScrambleAttempts) *
                                      100
                                  )}%`
                                : `${Math.round(
                                    (ogStats.totalGrossScrambles /
                                      ogStats.totalGrossScrambleAttempts) *
                                      100
                                  )}%`
                            }
                            hideComp={selStat === "overall"}
                            better={
                              (gross &&
                                ogStats.totalGrossScrambles /
                                  ogStats.totalGrossScrambleAttempts <
                                  stats.totalGrossScrambles /
                                    ogStats.totalGrossScrambleAttempts) ||
                              (!gross &&
                                ogStats.totalNetScrambles /
                                  ogStats.totalNetScrambleAttempts <
                                  stats.totalNetScrambles /
                                    stats.totalNetScrambleAttempts)
                            }
                          />
                          <StatsItem
                            title={"Sand Saves"}
                            grey={selStat !== "overall"}
                            stat={
                              stats.sandyAttempts > 0
                                ? `${Math.round(stats.sandSave * 100)}%`
                                : "0%"
                            }
                            comp={ogStats.sandSave * 100}
                            hideComp={selStat === "overall"}
                            better={ogStats.sandSave < stats.sandSave}
                          />
                        </div>
                        <div className="neh-box mt-20">
                          <h2 className="ne-header">Off the Tee</h2>
                        </div>
                        <div className="rs-box flex flex-wrap">
                          <StatsItem
                            title={"Average Fairways In Regulation"}
                            stat={`${Math.round(
                              (stats.averageFIR / 14) * 100
                            )}%`}
                            hideComp={selStat === "overall"}
                            comp={`${Math.round(
                              (ogStats.averageFIR / 14) * 100
                            )}%`}
                            better={ogStats.averageFIR < stats.averageFIR}
                          />
                          {selStat === "overall" && (
                            <StatsItem
                              grey
                              hideComp={selStat === "overall"}
                              title={"Par 4 Average (FIR)"}
                              stat={
                                !gross
                                  ? stats.parFourNetFIRScoringAverage
                                  : stats.parFourGrossFIRScoringAverage
                              }
                              comp={
                                !gross
                                  ? ogStats.parFourNetFIRScoringAverage
                                  : ogStats.parFourGrossFIRScoringAverage
                              }
                              better={
                                (gross &&
                                  ogStats.parFourGrossFIRScoringAverage >
                                    stats.parFourGrossFIRScoringAverage) ||
                                (!gross &&
                                  ogStats.parFourNetFIRScoringAverage >
                                    stats.parFourNetFIRScoringAverage)
                              }
                            />
                          )}
                          {selStat === "overall" && (
                            <StatsItem
                              grey
                              title={"Par 5 Average (FIR)"}
                              stat={
                                !gross
                                  ? stats.parFiveNetFIRScoringAverage
                                  : stats.parFiveGrossFIRScoringAverage
                              }
                              comp={
                                !gross
                                  ? ogStats.parFiveNetFIRScoringAverage
                                  : ogStats.parFiveGrossFIRScoringAverage
                              }
                              hideComp={selStat === "overall"}
                              better={
                                (gross &&
                                  ogStats.parFiveGrossFIRScoringAverage >
                                    stats.parFiveGrossFIRScoringAverage) ||
                                (!gross &&
                                  ogStats.parFiveNetFIRScoringAverage >
                                    stats.parFiveNetFIRScoringAverage)
                              }
                            />
                          )}
                        </div>
                        <div className="neh-box mt-20">
                          <h2 className="ne-header">Putting</h2>
                        </div>
                        <div className="rs-box flex flex-wrap">
                          <StatsItem
                            title="Average Putts"
                            hideComp={selStat === "overall"}
                            comp={ogStats.averagePutts}
                            stat={stats.averagePutts}
                            better={ogStats.averagePutts > stats.averagePutts}
                          />
                          <StatsItem
                            grey
                            title="Putts per Hole"
                            hideComp={selStat === "overall"}
                            stat={
                              Math.round((stats.averagePutts / 18) * 100) / 100
                            }
                            comp={
                              Math.round((ogStats.averagePutts / 18) * 100) /
                              100
                            }
                            better={ogStats.averagePutts > stats.averagePutts}
                          />
                          <StatsItem
                            grey
                            title="1 Putts per Round"
                            stat={
                              Math.round(
                                (stats.onePutts / stats.totalRounds) * 100
                              ) / 100
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              Math.round(
                                (ogStats.onePutts / ogStats.totalRounds) * 100
                              ) / 100
                            }
                            better={
                              ogStats.onePutts / ogStats.totalRounds >
                              stats.onePutts / stats.totalRounds
                            }
                          />
                          <StatsItem
                            title="3 Putts per Round"
                            stat={
                              Math.round(
                                (stats.threePutts / stats.totalRounds) * 100
                              ) / 100
                            }
                            hideComp={selStat === "overall"}
                            comp={
                              Math.round(
                                (ogStats.threePutts / ogStats.totalRounds) * 100
                              ) / 100
                            }
                            better={
                              ogStats.threePutts / ogStats.totalRounds <
                              stats.threePutts / stats.totalRounds
                            }
                          />
                        </div>
                        {sg && selStat === "overall" && (
                          <>
                            <div className="neh-box mt-20">
                              <h2 className="ne-header">Strokes Gained</h2>
                            </div>
                            <div className="rs-box flex flex-wrap">
                              <StatsItem stat={sg.firAve} title="Off the Tee" />
                              <StatsItem
                                stat={-sg.girAve}
                                title="Approach"
                                grey
                              />

                              <StatsItem
                                stat={sg.udAve}
                                title="Around the Green"
                                grey
                              />

                              <StatsItem stat={-sg.puttAve} title="Putting" />
                            </div>
                          </>
                        )}{" "}
                      </div>
                    </>
                  )}
                  {selStatType === "summary" && (
                    <ClientSeasonSummary
                      stats={stats}
                      grossStats={grossStats}
                      comps={ogStats}
                      grossComps={ogGrossStats}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          {sel === "graphs" && (
            <>
              <ClientStatsGraphs
                overallStats={ogStats}
                rounds={rds}
                stats={snaps}
                userID={userID}
                lengthStats={lengthStats}
              />
            </>
          )}
          {sel === "correl" && (
            <>
              <StatsCorrelation userID={userID} rounds={rds} />
            </>
          )}
          {sel === "courses" && (
            <>
              <div className="clista-box flex">
                <div className="clista-left">
                  {courses.map((course, i) => {
                    return (
                      <div
                        key={`${i}course-item`}
                        onClick={() => {
                          setSelCourse(course);
                        }}
                        className={
                          selCourse.courseID === course.courseID
                            ? "csl-item csli-active"
                            : "csl-item"
                        }
                      >
                        <p>{course.name}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="clista-right ccp-right">
                  {selCourse !== "" && (
                    <ClientCourseProfile course={selCourse} userID={userID} />
                  )}
                </div>
              </div>
            </>
          )}
          {sel === "drills" && (
            <>
              <ClientDrills
                clubID={portalSettings && portalSettings.clubID}
                clientID={userID}
                drillIDs={drillIDs}
                clientname={playerDetails.name}
                reload={loadDrills}
                hideTop={setHideTop}
              />
            </>
          )}
          {sel === "rounds" && (
            <>
              <ClientRounds userID={userID} />
            </>
          )}

          {sel === "deviation" && (
            <StandardDeviation rounds={rds} userID={userID} />
          )}
        </>
      )}
    </div>
  );
}

export default ClientStats;
