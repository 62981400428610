export function makeID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function yearsSince2022() {
  const dt = new Date();
  const yr = dt.getFullYear();
  const diff = yr - 2022;
  const arr = [];
  for (let i = 0; i < diff + 1; i++) {
    arr.push(2022 + i);
  }
  return arr;
}

export const featuresProp = {
  events: true,
  leagues: true,
  proStats: false,
  playerUploads: true,
  hcpUploads: true,
  teamLeagues: true,
  scorecardExports: true,
  eventExports: true,
  matchplayTournaments: true,
  cups: true,
  contests: true,
};

export const premiumFeatures = [
  "proStats",
  "playerUploads",
  // "contests",
  "hcpUploads",
  "scorecardExports",
  "eventExports",
];

export const featureVals = {
  events: "Events",
  leagues: "Leagues",
  proStats: "Pro Stats",
  playerUploads: "Player Uploads",
  hcpUploads: "Handicap Uploads",
  teamLeagues: "Team Leagues",
  matchplayTournaments: "Matchplay Tournaments",
  cups: "Cups",
  contests: "Contests",
  scorecardExports: "Scorecard Exports",
  eventExports: "Event Exports",
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const featureDetails = [
  {
    title: "Pro Stats",
    desc: <></>,
    key: "proStats",
    cost: "Varied",
  },
  {
    title: "Player Uploads",
    desc: (
      <>
        <p>
          Create player accounts in bulk using .csv uploads, as opposed to
          creating each profile individually. Upload up to 100 players at a
          time.
        </p>
      </>
    ),
    key: "playerUploads",
    cost: 500,
  },
  {
    title: "Handicap Uploads",
    desc: (
      <>
        <p>
          Update your member's handicaps with a simple .csv file upload, as
          opposed to updating each profile 1 by 1. Update as many players as you
          like in one go.
        </p>
      </>
    ),
    key: "hcpUploads",
    cost: 500,
  },
  {
    title: "Scorecard Creator",
    desc: (
      <>
        <p>
          Create & print fully custoizable, pre populated scorecards for your
          players, including stroke allocations and more.
        </p>
      </>
    ),
    key: "scorecardExports",
    cost: 500,
  },
  {
    title: "Event Exports",
    desc: (
      <>
        <p>
          Export tee times, leaderboards and other results/details pre & post
          event. Exports are available in PDF and CSV format.
        </p>
      </>
    ),
    key: "eventExports",
    cost: 500,
  },
];

export const ctCodes = {
  Afghanistan: "AF",
  "Aland Islands": "AX",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Indian Ocean Territory": "IO",
  "Brunei Darussalam": "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  "Cabo Verde": "CV",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands": "CC",
  Colombia: "CO",
  Comoros: "KM",
  Congo: "CG",
  "Congo, Democratic Republic of the": "CD",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Eswatini: "SZ",
  Ethiopia: "ET",
  "Falkland Islands (Malvinas)": "FK",
  "Faroe Islands": "FO",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Heard Island and McDonald Islands": "HM",
  "Holy See": "VA",
  Honduras: "HN",
  "Hong Kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR",
  Iraq: "IQ",
  Ireland: "IE",
  "Isle of Man": "IM",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  "Korea, Democratic People's Republic of": "KP",
  "Korea, Republic of": "KR",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  "Lao People's Democratic Republic": "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macao: "MO",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  "Micronesia (Federated States of)": "FM",
  "Moldova (Republic of)": "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "North Macedonia": "MK",
  "Northern Mariana Islands": "MP",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  "Palestine, State of": "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Pitcairn: "PN",
  Poland: "PL",
  Portugal: "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  Réunion: "RE",
  Romania: "RO",
  "Russian Federation": "RU",
  Rwanda: "RW",
  "Saint Barthélemy": "BL",
  "Saint Helena, Ascension and Tristan da Cunha": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin (French part)": "MF",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent and the Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  "Sint Maarten (Dutch part)": "SX",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Georgia and the South Sandwich Islands": "GS",
  "South Sudan": "SS",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  "Svalbard and Jan Mayen": "SJ",
  Sweden: "SE",
  Switzerland: "CH",
  "Syrian Arab Republic": "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  "Tanzania, United Republic of": "TZ",
  Thailand: "TH",
  "Timor-Leste": "TL",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks and Caicos Islands": "TC",
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United Kingdom of Great Britain and Northern Ireland": "GB",
};

export const errDefault =
  "Sorry, we encountered a technical difficulty there - please try again.";

export function getStartOfMonth(month, year) {
  // Note: month is 0-indexed (0 = January, 11 = December)
  const date = new Date(year, month, 1);
  date.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, milliseconds to zero
  return date;
}

export function getEndOfMonth(month, year) {
  // Note: month is 0-indexed (0 = January, 11 = December)
  const date = new Date(year, month + 1, 0); // This creates a Date object for the last day of the specified month
  date.setHours(23, 59, 59, 999); // Set hours, minutes, seconds, milliseconds to the end of the day
  return date;
}

export function convertCentsToDollars(cents) {
  return (cents / 100).toFixed(2);
}

export function getMonthsBetweenDates(date1, date2) {
  // Ensure date1 is before date2
  if (date1 > date2) [date1, date2] = [date2, date1];

  let startDate = new Date(date1.getFullYear(), date1.getMonth(), 1);
  let endDate = new Date(date2.getFullYear(), date2.getMonth(), 1);
  let months = [];

  while (startDate <= endDate) {
    let year = startDate.getFullYear();
    let month = startDate.getMonth() + 1; // Add 1 to month to match ISO 8601 format
    let isoDateString = `${year}-${month.toString().padStart(2, "0")}-01`;
    let lastDayOfMonth = new Date(year, month, 0); // Get last day of current month

    let label = lastDayOfMonth.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    let value = isoDateString;

    months.push({ label, value });

    startDate.setMonth(startDate.getMonth() + 1); // Move to next month
  }

  return months;
}

export function returnHandicap(hcp) {
  if (hcp < 0) {
    return `+${Math.abs(hcp)}`;
  } else {
    return hcp;
  }
}
export function daysBetween(date1, date2) {
  // Convert both dates to milliseconds
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const timeDifference = Math.abs(date2 - date1);

  // Calculate the difference in days
  const daysDifference = Math.ceil(timeDifference / oneDayInMilliseconds);

  return daysDifference;
}

export function validateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }

  return false;
}
