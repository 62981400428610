import React, { useState, useEffect, useCallback } from "react";
import "./Onboarding.css";
import { db, admin, auth, storage } from "../../firebase";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Cropper from "react-easy-crop";
import Compressor from "compressorjs";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import { Country, State, City } from "country-state-city";
import { Autocomplete, Backdrop, TextField } from "@mui/material";

import BackdropLoader from "../Loaders/BackdropLoader";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";
import { Close } from "@mui/icons-material";
import ErrorModal from "../ErrorModal/ErrorModal";
import PageHeader from "../display/Rows/PageHeader";

const stats = {
  majorWins: 0,
  seasonWins: 0,
  seasonTopThrees: 0,
  seasonTopTens: 0,
  seasonsPlayed: 0,
  eventRounds: 0,
  courseIDs: [],
  lowestEventNet: 0,
  lowestEventGross: 0,
  eventNetAverage: 0,
  eventGrossAverage: 0,
  customNetAverage: 0,
  customGrossAverage: 0,
  playoffsContested: 0,
  playoffsWon: 0,
  puttsPerGIR: 0,
  parFourFIRs: 0,
  parFiveFIRs: 0,
  parFourGrossFIRs: 0,
  parFiveGrossFIRs: 0,
  points: 0,
  totalPoints: 0,
  currentWinStreak: 0,
  highestWinStreak: 0,
  currentTopTenStreak: 0,
  highestTopTenStreak: 0,
  currentNoThreePuttStreak: 0,
  netBogeyFreeRounds: 0,
  grossBogeyFreeRounds: 0,
  totalNetGir: 0,
  sandHoleouts: 0,
  sandies: 0,
  sandyAttempts: 0,
  averageDTP: 0,
  dtpHoles: 0,
  mostPuttsMade: 0,
  longestPutt: 0,
  averageBirdies: 0,
  averageBogies: 0,
  averageDoubles: 0,
  averageEagles: 0,
  averageFIR: 0,
  averageGIR: 0,
  averageNetGIR: 0,
  averageGross: 0,
  averageNet: 0,
  averageParFives: 0,
  averageParFours: 0,
  averageParThrees: 0,
  averagePars: 0,
  averagePutts: 0,
  totalRounds: 0,
  totalHoles: 0,
  totalBirdies: 0,
  totalBogies: 0,
  totalDoubles: 0,
  totalEagles: 0,
  totalFIR: 0,
  totalGIR: 0,
  totalParFives: 0,
  totalParFours: 0,
  totalParThrees: 0,
  totalPars: 0,
  totalPutts: 0,
  netGIRPutts: 0,
  grossGIRPutts: 0,
  parThreeNetGIRs: 0,
  parFourNetGIRs: 0,
  parFiveNetGIRs: 0,
  parThreeGrossGIRs: 0,
  parFourGrossGIRs: 0,
  parFiveGrossGIRs: 0,
  wins: 0,
  topTwentyFinishes: 0,
  topTenFinishes: 0,
  topThreeFinishes: 0,
  finishes: [],
  parThreeGrossGIRScoringAverage: 0,
  parFourGrossGIRScoringAverage: 0,
  parFiveGrossGIRScoringAverage: 0,
  parThreeGrossFIRScoringAverage: 0,
  parFourGrossFIRScoringAverage: 0,
  parFiveGrossFIRScoringAverage: 0,
  girGrossAverageToPar: 0,
  firGrossAverageToPar: 0,
  parThreeNetGIRScoringAverage: 0,
  parFourNetGIRScoringAverage: 0,
  parFiveNetGIRScoringAverage: 0,
  parThreeNetFIRScoringAverage: 0,
  parFourNetFIRScoringAverage: 0,
  parFiveNetFIRScoringAverage: 0,
  girNetAverageToPar: 0,
  firNetAverageToPar: 0,
  lowestGrossNineHoleScore: 0,
  lowestNetNineHoleScore: 0,
  totalGrossScrambleAttempts: 0,
  totalNetScrambleAttempts: 0,
  totalGrossScrambles: 0,
  totalNetScrambles: 0,
  holeOuts: 0,
  onePutts: 0,
  threePutts: 0,
  grossBounceBackBirdies: 0,
  netBounceBackBirdies: 0,
  percentagePointsWon: 0,
  grossBirdieStreak: 0,
  netBirdieStreak: 0,
  highestGirStreak: 0,
  highestFirStreak: 0,
  highestNetGirStreak: 0,
  currentOnePuttStreak: 0,
  highestNoThreePuttStreak: 0,
  highestOnePuttStreak: 0,
  currentGrossSubParRoundsStreak: 0,
  currentNetSubParRoundsStreak: 0,
  highestGrossSubParRoundsStreak: 0,
  highestNetSubParRoundsStreak: 0,
  grossSubParHolesStreak: 0,
  netSubParHolesStreak: 0,
  averagePuttLength: 0,
  totalPuttLength: 0,
  netBirdieAttempts: 0,
  grossBirdieAttempts: 0,
  lowestRound: 0,
  lowestRoundGross: 0,
  highestRound: 0,
  highestRoundGross: 0,
  fewestPutts: 0,
  mostPutts: 0,
  totalDrives: 0,
  drivesCounted: 0,
  drivingDistance: 0,
  longestDrive: 0,
};
const grossStats = {
  averageBirdies: 0,
  averageBogies: 0,
  averageDoubles: 0,
  averageEagles: 0,
  averageOthers: 0,
  averageParFives: 0,
  averageParFours: 0,
  averageParThrees: 0,
  averagePars: 0,
  totalRounds: 0,
  totalHoles: 0,
  totalBirdies: 0,
  totalBogies: 0,
  totalDoubles: 0,
  totalEagles: 0,
  totalFIR: 0,
  totalGIR: 0,
  totalOthers: 0,
  totalParFives: 0,
  totalParFours: 0,
  totalParThrees: 0,
  totalPars: 0,
  totalBirdieAttempts: 0,
};
const onboarding = {
  home: false,
  performance: false,
  live: false,
  leagues: false,
  search: false,
  clubhouse: false,
  boardroom: false,
  eventCreator: false,
  eventHome: false,
  roundInput: false,
  leagueHome: false,
  seasonCreator: false,
  cupHome: false,
  cupCreator: false,
};

function Onboarding({ goBack = () => {}, edit = false }) {
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dob, setDob] = useState("");
  const [handicap, setHandicap] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [state, setState] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(edit);
  const [answerOne, setAnswerOne] = useState("");
  const [answerTwo, setAnswerTwo] = useState("");
  const [answerThree, setAnswerThree] = useState("");
  const [gender, setGender] = useState("male");

  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");

  const [questions, setQuestions] = useState([]);

  const [showCrop, setShowCrop] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setFC(croppedAreaPixels);
  };
  const [fc, setFC] = useState({});

  useEffect(() => {
    console.log(typeof image, typeof imageURL);
  }, [image]);
  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
    new Compressor(acceptedFiles[0], {
      convertSize: 1000000,
      success: (res) => {
        console.log(res);
        setImage(res);
        setShowCrop(true);
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
  });

  useEffect(() => {
    if (edit) {
      if (currentUser) {
        console.log(currentUser);
        loadUserDetails();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!edit) {
      loadCountries();
      loadQuestions();
    }
  }, []);

  async function loadUserDetails() {
    const profileDoc = await db.collection("users").doc(currentUser.uid).get();
    const profile = profileDoc.data();

    const {
      firstName,
      lastName,
      handicap,
      profileImage,
      country,
      city,
      state,
      profileQuestions,
      dob,
    } = profile;
    const dobDate = new Date(dob.toDate());
    const dobString = dobDate.toISOString().split("T")[0];
    const qs = profileQuestions.map((qna) => qna.question);
    console.log(profile);
    const countryObject = Country.getCountryByCode(country);
    countryObject.code = countryObject.isoCode;
    setCountryValue(countryObject.isoCode);
    setQuestions(qs);
    setAnswerOne(profileQuestions[0].answer);
    setAnswerTwo(profileQuestions[1].answer);
    setAnswerThree(profileQuestions[2].answer);
    setDob(dobString);
    loadCountries();
    setGender(profile.gender ?? "male");

    // setCountry(country);
    loadStates(countryObject);
    setState(state);
    loadCities(state);
    setCity(city);
    setImageURL(profileImage);
    setFirstName(firstName);
    setLastName(lastName);
    setLoading(false);
  }

  function shuffle(array) {
    // alert('everyday')
    // console.log(array)
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    // console.log(array)
    return array;
  }

  async function loadQuestions() {
    const questionDoc = await db
      .collection("admin")
      .doc("playerQuestions")
      .get();
    const doc = questionDoc.data();

    const { profile } = doc;

    const shuffledQuestions = shuffle(profile);
    const arr = [];
    for (let i = 0; i < 3; i++) {
      // const random = randomIntFromInterval(0, profile.length);
      arr.push(shuffledQuestions[i]);
    }

    // console.log(arr);
    setQuestions(arr);
  }

  async function loadCountries() {
    const cunts = Country.getAllCountries();
    const newArr = cunts.map((country) => {
      // console.log(country)
      const item = {
        name: country.name,
        label: country.name,
        value: country.name,
        code: country.isoCode,
      };
      return item;
    });
    // console.log(newArr)
    setCountries(newArr);
  }

  async function loadStates(country) {
    console.log(country);
    const states = State.getStatesOfCountry(country.code);

    for (let i = 0; i < states.length; i++) {
      states[i].label = states[i].name;
    }
    // return console.log(states);
    const countryDeets = Country.getCountryByCode(country.code);
    setCountry(countryDeets.name);
    setStates(states);
  }

  async function loadCities(state) {
    console.log(countryValue);
    const test = State.getStatesOfCountry(countryValue);
    console.log(test.length, state);
    let sc = "";
    let cc = "";
    for (let i = 0; i < test.length; i++) {
      if (test[i].name === state.name) {
        sc = test[i].isoCode;
        cc = test[i].countryCode;
      }
    }
    console.log(sc, cc);
    const cities = City.getCitiesOfState(cc, sc);

    const newArr = cities.map((city) => {
      const item = {
        name: city.name,
        value: city.name,
        label: city.name,
      };
      return item;
    });
    // console.log(newArr);
    setUpdate((current) => current + 1);
    setCities(newArr);
  }

  function validate() {
    setError("");

    // Names
    if (firstName === "") {
      setError("Please enter a first name");
      return false;
    }
    if (lastName === "") {
      setError("Please enter a last name");
      return false;
    }

    // Password
    if (password === "") {
      setError("Please enter a password");
      return false;
    }
    if (password !== confirmPassword) {
      setError("Please make sure the passwords match");
      return false;
    }

    // Dob
    if (dob === "") {
      setError("Please enter a date of birth");
      return false;
    }

    // Handicap
    if (handicap === "") {
      setError("Please enter a handicap");
      return false;
    }

    // Country
    if (country === "") {
      setError("Please enter a country");
      return false;
    }

    // State
    if (state === "") {
      setError("Please enter a state");
      return false;
    }

    // City
    if (city === "") {
      setError("Please enter a city");
      return false;
    }
  }

  async function saveUser() {
    setLoading(true);

    if (validate() === false) {
      return setLoading(false);
    }

    const allCountries = Country.getAllCountries();
    let cc = "";

    for (let i = 0; i < allCountries.length; i++) {
      if (allCountries[i].name === country) {
        cc = allCountries[i].code;
      }
    }

    const yardCunts = [
      "US",
      "CA",
      "JP",
      "GB",
      "KR",
      "CH",
      "TH",
      "IE",
      "PH",
      "VE",
      "CO",
      "DO",
      "ID",
      "VN",
      "KH",
    ];

    const settings = {
      units: yardCunts[cc] ? "yards" : "meters",
      inviteEmails: true,
      cancelEmails: true,
      privateProfile: false,
      friendsOnly: false,
      messageFriendsOnly: false,
    };

    const profileQuestions = [
      {
        question: questions[0],
        answer: answerOne,
      },
      {
        question: questions[1],
        answer: answerTwo,
      },
      {
        question: questions[2],
        answer: answerThree,
      },
    ];

    try {
      const newUser = await auth.createUserWithEmailAndPassword(
        emailAddress,
        password
      );

      await db
        .collection("users")
        .doc(newUser.user.uid)
        .set({
          totalRounds: 0,
          adminChat: false,
          state,
          onboarding,
          settings,
          emailAddress,
          firstName,
          lastName,
          handicap: parseInt(handicap),
          bio: "",
          country: cc,
          city,
          // age: 31,
          dob: new Date(dob),
          homeCourse: "",
          profileImage: "",
          leagueIDs: [],
          friends: [],
          premium: true,
          premiumMonth: false,
          premiumExpiry: "",
          premiumType: "indefinite",
          dateJoined: new Date(),
          lowestRound: "",
          highestRound: "",
          lowestRoundGross: "",
          highestRoundGross: "",
          lastRound: "",
          lastRoundGross: "",
          stats,
          grossStats,
          ugaStats: stats,
          ugaGrossStats: grossStats,
          totalUGARounds: 0,
          profileQuestions,
          careerPoints: 0,
          activeRoundID: "",
          activeEventID: "",
          activeCupID: 0,
          careerEarnings: 0,
          activeLeagueIDs: [],
          hcpArray: [],
          suspended: false,
          rankPoints: 0,
          nickname: "",
          eventsWon: [],
          status: "active",
          trophies: [],
          portalAccount: true,
          verified: false,
        });

      if (image !== "") {
        const task = storage
          .ref()
          .child(`${firstName}_${lastName}/${image.name}`)
          .put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            // console.log(`image url is ${url}`);
            db.collection("users").doc(newUser.user.uid).update({
              profileImage: url,
            });
          });
        });
      }

      setLoading(false);
      navigate("/");
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError("Could not upload your profile, please try again");
    }
  }

  async function saveChanges() {
    const allCountries = Country.getAllCountries();
    let cc = "";

    for (let i = 0; i < allCountries.length; i++) {
      if (allCountries[i].name === country) {
        cc = allCountries[i].code;
      }
    }

    // return console.log(cc);
    setLoading(true);
    const pqs = [];
    pqs.push({
      question: questions[0],
      answer: answerOne,
    });
    pqs.push({
      question: questions[1],
      answer: answerTwo,
    });
    pqs.push({
      question: questions[2],
      answer: answerThree,
    });

    try {
      await db
        .collection("users")
        .doc(currentUser.uid)
        .update({
          firstName,
          lastName,
          dob: new Date(dob),
          country: cc,
          state,
          city,
          handicap: parseFloat(handicap),
        });

      if (image !== "") {
        const task = storage
          .ref()
          .child(`${firstName}_${lastName}/${image.name}`)
          .put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            // console.log(`image url is ${url}`);
            db.collection("users").doc(currentUser.uid).update({
              profileImage: url,
            });
            goBack();
          });
        });
      } else {
        goBack();
      }
      toast.success("Profile successfully edited", { icon: successIco });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  async function cropImage() {
    const image = new Image();
    image.src = imageURL;
    const crop = fc;
    await new Promise((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");

    // console.log(croppedImage);
    // Create a new image element
    const newImage = new Image();

    // Set the source of the new image element to the cropped image data URL
    newImage.src = croppedImage;
    // Set croppedImage to a variable, use it within the function, but don't return it
    // console.log(newImage);
    setImage(croppedImage);
    setImageURL(croppedImage);
  }
  return (
    <div className="onboarding ">
      {loading && <BackdropLoader />}
      {showCrop && (
        <Backdrop className="crop-drop" open>
          <div className="crop-co">
            <div className="cd-close">
              <Close onClick={() => setShowCrop(false)} />
            </div>
            <div className="crop-box">
              <Cropper
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                image={imageURL}
              />
            </div>
            <div className="flex-center crop-but">
              <button
                onClick={() => {
                  cropImage();
                  setShowCrop(false);
                }}
                className="default-button"
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      <div onClick={() => navigate(-1)} className="back-row">
        <ArrowBack />
        <p>Back</p>
      </div>
      {/* <div className="page-header">
        <h2>{edit ? "Edit Profile" : "New Account"}</h2>
      </div> */}
      <PageHeader text={edit ? "Edit Profile" : "New Account"} />
      <div className="ph-msg">
        {edit ? (
          <p>Edit your profile here</p>
        ) : (
          <p>Fill in your details below to create an account</p>
        )}
      </div>
      <div className="ui-inputs">
        {!edit && (
          <div className="input-group">
            <p>Email Address</p>
            <input
              type="text"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              placeholder="Start typing..."
              className={
                error !== "" && emailAddress === ""
                  ? "default-input di-error"
                  : "default-input"
              }
            />
          </div>
        )}
        {!edit && (
          <div className="input-group">
            <p>Password</p>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Start typing..."
              className={
                error !== "" && password === ""
                  ? "default-input di-error"
                  : "default-input"
              }
            />
          </div>
        )}
        {!edit && (
          <div className="input-group">
            <p>Confirm Password</p>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              placeholder="Start typing..."
              className={
                error !== "" && password === ""
                  ? "default-input di-error"
                  : "default-input"
              }
            />
          </div>
        )}
        <div className="input-group">
          <p>First Name</p>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Start typing..."
            className={
              error !== "" && firstName === ""
                ? "default-input di-error"
                : "default-input"
            }
          />
        </div>
        <div className="input-group">
          <p>Last Name</p>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Start typing..."
            className={
              error !== "" && lastName === ""
                ? "default-input di-error"
                : "default-input"
            }
          />
        </div>

        <div className="input-group">
          <p>Date of Birth</p>
          <input
            type="date"
            value={dob}
            onChange={(e) => {
              setDob(e.target.value);
            }}
            placeholder="Start typing..."
            className={
              error !== "" && dob === ""
                ? "default-input di-error"
                : "default-input"
            }
          />
        </div>
        <>
          <div className="input-group select-group">
            <p>Country</p>
            <Autocomplete
              // open
              className={error !== "" && country === "" ? "di-auto-error" : ""}
              value={country}
              // open={true}
              onChange={(event, newValue) => {
                console.log(newValue);
                loadStates(newValue);
                setCountryValue(newValue.code);
                setCountry(newValue.name);
              }}
              popupIcon={null}
              disablePortal
              sx={{ width: 300 }}
              options={countries}
              renderInput={(params) => (
                <TextField
                  placeholder="Select Country..."
                  variant="standard"
                  {...params}
                />
              )}
            />
          </div>
          <div className="input-group select-group">
            <p>State / Province</p>
            <Autocomplete
              className={error !== "" && country === "" ? "di-auto-error" : ""}
              value={state}
              onChange={(event, newValue) => {
                console.log(newValue);
                loadCities(newValue);
                setState(newValue.name);
              }}
              // autoComplete={false}
              disabled={country === ""}
              popupIcon={null}
              disablePortal
              sx={{ width: 300 }}
              options={states}
              renderInput={(params) => (
                <TextField
                  placeholder="Select State..."
                  variant="standard"
                  {...params}
                />
              )}
            />
          </div>
          <div className="input-group select-group">
            <p>City</p>
            <Autocomplete
              className={error !== "" && country === "" ? "di-auto-error" : ""}
              value={city}
              disabled={state === ""}
              onChange={(event, newValue) => {
                console.log(newValue);
                setCity(newValue.name);
              }}
              popupIcon={null}
              disablePortal
              sx={{ width: 300 }}
              options={cities}
              renderInput={(params) => (
                <TextField
                  placeholder="Select City..."
                  variant="standard"
                  {...params}
                />
              )}
            />
          </div>
          <div className="input-group">
            <p>Gender</p>
            <div className="selector-group pd-20">
              <button
                onClick={() => setGender("male")}
                className={gender === "male" ? "sg-left sg-active" : "sg-left"}
              >
                Male
              </button>
              <button
                onClick={() => setGender("female")}
                className={
                  gender === "female" ? "sg-left sg-active" : "sg-left"
                }
              >
                Female
              </button>
            </div>
          </div>
          {false && (
            <>
              <div className="input-group">
                <p>{questions[0]}</p>
                <input
                  value={answerOne}
                  onChange={(e) => setAnswerOne(e.target.value)}
                  type="text"
                  className="default-input"
                  placeholder="Start typing..."
                />
              </div>

              <div className="input-group">
                <p>{questions[1]}</p>
                <input
                  value={answerTwo}
                  onChange={(e) => setAnswerTwo(e.target.value)}
                  type="text"
                  className="default-input"
                  placeholder="Start typing..."
                />
              </div>

              <div className="input-group">
                <p>{questions[2]}</p>
                <input
                  value={answerThree}
                  onChange={(e) => setAnswerThree(e.target.value)}
                  type="text"
                  className="default-input"
                  placeholder="Start typing..."
                />
              </div>
            </>
          )}
          {/* {!edit && ( */}
          <div className="input-group">
            <p>Handicap</p>
            <input
              type="number"
              value={handicap}
              onChange={(e) => {
                if (e.target.value > 54 || e.target.value < -10) {
                  return;
                }
                setHandicap(e.target.value);
              }}
              placeholder="Start typing..."
              className={
                error !== "" && handicap === ""
                  ? "default-input di-error"
                  : "default-input"
              }
            />
          </div>
          {/* )} */}
        </>
      </div>
      <div className="input-group mb-20 pl-20">
        <p>Profile Picture</p>
        <div className="image-buttons">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dz-sel">
              {imageURL !== "" && (
                <div className="event-img">
                  <img className="" alt="profile" src={imageURL} />
                </div>
              )}
              {imageURL === "" && (
                <h5>
                  Drag 'n' drop an image here, or click to open up the file
                  explorer
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>{" "}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {/* {error !== "" && (
        <div className="error-message animate__animated animate__fadeInUp">
          <p>{error}</p>
        </div>
      )} */}
      <div className="flex-center mt-40 mb-40">
        <button
          onClick={() => {
            if (edit) {
              saveChanges();
            } else {
              saveUser();
            }
          }}
          className="default-button"
        >
          {edit ? "Save Changes" : "Create Account"}
        </button>
      </div>
    </div>
  );
}

export default Onboarding;
