import React, { useState, useEffect } from "react";
import "./ClientDrills.css";
import { db } from "../../../firebase";

// Icons

// Images
import approach from "../../../assets/images/drills/approach.jpg";

// Components
import CPLoader from "../CPLoader";
import { Tabs, Tab } from "@mui/material";
import DrillItem from "../Drills/DrillItem";
import DrillScoreInput from "./DrillScoreInput";
import DrillAttempt from "./DrillAttempt";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { toast } from "react-toastify";
import { errorDefault, successIco } from "../HelperFunctions";
import DrillScores from "./DrillScores";
import Pill from "../../Buttons/Pill";
import ClientDrillLoader from "./ClientDrillLoader";
import DrillDetails from "../Drills/DrillDetails";
import ApproachMissDrillInput from "../Drills/ScenarioDrills/ApproachMiss/ApproachMissDrillInput";
import ApproachMissDrill from "../Drills/ScenarioDrills/ApproachMiss/ApproachMissDrill";
import PuttMissDrill from "../Drills/ScenarioDrills/PuttMiss/PuttMissDrill";
import ShotRandomizer from "../Drills/ScenarioDrills/ShotRandomizer/ShotRandomizer";
import FairwayFinder from "../Drills/ScenarioDrills/FairwayFinder/FairwayFinder";

function ClientDrills({
  clubID = "",
  clientID = "",
  drillIDs = [],
  clientname = "",
  reload = () => {},
  hideTop = () => {},
}) {
  // Admin
  const scenarioMap = {
    L7DtGLkb1KmnCrgaRT7A: (
      <ApproachMissDrill
        clientID={clientID}
        scenarioID="L7DtGLkb1KmnCrgaRT7A"
        clubID={clubID}
        goBack={() => {
          hideTop(false);
          setShowPage(false);
        }}
      />
    ),
    shGl58qoslSxluzIWY5K: (
      <PuttMissDrill
        clientID={clientID}
        scenarioID="shGl58qoslSxluzIWY5K"
        clubID={clubID}
        goBack={() => {
          hideTop(false);
          setShowPage(false);
        }}
      />
    ),
    "0Ulg31l5RK3hOfELLioS": (
      <ShotRandomizer
        clientID={clientID}
        scenarioID="0Ulg31l5RK3hOfELLioS"
        clubID={clubID}
        goBack={() => {
          hideTop(false);
          setShowPage(false);
        }}
      />
    ),
    "7SiX9Sxo6BCBbpSfBlUp": (
      <FairwayFinder
        clientID={clientID}
        scenarioID="7SiX9Sxo6BCBbpSfBlUp"
        clubID={clubID}
        goBack={() => {
          hideTop(false);
          setShowPage(false);
        }}
      />
    ),
  };

  // State
  const [loading, setLoading] = useState(true);
  const [drills, setDrills] = useState([]);
  const [attempts, setAttempts] = useState([]);
  const [sel, setSel] = useState("drills");
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [error, setError] = useState("");
  const [scenarioDrills, setScenarioDrills] = useState([]);
  const [showPage, setShowPage] = useState(false);
  const [pageToShow, setPageToShow] = useState();

  // UE
  useEffect(() => {
    loadDrills();
    loadScenarios();
  }, [drillIDs]);

  // Funcs
  async function loadDrills() {
    console.log("LOAD DRILLS");
    setLoading(true);
    const drillRefs = drillIDs.map((id) =>
      db.collection("clubs").doc(clubID).collection("drills").doc(id).get()
    );

    const drillDocs = await Promise.all(drillRefs);

    const drillArr = [];
    let attemptArr = [];

    for (let i = 0; i < drillDocs.length; i++) {
      const drillDoc = drillDocs[i];
      const drill = drillDoc.data();

      const attempts = drill.attempts ?? [];

      const filteredAttempts = attempts.filter((a) => a.clientID === clientID);

      if (filteredAttempts.length > 0) {
        drill.highScore = filteredAttempts[0].score;
      } else {
        drill.highScore = 0;
      }

      drillArr.attempts = filteredAttempts;

      drillArr.push(drill);
      attemptArr = [...attemptArr, ...filteredAttempts];
    }

    for (let i = 0; i < attemptArr.length; i++) {
      const att = attemptArr[i];
      const { date } = att;
      const dateObject = new Date(date.toDate());
      attemptArr[i].dateObject = dateObject;
    }

    attemptArr.sort((a, b) => b.date - a.date);

    setDrills(drillArr);
    setAttempts(attemptArr);
    setLoading(false);
  }

  async function loadScenarios() {
    const scenarioDocs = await db
      .collection("admin")
      .doc("academy")
      .collection("scenarios")
      .get();
    const arr = [];

    for (let i = 0; i < scenarioDocs.docs.length; i++) {
      const scenarioDoc = scenarioDocs.docs[i];
      const scenarioID = scenarioDoc.id;
      const scenario = scenarioDoc.data();
      const attempts = scenario.attempts ?? [];
      const clientAttempts = attempts.filter(
        (att) => att.playerID === clientID
      );

      let highestScore = "";

      for (let i = 0; i < clientAttempts.length; i++) {
        const att = clientAttempts[i];
        const totalPerc = parseFloat(
          att.totalPerc ?? att.totalRating ?? att.totalPoints
        );
        if (att.totalPerc) {
          if (highestScore === "" || totalPerc < highestScore) {
            highestScore = totalPerc;
          }
        } else {
          if (highestScore === "" || totalPerc > highestScore) {
            highestScore = totalPerc;
          }
        }
      }

      if (highestScore === "") {
        highestScore = 0;
      }

      scenario.highScore = highestScore;
      scenario.attempts = clientAttempts;

      scenario.scenarioID = scenarioID;
      scenario.element = scenarioMap[scenarioID];
      arr.push(scenario);
    }
    setScenarioDrills(arr);
  }

  async function deleteDrillAttempt(drillID, attemptID) {
    setLoading(true);
    try {
      const drillDoc = await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drillID)
        .get();
      const drillData = drillDoc.data();
      const { attempts, custom } = drillData;

      const filteredAttempts = attempts.filter(
        (att) => att.attemptID !== attemptID
      );

      await drillDoc.ref.update({
        attempts: filteredAttempts,
      });

      if (custom) {
        const drillDoc = await db
          .collection("admin")
          .doc("academy")
          .collection("drills")
          .doc(drillID)
          .get();
        const drillData = drillDoc.data();
        const { attempts } = drillData;

        const filteredAttempts = attempts.filter(
          (att) => att.attemptID !== attemptID
        );

        await drillDoc.ref.update({
          attempts: filteredAttempts,
        });
      }

      toast.success("The attempt was successfully deleted", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(errorDefault);
    }
    setLoading(false);
  }

  async function removeDrill(drillID) {
    setLoading(true);
    try {
      const userDoc = await db.collection("users").doc(clientID).get();
      const user = userDoc.data();
      const { drillIDs } = user;
      const clubDrillIDs = drillIDs[clubID];

      const filt = clubDrillIDs.filter((id) => id !== drillID);
      drillIDs[clubID] = filt;
      // setLoading(false);
      // return console.log(drillIDs);

      await userDoc.ref.update({
        drillIDs,
      });
      reload();
      toast.success("This drill was successfully removed.", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="client-drills">
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}

      {loading ? (
        <CPLoader />
      ) : showPage ? (
        pageToShow
      ) : (
        <>
          {show && toShow}
          <div className="flex-ac flex-w rg-20">
            <div className="dash-tab pl-0 ml-20">
              <Tabs
                value={sel}
                onChange={(e, v) => setSel(v)}
                className="dt-tabs shadow-md"
              >
                <Tab value={"drills"} label="Drills" />
                <Tab value={"attempts"} label="Attempts" />
                <Tab value={"scenarios"} label="Scenarios" />
              </Tabs>
            </div>
            <div className="ml-20 flex-ac">
              <Pill
                text={"Add Drill"}
                onClick={() => {
                  setToShow(
                    <ClientDrillLoader
                      clientDrillIDs={drillIDs}
                      clientID={clientID}
                      close={() => setShow(false)}
                      clubID={clubID}
                      reload={reload}
                    />
                  );
                  setShow(true);
                }}
              />
            </div>
          </div>
          <div className="cd-box">
            <div className="wbg bss-white bs-subtle pd-20">
              {sel === "drills" && (
                <>
                  {drills.length === 0 && (
                    <div className="zer-reqs mt-40 mb-40">
                      <p>
                        No drills have been added to this client's profile yet.
                      </p>
                    </div>
                  )}
                  <div className="flex flex-wrap wbg-box">
                    {drills.map((drill, i) => {
                      return (
                        <DrillItem
                          showRemove
                          removeAct={() => {
                            removeDrill(drill.drillID);
                          }}
                          academyID={clubID}
                          drill={drill}
                          showAddScore
                          showAttempts
                          viewAct={() => {
                            setPageToShow(
                              <DrillDetails
                                clientname={clientname}
                                clientID={clientID}
                                clubID={clubID}
                                goBack={() => {
                                  setShowPage(false);
                                  hideTop(false);
                                }}
                                id={drill.drillID}
                              />
                            );
                            hideTop(true);
                            setShowPage(true);
                          }}
                          viewAttempts={() => {
                            setPageToShow(
                              <DrillScores
                                clientID={clientID}
                                clubID={clubID}
                                reload={loadDrills}
                                attempts={drill.attempts}
                                back={() => setShowPage(false)}
                                client={clientname}
                                drill={drill}
                              />
                            );
                            setShowPage(true);
                          }}
                          addScore={() => {
                            setToShow(
                              <DrillScoreInput
                                clubID={clubID}
                                clientID={clientID}
                                close={() => setShow(false)}
                                drill={drill}
                                clientName={clientname}
                                reload={loadDrills}
                              />
                            );
                            setShow(true);
                          }}
                          key={drill.drillID}
                        />
                      );
                    })}{" "}
                  </div>
                </>
              )}
              {sel === "attempts" && (
                <>
                  {attempts.length === 0 && (
                    <div className="zer-reqs mt-40 mb-40">
                      <p>
                        No attempts have been added to this client's profile
                        yet.
                      </p>
                    </div>
                  )}

                  <div className="drill-attempt da-header flex flex-align-center">
                    <div>
                      <p>Date</p>
                    </div>
                    <div>
                      <p>Drill</p>
                    </div>
                    <div>
                      <p>Category</p>
                    </div>
                    <div>
                      <p>Difficulty</p>
                    </div>
                    <div>
                      <p>Score</p>
                    </div>
                    <div>
                      <p></p>
                    </div>
                  </div>

                  {attempts.map((att, i) => {
                    return (
                      <DrillAttempt
                        editAct={() => {
                          setToShow(
                            <DrillScoreInput
                              clientName={clientname}
                              clubID={clubID}
                              clientID={clientID}
                              close={() => setShow(false)}
                              defaults={att}
                              reload={loadDrills}
                              edit
                              drill={att}
                            />
                          );
                          setShow(true);
                        }}
                        deleteAct={() => {
                          deleteDrillAttempt(att.drillID, att.attemptID);
                        }}
                        key={i}
                        attempt={att}
                      />
                    );
                  })}
                </>
              )}
              {sel === "scenarios" && (
                <>
                  <div className="flex flex-wrap wbg-box">
                    {scenarioDrills.map((sce, i) => {
                      return (
                        <DrillItem
                          key={sce.scenarioID}
                          hideVert
                          drill={sce}
                          scenario
                          cursor
                          handleClick={() => {
                            setPageToShow(sce.element);
                            setShowPage(true);
                            hideTop(true);
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </div>{" "}
          </div>
        </>
      )}
    </div>
  );
}

export default ClientDrills;
