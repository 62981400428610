import React, { useState, useEffect } from "react";
import "./Menu.css";
import { db, auth } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

// Components
import logo from "../../assets/images/hldark.png";
import cw from "../../assets/images/comwide.png";
import cl from "../../assets/images/comish.png";
import ttl from "../../assets/images/ttlogowide.png";
import {
  Drawer,
  Badge,
  Avatar,
  Divider,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useNavigate, NavLink, useLocation, Link } from "react-router-dom";
import moment from "moment";
import SDMenu from "../SDMenu/SDMenu";
import ChatDrop from "../ChatDrop/ChatDrop";
import { RiMenuUnfoldFill } from "react-icons/ri";

import { useMenuTheme, useClickTheme } from "../../contexts/MenuContext";
import Login from "../../pages/Login/Login";
import { MdAccountBalanceWallet } from "react-icons/md";
import Helper from "../Helper/Helper";
import { IoStatsChart } from "react-icons/io5";

// Icons
import { HiOutlineMenu } from "react-icons/hi";
import { TiMessages } from "react-icons/ti";
import { IoNotificationsOutline } from "react-icons/io5";
import { HiMiniCalendar } from "react-icons/hi2";
import { RiHome6Fill } from "react-icons/ri";
import { IoGolf } from "react-icons/io5";
import { RiTrophyFill } from "react-icons/ri";
import { PiRankingFill } from "react-icons/pi";
import { RiFolderSettingsFill } from "react-icons/ri";
import { IoLogOut } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import { Menu as MMeu } from "@mui/material";

import Groups3Icon from "@mui/icons-material/Groups3";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HomeIcon from "@mui/icons-material/Home";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SportsMmaIcon from "@mui/icons-material/SportsMma";
import SettingsIcon from "@mui/icons-material/Settings";
import DoorBackIcon from "@mui/icons-material/DoorBack";
import TodayIcon from "@mui/icons-material/Today";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FixturePreview from "../display/FixturePreview/FixturePreview";
import NotificationDrop from "../Notifications/NotificationDrop";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import _ from "lodash";
import { isMobile } from "react-device-detect";
import PageHeader from "../display/Rows/PageHeader";
import { AccountCircle, ExitToApp } from "@mui/icons-material";
import AlertTray from "../AlertTray/AlertTray";

const featuresProp = {
  events: true,
  leagues: true,
  proStats: false,
  playerUploads: true,
  hcpUploads: true,
  teamLeagues: true,
  matchplayTournaments: true,
  cups: true,
  contests: true,
};

const GradientHomeIcon = () => (
  <>
    <HomeIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);
const GradientSupportIcon = () => (
  <>
    <SupportAgentIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);

const GradientCalendarTodayIcon = () => (
  <>
    <CalendarTodayIcon
      className="ps-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
const GradientGroupsIcon = () => (
  <>
    <GroupsIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);
const GradientFormatIcon = () => (
  <>
    <FormatListNumberedIcon
      className="ps-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
const GradientSportsIcon = () => (
  <>
    <SportsMmaIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);
const GradientTrophyIcon = () => (
  <>
    <EmojiEventsIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);
const GradientSettingsIcon = () => (
  <>
    <SettingsIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);
const GradientDoorIcon = () => (
  <>
    <DoorBackIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);
const GradientPeopleIcon = () => (
  <>
    <PeopleAltIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);

function Menu({ sdClick = 0 }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userDetails, logOut, portalSettings, clubFeatures } =
    useAuth();
  const usdOpts = useAuth().sdOpts;
  const showClick = useClickTheme();

  useEffect(() => {}, [currentUser]);

  const [verified, setVerified] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [accountType, setAccountType] = useState("free");

  useEffect(() => {
    if (userDetails) {
      // console.log(userDetails.accountType);
      setVerified(userDetails.portal ?? false);
      loadChats();
      loadNotifications();
      checkActiveEvents();
      // console.log(userDetails.accountType);
      setAccountType(userDetails.accountType);
      if (
        userDetails.accountType === "scorer" ||
        userDetails.accountType === "portal"
      ) {
        navigate("/calendar");
      }
      if (userDetails.accountType === "society") {
        // navigate("/leagues");
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (portalSettings && currentUser) {
      checkActiveEvents();
    }

    if (portalSettings && userDetails) {
      if (
        userDetails.accountType === "admin" ||
        userDetails.accountType === "club"
      ) {
        setSDOpts(usdOpts);
        setShowSD(portalSettings.showQuickMenu ?? true);
      }
    }
  }, [portalSettings, currentUser, userDetails]);

  const [showDrawer, setShowDrawer] = useState(false);
  const [features, setFeatures] = useState(featuresProp);

  const [unreads, setUnreads] = useState(0);
  const [unopeneds, setUnopened] = useState(0);

  const [showChat, setShowChat] = useState(false);
  const [chats, setChats] = useState([]);

  const [showNots, setShowNots] = useState(false);
  const [nots, setNots] = useState([]);

  const [newChat, setNewChat] = useState(false);
  const [newPlayer, setNewPlayer] = useState();
  const [showNewChat, setShowNewChat] = useState(false);

  const [activeEvents, setActiveEvents] = useState([]);
  const [showActive, setShowActive] = useState(false);

  const [showSD, setShowSD] = useState(false);
  const [sdOpts, setSDOpts] = useState([]);

  const [chatID, setChatID] = useState("");

  const [showHelper, setShowHelper] = useState(false);

  const clicked = useMenuTheme();

  const [createAdmin, setCreateAdmin] = useState(false);

  const [chatMessage, setChatMessage] = useState("");

  async function loadChats() {
    db.collection("chats")
      .where("userIDs", "array-contains", currentUser.uid)
      .orderBy("lastMessage.sentAt", "desc")
      .onSnapshot(async (snapshot) => {
        // alert('loading')
        const arr = [];
        const userMap = {};
        let unreadChats = 0;
        const ids = [];

        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          const { userIDs } = data;
          for (let i = 0; i < userIDs.length; i++) {
            const uid = userIDs[i];
            if (!ids.includes(uid)) {
              ids.push(uid);
            }
          }
        });

        const refs = ids.map((uid) => db.collection("users").doc(uid).get());
        const userDocs = await Promise.all(refs);
        for (let i = 0; i < userDocs.length; i++) {
          if (ids[i] === "admin") {
            continue;
          }
          if (!userDocs[i].exists) {
            continue;
          }
          const user = userDocs[i].data();
          const userID = userDocs[i].id;
          const { firstName, lastName, profileImage, handicap } = user;

          userMap[userID] = {
            name: `${firstName} ${lastName}`,
            profileImage,
            handicap,
            userID,
          };
        }

        for (let i = 0; i < snapshot.docs.length; i++) {
          const doc = snapshot.docs[i];
          const chat = doc.data();
          const chatID = doc.id;

          const { userIDs, lastMessage, isGroupChat, adminIDs } = chat;

          if (lastMessage === undefined) {
            continue;
          }
          const sentAt = new Date(lastMessage.sentAt.toDate());
          const { read, receiver } = lastMessage;
          let unreads = 0;
          if (!read && receiver === currentUser.uid) {
            unreadChats += 1;
            const docs = await db
              .collection("chats")
              .doc(chatID)
              .collection("messages")
              .where("read", "==", false)
              .where("receiver", "==", currentUser.uid)
              .get();
            unreads = docs.docs.length;
          }

          const nowMoment = moment();
          const msgMoment = moment(sentAt);

          let time = "";

          if (nowMoment.diff(msgMoment, "hours") < 24) {
            time = sentAt.toLocaleTimeString("en-gb", { timeStyle: "short" });
          } else if (nowMoment.diff(msgMoment, "days") < 7) {
            time = sentAt.toLocaleDateString("en-gb", { weekday: "short" });
          } else {
            time = sentAt.toLocaleDateString("en-gb", {
              month: "short",
              day: "numeric",
            });
          }

          if (isGroupChat) {
            const players = [];
            for (let j = 0; j < userIDs.length; j++) {
              players.push(userMap[userIDs[j]]);
            }

            arr.push({
              image: chat.imageLink,
              adminIDs,
              name: chat.chatName,
              message: lastMessage.text,
              sent: lastMessage.sender === currentUser.uid,
              time,
              chatType: chat.chatType ?? "group",
              unreads,
              // receiverID,
              players,
              chatID,
              isGroupChat,
              groupID: chat.leagueID,
            });
          } else {
            let receiverID = "";
            if (userIDs[0] === currentUser.uid) {
              receiverID = userIDs[1];
            } else {
              receiverID = userIDs[0];
            }

            if (receiverID === "admin") {
              arr.push({
                image:
                  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/images%2Fgeneral%2FUntitled%20design%20(2).png?alt=media&token=9db74cbc-c820-499e-add1-46e578f0d7e2",
                name: "UGA HQ",
                message: lastMessage.text,
                sent: lastMessage.sender === currentUser.uid,
                time,
                unreads,
                receiverID,
                chatID,
                isGroupChat,
                players: [],
                groupID: "",
              });
            } else {
              const user = userMap[receiverID];

              arr.push({
                image: user.profileImage,
                name: user.name,
                message: lastMessage.text,
                sent: lastMessage.sender === currentUser.uid,
                time,
                unreads,
                receiverID,
                chatID,
                isGroupChat,
                players: [],
                groupID: "",
              });
            }
          }
        }
        setUnreads(unreadChats);
        setChats(arr);
        // setLoading(false)
      });
  }

  async function checkActiveEvents() {
    let events;

    if (portalSettings.clubID && userDetails.accountType !== "society") {
      db.collection("events")
        .where("roundIDs", "!=", [])
        .where("completed", "==", false)
        .where("clubID", "==", portalSettings.clubID)
        .onSnapshot(async (events) => {
          const arr = [];
          for (let i = 0; i < events.docs.length; i++) {
            const eventDoc = events.docs[i];
            const eventID = eventDoc.id;
            const event = eventDoc.data();
            if (event.portalComplete) {
              continue;
            }
            event.eventID = eventID;
            event.dateObj = new Date(event.eventDate.toDate());
            const dateString = new Date(
              event.eventDate.toDate()
            ).toLocaleDateString("en-us", { dateStyle: "full" });
            event.dateString = dateString;
            event.teamEvent = false;
            arr.push(event);
          }

          setActiveEvents((current) => {
            const filt = current.filter((evt) => evt.teamEvent === true);
            const narr = [...arr, ...filt];
            const uq = _.uniq(narr);

            uq.sort((a, b) => b.dateObj - a.dateObj);

            return uq;
          });
        });
      db.collection("teamEvents")
        .where("roundIDs", "!=", [])
        .where("completed", "==", false)
        .where("clubID", "==", portalSettings.clubID)
        .onSnapshot(async (events) => {
          const tarr = [];

          for (let i = 0; i < events.docs.length; i++) {
            const eventDoc = events.docs[i];
            const eventID = eventDoc.id;
            const event = eventDoc.data();
            event.eventID = eventID;
            event.dateObj = new Date(event.eventDate.toDate());
            const dateString = new Date(
              event.eventDate.toDate()
            ).toLocaleDateString("en-us", { dateStyle: "full" });
            event.dateString = dateString;
            event.teamEvent = true;
            tarr.push(event);
          }
          setActiveEvents((current) => {
            const filt = current.filter((evt) => evt.teamEvent !== true);

            const narr = [...tarr, ...filt];
            const uq = _.uniq(narr);

            uq.sort((a, b) => b.dateObj - a.dateObj);

            return uq;
          });
        });
      db.collection("cups")
        .where("roundIDs", "!=", [])
        .where("completed", "==", false)
        .where("admins", "array-contains", currentUser.uid)
        .onSnapshot(async (events) => {
          const tarr = [];
          // console.log(events.docs.length);
          for (let i = 0; i < events.docs.length; i++) {
            const eventDoc = events.docs[i];
            const eventID = eventDoc.id;
            const event = eventDoc.data();
            event.cupID = eventID;
            event.dateObj = new Date(event.cupDate.toDate());
            const dateString = new Date(
              event.cupDate.toDate()
            ).toLocaleDateString("en-us", { dateStyle: "full" });
            event.dateString = dateString;
            event.cup = true;
            event.eventName = event.cupName;
            tarr.push(event);
          }
          setActiveEvents((current) => {
            const filt = current.filter((evt) => evt.cup !== true);

            const narr = [...tarr, ...filt];
            const uq = _.uniq(narr);

            uq.sort((a, b) => b.dateObj - a.dateObj);

            return uq;
          });
        });
      // .get();
    } else {
      const leagues = await db
        .collection("leagues")
        .where("admins", "array-contains", currentUser.uid)
        .get();
      const leagueIDs = leagues.docs.map((league) => league.id);

      if (leagueIDs.length === 0) {
        return;
      }

      const now = new Date();
      if (userDetails.accountType === "society") {
        db.collection("events")
          .where("roundIDs", "!=", [])
          .where("completed", "==", false)
          .where("leagueID", "==", portalSettings.clubID)
          // .where("leagueID", "in", leagueIDs)
          .onSnapshot(async (events) => {
            const arr = [];
            for (let i = 0; i < events.docs.length; i++) {
              const eventDoc = events.docs[i];
              const eventID = eventDoc.id;
              const event = eventDoc.data();
              if (event.portalComplete) {
                continue;
              }
              event.eventID = eventID;
              const dateString = new Date(
                event.eventDate.toDate()
              ).toLocaleDateString("en-us", { dateStyle: "full" });
              event.dateString = dateString;
              arr.push(event);
            }
            setActiveEvents(arr);
          });
      } else {
        db.collection("events")
          .where("roundIDs", "!=", [])
          .where("completed", "==", false)
          .where("adminIDs", "array-contains", currentUser.uid)
          // .where("leagueID", "in", leagueIDs)
          .onSnapshot(async (events) => {
            const arr = [];
            for (let i = 0; i < events.docs.length; i++) {
              const eventDoc = events.docs[i];
              const eventID = eventDoc.id;
              const event = eventDoc.data();
              if (event.portalComplete) {
                continue;
              }
              event.eventID = eventID;
              const dateString = new Date(
                event.eventDate.toDate()
              ).toLocaleDateString("en-us", { dateStyle: "full" });
              event.dateString = dateString;
              arr.push(event);
            }
            setActiveEvents(arr);
          });
      }
    }
  }

  async function loadNotifications() {
    db.collection("users")
      .doc(currentUser.uid)
      .collection("portalNotifications")
      .orderBy("date", "desc")
      .limit(20)
      .onSnapshot((notifications) => {
        let unopeneds = 0;
        const arr = [];

        for (let i = 0; i < notifications.docs.length; i++) {
          const notificationDoc = notifications.docs[i];
          const notificationID = notificationDoc.id;
          const notification = notificationDoc.data();
          const dateObj = new Date(notification.date.toDate());
          const dateString = dateObj.toLocaleDateString("en-us", {
            dateStyle: "full",
          });
          notification.dateObject = dateObj;
          notification.notificationID = notificationID;
          notification.dateString = dateString;
          const notMoment = moment(dateObj);
          const timeSince = notMoment.fromNow();
          notification.timeSince = timeSince;
          notification.dateMoment = notMoment;
          arr.push(notification);
          if (!notification.read) {
            unopeneds += 1;
          }
        }
        setUnopened(unopeneds);
        setNots(arr);
      });
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (clubFeatures) {
      // console.log(clubFeatures);
      setFeatures(clubFeatures);
    }
  }, []);

  useEffect(() => {
    if (typeof clicked === "string") {
      return;
    }

    if (!clicked.type.includes("chat")) {
      return;
    }

    if (clicked.type === "admin-chat") {
      if (clicked.chatID !== "") {
        setChatID(clicked.chatID);
        setChatMessage(clicked.message ?? "");
        setShowChat(true);
      }
      if (clicked.chatID === "") {
        setChatMessage(clicked.message ?? "");

        setCreateAdmin(true);
        setShowChat(true);
      }
      return;
    }

    let found = false;
    let id = "";
    for (let i = 0; i < chats.length; i++) {
      if (chats[i].receiverID === clicked.playerID) {
        found = true;
        id = chats[i].chatID;
      }
    }
    if (found) {
      setChatID(id);
      setShowChat(true);
    } else {
      setNewPlayer(clicked);
      setNewChat(true);
      setShowChat(true);
    }
  }, [clicked]);

  return (
    <>
      {!location.pathname.includes("contestboards/") && (
        <div className="menu-bar">
          {showLogin && <Login hide={() => setShowLogin(false)} />}
          <Drawer
            anchor={isMobile ? "right" : "top"}
            className="ae-draw"
            open={showActive}
            onClose={() => setShowActive(false)}
          >
            <div className="mb-active-events">
              <PageHeader
                text="Active Events"
                showClose
                close={() => setShowActive(false)}
              />
              <div className="mb-ae-box">
                {activeEvents.map((event) => {
                  return (
                    <Link
                      onClick={() => setShowActive(false)}
                      key={`active${event.eventID ?? event.cupID}`}
                      to={
                        event.teamEvent
                          ? `/team-evt-dash/${event.eventID}`
                          : event.cup
                          ? `/cup-dash/${event.cupID}`
                          : `/evt-dash/${event.eventID}`
                      }
                    >
                      <FixturePreview
                        past
                        event={event}
                        hideIcons
                        // showDetails={() => {
                        //   if (event.teamEvent) {
                        //     navigate("/calendar", {
                        //       state: {
                        //         type: "team-active",
                        //         eventID: event.eventID,
                        //       },
                        //     });
                        //   } else if (event.cup) {
                        //     navigate("/calendar", {
                        //       state: {
                        //         type: "cup-active",
                        //         cupID: event.cupID,
                        //       },
                        //     });
                        //   } else {
                        //     navigate("/calendar", {
                        //       state: {
                        //         type: "active",
                        //         eventID: event.eventID,
                        //       },
                        //     });
                        //   }
                        //   setShowActive(false);
                        //   if (location.pathname === "/calendar") {
                        //     if (event.teamEvent) {
                        //       showClick({
                        //         type: "active-team-event",
                        //         eventID: event.eventID,
                        //       });
                        //     } else if (event.cup) {
                        //       showClick({
                        //         type: "active-cup",
                        //         cupID: event.cupID,
                        //       });
                        //     } else {
                        //       showClick({
                        //         type: "active-event",
                        //         eventID: event.eventID,
                        //       });
                        //     }
                        //   }
                        // }}
                        // key={`active${event.eventID}`}
                      />
                    </Link>
                  );
                })}
              </div>
            </div>
          </Drawer>
          <Drawer
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            onClick={() => setShowDrawer(false)}
          >
            <div className="menu-draw">
              <div className="md-top">
                <div className="md-logo">
                  <img alt="menu-logo" src={cl} />
                </div>
                {currentUser && userDetails && (
                  <div className="md-profile mt-20">
                    <div className="mdp-left">
                      <Avatar
                        alt={userDetails.firstName}
                        src={userDetails.profileImage}
                      />
                    </div>

                    <div className="mdp-right">
                      <h3>
                        {userDetails.firstName} {userDetails.lastName}
                      </h3>
                      <p>{userDetails.emailAddress}</p>
                    </div>
                  </div>
                )}
                <div className="md-options">
                  {(accountType === "scorer" || accountType === "portal") && (
                    <>
                      <NavLink
                        to={"calendar"}
                        onClick={() => showClick("events")}
                        className={({ isActive }) =>
                          isActive ? "md-item md-active" : "md-item"
                        }
                      >
                        <div className="md-link">
                          <GradientCalendarTodayIcon />
                          <p>Calendar</p>
                        </div>
                      </NavLink>
                      <NavLink
                        to={"support"}
                        onClick={() => showClick("support")}
                        className={({ isActive }) =>
                          isActive ? "md-item md-active" : "md-item"
                        }
                      >
                        <div className="md-link">
                          <BiSupport />
                          <p>Support</p>
                        </div>
                      </NavLink>
                      <NavLink
                        to={"settings"}
                        onClick={() => showClick("settings")}
                        className={({ isActive }) =>
                          isActive ? "md-item md-active" : "md-item"
                        }
                      >
                        <div className="md-link">
                          <GradientSettingsIcon />
                          <p>Settings</p>
                        </div>
                      </NavLink>
                    </>
                  )}
                  {verified &&
                    accountType !== "scorer" &&
                    accountType !== "portal" && (
                      <>
                        <NavLink
                          to={""}
                          onClick={() => showClick("home")}
                          className={({ isActive }) =>
                            isActive ? "md-item md-active" : "md-item"
                          }
                        >
                          <div className="md-link">
                            <RiHome6Fill />
                            <p>Home</p>
                          </div>
                        </NavLink>
                        {(accountType === "admin" ||
                          (userDetails && userDetails.test)) && (
                          <NavLink
                            onClick={() => showClick("account")}
                            to={"account"}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <MdAccountBalanceWallet />
                              <p>Account</p>
                            </div>
                          </NavLink>
                        )}
                        {accountType === "admin" && (
                          <NavLink
                            to={"clubs"}
                            onClick={() => showClick("clubs")}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <Groups3Icon />
                              <p>Clubs</p>
                            </div>
                          </NavLink>
                        )}
                        {features.events && (
                          <NavLink
                            to={"calendar"}
                            onClick={() => showClick("events")}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <HiMiniCalendar />
                              <p>Calendar</p>
                            </div>
                          </NavLink>
                        )}

                        {/* <NavLink
              to={"societies"}
              className={({ isActive }) =>
                isActive ? "md-item md-active" : "md-item"
              }
            >
              <div className="md-link">
                <GradientGroupsIcon />
                <p>Societies</p>
              </div>
            </NavLink> */}
                        {/* {(accountType === "admin" ||
                          (userDetails && userDetails.test)) && ( */}
                        {accountType !== "society" && portalSettings && (
                          <NavLink
                            to={`members/${portalSettings.clubID}`}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <GradientPeopleIcon />
                              <p>Players</p>
                            </div>
                          </NavLink>
                        )}
                        {accountType === "society" && (
                          <NavLink
                            to={`players`}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <GradientPeopleIcon />
                              <p>Players</p>
                            </div>
                          </NavLink>
                        )}

                        {/* )} */}
                        {/* {(accountType === "admin" ||
                          (userDetails && userDetails.test)) && ( */}
                        {accountType !== "society" &&
                          portalSettings &&
                          features.leagues && (
                            <NavLink
                              to={`clubleagues/${portalSettings.clubID}`}
                              onClick={() => showClick("leagues")}
                              className={({ isActive }) =>
                                isActive ? "md-item md-active" : "md-item"
                              }
                            >
                              <div className="md-link">
                                <PiRankingFill />
                                <p>Leagues</p>
                              </div>
                            </NavLink>
                          )}
                        {/* {accountType === "society" && (
                          <NavLink
                            to={`leagues`}
                            onClick={() => showClick("leagues")}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <GradientFormatIcon />
                              <p>Leagues</p>
                            </div>
                          </NavLink>
                        )} */}
                        {/* )} */}

                        {features.proStats && (
                          <NavLink
                            to={`pl-stats-home/${portalSettings.clubID}`}
                            // onClick={() => showClick("events")}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <IoStatsChart />
                              <p>Pro Stats</p>
                            </div>
                          </NavLink>
                        )}

                        {features.matchplayTournaments && (
                          <NavLink
                            onClick={() => showClick("tournaments")}
                            to={`/clubtournaments/${portalSettings.clubID}`}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <GradientSportsIcon />
                              <p>Tournaments</p>
                            </div>
                          </NavLink>
                        )}

                        {accountType !== "society" && features.cups && (
                          <NavLink
                            to={"cups"}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <RiTrophyFill />
                              <p>Cups</p>
                            </div>
                          </NavLink>
                        )}
                        {accountType === "club" && features.contests && (
                          <NavLink
                            to={"contests"}
                            className={({ isActive }) =>
                              isActive ? "md-item md-active" : "md-item"
                            }
                          >
                            <div className="md-link">
                              <IoGolf />
                              <p>Contests</p>
                            </div>
                          </NavLink>
                        )}

                        <NavLink
                          to={"support"}
                          onClick={() => showClick("support")}
                          className={({ isActive }) =>
                            isActive ? "md-item md-active" : "md-item"
                          }
                        >
                          <div className="md-link">
                            <BiSupport />
                            <p>Support</p>
                          </div>
                        </NavLink>
                        <NavLink
                          to={"settings"}
                          onClick={() => showClick("settings")}
                          className={({ isActive }) =>
                            isActive ? "md-item md-active" : "md-item"
                          }
                        >
                          <div className="md-link">
                            <RiFolderSettingsFill />
                            <p>Settings</p>
                          </div>
                        </NavLink>
                      </>
                    )}
                  <NavLink
                    to={""}
                    onClick={() => {
                      auth.signOut().then(() => {
                        // console.log("logged out");
                        navigate("/");
                        window.location.reload();
                      });
                      // navigate("/");
                    }}
                    className="md-item"
                  >
                    <div className="md-link">
                      <IoLogOut />
                      <p>Logout</p>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="md-bot">
                <p onClick={() => navigate("/terms-conditions")}>
                  Terms & Conditions
                </p>
                <p onClick={() => navigate("/privacy-policy")}>
                  Privacy Policy
                </p>
              </div>
            </div>
          </Drawer>

          {showSD && (
            <SDMenu
              clicked={sdClick}
              options={sdOpts}
              clubID={portalSettings && portalSettings.clubID}
            />
          )}

          <div className="mb-left">
            <div className="mbl-ico-box">
              {currentUser && (
                <HiOutlineMenu
                  className="mbl-ico"
                  onClick={() => setShowDrawer(true)}
                />
              )}
            </div>

            <Link
              to={
                accountType === "scorer" || accountType === "portal"
                  ? "/calendar"
                  : accountType === "society"
                  ? "/"
                  : "/"
              }
            >
              <img
                // onClick={() => {
                //   if (accountType === "scorer" || accountType === "portal") {
                //     showClick("calendar");

                //     navigate("/calendar");
                //   } else if (accountType === "society") {
                //     showClick("leagues");

                //     navigate("/leagues");
                //   } else {
                //     showClick("home");
                //     navigate("/");
                //   }
                // }}
                alt="logo"
                src={cw}
              />
            </Link>
          </div>
          {!currentUser && (
            <div className="mb-middle mb-navs">
              <NavLink
                to={""}
                className={({ isActive }) =>
                  isActive ? "mbm-nav mbm-active" : "mbm-nav"
                }
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                to={"about"}
                className={({ isActive }) =>
                  isActive ? "mbm-nav mbm-active" : "mbm-nav"
                }
              >
                <p>About</p>
              </NavLink>
              {false && (
                <>
                  <NavLink
                    to={"features"}
                    className={({ isActive }) =>
                      isActive ? "mbm-nav mbm-active" : "mbm-nav"
                    }
                  >
                    <p>Features</p>
                  </NavLink>
                  <NavLink
                    to={"pricing"}
                    className={({ isActive }) =>
                      isActive ? "mbm-nav mbm-active" : "mbm-nav"
                    }
                  >
                    <p>Pricing</p>
                  </NavLink>
                </>
              )}
              <NavLink
                to={"contact"}
                className={({ isActive }) =>
                  isActive ? "mbm-nav mbm-active" : "mbm-nav"
                }
              >
                <p>Contact</p>
              </NavLink>
            </div>
          )}
          {currentUser && (
            <div className="mb-middle">
              <p>Powered by THE UGA</p>
            </div>
          )}
          {verified && currentUser && (
            <div className="mb-right">
              {activeEvents.length > 0 && (
                <div className="mbr-box">
                  <Badge
                    onClick={() => setShowActive((current) => !current)}
                    badgeContent={activeEvents.length}
                  >
                    <HiMiniCalendar />
                  </Badge>
                </div>
              )}
              <div className="mbr-box">
                <Badge
                  onClick={() => {
                    setShowChat((current) => !current);
                    setShowNots(false);
                  }}
                  badgeContent={unreads}
                >
                  <TiMessages />
                </Badge>
              </div>
              <div className="mbr-box">
                <Badge
                  onClick={() => {
                    setShowNots((current) => !current);
                    setShowChat(false);
                  }}
                  badgeContent={unopeneds}
                >
                  <IoNotificationsOutline />
                </Badge>
              </div>
              {userDetails && userDetails.test && (
                <div className="mbr-box">
                  <Tooltip title="My Account">
                    <Avatar
                      onClick={handleClick}
                      className="mbr-ava drop-shadow-md"
                      src={userDetails && userDetails.profileImage}
                      alt={userDetails ? userDetails.name : "prof-pic"}
                    />
                  </Tooltip>
                </div>
              )}

              {/* <p>f</p> */}
              <MMeu
                className="mm-eu"
                anchorEl={anchorEl}
                id="account-menu"
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
              >
                <div className="mm-eu-user shadow-md flex-align-center">
                  <Avatar
                    className="mbr-ava"
                    src={userDetails && userDetails.profileImage}
                    alt={userDetails ? userDetails.name : "prof-pic"}
                  />
                  <div>
                    <h2>{userDetails && userDetails.name}</h2>
                    <p>{userDetails && userDetails.emailAddress}</p>
                  </div>
                </div>

                <Divider />

                {true && (
                  <MenuItem onClick={handleClose} className="mt-10">
                    <Link to={"/profile-edit"}>
                      <AccountCircle className="mm-eu-ico" /> <p>Profile</p>
                    </Link>
                  </MenuItem>
                )}
                <MenuItem className="mt-10" onClick={handleClose}>
                  <Link to={"/settings"}>
                    <SettingsIcon className="mm-eu-ico" /> <p>Settings</p>
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClose();
                    auth.signOut().then(() => {
                      navigate("/");
                      window.location.reload();
                    });
                  }}
                >
                  <ExitToApp className="mm-eu-ico" /> <p>Logout</p>
                </MenuItem>
              </MMeu>
            </div>
          )}
          {!currentUser && (
            <div className="mb-right">
              {false && (
                <NavLink to={"/onboarding"}>
                  <button
                    // onClick={() => setShowLogin(true)}
                    className="default-button black-button mr-20"
                  >
                    Sign Up
                  </button>
                </NavLink>
              )}
              <button
                onClick={() => setShowLogin(true)}
                className="default-button"
                // className="dbr-but"
              >
                Login
              </button>
            </div>
          )}
          {currentUser && userDetails && (
            <Drawer
              anchor="top"
              className="chat-drawer"
              open={showChat}
              onClose={() => setShowChat(false)}
              // onClick={() => setShowChat(false)}
            >
              <div style={{ marginTop: "0px" }}>
                <ChatDrop
                  chatID={chatID}
                  setChatID={setChatID}
                  newChat={newChat}
                  newUser={newPlayer}
                  createAdmin={createAdmin}
                  messages={chats}
                  message={chatMessage}
                  setNewUser={setNewChat}
                  setCreateAdmin={setCreateAdmin}
                  goBack={() => setShowChat(false)}
                  // goBack={() => setChatHeight("0")}
                  // height={chatHeight}
                  friends={userDetails.friends}
                />
              </div>
            </Drawer>
          )}
          {currentUser && userDetails && (
            <Drawer
              anchor="top"
              className="chat-drawer"
              open={showNots}
              onClose={() => setShowNots(false)}
              // onClick={() => setShowChat(false)}
            >
              <div style={{ marginTop: "0px" }}>
                <NotificationDrop
                  hide={() => setShowNots(false)}
                  notifications={nots}
                />
              </div>
            </Drawer>
          )}
          {currentUser && false && verified && <AlertTray alerts={nots} />}
        </div>
      )}
    </>
  );
}

export default Menu;
