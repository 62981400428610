import React from "react";
import "./FixtureRow.css";
import { Avatar } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Home, RssFeed, Visibility } from "@mui/icons-material";

function FixtureRow({
  date = "",
  title = "",
  courses = "",
  players = "",
  imageLink = "",
  eventID = "",
  winner = "",
  season = "",
  league = "",
  cup = false,
  team = false,
  active = false,
}) {
  return (
    <div className="fixture-row">
      <div className="fr-item fri-ava">
        <Avatar src={imageLink} />
      </div>
      <div className="fr-item fri-15">
        <p>{date}</p>
      </div>
      <div className="fr-item fri-2">
        <p>{title}</p>
      </div>
      <div className="fr-item fri-15 fri-course">
        <p>{courses}</p>
      </div>
      <div className="fr-item fri-1">
        <p>{league}</p>
      </div>
      <div className="fr-item fri-1">
        <p>{season}</p>
      </div>
      {true && (
        <div className="fr-item fri-1">
          <p>{winner}</p>
        </div>
      )}
      <div className="fr-item fri-link">
        <p>{players}</p>
      </div>

      <div className="fr-item  fri-link">
        <NavLink
          target="_blank"
          to={
            cup
              ? `/cup-home/${eventID}`
              : team
              ? `/team-event-home/${eventID}`
              : `/event-home/${eventID}`
          }
        >
          <Home className="dg-icon-bg" />
        </NavLink>
      </div>
      <div className="fr-item  fri-link">
        {active ? (
          <NavLink
            target="_blank"
            to={
              cup
                ? `/cup-dash/${eventID}`
                : team
                ? `/team-evt-dash/${eventID}`
                : `/evt-dash/${eventID}`
            }
          >
            <RssFeed className="dg-icon-bg" />
          </NavLink>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}

export default FixtureRow;
