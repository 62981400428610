import React from "react";

function DisputeRow({ row = {} }) {
  return (
    <div className="dispute-row">
      <div className="dr-item">
        <p>{row.dateString}</p>
      </div>
      <div className="dr-item">
        <p>{row.reference}</p>
      </div>
      <div className="dr-item dr-stat">
        <p>{row.status}</p>
      </div>
    </div>
  );
}

export default DisputeRow;
