import { Payment } from "@mui/icons-material";
import React from "react";

function OPRow({ op = {}, settleAct = () => {} }) {
  return (
    <div className="op-row">
      <div className="opr-date">
        <p>{op.dateString}</p>
      </div>
      <div className="opr-mth">
        <p>{op.monthValue}</p>
      </div>
      <div className="opr-year">
        <p>{op.year}</p>
      </div>
      <div className="opr-ref">
        <p>{op.reference}</p>
      </div>
      <div className="opr-act">
        <Payment className="dg-icon icon-cursor hgb-ico" onClick={settleAct} />
      </div>
    </div>
  );
}

export default OPRow;
