import React, { useState, useEffect } from "react";
import "./VerifyPage.css";
import { NavLink, useLocation } from "react-router-dom";
import { auth } from "../../firebase";
import { Helmet } from "react-helmet";

function VerifyPage() {
  const location = useLocation();

  useEffect(() => {
    const queryParas = new URLSearchParams(location.search);
    const oobCode = queryParas.get("oobCode");
    console.log(oobCode);

    if (oobCode) {
      auth
        .applyActionCode(oobCode)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div className="verify-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Tour | Verify Account</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="vp-box">
        <div className="vpb-box">
          <h2>Account Verified</h2>
          <p>
            Thank you for verifying your email address. Enjoy using The Tour!
          </p>
          <NavLink to={"/"}>
            <button className="mt-20 default-button">Continue</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default VerifyPage;
