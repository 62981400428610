import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import "./Clubs.css";

// Icons

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PageHeader from "../../components/display/Rows/PageHeader";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import Chart from "react-apexcharts";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BackRow from "../../components/display/Rows/BackRow";

function ClubAnalytics() {
  // Admin
  const clubID = useParams().id;
  const typeOpts = [
    {
      value: "days",
      label: "Days",
    },
    {
      value: "months",
      label: "Months",
    },
  ];
  const navigate = useNavigate();

  const valMap = {
    totalMembers: "Total Members",
    totalLeagues: "Total Leagues",
    totalEvents: "Total Events",
    totalRounds: "totalRounds",
    totalPlayerRounds: "Total Player Rounds",
  };

  // State
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(0);
  const [series, setSeries] = useState([]);
  const [error, setError] = useState("");
  const [selType, setSelType] = useState("days");
  const [details, setDetails] = useState({
    members: 0,
    dateCreated: new Date(),
    name: "",
  });
  const [selected, setSelected] = useState("");

  const [analArray, setAnalArray] = useState([]);
  const [barLabels, setBarLabels] = useState([]);

  const [monthData, setMonthData] = useState([]);
  const [monthDates, setMonthDates] = useState([]);

  const graphOptions = [
    {
      label: "Total Members",
      value: "totalMembers",
    },
    {
      label: "Total Leagues",
      value: "totalLeagues",
    },
    {
      label: "Total Events",
      value: "totalEvents",
    },
    {
      label: "Total Rounds",
      value: "totalRounds",
    },
    {
      label: "Total Player Rounds",
      value: "totalPlayerRounds",
    },
  ];

  const barProp = {
    series: [
      {
        name: "PRODUCT A",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "PRODUCT B",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "PRODUCT C",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "PRODUCT D",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      colors: ["#1e7a69", "#db5461", "#232c2e"],
      dataLabels: {
        enabled: true,
        style: {
          borderRadius: "20px",
          padding: "5px",
          fontSize: "12px",
        },
        background: {
          borderRadius: "40%",
          enabled: true,
          borderWidth: "1px",
          borderColor: "#1e7a69",
          // padding: "20px",
          // color: "#1e7a69",
        },
      },
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        stackType: "100",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: false,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.3,
        },
      },
      //   plotOptions: {
      //     bar: {
      //       colors: {
      //         ranges: [
      //           {
      //             from: -100,
      //             to: -46,
      //             color: "#F15B46",
      //           },
      //           {
      //             from: -45,
      //             to: 0,
      //             color: "#FEB019",
      //           },
      //         ],
      //       },
      //       columnWidth: "80%",
      //     },
      //   },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          distributed: selected.includes("Averages") || selected === "misses",
          colors: {
            ranges: selected.includes("Averages")
              ? []
              : selected === "puttHoles"
              ? [
                  {
                    from: 1,
                    to: 2,
                    color: "#6ba390",
                  },
                  {
                    from: 2,
                    to: 3,
                    color: "#000000",
                  },
                  {
                    from: 3,
                    to: 5,
                    color: "#db5461",
                  },
                ]
              : [
                  {
                    from: -2,
                    to: -1,
                    color: "#209E73",
                  },
                  {
                    from: -1,
                    to: 0,
                    color: "#6ba390",
                  },
                  {
                    from: 0,
                    to: 1,
                    color: "#db5461",
                  },
                  {
                    from: 2,
                    to: 5,
                    color: "#A9363F",
                  },
                ],
          },
        },
      },
      xaxis: {
        categories: barLabels,
        labels: {
          rotate: -90,
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            // console.log(value);
            return value;
            // return value.toFixed(0);
          },
        },
        // min: -2,
        // ...(scoring === "net" && selected === "columns" && { max: 2 }),
        title: {
          // text: selected,
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  // UE
  useEffect(() => {
    loadAnalytics();
    loadMonthly();
  }, []);

  // Funcs
  async function loadAnalytics() {
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();

    const analDocs = await clubDoc.ref
      .collection("analytics")
      .orderBy("date", "desc")
      .limit(30)
      .get();
    const arr = [];

    // analDocs.docs.reverse();

    for (let i = 0; i < analDocs.docs.length; i++) {
      const analDoc = analDocs.docs[i];
      const analData = analDoc.data();

      const { date } = analData;

      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "short",
      });

      analData.dateObject = dateObject;

      analData.dateString = dateString;
      arr.push(analData);
    }

    arr.reverse();

    setAnalArray(arr);

    const { clubName, memberIDs, dateCreated } = club;
    const dcObject = new Date(dateCreated.toDate());
    const dcString = dcObject.toLocaleDateString("en-us", {
      dateStyle: "medium",
    });
    setDetails({
      members: memberIDs.length,
      name: clubName,
      dateCreated: dcString,
    });
    setLoading(false);
  }

  async function loadMonthly() {
    const analDocs = await db
      .collection("clubs")
      .doc(clubID)
      .collection("analytics")
      .where("isLast", "==", true)
      .orderBy("date", "asc")
      .get();

    if (analDocs.docs.length === 0) {
      return;
    }

    const arr = [];
    const mthArr = [];

    for (let i = 0; i < analDocs.docs.length; i++) {
      const analDoc = analDocs.docs[i];
      const analData = analDoc.data();
      const dateObject = new Date(analData.date.toDate());
      // console.log(dateObject);
      const year = dateObject.getFullYear();
      const month = dateObject.toLocaleString("default", { month: "long" });
      mthArr.push(`${month} ${year}`);
      arr.push(analData);
    }

    const lastDoc = await db
      .collection("clubs")
      .doc(clubID)
      .collection("analytics")
      .orderBy("date", "desc")
      .limit(1)
      .get();
    const lastData = lastDoc.docs[0].data();
    const dateObject = new Date(lastData.date.toDate());
    // console.log(dateObject);
    const year = dateObject.getFullYear();
    const month = dateObject.toLocaleString("default", { month: "long" });
    mthArr.push(`${month} ${year}`);
    arr.push(lastData);
    setMonthData(arr);
    setMonthDates(mthArr);
  }

  function sortGraph(value, label) {
    const arr = [];
    const labelArr = [];

    for (let i = 0; i < analArray.length; i++) {
      const row = analArray[i];
      const val = row[value];
      arr.push(val);
      labelArr.push(row.dateString);
    }

    setBarLabels(labelArr);
    // console.log(label);
    setSeries([
      {
        name: label,
        data: arr,
      },
    ]);
    setUpdate((c) => c + 1);
  }

  function sortMonthGraph(value, label) {
    const arr = [];
    const labelArr = [];

    for (let i = 0; i < monthData.length; i++) {
      const row = monthData[i];
      console.log(row);
      const val = row[value];
      arr.push(val);
      labelArr.push(monthDates[i]);
    }

    setBarLabels(labelArr);
    console.log(label);
    setSeries([
      {
        name: label,
        data: arr,
      },
    ]);
    setUpdate((c) => c + 1);
  }

  return (
    <div className="club-analytics">
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      {loading && <BackdropLoader />}
      <BackRow action={() => navigate(-1)} />
      <PageHeader text={`Club Analytics - ${details.name}`} />

      <div className="flex-ac">
        <div className="input-group mt-20 pl-20">
          <p>Select Type</p>
          <Dropdown
            arrowClosed={<KeyboardArrowDownIcon className="drop-ico" />}
            arrowOpen={<KeyboardArrowUpIcon className="drop-ico" />}
            value={selType}
            className="def-drop"
            options={typeOpts}
            onChange={(e) => {
              console.log(e);
              setSelType(e.value);
              // sortGraph(e.value, e.label);
              if (selected !== "") {
                // console.log(selected);
                const label = valMap[selected];
                console.log(label);
                if (e.value === "days") {
                  sortGraph(selected, label);
                } else {
                  sortMonthGraph(selected, label);
                }
              }
              setUpdate((current) => current + 1);
            }}
          />
        </div>
        <div className="input-group mt-20 pl-20">
          <p>Select Stat</p>
          <Dropdown
            arrowClosed={<KeyboardArrowDownIcon className="drop-ico" />}
            arrowOpen={<KeyboardArrowUpIcon className="drop-ico" />}
            value={selected}
            className="def-drop"
            options={graphOptions}
            onChange={(e) => {
              console.log(e.label);
              setSelected(e.value);
              if (selType === "days") {
                sortGraph(e.value, e.label);
              } else {
                sortMonthGraph(e.value, e.label);
              }
              setUpdate((current) => current + 1);
            }}
          />
        </div>
      </div>
      <div className="flex-jc mt-20">
        <div className="r-g-box ac-graph bss-white">
          <Chart
            options={barProp.options}
            series={series}
            type={"line"}
            height={320}
          />
        </div>
      </div>
    </div>
  );
}

export default ClubAnalytics;
