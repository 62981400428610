import React, { useState, useEffect } from "react";
import "./Notifications.css";
import { useNavigate } from "react-router-dom";

// Icons
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleIcon from "@mui/icons-material/Circle";
// Components
import { Avatar } from "@mui/material";
import moment from "moment";
import { Close } from "@mui/icons-material";
import classNames from "classnames";
import { db } from "../../firebase";

const GradientIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AccessTimeIcon className="dg-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);

function NotificationItem({
  notification,
  shadow = false,
  hide = () => {},
  showClose = false,
  close = () => {},
  userID = "",
}) {
  const navigate = useNavigate();

  async function handleClick() {
    console.log(notification);
    const { type, docID } = notification;
    if (type === "event-join") {
      navigate(`event-home/${docID}`, {
        state: {
          // eventID: docID,
          // type: "event-home",
        },
      });
    }
    if (type === "account") {
      navigate("/account");
    }
    db.collection("users")
      .doc(userID)
      .collection("portalNotifications")
      .doc(notification.notificationID)
      .update({
        read: true,
      });
    hide();
  }

  return (
    <div
      onClick={(e) => {
        // console.log(e);
        if (e.target.classList) {
          const list = Array.from(e.target.classList);
          if (list.includes("hgb-ico")) {
            // alert("ret");
            return;
          }
        }
        if (!shadow) {
          handleClick();
        }
      }}
      //   style={{
      //     backgroundColor:
      //       notification.read === false && !shadow ? "#F8FDFB" : "white",
      //   }}
      className={shadow ? "notification-item home-ni" : "notification-item"}
    >
      <div className="ni-box">
        <div className="ni-left flex-center">
          <Avatar src={notification.imageLink} alt={"notification"} />
        </div>
        <div className="ni-right">
          <div
            className={classNames("nir-top", {
              "nit-close": showClose,
            })}
          >
            <div className="flex-ac">
              <GradientIcon />
              <h6>{moment(notification.dateMoment).fromNow()}</h6>
            </div>
            {showClose && (
              <div className="ai-close">
                <Close onClick={() => close()} className="hgb-ico" />
              </div>
            )}
          </div>
          <h5>{notification.bold}</h5>
          <p>{notification.text}</p>
        </div>
      </div>

      {!shadow && (
        <div className="ni-unread">
          {notification.read === false && <CircleIcon />}
        </div>
      )}
    </div>
  );
}

export default NotificationItem;
