import React from "react";
import "./Buttons.css";
import classNames from "classnames";

function Pill({ text, onClick, red = false, disabled = false }) {
  return (
    <div
      onClick={() => {
        if (disabled) {
          return;
        } else {
          onClick();
        }
      }}
      className={classNames("pill-button", {
        "red-pill-button": red,
        "dis-pill": disabled,
      })}
    >
      <p>{text}</p>
    </div>
  );
}

export default Pill;
