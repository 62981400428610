import React from "react";

function CreditRowHeader() {
  return (
    <div className="credit-row cr-header">
      <div className="cr-item">
        <p>Date</p>
      </div>
      <div className="cr-item">
        <p>Type</p>
      </div>
      <div className="cr-item">
        <p>Value</p>
      </div>
      <div className="cr-item">
        <p>Balance</p>
      </div>
      <div className="cr-item">
        <p>Details</p>
      </div>
    </div>
  );
}

export default CreditRowHeader;
