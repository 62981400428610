import React, { useState, useEffect, useRef } from "react";
import { admin, db } from "../../../../../firebase";
import { successIco } from "../../../HelperFunctions";

// Icons

// Components
import CustomDrop from "../../../../CustomDrop/CustomDrop";
import { Dialog } from "@mui/material";
import PageHeader from "../../../../display/Rows/PageHeader";
import CPLoader from "../../../CPLoader";
import Pill from "../../../../Buttons/Pill";
import { toast } from "react-toastify";
import PuttMissDrillRow from "./PuttMissDrillRow";

function PuttMissDrillInput({
  close = () => {},
  clubID = "",
  playerID = "",
  scenarioID = "",
  reload = () => {},
  pastAttempts = [],
}) {
  const attProp = {
    distance: 0,
    miss: 0,
  };

  // State
  const [pastAttemptOpts, setPastAttemptOpts] = useState([]);
  const [attempts, setAttempts] = useState([]);
  const [totalPerc, setTotalPerc] = useState(0);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const attRef = useRef();
  attRef.current = attempts;

  // UE
  useEffect(() => {
    const opts = pastAttempts.map((att, i) => {
      const toRet = {
        label: att.dateString,
        value: i.toString(),
      };
      return toRet;
    });
    console.log(opts);
    setPastAttemptOpts(opts);
  }, []);

  // Functions
  function sortTotalPerc(attempts = []) {
    let total = 0;
    for (let i = 0; i < attempts.length; i++) {
      const att = attempts[i];
      total += parseFloat(att.missPercentage);
    }

    const totalMissPercentage = total / attempts.length;
    const tpFixed = totalMissPercentage.toFixed(2);
    setTotalPerc(tpFixed);
  }

  async function saveDrillAttempt() {
    setLoading(true);

    for (let i = 0; i < attempts.length; i++) {
      const att = attempts[i];
      const { distance } = att;
      if (distance === 0) {
        setError("Please ensure all fields are filled in");
        return setLoading(false);
      }
    }

    await db.collection("clubs").doc(clubID).collection("playerScenarios").add({
      playerID,
      dateAdded: new Date(),
      attempts,
      scenarioID,
      totalPerc,
    });

    const toAdd = {
      playerID,
      dateAdded: new Date(),
      attempts,
      totalPerc,
    };

    await db
      .collection("admin")
      .doc("academy")
      .collection("scenarios")
      .doc(scenarioID)
      .update({
        attempts: admin.firestore.FieldValue.arrayUnion(toAdd),
      });

    toast.success("Scenario successfully saved", {
      icon: successIco,
    });
    reload();
    setLoading(false);
    close();
  }

  function addRow() {
    setAttempts((c) => {
      c.push(attProp);
      return c;
    });
    setUpdate((c) => c + 1);
  }

  return (
    <Dialog open onClose={close} className="accuracy-drill putt-accuracy-drill">
      <div className="acc-drill-ctt">
        <PageHeader text="Putt Miss Drill" close={close} showClose />
        {loading ? (
          <div className="cpl-box">
            <CPLoader />
          </div>
        ) : (
          <>
            <div className="flex-ac pl-20">
              {pastAttemptOpts.length > 0 && (
                <div className="input-group mr-20">
                  <p>Load Previous </p>
                  <CustomDrop
                    options={pastAttemptOpts}
                    onChange={(e) => {
                      const val = e.value;
                      const paTemp = [...pastAttempts];
                      const att = paTemp[val];
                      const attempts = [...att.attempts];
                      const arr = [];
                      for (let i = 0; i < attempts.length; i++) {
                        const attempt = attempts[i];
                        const temp = { ...attempt };
                        temp.miss = 0;
                        temp.missPercentage = "0";
                        arr.push(temp);
                      }
                      console.log(arr);
                      setAttempts((c) => {
                        c = [];
                        const spread = [...arr];
                        for (let i = 0; i < spread.length; i++) {
                          c.push(spread[i]);
                        }
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                  />
                </div>
              )}
              <Pill
                text={"Add Shot"}
                onClick={addRow}
                disabled={attempts.length === 10}
              />
            </div>
            <div className="input-group pl-20">
              <p>Total Miss Percentage</p>
              <h3>{totalPerc}%</h3>
            </div>
            <div className="adc-shots">
              {attempts.map((att, i) => {
                return (
                  <PuttMissDrillRow
                    row={att}
                    key={`attt${i}`}
                    removeRow={() => {
                      setAttempts((c) => {
                        c.splice(i, 1);
                        console.log(i, c);
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                    index={i + 1}
                    saveRow={(e) => {
                      setAttempts((c) => {
                        c[i] = e;
                        sortTotalPerc(c);
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                  />
                );
              })}
            </div>

            <div className="error-message">
              <p>{error}</p>
            </div>

            <div className="mt-20 pl-20 pb-20">
              <button
                onClick={saveDrillAttempt}
                disabled={attempts.length === 0}
                className="default-button"
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default PuttMissDrillInput;
