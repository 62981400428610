import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

// Icons
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AddCircle } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Components
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackdropLoader from "../Loaders/BackdropLoader";
import { Dialog, Avatar } from "@mui/material";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import { makeID } from "../HelperFunctions";

function TeamLeagueRound({
  goBack = () => {},
  eventID = "",
  reload = () => {},
  edit = false,
  roundIndex = 0,
  round = {},
  live = false,
  hideMP = false,
}) {
  const formats = [
    ...(!hideMP
      ? [
          {
            value: "Matchplay",
            label: "Matchplay",
          },
        ]
      : []),
    {
      value: "Fourball",
      label: "Fourball",
    },
  ];

  const [teams, setTeams] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [update, setUpdate] = useState(0);
  const [courses, setCourses] = useState([]);
  const [format, setFormat] = useState(hideMP ? "Fourball" : "Matchplay");

  const [changedIDs, setChangedIDs] = useState([]);

  const [roundDate, setRoundDate] = useState(round.date ?? "");

  const [showMatch, setShowMatch] = useState(false);
  const [editing, setEditing] = useState(false);
  const [toEdit, setToEdit] = useState();

  const [search, setSearch] = useState("");

  const [matches, setMatches] = useState([]);

  const [playing, setPlaying] = useState([]);

  const [players, setPlayers] = useState([]);

  const [editIndex, setEditIndex] = useState([]);

  const [selPlayer, setSelPlayer] = useState([]);

  const [courseID, setCourseID] = useState("");

  const [matchups, setMatchups] = useState([]);
  const [matchesPerTeam, setMatchesPerTeam] = useState(
    round.matchesPerTeam ?? 6
  );
  const [oppos, setOppos] = useState({});

  useEffect(() => {
    console.log(live);
    loadDetails();
  }, []);

  async function loadDetails() {
    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();
    const { leagueID, playerIDs, courses, eventDate } = event;
    const matchups = event.matchups ?? [];

    const oppMap = {};

    for (let i = 0; i < matchups.length; i++) {
      const matchup = matchups[i];
      const { teamOne, teamTwo } = matchup;

      oppMap[teamOne.teamID] = teamTwo.teamID;
      oppMap[teamTwo.teamID] = teamOne.teamID;
    }

    setOppos(oppMap);
    // console.log(oppMap);

    setMatchups(event.matchups ?? []);
    setCourseID(event.courseID);
    if (!edit) {
      const dateObj = new Date(eventDate.toDate());
      const ds = dateObj.toISOString().split("T")[0];
      setRoundDate(ds);
    } else {
      const dateObj = new Date(round.date.toDate());

      const ds = dateObj.toISOString().split("T")[0];
      setRoundDate(ds);
    }
    const courseOptions = courses.map((course) => {
      const toRet = {
        label: course.name,
        value: course.courseID,
      };
      return toRet;
    });
    setCourses(courseOptions);
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();

    const { teams } = league;
    for (let i = 0; i < teams.length; i++) {
      teams[i].players = teams[i].players.filter((pl) =>
        playerIDs.includes(pl.playerID)
      );
    }

    const plarr = [];

    const teamMap = {};

    for (let i = 0; i < teams.length; i++) {
      const players = teams[i].players;

      teamMap[teams[i].teamID] = teams[i].teamName;

      for (let j = 0; j < players.length; j++) {
        const player = players[j];
        player.teamName = teams[i].teamName;
        player.teamID = teams[i].teamID;
        plarr.push(players[j]);
      }
    }
    setPlayers(plarr);

    if (edit && !live) {
      const arr = [];
      console.log(round.matches);
      setFormat(round.format);
      const matches = round.matches ?? [];
      // console.log(matches);
      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        if (round.format === "Matchplay") {
          const { playerOne, playerTwo } = match;
          arr.push(playerOne.playerID);
          arr.push(playerTwo.playerID);
        } else {
          const { teamOne, teamTwo } = match;
          for (let j = 0; j < teamOne.length; j++) {
            const pl = teamOne[j];
            arr.push(pl.playerID);
          }
          for (let j = 0; j < teamTwo.length; j++) {
            const pl = teamTwo[j];
            arr.push(pl.playerID);
          }
        }
      }

      setPlaying(arr);
      setMatches(matches);
    }
    if (live) {
      const roundDoc = await db
        .collection("rounds")
        .doc(event.activeRoundID)
        .get();
      const round = roundDoc.data();

      const matches = round.matches ?? [];
      // console.log(matches);

      setFormat(round.format);
      const arr = [];
      console.log(matches);
      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        // console.log(match);
        if (round.format === "Matchplay") {
          const { playerOne, playerTwo } = match;
          match.playerOne.teamName = teamMap[match.teamOneID];
          match.playerTwo.teamName = teamMap[match.teamTwoID];
          arr.push(playerOne.playerID);
          arr.push(playerTwo.playerID);
        } else {
          const { teamOne, teamTwo } = match;
          for (let j = 0; j < teamOne.length; j++) {
            const pl = teamOne[j];
            match.teamOne[j].teamName = teamMap[match.teamOneID];
            arr.push(pl.playerID);
          }
          for (let j = 0; j < teamTwo.length; j++) {
            const pl = teamTwo[j];
            match.teamTwo[j].teamName = teamMap[match.teamTwoID];

            arr.push(pl.playerID);
          }
        }
      }

      setPlaying(arr);
      setMatches(matches);
    }
    setTeams(teams);
    setUpdate((current) => current + 1);

    setLoading(false);
  }

  function addMatch() {
    const matchID = makeID(10);
    if (format === "Matchplay") {
      setMatches((current) => {
        current.push({
          complete: false,
          leader: "",
          playerOne: {},
          playerTwo: {},
          time: "",
          courseID,
          matchID,
        });
        return current;
      });
    } else {
      setMatches((current) => {
        current.push({
          complete: false,
          leader: ["", ""],
          teamOne: [{}, {}],
          teamTwo: [{}, {}],
          time: "",
          courseID,
          matchID,
        });
        return current;
      });
      setChangedIDs((c) => {
        c.push(matchID);
        return c;
      });
    }
    setUpdate((current) => current + 1);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      const eventDoc = await db.collection("teamEvents").doc(eventID).get();
      const event = eventDoc.data();
      const { rounds } = event;

      if (!edit) {
        rounds.push({
          matches,
          date: new Date(roundDate),
          format,
          matchesPerTeam,
        });
      } else {
        rounds[roundIndex].matches = matches;
        rounds[roundIndex].date = new Date(roundDate);
        rounds[roundIndex].format = format;
        rounds[roundIndex].matchesPerTeam = matchesPerTeam;
        // rounds[roundIndex].teamSubmissions
        // rounds[roundIndex] = {
        //   matches,
        //   date: new Date(roundDate),
        //   format,
        //   matchesPerTeam,
        //   teamSubmissions: round.teamSubmissions ?? {},
        // };
      }
      await eventDoc.ref.update({
        rounds,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function saveLiveChanges() {
    // return console.log(matches, changedIDs);
    setLoading(true);
    try {
      await db.collection("triggers").add({
        type: "te-match-edit",
        dateAdded: new Date(),
        eventID,
        roundIndex,
        matches,
        changed: changedIDs,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="cup-round team-league-round">
      <BackRow action={goBack} />
      <PageHeader text={edit ? "Manage Round" : "Add Round"} />
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog open={showMatch} onClose={() => setShowMatch(false)}>
        <div className="tlr-select">
          <PageHeader
            text="Select Player"
            close={() => setShowMatch(false)}
            showClose
          />
          <div className="input-group pl-20">
            <p>Search</p>
            <input
              type="text"
              className="default-input"
              value={search}
              placeholder="Start typing..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="flex-center mb-20">
            <button
              onClick={() => {
                if (format === "Matchplay") {
                  setMatches((current) => {
                    if (editIndex[1] === 1) {
                      current[editIndex[0]].playerOne = selPlayer;
                    } else {
                      current[editIndex[0]].playerTwo = selPlayer;
                    }
                    return current;
                  });
                } else {
                  setMatches((current) => {
                    const i1 = editIndex[0];
                    const i2 = editIndex[1];
                    const i3 = editIndex[2] - 1;

                    if (i2 === 1) {
                      current[i1].teamOne[i3] = selPlayer;
                      current[i1].teamOneID = selPlayer.teamID;
                    } else {
                      current[i1].teamTwo[i3] = selPlayer;
                      current[i1].teamTwoID = selPlayer.teamID;
                    }

                    return current;
                  });
                }
                setPlaying((current) => {
                  current.push(selPlayer.playerID);
                  return current;
                });
                setUpdate((current) => current + 1);
                setEditIndex([]);
                setSelPlayer();
                setShowMatch(false);
              }}
              className="default-button mt-20"
              disabled={selPlayer === undefined}
            >
              Select Player
            </button>
          </div>
          <div className="tlr-players pd-20">
            {false &&
              players.map((player, index) => (
                <PlayerItem
                  key={player.playerID}
                  name={player.name}
                  hideIcons
                  noLink
                />
              ))}
            {teams.map((team, index) => {
              if (matchups.length > 0) {
                if (format === "Matchplay") {
                  const mtc = matches[editIndex[0]];
                  // console.log(mtc);
                  if (mtc) {
                    const plIndex = editIndex[1];
                    if (plIndex === 1) {
                      if (mtc.playerTwo.teamID) {
                        if (team.teamID !== oppos[mtc.playerTwo.teamID]) {
                          return null;
                        }
                      }
                    }
                    if (plIndex === 2) {
                      if (mtc.playerOne.teamID) {
                        if (team.teamID !== oppos[mtc.playerOne.teamID]) {
                          return null;
                        }
                      }
                    }
                  }
                }

                if (format === "Fourball") {
                  // console.log(matches, editIndex[0]);
                  const mtc = matches[editIndex[0]];
                  if (mtc) {
                    // console.log(mtc);
                    const { teamOneID, teamTwoID } = mtc;
                    const teamIndex = editIndex[1];
                    let tm = "teamOne";
                    if (teamIndex === 2) {
                      tm = "teamTwo";
                    }

                    if (teamIndex === 1) {
                      if (teamOneID) {
                        if (teamOneID !== team.teamID) {
                          return null;
                        }
                      }
                      if (teamTwoID) {
                        if (oppos[teamTwoID]) {
                          if (team.teamID !== oppos[teamTwoID]) {
                            return null;
                          }
                        }
                      }
                    }

                    if (teamIndex === 2) {
                      if (teamTwoID) {
                        if (teamTwoID !== team.teamID) {
                          return null;
                        }
                      }
                      if (teamOneID) {
                        console.log(teamOneID);

                        if (oppos[teamOneID]) {
                          if (team.teamID !== oppos[teamOneID]) {
                            return null;
                          }
                        }
                      }
                    }
                  }
                }
              }

              return (
                <div className="ltm-team pd-20" key={team.teamID}>
                  <div className="ltm-team-top flex-align-center">
                    <Avatar alt={team.teamName} src={team.imageLink} />
                    <h3 className="mr-5v ml-20">{team.teamName}</h3>
                  </div>
                  <div className="ltm-members pd-20 flex-wrap">
                    {team.players.map((player, index2) => {
                      if (playing.includes(player.playerID)) {
                        return null;
                      }
                      if (search !== "") {
                        const sl = search.toLowerCase();
                        const pl = player.name.toLowerCase();
                        if (!pl.includes(sl)) {
                          return null;
                        }
                      }
                      return (
                        <PlayerItem
                          key={player.playerID}
                          name={player.name}
                          noLink
                          showAdd
                          showProfile={() => {
                            if (format === "Matchplay") {
                              setMatches((current) => {
                                if (editIndex[1] === 1) {
                                  setPlaying((c) => {
                                    const filt = c.filter(
                                      (item) =>
                                        item !==
                                        current[editIndex[0]].playerOne.playerID
                                    );
                                    return filt;
                                  });
                                  current[editIndex[0]].playerOne = player;
                                } else {
                                  setPlaying((c) => {
                                    const filt = c.filter(
                                      (item) =>
                                        item !==
                                        current[editIndex[0]].playerTwo.playerID
                                    );
                                    return filt;
                                  });
                                  current[editIndex[0]].playerTwo = player;
                                }

                                const matchID = current[editIndex[0]].matchID;
                                console.log(matchID);
                                setChangedIDs((c) => {
                                  if (!c.includes(matchID)) {
                                    c.push(matchID);
                                  }
                                  return c;
                                });

                                return current;
                              });
                            } else {
                              setMatches((current) => {
                                const i1 = editIndex[0];
                                const i2 = editIndex[1];
                                const i3 = editIndex[2] - 1;

                                if (i2 === 1) {
                                  setPlaying((c) => {
                                    const filt = c.filter(
                                      (item) =>
                                        item !==
                                        current[i1].teamOne[i3].playerID
                                    );
                                    return filt;
                                  });
                                  current[i1].teamOne[i3] = player;
                                  current[i1].teamOneID = player.teamID;
                                } else {
                                  setPlaying((c) => {
                                    const filt = c.filter(
                                      (item) =>
                                        item !==
                                        current[i1].teamTwo[i3].playerID
                                    );
                                    return filt;
                                  });
                                  current[i1].teamTwo[i3] = player;
                                  current[i1].teamTwoID = player.teamID;
                                }

                                const matchID = current[editIndex[0]].matchID;
                                console.log(matchID);
                                setChangedIDs((c) => {
                                  if (!c.includes(matchID)) {
                                    c.push(matchID);
                                  }
                                  return c;
                                });

                                return current;
                              });
                            }
                            setPlaying((current) => {
                              current.push(player.playerID);
                              return current;
                            });
                            setUpdate((current) => current + 1);
                            setEditIndex([]);
                            setSelPlayer();
                            setSearch("");
                            setShowMatch(false);
                          }}
                          showBg={
                            selPlayer && selPlayer.playerID === player.playerID
                          }
                          playerID={player.playerID}
                          hideProfile
                          hideEdit
                          hideIcons
                          //   hideIcons
                          hideImage
                          hideHCP
                          removeAction={() => {}}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex-center mb-20">
            <button
              onClick={() => {
                if (format === "Matchplay") {
                  setMatches((current) => {
                    if (editIndex[1] === 1) {
                      setPlaying((c) => {
                        const filt = c.filter(
                          (item) =>
                            item !== current[editIndex[0]].playerOne.playerID
                        );
                        return filt;
                      });
                      current[editIndex[0]].playerOne = selPlayer;
                    } else {
                      setPlaying((c) => {
                        const filt = c.filter(
                          (item) =>
                            item !== current[editIndex[0]].playerTwo.playerID
                        );
                        return filt;
                      });
                      current[editIndex[0]].playerTwo = selPlayer;
                    }
                    return current;
                  });
                } else {
                  setMatches((current) => {
                    const i1 = editIndex[0];
                    const i2 = editIndex[1];
                    const i3 = editIndex[2] - 1;

                    if (i2 === 1) {
                      current[i1].teamOne[i3] = selPlayer;
                      current[i1].teamOneID = selPlayer.teamID;
                    } else {
                      current[i1].teamTwo[i3] = selPlayer;
                      current[i1].teamTwoID = selPlayer.teamID;
                    }

                    return current;
                  });
                }
                setPlaying((current) => {
                  current.push(selPlayer.playerID);
                  return current;
                });
                setUpdate((current) => current + 1);
                setEditIndex([]);
                setSelPlayer();
                setShowMatch(false);
              }}
              className="default-button"
              disabled={selPlayer === undefined}
            >
              Select Player
            </button>
          </div>
        </div>
      </Dialog>

      <div className="ec-box">
        {!live && (
          <div className="input-group">
            <p>Date</p>
            <input
              type="date"
              className="default-input"
              value={roundDate}
              onChange={(e) => setRoundDate(e.target.value)}
            />
          </div>
        )}
        {!live && (
          <div className="input-group league-hint ">
            <div className="ig-header">
              <p>Format</p>
            </div>

            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              options={formats}
              value={format}
              onChange={(e) => {
                setFormat(e.value);
                setMatches([]);
                setUpdate((current) => current + 1);
              }}
            />
          </div>
        )}
        {!live && (
          <>
            <div className="input-group">
              <div className="ig-header">
                <p>Matches Per Team</p>
              </div>
              <input
                className="default-input"
                type={"number"}
                min={1}
                value={matchesPerTeam}
                onChange={(e) => {
                  if (e.target.value < 1) {
                    setMatchesPerTeam(1);
                  } else {
                    setMatchesPerTeam(e.target.valueAsNumber);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="ehb-header">
        <h2 className="ne-header mr-5v">Matches</h2>
        <AddCircle
          className="icon-cursor dg-icon-bg"
          onClick={() => addMatch()}
        />
      </div>
      <div className="tlr-matches">
        {format === "Matchplay" &&
          matches.map((match, index) => {
            const { playerOne, playerTwo } = match;
            // console.log(match);
            return (
              <div className="tlr-match" key={`${index}match`}>
                <div className="tlrm-left">
                  <PlayerItem
                    name={
                      playerOne.name
                        ? `${playerOne.name} (${playerOne.teamName})`
                        : "Select player"
                    }
                    noLink
                    hideImage
                    hideMessage
                    hideProfile
                    hideHCP
                    showAdd
                    hideRemove={playerOne.name === undefined}
                    editAction={() => {
                      setEditIndex([index, 1]);
                      setShowMatch(true);
                    }}
                    removeAction={() => {
                      setMatches((current) => {
                        current[index].playerOne = {};
                        return current;
                      });
                      setPlaying((current) => {
                        const filt = current.filter(
                          (id) => id !== playerOne.playerID
                        );
                        return filt;
                      });
                      setUpdate((current) => current + 1);

                      live &&
                        setChangedIDs((c) => {
                          if (!c.includes(match.matchID)) {
                            c.push(match.matchID);
                          }
                          return c;
                        });
                    }}
                  />
                </div>
                <div className="vs-pee">
                  <p>vs</p>
                </div>
                <div className="tlrm-left">
                  <PlayerItem
                    name={
                      playerTwo.name
                        ? `${playerTwo.name} (${playerTwo.teamName})`
                        : "Select player"
                    }
                    noLink
                    hideImage
                    hideMessage
                    hideProfile
                    hideHCP
                    showAdd
                    hideRemove={playerTwo.name === undefined}
                    editAction={() => {
                      setEditIndex([index, 2]);

                      setShowMatch(true);
                    }}
                    removeAction={() => {
                      setMatches((current) => {
                        current[index].playerTwo = {};
                        return current;
                      });
                      setPlaying((current) => {
                        const filt = current.filter(
                          (id) => id !== playerTwo.playerID
                        );
                        return filt;
                      });
                      setUpdate((current) => current + 1);
                      live &&
                        setChangedIDs((c) => {
                          if (!c.includes(match.matchID)) {
                            c.push(match.matchID);
                          }
                          return c;
                        });
                    }}
                  />
                </div>
                <div className="input-group league-hint ml-20">
                  <div className="ig-header">
                    <p>Course</p>
                  </div>

                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={courses}
                    value={match.courseID}
                    onChange={(e) => {
                      setMatches((current) => {
                        current[index].courseID = e.value;
                        return current;
                      });
                      setUpdate((current) => current + 1);
                      live &&
                        setChangedIDs((c) => {
                          if (!c.includes(match.matchID)) {
                            c.push(match.matchID);
                          }
                          return c;
                        });
                    }}
                  />
                </div>
                <div className="input-group">
                  <p>Time</p>
                  <input
                    type="time"
                    value={match.time}
                    onChange={(e) => {
                      setMatches((current) => {
                        current[index].time = e.target.value;
                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                    className="default-input small-input"
                  />
                </div>
                <DeleteForeverIcon
                  className="icon-cursor red-icon-bg ml-20"
                  onClick={() => {
                    setMatches((current) => {
                      delete current[index];
                      const filt = current.filter((item) => item !== null);
                      return filt;
                    });
                    setPlaying((current) => {
                      const filt = current.filter(
                        (id) => id !== playerOne.playerID
                      );
                      const filt2 = filt.filter(
                        (id) => id !== playerTwo.playerID
                      );
                      return filt2;
                    });
                    setUpdate((current) => current + 1);
                    live &&
                      setChangedIDs((c) => {
                        if (!c.includes(match.matchID)) {
                          c.push(match.matchID);
                        }
                        return c;
                      });
                  }}
                />
              </div>
            );
          })}
        {format === "Fourball" &&
          matches.map((match, index) => {
            const { teamOne, teamTwo } = match;

            const t1P1 = teamOne[0] ?? {};
            const t1P2 = teamOne[1] ?? {};
            const t2P1 = teamTwo[0] ?? {};
            const t2P2 = teamTwo[1] ?? {};

            return (
              <div className="tlr-match" key={`${index}match`}>
                <div className="tlrm-left">
                  <PlayerItem
                    name={
                      t1P1.name
                        ? `${t1P1.name} (${t1P1.teamName})`
                        : "Select player"
                    }
                    noLink
                    hideImage
                    hideMessage
                    hideProfile
                    hideHCP
                    showAdd
                    hideRemove={t1P1.name === undefined}
                    editAction={() => {
                      setEditIndex([index, 1, 1]);
                      setShowMatch(true);
                    }}
                    removeAction={() => {
                      setMatches((current) => {
                        current[index].teamOne[0] = {};
                        if (current[index].teamOne[1].teamID === undefined) {
                          current[index].teamOneID = undefined;
                        }
                        return current;
                      });
                      setPlaying((current) => {
                        const filt = current.filter(
                          (id) => id !== t1P1.playerID
                        );
                        return filt;
                      });
                      setUpdate((current) => current + 1);
                      live &&
                        setChangedIDs((c) => {
                          if (!c.includes(match.matchID)) {
                            c.push(match.matchID);
                          }
                          return c;
                        });
                    }}
                  />
                  <PlayerItem
                    name={
                      t1P2.name
                        ? `${t1P2.name} (${t1P2.teamName})`
                        : "Select player"
                    }
                    noLink
                    hideImage
                    hideMessage
                    hideProfile
                    hideHCP
                    showAdd
                    hideRemove={t1P2.name === undefined}
                    editAction={() => {
                      setEditIndex([index, 1, 2]);
                      setShowMatch(true);
                    }}
                    removeAction={() => {
                      setMatches((current) => {
                        console.log(current);
                        current[index].teamOne[1] = {};
                        if (current[index].teamOne[0].teamID === undefined) {
                          current[index].teamOneID = undefined;
                        }
                        return current;
                      });
                      setPlaying((current) => {
                        const filt = current.filter(
                          (id) => id !== t1P2.playerID
                        );
                        return filt;
                      });
                      setUpdate((current) => current + 1);
                      live &&
                        setChangedIDs((c) => {
                          if (!c.includes(match.matchID)) {
                            c.push(match.matchID);
                          }
                          return c;
                        });
                    }}
                  />
                </div>
                <div className="vs-pee">
                  <p>vs</p>
                </div>
                <div className="tlrm-left">
                  <PlayerItem
                    name={
                      t2P1.name
                        ? `${t2P1.name} (${t2P1.teamName})`
                        : "Select player"
                    }
                    noLink
                    hideImage
                    hideMessage
                    hideProfile
                    hideHCP
                    showAdd
                    hideRemove={t2P1.name === undefined}
                    editAction={() => {
                      setEditIndex([index, 2, 1]);

                      setShowMatch(true);
                    }}
                    removeAction={() => {
                      setMatches((current) => {
                        current[index].teamTwo[0] = {};
                        if (current[index].teamTwo[1].teamID === undefined) {
                          current[index].teamTwoID = undefined;
                        }
                        return current;
                      });
                      setPlaying((current) => {
                        const filt = current.filter(
                          (id) => id !== t2P1.playerID
                        );
                        return filt;
                      });
                      setUpdate((current) => current + 1);
                      live &&
                        setChangedIDs((c) => {
                          if (!c.includes(match.matchID)) {
                            c.push(match.matchID);
                          }
                          return c;
                        });
                    }}
                  />
                  <PlayerItem
                    name={
                      t2P2.name
                        ? `${t2P2.name} (${t2P2.teamName})`
                        : "Select player"
                    }
                    noLink
                    hideImage
                    hideMessage
                    hideProfile
                    hideHCP
                    showAdd
                    hideRemove={t2P2.name === undefined}
                    editAction={() => {
                      setEditIndex([index, 2, 2]);

                      setShowMatch(true);
                    }}
                    removeAction={() => {
                      setMatches((current) => {
                        current[index].teamTwo[1] = {};
                        if (current[index].teamTwo[0].teamID === undefined) {
                          current[index].teamTwoID = undefined;
                        }
                        return current;
                      });
                      setPlaying((current) => {
                        const filt = current.filter(
                          (id) => id !== t2P2.playerID
                        );
                        return filt;
                      });
                      setUpdate((current) => current + 1);
                      live &&
                        setChangedIDs((c) => {
                          if (!c.includes(match.matchID)) {
                            c.push(match.matchID);
                          }
                          return c;
                        });
                    }}
                  />
                </div>
                <div className="input-group league-hint ml-20">
                  <div className="ig-header">
                    <p>Course</p>
                  </div>

                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={courses}
                    value={match.courseID}
                    onChange={(e) => {
                      setMatches((current) => {
                        current[index].courseID = e.value;
                        return current;
                      });
                      setUpdate((current) => current + 1);
                      live &&
                        setChangedIDs((c) => {
                          if (!c.includes(match.matchID)) {
                            c.push(match.matchID);
                          }
                          return c;
                        });
                    }}
                  />
                </div>
                <DeleteForeverIcon
                  className="icon-cursor red-icon-bg ml-20"
                  onClick={() => {
                    setMatches((current) => {
                      delete current[index];
                      const filt = current.filter((item) => item !== null);

                      return filt;
                    });
                    setPlaying((current) => {
                      const filt = current.filter((id) => id !== t1P1.playerID);
                      const filt2 = filt.filter((id) => id !== t1P2.playerID);
                      const filt3 = filt2.filter((id) => id !== t2P1.playerID);
                      const filt4 = filt3.filter((id) => id !== t2P2.playerID);
                      return filt4;
                    });
                    setUpdate((current) => current + 1);
                  }}
                />
              </div>
            );
          })}
      </div>

      <div className="flex-center mt-40 mb-40">
        <button
          onClick={live ? saveLiveChanges : saveChanges}
          className="default-button"
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default TeamLeagueRound;
