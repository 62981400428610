import React, { useState } from "react";

import PlayerRow from "./PlayerRow";
import PlayerRowHeader from "./PlayerRowHeader";

function PlayerDisplay({
  players = [],
  showTeam = false,
  search = "",
  showRemove = false,
  removeAction = () => {},
}) {
  // State
  // const [search, setSearch] = useState("");
  const [currentSorter, setCurrentSorter] = useState("");
  const [dir, setDir] = useState("");
  const [sortedPlayers, setSortedPlayers] = useState(players);
  const [update, setUpdate] = useState(0);

  function sortPlayers({ sorter }) {
    if (sorter === currentSorter) {
      if (dir === "up") {
        if (
          sorter === "lastName" ||
          sorter === "city" ||
          sorter === "country" ||
          sorter === "team"
        ) {
          setSortedPlayers((c) => {
            c.sort((a, b) => b[sorter].localeCompare(a[sorter]));
            return c;
          });
        } else {
          setSortedPlayers((c) => {
            c.sort((a, b) => b[sorter] - a[sorter]);
            return c;
          });
        }
        setDir("down");
      } else {
        if (
          sorter === "lastName" ||
          sorter === "city" ||
          sorter === "country" ||
          sorter === "team"
        ) {
          setSortedPlayers((c) => {
            c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
            return c;
          });
        } else {
          setSortedPlayers((c) => {
            c.sort((a, b) => a[sorter] - b[sorter]);
            return c;
          });
        }
        setDir("up");
      }
    } else {
      if (
        sorter === "lastName" ||
        sorter === "city" ||
        sorter === "country" ||
        sorter === "team"
      ) {
        setSortedPlayers((c) => {
          c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
          return c;
        });
      } else {
        setSortedPlayers((c) => {
          c.sort((a, b) => a[sorter] - b[sorter]);
          return c;
        });
      }
      setCurrentSorter(sorter);
      setDir("up");
    }

    setUpdate((c) => c + 1);
  }

  return (
    <div className="league-members mt-40 pd-10">
      {/* <div className="lm-search mb-20"></div> */}

      <PlayerRowHeader
        showTeam={showTeam}
        showRemove={showRemove}
        currentSorter={currentSorter}
        dir={dir}
        sortByHeader={(e) => {
          sortPlayers({ sorter: e });
        }}
      />
      {sortedPlayers.map((player, i) => {
        if (search !== "") {
          const { name } = player;
          const sl = search.toLowerCase();
          const nl = name.toLowerCase();

          if (!nl.includes(sl)) {
            return null;
          }
        }

        return (
          <PlayerRow
            showRemove={showRemove}
            removeAction={() => removeAction(player.playerID)}
            key={player.playerID}
            city={player.city}
            country={player.country}
            handicap={player.handicap}
            joined={player.djString}
            team={player.team}
            name={player.name}
            profileImage={player.profileImage}
          />
        );
      })}
    </div>
  );
}

export default PlayerDisplay;
