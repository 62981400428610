import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// CSS
import "./CalendarPage.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Admin
import moment from "moment";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useMenuTheme } from "../../contexts/MenuContext";
import { useHelperClickTheme } from "../../contexts/HelperContext";

// Components
import CustomToolBar from "./CustomToolBar";
import CustomEvent from "./CustomEvent";
import { Calendar, momentLocalizer } from "react-big-calendar";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import EventHome from "../../components/Events/EventCreator/EventHome";
import EventCreator from "../../components/Events/EventCreator/EventCreator";
import InviteModal from "../../components/Events/InviteModal/InviteModal";
import RadioGroup from "../../components/Buttons/RadioGroup/RadioGroup";
import EventRounds from "../../components/Events/EventCreator/EventRounds";
import FixturePreview from "../../components/display/FixturePreview/FixturePreview";
import Switch from "react-ios-switch/lib/Switch";
import EventDashboard from "../../components/Events/EventDashboard/EventDashboard";
import Pill from "../../components/Buttons/Pill";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import HomeActiveEvents from "../Home/HomeActiveEvents";
import TeamEventDashboard from "../../components/Events/EventDashboard/TeamEventDashboard";
import TeamLeagueEventHome from "../../components/ClubLeagues/TeamLeagueEventHome";
import CupDashboard from "../../components/Events/EventDashboard/CupDashboard";
import { isMobile } from "react-device-detect";
import MobileCalendar from "./MobileCalendar";
import PageHeader from "../../components/display/Rows/PageHeader";
import FixtureList from "../../components/FixtureRow/FixtureList";

function CalendarPage() {
  // Admin
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const { userDetails, currentUser, portalSettings } = useAuth();
  const location = useLocation();
  const showHelperClick = useHelperClickTheme();
  // State
  const [events, setEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [futEvents, setFutEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listType, setListType] = useState("items");

  const [accountType, setAccountType] = useState("");

  const [clubID, setClubID] = useState(
    portalSettings ?? portalSettings.clubID ? portalSettings.clubID : ""
  );

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [active, setActive] = useState("calendar");

  const [checked, setChecked] = useState(true);

  const [searchInput, setSearchInput] = useState("");

  const clicked = useMenuTheme();

  useEffect(() => {
    if (userDetails) {
      setAccountType(userDetails.accountType);
    }
  }, [userDetails]);

  useEffect(() => {
    // console.log(clicked);
    if (typeof clicked === "string") {
      if (clicked.includes("events")) {
        setShow(false);
      }
    } else {
      if (clicked.type === "active-event") {
        setToShow(
          <EventDashboard
            eventID={location.state.eventID}
            goBack={() => setShow(false)}
          />
        );
        setShow(true);
      }

      if (clicked.type === "active-cup") {
        console.log(location.state);
        setToShow(
          <CupDashboard
            cupID={location.state.cupID ?? location.state.eventID}
            goBack={() => setShow(false)}
          />
        );
        setShow(true);
      }

      if (clicked.type === "active-team-event") {
        setToShow(
          <TeamEventDashboard
            eventID={location.state.eventID}
            goBack={() => setShow(false)}
          />
        );
        setShow(true);
      }
    }
  }, [clicked]);

  // UE
  useEffect(() => {
    if (currentUser && portalSettings && userDetails) {
      if (
        userDetails.accountType !== "portal" &&
        userDetails.accountType !== "scorer"
      ) {
        loadEvents();
      } else {
        setLoading(false);
      }
    }
  }, [userDetails, currentUser, portalSettings, userDetails]);

  useEffect(() => {
    // console.log(location.state);
    if (location.state) {
      // console.log(location.state);
      if (location.state.type === "active") {
        setToShow(
          <EventDashboard
            eventID={location.state.eventID}
            goBack={() => setShow(false)}
          />
        );
        setShow(true);
        window.history.replaceState({}, document.title);
      }

      if (location.state.type === "cup-active") {
        console.log(location.state);
        setToShow(
          <CupDashboard
            cupID={location.state.cupID}
            goBack={() => setShow(false)}
          />
        );
        setShow(true);
      }

      if (location.state.type === "team-active") {
        setToShow(
          <TeamEventDashboard
            eventID={location.state.eventID}
            goBack={() => setShow(false)}
          />
        );
        setShow(true);
        window.history.replaceState({}, document.title);
      }
      if (location.state.type === "event-home") {
        setToShow(
          <EventHome
            eventID={location.state.eventID}
            goBack={() => setShow(false)}
            reload={loadEvents}
          />
        );
        setShow(true);
        window.history.replaceState({}, document.title);
      }
    }
  }, []);

  // Functions
  async function loadEvents() {
    // console.log(portalSettings.clubID);

    const now = new Date();

    let leagues, standalones, standalones2;

    if (portalSettings.clubID) {
      leagues = await db
        .collection("leagues")
        // .where("admins", "array-contains", currentUser.uid)
        .where("clubID", "==", portalSettings.clubID)
        .get();

      standalones = await db
        .collection("events")
        .where("standalone", "==", true)
        // .where("adminID", "==", currentUser.uid)
        // .where("eventDate", ">", now)
        // .where("adminIDs", "array-contains", currentUser.uid)

        .where("clubID", "==", portalSettings.clubID)
        .get();
      console.log(standalones.docs.length);
    } else {
      leagues = await db
        .collection("leagues")
        .where("admins", "array-contains", currentUser.uid)
        .get();

      standalones = await db
        .collection("events")
        .where("standalone", "==", true)
        .where("adminID", "==", currentUser.uid)
        // .where("eventDate", ">", now)
        .get();
      standalones2 = await db
        .collection("events")
        .where("standalone", "==", true)
        .where("adminIDs", "array-contains", currentUser.uid)
        // .where("eventDate", ">", now)
        .get();
    }

    const teamEvents = await db
      .collection("teamEvents")
      .where("clubID", "==", clubID)
      // .where("adminIDs", "array-contains", currentUser.uid)
      // .where("eventDate", ">", now)
      .get();

    console.log(teamEvents.docs.length);

    const arr = [];
    const parr = [];
    const farr = [];

    const leagueIDs = leagues.docs.map((league) => league.id);

    const seasonDocs = await db
      .collection("seasons")
      .where("leagueID", "in", leagueIDs)
      .get();
    const seasonMap = {};

    for (let i = 0; i < seasonDocs.docs.length; i++) {
      const seasonDoc = seasonDocs.docs[i];
      const seasonData = seasonDoc.data();
      const seasonID = seasonDoc.id;
      const { seasonName } = seasonData;
      seasonMap[seasonID] = seasonName;
    }

    for (let i = 0; i < leagues.docs.length; i++) {
      const leagueID = leagues.docs[i].id;
      const eventDocs = await db
        .collection("events")
        .where("leagueID", "==", leagueID)
        .orderBy("eventDate", "desc")
        .get();
      for (let j = 0; j < eventDocs.docs.length; j++) {
        const eventDoc = eventDocs.docs[j];
        const eventID = eventDoc.id;
        const event = eventDoc.data();
        const eventDate = new Date(event.eventDate.toDate());
        const eventDateString = eventDate.toLocaleDateString("en-us", {
          dateStyle: "medium",
        });
        event.dateObject = eventDate;
        const seasonID = event.seasonID ?? "";
        const season = seasonMap[seasonID] ?? "";
        event.season = season;
        event.eventDate = eventDate;
        event.dateString = eventDateString;
        event.eventDateString = eventDateString;
        event.start = eventDate;
        const end = new Date(eventDate);
        end.setHours(end.getHours() + 6);
        event.end = end;
        event.title = event.eventName;
        event.eventID = eventID;
        event.accountType = userDetails.accountType;
        if (event.roundIDs.length > 0) {
          event.active = true;
        } else {
          event.active = false;
        }
        function showEvent() {
          navigate(`/event-home/${eventID}`);

          // setToShow(
          //   <EventHome goBack={() => setShow(false)} eventID={eventID} />
          // );
          // setShow(true);
        }
        function showInvite() {
          setToShow(
            <InviteModal hide={() => setShow(false)} eventID={event.eventID} />
          );
          setShow(true);
        }
        function showActiveEvent() {
          navigate(`/evt-dash/${eventID}`);

          // setToShow(
          //   <EventDashboard eventID={eventID} goBack={() => setShow(false)} />
          // );
          // setShow(true);
        }
        event.showEvent = showEvent;
        event.showInvite = showInvite;
        event.showActiveEvent = showActiveEvent;

        if (now > eventDate) {
          parr.push(event);
          event.past = true;
        } else {
          farr.push(event);
          event.past = false;
        }

        arr.push(event);
      }
    }

    const stlArr = [];

    for (let j = 0; j < standalones.docs.length; j++) {
      const eventDoc = standalones.docs[j];
      const eventID = eventDoc.id;
      stlArr.push(eventID);
      const event = eventDoc.data();
      const eventDate = new Date(event.eventDate.toDate());
      const eventDateString = eventDate.toLocaleDateString("en-us", {
        dateStyle: "medium",
      });
      event.eventDate = eventDate;
      event.dateString = eventDateString;
      event.eventDateString = eventDateString;
      event.start = eventDate;
      event.dateObject = eventDate;

      const end = new Date(eventDate);
      end.setHours(end.getHours() + 6);
      event.end = end;
      event.eventType = "regular";
      event.title = event.eventName;
      event.eventID = eventID;
      event.accountType = userDetails.accountType;

      if (event.roundIDs.length > 0 && event.completed === false) {
        event.active = true;
      } else {
        event.active = false;
      }
      function showEvent() {
        navigate(`/event-home/${eventID}`);

        // setToShow(
        //   <EventHome goBack={() => setShow(false)} eventID={eventID} />
        // );
        // setShow(true);
      }
      function showInvite() {
        setToShow(
          <InviteModal hide={() => setShow(false)} eventID={event.eventID} />
        );
        setShow(true);
      }
      function showActiveEvent() {
        navigate(`/evt-dash/${eventID}`);

        // setToShow(
        //   <EventDashboard eventID={eventID} goBack={() => setShow(false)} />
        // );
        // setShow(true);
      }
      event.showEvent = showEvent;
      event.showInvite = showInvite;
      event.showActiveEvent = showActiveEvent;

      if (now > eventDate) {
        parr.push(event);
        event.past = true;
      } else {
        farr.push(event);
        event.past = false;
      }

      arr.push(event);
    }

    if (standalones2) {
      for (let j = 0; j < standalones2.docs.length; j++) {
        const eventDoc = standalones2.docs[j];
        const eventID = eventDoc.id;
        if (stlArr.includes(eventID)) {
          continue;
        }
        const event = eventDoc.data();
        const eventDate = new Date(event.eventDate.toDate());
        const eventDateString = eventDate.toLocaleDateString("en-us", {
          dateStyle: "medium",
        });
        event.dateObject = eventDate;

        event.eventDate = eventDate;
        event.dateString = eventDateString;
        event.eventDateString = eventDateString;
        event.start = eventDate;
        const end = new Date(eventDate);
        end.setHours(end.getHours() + 6);
        event.end = end;
        event.eventType = "regular";
        event.title = event.eventName;
        event.eventID = eventID;
        event.accountType = userDetails.accountType;

        if (event.roundIDs.length > 0 && event.completed === false) {
          event.active = true;
        } else {
          event.active = false;
        }
        function showEvent() {
          navigate(`/event-home/${eventID}`);

          // setToShow(
          //   <EventHome goBack={() => setShow(false)} eventID={eventID} />
          // );
          // setShow(true);
        }
        function showInvite() {
          setToShow(
            <InviteModal hide={() => setShow(false)} eventID={event.eventID} />
          );
          setShow(true);
        }
        function showActiveEvent() {
          navigate(`/evt-dash/${eventID}`);

          // setToShow(
          //   <EventDashboard eventID={eventID} goBack={() => setShow(false)} />
          // );
          // setShow(true);
        }
        event.showEvent = showEvent;
        event.showInvite = showInvite;
        event.showActiveEvent = showActiveEvent;

        if (now > eventDate) {
          parr.push(event);
          event.past = true;
        } else {
          farr.push(event);
          event.past = false;
        }

        arr.push(event);
      }
    }

    for (let j = 0; j < teamEvents.docs.length; j++) {
      const eventDoc = teamEvents.docs[j];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      const eventDate = new Date(event.eventDate.toDate());
      const eventDateString = eventDate.toLocaleDateString("en-us", {
        dateStyle: "medium",
      });
      // console.log(event);
      event.accountType = userDetails.accountType;
      event.teamEvent = true;
      event.eventDate = eventDate;
      event.dateObject = eventDate;
      event.dateString = eventDateString;
      event.eventDateString = eventDateString;
      event.start = eventDate;
      const end = new Date(eventDate);
      end.setHours(end.getHours() + 6);
      event.end = end;
      event.title = event.eventName;
      event.eventID = eventID;
      if (event.roundIDs.length > 0) {
        event.active = true;
      } else {
        event.active = false;
      }
      function showEvent() {
        navigate(`/team-event-home/${eventID}`);
        // setToShow(
        //   <TeamLeagueEventHome
        //     goBack={() => setShow(false)}
        //     eventID={eventID}
        //   />
        // );
        // setShow(true);
      }
      function showActiveEvent() {
        navigate(`/team-evt-dash/${eventID}`);

        // setToShow(
        //   <TeamEventDashboard eventID={eventID} goBack={() => setShow(false)} />
        // );
        // setShow(true);
      }

      event.showActiveEvent = showActiveEvent;
      event.showEvent = showEvent;
      if (now > eventDate) {
        parr.push(event);
        event.past = true;
      } else {
        farr.push(event);
        event.past = false;
      }

      arr.push(event);
    }

    const futSorted = farr.sort((a, b) => (a.eventDate > b.eventDate ? 1 : -1));
    const pastSorted = parr.sort((a, b) =>
      a.eventDate < b.eventDate ? 1 : -1
    );
    setEvents(arr);
    setPastEvents(pastSorted);
    setFutEvents(futSorted);
    setLoading(false);
  }

  return (
    <div className="calendar-page">
      {show ? (
        toShow
      ) : (
        <>
          <PageHeader text="Calendar" />
          <HomeActiveEvents />
          {accountType !== "scorer" && accountType !== "portal" && (
            <div className="sub-header">
              <h5>Quick Links</h5>
            </div>
          )}
          {accountType !== "scorer" &&
            accountType !== "society" &&
            accountType !== "portal" && (
              <div className="quick-links">
                <Pill
                  text={"New Event"}
                  onClick={() => {
                    setToShow(
                      <EventCreator
                        goBack={() => {
                          setShow(false);
                          showHelperClick("calendar");
                        }}
                        reload={loadEvents}
                        edit={false}
                      />
                    );
                    setShow(true);
                    showHelperClick("evt-edit");
                  }}
                />
              </div>
            )}
          {loading && <BackdropLoader />}
          {accountType !== "scorer" && accountType !== "portal" && (
            <div className="radio-box">
              <RadioGroupTwo
                active={active}
                setActive={setActive}
                buttons={[
                  { text: "Calendar", value: "calendar" },
                  { text: "List", value: "list" },
                  // { text: "List", value: "list" },
                ]}
              />
            </div>
          )}
          {active === "list" && (
            <>
              <div className="switch-row">
                <RadioGroupTwo
                  active={checked ? "upcoming" : "past"}
                  buttons={[
                    {
                      text: "Past",
                      value: "past",
                    },
                    {
                      text: "Upcoming",
                      value: "upcoming",
                    },
                  ]}
                  setActive={(e) => {
                    setChecked((current) => !current);
                  }}
                />

                {userDetails && userDetails.test && !isMobile && (
                  <RadioGroupTwo
                    active={listType}
                    buttons={[
                      {
                        text: "Items",
                        value: "items",
                      },
                      {
                        text: "List",
                        value: "list",
                      },
                    ]}
                    setActive={(e) => {
                      setListType(e);
                    }}
                  />
                )}

                <input
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="text"
                  placeholder="Search for event..."
                  className="default-input cal-search"
                />
              </div>

              {listType === "list" && (
                <>
                  {checked && (
                    <FixtureList search={searchInput} eventArr={futEvents} />
                  )}
                  {!checked && (
                    <FixtureList search={searchInput} eventArr={pastEvents} />
                  )}
                </>
              )}

              {listType === "items" && (
                <div className="cal-events">
                  {checked &&
                    futEvents.map((event) => {
                      if (searchInput !== "") {
                        if (
                          !event.eventName
                            .toLowerCase()
                            .includes(searchInput.toLowerCase())
                        ) {
                          return null;
                        }
                      }
                      return (
                        <FixturePreview
                          showLive={event.roundIDs.length > 0}
                          hideRounds={
                            event.teamEvent || event.roundIDs.length > 0
                          }
                          hidePerson={
                            event.teamEvent || event.roundIDs.length > 0
                          }
                          event={event}
                          hideIcons={accountType === "scorer"}
                          key={event.eventID}
                          timeAction={() => {
                            setToShow(
                              <EventRounds
                                eventID={event.eventID}
                                goBack={() => setShow(false)}
                              />
                            );
                            setShow(true);
                          }}
                          personAction={() => {
                            setToShow(
                              <InviteModal
                                eventID={event.eventID}
                                hide={() => setShow(false)}
                              />
                            );
                            setShow(true);
                          }}
                          editAction={() => {
                            event.showEvent();
                            // setToShow(
                            //   <EventHome
                            //     eventID={event.eventID}
                            //     goBack={() => setShow(false)}
                            //     reload={loadEvents}
                            //   />
                            // );
                            // setShow(true);
                          }}
                        />
                      );
                    })}
                  {!checked &&
                    pastEvents.map((event) => {
                      if (searchInput !== "") {
                        if (
                          !event.eventName
                            .toLowerCase()
                            .includes(searchInput.toLowerCase())
                        ) {
                          return null;
                        }
                      }
                      return (
                        <FixturePreview
                          showLive={event.roundIDs.length > 0}
                          event={event}
                          hideRounds={
                            event.teamEvent || event.roundIDs.length > 0
                          }
                          hidePerson={
                            event.teamEvent || event.roundIDs.length > 0
                          }
                          hideIcons={accountType === "scorer"}
                          key={event.eventID}
                          past
                          personAction={() => {
                            setToShow(
                              <InviteModal
                                eventID={event.eventID}
                                hide={() => setShow(false)}
                              />
                            );
                            setShow(true);
                          }}
                          editAction={() => {
                            setToShow(
                              <EventHome
                                eventID={event.eventID}
                                goBack={() => setShow(false)}
                                reload={loadEvents}
                              />
                            );
                            setShow(true);
                          }}
                        />
                      );
                    })}
                  {checked && futEvents.length === 0 && (
                    <div className="no-msg">
                      <p>No events to display</p>
                    </div>
                  )}
                  {!checked && pastEvents.length === 0 && (
                    <div className="no-msg">
                      <p>No events to display</p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {active === "calendar" && (
            <>
              {isMobile ? (
                <MobileCalendar events={events} />
              ) : (
                <div className="cp-container">
                  <Calendar
                    view="month"
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    components={{
                      toolbar: CustomToolBar,
                      event: CustomEvent,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default CalendarPage;
