import classNames from "classnames";
import React from "react";

const playerProp = {
  firstName: "",
  lastName: "",
  handicap: 0,
  emailAddress: "",
  password: "",
  gender: "male",
  dob: "",
};

function BPCRow({
  setRow = () => {},
  rowIndex = 0,
  player = playerProp,
  upd = () => {},
  errorRow = false,
  errorType = "",
}) {
  return (
    <div
      className={classNames("bpc-row", {
        "bpc-err": errorRow === rowIndex,
      })}
    >
      <div
        className={classNames("bpc-item", {
          "bpci-err": errorRow === rowIndex && errorType === "firstName",
        })}
      >
        <input
          placeholder="Start typing..."
          value={player.firstName}
          className=""
          onChange={(e) => {
            setRow((c) => {
              c[rowIndex].firstName = e.target.value;
              return c;
            });
            upd();
          }}
        />
      </div>
      <div
        className={classNames("bpc-item", {
          "bpci-err": errorRow === rowIndex && errorType === "lasstName",
        })}
      >
        {" "}
        <input
          placeholder="Start typing..."
          value={player.lastName}
          className=""
          onChange={(e) => {
            setRow((c) => {
              c[rowIndex].lastName = e.target.value;
              return c;
            });
            upd();
          }}
        />
      </div>

      <div className="bpc-item">
        <input
          placeholder="Start typing..."
          value={player.handicap}
          className=""
          type="number"
          onChange={(e) => {
            setRow((c) => {
              c[rowIndex].handicap = e.target.value;
              return c;
            });
            upd();
          }}
        />
      </div>
      <div
        className={classNames("bpc-item", {
          "bpci-err": errorRow === rowIndex && errorType === "emailAddress",
        })}
      >
        {" "}
        <input
          placeholder="Start typing..."
          value={player.emailAddress}
          className=""
          type="email"
          onChange={(e) => {
            setRow((c) => {
              c[rowIndex].emailAddress = e.target.value;
              return c;
            });
            upd();
          }}
        />
      </div>
      <div
        className={classNames("bpc-item", {
          "bpci-err": errorRow === rowIndex && errorType === "password",
        })}
      >
        {" "}
        <input
          placeholder="Start typing..."
          value={player.password}
          className=""
          type="text"
          onChange={(e) => {
            setRow((c) => {
              c[rowIndex].password = e.target.value;
              return c;
            });
            upd();
          }}
        />
      </div>
      <div
        className={classNames("bpc-item", {
          "bpci-err": errorRow === rowIndex && errorType === "dob",
        })}
      >
        {" "}
        <input
          value={player.dob}
          className=""
          type="date"
          onChange={(e) => {
            setRow((c) => {
              c[rowIndex].dob = e.target.value;
              return c;
            });
            upd();
          }}
        />
      </div>
      <div className="bpc-item">
        <div className="selector-group sg-small">
          <button
            onClick={() => {
              setRow((c) => {
                c[rowIndex].gender = "male";
                return c;
              });
              upd();
            }}
            className={player.gender === "male" ? "sg-active" : ""}
          >
            Male
          </button>
          <button
            onClick={() => {
              setRow((c) => {
                c[rowIndex].gender = "female";
                return c;
              });
              upd();
            }}
            className={player.gender === "female" ? "sg-active" : ""}
          >
            Female
          </button>
        </div>
      </div>
    </div>
  );
}

export default BPCRow;
