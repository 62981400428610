import React, { useState, useEffect } from "react";
import { db } from "../../../../../firebase";
import Chart from "react-apexcharts";

// Icons

// Components
import ApproachMissDrillInput from "./ApproachMissDrillInput";
import PageHeader from "../../../../display/Rows/PageHeader";
import BackdropLoader from "../../../../Loaders/BackdropLoader";
import { Tabs, Tab, Avatar } from "@mui/material";
import Pill from "../../../../Buttons/Pill";
import BackRow from "../../../../display/Rows/BackRow";
import { Visibility } from "@mui/icons-material";
import ApproachMissDisplay from "./ApproachMissDisplay";

function ApproachMissDrill({
  goBack = () => {},
  clubID = "",
  clientID = "",
  scenarioID = "",
}) {
  // Admin
  const tabOpts = ["Details", "Attempts", "Graph"];

  // State
  const [loading, setLoading] = useState(true);
  const [pastAttempts, setPastAttempts] = useState([]);
  const [showIP, setShowIP] = useState(false);
  const [selTab, setSelTab] = useState("Details");
  const [details, setDetails] = useState({
    description: "",
    mainImage: "",
  });
  const [tags, setTags] = useState([]);
  const [notes, setnotes] = useState([]);

  const [toShow, setToShow] = useState(false);
  const [show, setShow] = useState(false);

  const [series, setSeries] = useState([]);
  const [dates, setDates] = useState([]);

  // UE
  useEffect(() => {
    loadPastAttempts();
  }, []);

  const proptions = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    colors: ["#6ba390", "#232c2e", "#A9363F"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      type: "date",
      categories: dates,
    },
    yaxis: {
      title: {
        text: "Score",
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      intersect: true,
      shared: false,
    },
  };

  // Funcs
  async function loadPastAttempts() {
    const attemptDocs = await db
      .collection("clubs")
      .doc(clubID)
      .collection("playerScenarios")
      .where("playerID", "==", clientID)
      .where("scenarioID", "==", scenarioID)
      .orderBy("dateAdded", "desc")
      .get();
    console.log(attemptDocs.docs.length);
    const arr = [];
    const seriesArr = [];
    const dateArr = [];
    for (let i = 0; i < attemptDocs.docs.length; i++) {
      const attemptDoc = attemptDocs.docs[i];
      const attempt = attemptDoc.data();
      const dateObject = new Date(attempt.dateAdded.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "long",
      });

      dateArr.push(dateString);

      attempt.dateObject = dateObject;
      attempt.dateString = dateString;
      arr.push(attempt);
      seriesArr.push(attempt.totalPerc);
    }

    setDates(dates);
    console.log(seriesArr);
    seriesArr.reverse();
    setSeries([
      {
        label: "Attempts",
        data: seriesArr,
      },
    ]);

    setPastAttempts(arr);

    const drillDoc = await db
      .collection("admin")
      .doc("academy")
      .collection("scenarios")
      .doc(scenarioID)
      .get();
    const drillData = drillDoc.data();

    const { description, mainImage } = drillData;
    const tags = drillData.tags ?? [];
    const notes = drillData.notes ?? [];
    setnotes(notes);
    setTags(tags);
    setDetails({
      description,
      mainImage,
    });

    setLoading(false);
  }

  return (
    <div className="approach-drill">
      {show && toShow}
      {loading && <BackdropLoader />}
      {showIP && (
        <ApproachMissDrillInput
          close={() => setShowIP(false)}
          clubID={clubID}
          playerID={clientID}
          scenarioID={scenarioID}
          reload={loadPastAttempts}
          pastAttempts={pastAttempts}
        />
      )}
      <BackRow action={goBack} />
      <PageHeader text="Approach Misses" />
      <div className="flex-ac flex-w sce-details pl-20">
        <div className="gre-meu ec-tabs mr-20">
          <Tabs value={selTab} onChange={(e, v) => setSelTab(v)}>
            {tabOpts.map((opt, i) => (
              <Tab key={opt} value={opt} label={opt} />
            ))}
          </Tabs>
        </div>
        <Pill text={"Add Attempt"} onClick={() => setShowIP(true)} />
      </div>

      {selTab === "Details" && (
        <>
          <div className="acg-box">
            <div className="ac-graph bss-white">
              <div className="sce-details">
                <div className="flex-center">
                  <Avatar alt={"drill"} src={details.mainImage} />{" "}
                </div>
                <div className="sd-text">
                  {" "}
                  <div className="sce-tags mb-10">
                    {tags.map((tag, i) => (
                      <div key={tag} className="sce-tag">
                        <p>
                          {tag}
                          {/* {i < tags.length - 1 && " · "} */}
                        </p>
                      </div>
                    ))}
                  </div>
                  <h3>Description</h3>
                  <p className="">{details.description}</p>
                  <div className="sce-notes">
                    <ul>
                      {notes.map((note, i) => {
                        return <li key={`note${i}`}>{note}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {selTab === "Attempts" && (
        <>
          <div className="acg-box">
            <div className="ac-graph bss-white">
              <div className="amd-row amd-header">
                <div className="adr-item">
                  <p>Date</p>
                </div>
                <div className="adr-item">
                  <p>Attempts</p>
                </div>
                <div className="adr-item">
                  <p>Total %</p>
                </div>
                <div className="adr-item">
                  <p>View</p>
                </div>
              </div>
              {pastAttempts.map((att, i) => {
                const { attempts } = att;
                return (
                  <div key={`attrow${i}`} className="amd-row">
                    <div className="adr-date adr-item">
                      <p>{att.dateString}</p>
                    </div>
                    <div className="adr-atts adr-item">
                      <p>{attempts.length}</p>
                    </div>

                    <div className="adr-total adr-item">
                      <p>{att.totalPerc}%</p>
                    </div>

                    <div className="adr-view adr-item">
                      <Visibility
                        onClick={() => {
                          setToShow(
                            <ApproachMissDisplay
                              close={() => setShow(false)}
                              details={att}
                            />
                          );
                          setShow(true);
                        }}
                        className="dg-icon hgb-ico icon-cursor"
                      />
                    </div>
                  </div>
                );
              })}{" "}
            </div>
          </div>
        </>
      )}

      {selTab === "Graph" && (
        <>
          <div className="acg-box">
            <div className="ac-graph bss-white">
              <Chart
                options={proptions}
                series={series}
                type={"line"}
                height={320}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ApproachMissDrill;
