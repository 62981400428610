import React, { useState, useEffect } from "react";

// Components
import CustomDrop from "../../../../CustomDrop/CustomDrop";
import { Dialog } from "@mui/material";
import PageHeader from "../../../../display/Rows/PageHeader";
import CPLoader from "../../../CPLoader";
import Pill from "../../../../Buttons/Pill";
import { toast } from "react-toastify";
import { admin, db } from "../../../../../firebase";
import { successIco } from "../../../HelperFunctions";
import ShotRandomizerRow from "./ShotRandomizerRow";

function ShotRandomizerInput({
  close = () => {},
  clubID = "",
  playerID = "",
  scenarioID = "",
  reload = () => {},
  pastAttempts = [],
}) {
  // Admin
  const shapes = {
    Easy: ["Straight"],
    Medium: ["Straight", "Draw", "Fade"],
    Hard: ["Straight", "Draw", "Fade"],
    Pro: ["Straight", "Draw", "Fade", "Slice", "Hook"],
  };
  const trajectories = {
    Easy: ["Normal"],
    Medium: ["Normal", "Low", "High"],
    Hard: ["Normal", "Low", "High"],
    Pro: ["Low", "Mid-Low", "Normal", "Mid-High", "High"],
  };
  const distances = {
    Easy: [30, 100],
    Medium: [50, 150],
    Hard: [50, 200],
    Pro: [50, 250],
  };

  const shapeOpts = ["Straight", "Draw", "Fade", "Hook", "Slice"];
  const trajectOpts = ["Low", "Mid-Low", "Normal", "Mid-High", "High"];
  const numberStrings = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const difficulties = ["Easy", "Medium", "Hard", "Pro"];
  const shotProp = {
    shape: "",
    trajectory: "",
    distance: "",
    rating: "",
  };

  function getRandomItem(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

  function getRandomNumber({ min, max }) {
    const step = 10;

    const randomMultiplier = Math.floor(
      Math.random() * ((max - min) / step + 1)
    );
    return min + randomMultiplier * step;
  }

  // State
  const [loading, setLoading] = useState(false);
  const [shots, setShots] = useState([]);
  const [pastAttemptOpts, setPastAttemptOpts] = useState([]);
  const [attempts, setAttempts] = useState([]);
  const [error, setError] = useState("");
  const [update, setUpdate] = useState(0);
  const [totalRating, setTotalRating] = useState("");
  const [selDiff, setSelDiff] = useState("Easy");
  // UE
  useEffect(() => {
    const opts = pastAttempts.map((att, i) => {
      const toRet = {
        label: att.dateString,
        value: i.toString(),
      };
      return toRet;
    });
    setPastAttemptOpts(opts);
  }, []);

  // Functions
  function addRow() {
    const shape = getRandomItem(shapes[selDiff]);
    const trajectory = getRandomItem(trajectories[selDiff]);
    const min = distances[selDiff][0];
    const max = distances[selDiff][1];
    const distance = getRandomNumber({ max, min });

    const shot = {
      shape,
      trajectory,
      distance,
      rating: "",
    };

    setShots((c) => {
      c.push(shot);
      return c;
    });

    setUpdate((c) => c + 1);
  }

  async function saveDrillAttempt() {
    setLoading(true);

    for (let i = 0; i < attempts.length; i++) {
      const att = attempts[i];
      const { rating } = att;
      if (rating === "") {
        setError("Please ensure all fields are filled in");
        return setLoading(false);
      }
    }

    await db.collection("clubs").doc(clubID).collection("playerScenarios").add({
      playerID,
      dateAdded: new Date(),
      attempts: shots,
      scenarioID,
      totalRating,
    });

    const toAdd = {
      playerID,
      dateAdded: new Date(),
      attempts: shots,
      totalRating,
    };

    await db
      .collection("admin")
      .doc("academy")
      .collection("scenarios")
      .doc(scenarioID)
      .update({
        attempts: admin.firestore.FieldValue.arrayUnion(toAdd),
      });

    toast.success("Scenario successfully saved", {
      icon: successIco,
    });
    reload();
    setLoading(false);
    close();
  }

  function sortTotalRating(arr = []) {
    console.log("TRIGGEd");
    let total = 0;
    console.log(arr);

    for (let i = 0; i < arr.length; i++) {
      const row = arr[i];
      total += parseInt(row.rating);
    }

    console.log(total);

    const totalRating = (total / arr.length).toFixed(2);
    setTotalRating(totalRating);
    setUpdate((c) => c + 1);
  }

  return (
    <Dialog open onClose={close} className="accuracy-drill sr-ip">
      <div className="acc-drill-ctt">
        <PageHeader text="Shot Randomizer" close={close} showClose />
        {loading ? (
          <div className="cpl-box">
            <CPLoader />
          </div>
        ) : (
          <>
            <div className="flex-ac pl-20">
              {pastAttemptOpts.length > 0 && (
                <div className="input-group mr-20">
                  <p>Load Previous </p>
                  <CustomDrop
                    options={pastAttemptOpts}
                    onChange={(e) => {
                      const val = e.value;
                      const paTemp = [...pastAttempts];
                      const att = paTemp[val];
                      const attempts = [...att.attempts];
                      const arr = [];
                      for (let i = 0; i < attempts.length; i++) {
                        const attempt = attempts[i];
                        const temp = { ...attempt };
                        temp.miss = 0;
                        temp.missPercentage = "0";
                        arr.push(temp);
                      }
                      console.log(arr);
                      setShots((c) => {
                        c = [];
                        const spread = [...arr];
                        for (let i = 0; i < spread.length; i++) {
                          c.push(spread[i]);
                        }
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                  />
                </div>
              )}

              <div className="input-group mr-20">
                <p>Select Difficulty</p>
                <CustomDrop
                  options={difficulties}
                  value={selDiff}
                  onChange={(e) => setSelDiff(e.value)}
                />
              </div>

              <Pill
                text={"Add Shot"}
                onClick={addRow}
                disabled={shots.length === 10}
              />
            </div>
            <div className="input-group pl-20">
              <p>Overall Rating</p>
              <h3>{totalRating}</h3>
            </div>

            <div className="adc-shots">
              {shots.map((shot, i) => {
                return (
                  <ShotRandomizerRow
                    row={shot}
                    key={i}
                    index={i + 1}
                    deleteRow={() => {
                      setShots((c) => {
                        c.splice(i, 1);
                        console.log(i, c);
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                    saveRow={(e) => {
                      console.log(e, i);
                      setShots((c) => {
                        console.log(c);
                        if (c[i]) {
                          c[i].rating = e;
                          sortTotalRating(c);
                        }
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                  />
                );
              })}
            </div>

            <div className="error-message">
              <p>{error}</p>
            </div>

            <div className="mt-20 pl-20 pb-20">
              <button
                onClick={saveDrillAttempt}
                disabled={shots.length === 0}
                className="default-button"
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default ShotRandomizerInput;
