import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase";

// Icons

// Components
import BackdropLoader from "../Loaders/BackdropLoader";
import { Oval } from "react-loader-spinner";
import {
  convertCentsToDollars,
  getEndOfMonth,
  getMonthsBetweenDates,
  getStartOfMonth,
} from "../HelperFunctions";
import { LineChart } from "@mui/x-charts/LineChart";
import Chart from "react-apexcharts";
import CustomDrop from "../CustomDrop/CustomDrop";

// Helpers
import { featuresProp } from "../HelperFunctions";
import { areDatesOnSameDay } from "../ClientStats/HelperFunctions";
import CustomDropdown from "../ClientStats/CustomDropdown";
import { Tab, Tabs } from "@mui/material";
import classNames from "classnames";

const chartProp = [];
const costProp = {
  cupCosts: 0,
  eventCosts: 0,
  leagueCosts: 0,
  memberCosts: 0,
  premiumCost: 0,
  totalCosts: 0,
  proStatsCosts: 0,
  mptCosts: 0,
};

const premMapDefs = {
  playerUploads: "Player Uploads",
  hcpUploads: "Handicap Uploads",
};

const breakdownProp = {};

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

const bdOpts = [
  "Cups",
  "Events",
  "Leagues",
  "Tournaments",
  "Premium",
  "Members",
  "ProStats",
];

const graphOpts = ["Line", "Pie"];

function AccountCosts({ clubID = "" }) {
  // Admin

  // State
  const [loading, setLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [currentYear, setCurrentYear] = useState(0);
  const [costSummary, setCostSummary] = useState(costProp);
  const [clubFeatures, setClubFeatures] = useState(featuresProp);
  const [series, setSeries] = useState([]);
  const [update, setUpdate] = useState();
  const [selMonth, setSelMonth] = useState({});
  const [selTab, setSelTab] = useState("graphs");
  const [dateSeries, setDateSeries] = useState([]);
  const [selBD, setSelBD] = useState("Cups");
  const [totalSeries, setTotalSeries] = useState([]);
  const [totalDates, setTotalDates] = useState([]);
  const [proStatsArr, setProStatsArr] = useState([]);

  const [pieBreakdown, setPieBreakdown] = useState([]);

  const [selGraph, setSelGraph] = useState("Line");

  const [bds, setBDs] = useState({
    Total: {},
    Cups: {},
    Events: {},
    Leagues: {},
    Premium: {},
    Members: {},
    ProStats: {},
    Tournaments: {},
  });
  const [selBDItem, setSelBDItem] = useState({});

  const [monthOpts, setMonthOpts] = useState([]);

  const chartRef = useRef();

  const pieProp = {
    options: {
      colors: [
        "#A9363F",
        "#1e7a69",
        "#d4af37",
        "#8e9191",
        "#86A5D9",
        "#6ba390",
        "#ffa500",
      ],

      dataLabels: {
        enabled: true,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      chart: {
        width: 400,
        type: "pie",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.3,
        },
      },
      legend: {
        position: "bottom",
      },
      labels: bdOpts,
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };

  const proptions = {
    chart: {
      height: 350,
      //   type,
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    colors: [
      "#232c2e",
      "#A9363F",
      "#1e7a69",
      "#d4af37",
      "#8e9191",
      "#86A5D9",
      "#6ba390",
      "#ffa500",
    ],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    stroke: {
      width: 2,

      //   curve: "smooth",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      type: "date",
      categories: selTab === "history" ? totalDates : dateSeries,
    },
    yaxis: {
      title: {
        // text: selStat.label,
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      intersect: true,
      shared: false,
    },
  };

  // UE
  useEffect(() => {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    setCurrentMonth(month);
    setCurrentYear(year);
    loadCharges({ year, month });
    loadMonthlyCharges();
    loadDetails();
  }, []);

  // Functions
  async function loadDetails() {
    const clubRef = db.collection("clubs").doc(clubID);
    const clubDoc = await clubRef.get();
    const club = clubDoc.data();
    const { dateCreated } = club;
    const dcObject = new Date(dateCreated.toDate());
    const today = new Date();

    const marr = getMonthsBetweenDates(today, dcObject);
    marr.reverse();
    setMonthOpts(marr);
    setSelMonth(marr[0]);
  }

  async function loadCharges({ year, month }) {
    setLoading(true);
    const clubRef = db.collection("clubs").doc(clubID);

    const bom = getStartOfMonth(month, year);
    const eom = getEndOfMonth(month, year);

    const dateArr = [];
    const dateObejctArr = [];
    const cupArr = [];
    const evtArr = [];
    const leagueArr = [];
    const memberArr = [];
    const premArr = [];
    const proStatsArr = [];
    let proStatsDetails = [];
    const totalArr = [];

    const cupBDArr = [];
    const leagueBDArr = [];
    const eventBDArr = [];
    const mptBDArr = [];
    const premiumBDArr = [];

    const leagueIDs = [];
    const eventIDs = [];
    const cupIDs = [];

    const mptArray = [];
    const tournIDs = [];

    const usageDocs = await clubRef
      .collection("usageCost")
      .where("date", ">=", bom)
      .where("date", "<=", eom)
      .orderBy("date", "asc")
      .get();

    for (let i = 0; i < usageDocs.docs.length; i++) {
      const doc = usageDocs.docs[i];
      const docID = doc.id;
      const usageData = doc.data();

      const {
        date,
        cupCostArr,
        cupCosts,
        eventCostArr,
        eventCosts,
        eventRounds,
        leagueCostArr,
        leagueCosts,
        memberCosts,
        premiumArr,
        premiumCost,
        totalCost,
        totalEvents,
        totalMembers,
      } = usageData;

      const mptArr = usageData.mptArr ?? [];
      const mptCost = usageData.mptCost ?? 0;

      const proStatsCosts = usageData.proStatsCosts ?? 0;
      const convertedPSC = convertCentsToDollars(proStatsCosts);

      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "medium",
      });

      if (i === usageDocs.docs.length - 1) {
        proStatsDetails = usageData.proStatsArr ?? [];
      }

      if (i !== usageDocs.docs.length - 1) {
        const nextDoc = usageDocs.docs[i + 1];
        const nextDocData = nextDoc.data();

        const ndDateObject = new Date(nextDocData.date.toDate());
        const isSameDay = areDatesOnSameDay(dateObject, ndDateObject);
        if (isSameDay) {
          continue;
        }
      }

      dateArr.push(dateString);
      dateObejctArr.push(dateObject);
      proStatsArr.push(convertedPSC);
      cupArr.push(convertCentsToDollars(cupCosts));
      evtArr.push(convertCentsToDollars(eventCosts));
      leagueArr.push(convertCentsToDollars(leagueCosts));
      memberArr.push(convertCentsToDollars(memberCosts));
      premArr.push(convertCentsToDollars(premiumCost));
      totalArr.push(convertCentsToDollars(totalCost));
      mptArray.push(convertCentsToDollars(mptCost));
      leagueBDArr.push(leagueCostArr);
      eventBDArr.push(eventCostArr);
      mptBDArr.push(mptArr);
      cupBDArr.push(cupCostArr);
      premiumBDArr.push(premiumArr);

      for (let x = 0; x < leagueBDArr.length; x++) {
        const leagueArr = leagueBDArr[x];
        for (let j = 0; j < leagueArr.length; j++) {
          const league = leagueArr[j];
          const { leagueID } = league;
          if (!leagueID) {
            continue;
          }
          if (!leagueIDs.includes(leagueID)) {
            leagueIDs.push(leagueID);
          }
        }
      }
      for (let x = 0; x < eventBDArr.length; x++) {
        const eventArr = eventBDArr[x];
        for (let j = 0; j < eventArr.length; j++) {
          const event = eventArr[j];
          const { eventID } = event;
          if (!eventID) {
            continue;
          }
          if (!eventIDs.includes(eventID)) {
            eventIDs.push(eventID);
          }
        }
      }
      for (let x = 0; x < cupBDArr.length; x++) {
        const cupArr = cupBDArr[x];
        for (let j = 0; j < cupArr.length; j++) {
          const cup = cupArr[j];
          const { cupID } = cup;
          if (!cupID) {
            continue;
          }
          if (!cupIDs.includes(cupID)) {
            cupIDs.push(cupID);
          }
        }
      }
      for (let x = 0; x < mptBDArr.length; x++) {
        const tournArr = mptBDArr[x];
        for (let j = 0; j < tournArr.length; j++) {
          const tourn = tournArr[j];
          const { tournamentID } = tourn;
          if (!tournIDs.includes(tournamentID)) {
            tournIDs.push(tournamentID);
          }
        }
      }
    }

    const leagueMap = {};
    const eventMap = {};
    const cupMap = {};
    const premMap = {};
    const tournMap = {};

    for (let i = 0; i < premiumBDArr.length; i++) {
      const day = premiumBDArr[i];
      for (let j = 0; j < day.length; j++) {
        const prem = day[j];
        prem.date = dateArr[i];
        prem.dateObject = dateObejctArr[i];
        const { type } = prem;
        if (premMap[type]) {
          premMap[type].costs.push(prem);
        } else {
          premMap[type] = {
            costs: [prem],
            name: type,
          };
        }
      }
    }

    const leagueRefs = leagueIDs.map((id) =>
      db.collection("leagues").doc(id).get()
    );
    const leagueDocs = await Promise.all(leagueRefs);

    for (let i = 0; i < leagueDocs.length; i++) {
      const leagueDoc = leagueDocs[i];
      const league = leagueDoc.data();
      const leagueID = leagueDoc.id;
      const { leagueName } = league;
      leagueMap[leagueID] = {
        costs: [],
        name: leagueName,
      };
    }

    const eventRefs = eventIDs.map((id) =>
      db.collection("events").doc(id).get()
    );
    const eventDocs = await Promise.all(eventRefs);

    for (let i = 0; i < eventDocs.length; i++) {
      const eventDoc = eventDocs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();

      const { eventName } = event;
      eventMap[eventID] = {
        costs: [],
        name: eventName,
      };
    }

    const cupRefs = cupIDs.map((id) => db.collection("cups").doc(id).get());
    const cupDocs = await Promise.all(cupRefs);

    for (let i = 0; i < cupDocs.length; i++) {
      const cupDoc = cupDocs[i];
      const cupID = cupDoc.id;
      const cup = cupDoc.data();
      const { cupName } = cup;
      cupMap[cupID] = {
        costs: [],
        name: cupName,
      };
    }

    const tournRefs = tournIDs.map((id) =>
      db.collection("tournaments").doc(id).get()
    );
    const tournDocs = await Promise.all(tournRefs);

    for (let i = 0; i < tournDocs.length; i++) {
      const tournDoc = tournDocs[i];
      const tournID = tournDoc.id;
      const tourn = tournDoc.data();
      const { tournamentName } = tourn;
      tournMap[tournID] = {
        costs: [],
        name: tournamentName,
      };
    }

    for (let i = 0; i < eventBDArr.length; i++) {
      const day = eventBDArr[i];
      for (let j = 0; j < day.length; j++) {
        const event = day[j];
        // event.date = dateArr[i];
        // event.dateObject = dateObejctArr[i];
        const { eventID } = event;
        eventMap[eventID].costs.push(event);
      }
    }

    for (let i = 0; i < leagueBDArr.length; i++) {
      const day = leagueBDArr[i];
      for (let j = 0; j < day.length; j++) {
        const league = day[j];
        // const date =
        league.date = dateArr[i];
        league.dateObject = dateObejctArr[i];
        const { leagueID } = league;
        leagueMap[leagueID].costs.push(league);
      }
    }

    setBDs((c) => {
      c.Leagues = leagueMap;
      c.Premium = premMap;
      c.Events = eventMap;
      c.ProStats = proStatsArr;
      c.Tournaments = tournMap;
      return c;
    });

    const srsArr = [];
    srsArr.push({
      data: totalArr,
      name: "Total",
    });
    srsArr.push({
      data: cupArr,
      name: "Cups",
    });
    srsArr.push({
      data: evtArr,
      name: "Events",
    });
    srsArr.push({
      data: leagueArr,
      name: "Leagues",
    });
    srsArr.push({
      data: mptArray,
      name: "Tournaments",
    });
    srsArr.push({
      data: premArr,
      name: "Premium Features",
    });
    srsArr.push({
      data: memberArr,
      name: "Members",
    });
    srsArr.push({
      data: proStatsArr,
      name: "ProStats",
    });

    const pieArr = [
      parseFloat(cupArr.at(-1) ?? 0),
      parseFloat(evtArr.at(-1) ?? 0),
      parseFloat(leagueArr.at(-1)) ?? 0,
      parseFloat(premArr.at(-1)) ?? 0,
      parseFloat(memberArr.at(-1)) ?? 0,
      parseFloat(proStatsArr.at(-1) ?? 0),
      parseFloat(mptArray.at(-1) ?? 0),
    ];

    const costMap = {
      totalCosts: totalArr.at(-1) ?? "0.00",
      cupCosts: cupArr.at(-1) ?? "0.00",
      eventCosts: evtArr.at(-1) ?? "0.00",
      leagueCosts: leagueArr.at(-1) ?? "0.00",
      memberCosts: memberArr.at(-1) ?? "0.00",
      premiumCost: premArr.at(-1) ?? "0.00",
      proStatsCosts: proStatsArr.at(-1) ?? "0.00",
      mptCosts: mptArray.at(-1) ?? "0.00",
    };

    setProStatsArr(proStatsDetails);
    setCostSummary(costMap);
    setPieBreakdown(pieArr);

    setSeries(srsArr);
    setDateSeries(dateArr);
    setSelBD("Cups");
    setSelBDItem(bds.Cups);
    setLoading(false);
  }

  async function loadMonthlyCharges() {
    const usageDocs = await db
      .collection("clubs")
      .doc(clubID)
      .collection("usageCost")
      .where("isLast", "==", true)
      .orderBy("date", "asc")
      .get();

    const lastDoc = await db
      .collection("clubs")
      .doc(clubID)
      .collection("usageCost")
      .orderBy("date", "desc")
      .limit(1)
      .get();

    if (lastDoc.docs.length === 0) {
      return;
    }

    const dateArr = [];
    const dateObjectArr = [];
    const totalArr = [];

    for (let i = 0; i < usageDocs.docs.length; i++) {
      const usageDoc = usageDocs.docs[i];
      const usageData = usageDoc.data();

      const { totalCost, date } = usageData;
      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "medium",
      });
      const totalFixed = (totalCost / 100).toFixed(2);

      const month = dateObject.toLocaleString("default", { month: "long" });
      const year = dateObject.getFullYear();

      dateObjectArr.push(dateObject);
      dateArr.push(`${month} ${year}`);
      totalArr.push(totalFixed);
    }

    const lastData = lastDoc.docs[0].data();
    const { totalCost, date } = lastData;
    const dateObject = new Date(date.toDate());

    const month = dateObject.toLocaleString("default", { month: "long" });
    const year = dateObject.getFullYear();
    const totalFixed = (totalCost / 100).toFixed(2);

    dateObjectArr.push(dateObject);
    dateArr.push(`${month} ${year}`);
    totalArr.push(totalFixed);

    const srsArr = [];
    srsArr.push({
      data: totalArr,
      name: "Total",
    });

    setTotalSeries(srsArr);
    setTotalDates(dateArr);
  }

  const highlightSeries = (seriesIndex) => {
    if (chartRef.current) {
      chartRef.current.chart.toggleSeries(series[seriesIndex].name);
      //   chartRef.current.chart.toggleSeries(series[seriesIndex].name);
    }
  };

  return (
    <div className="account-costs">
      {loading ? (
        <>
          <div className="acg-box">
            <div className="ac-graph bss-white mb-40">
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="sub-header">
            <h3>Account Costs</h3>
          </div>
          <div className="flex-ac">
            <div className="gre-meu gmb ml-20 mt-20">
              <Tabs value={selTab} onChange={(e, v) => setSelTab(v)}>
                <Tab label={"Graphs"} value={"graphs"} />
                <Tab label={"Breakdown"} value={"breakdown"} />
                <Tab label={"History"} value={"history"} />
              </Tabs>
            </div>
          </div>
          {["graphs", "breakdown"].includes(selTab) && (
            <div className="flex-ac ac-top ">
              <div className="input-group ">
                <p>Select Month</p>
                <CustomDropdown
                  value={selMonth}
                  handleChange={(e) => {
                    const date = new Date(e.value);
                    const year = date.getFullYear();
                    const month = date.getMonth();
                    setSelMonth(e);
                    loadCharges({ year, month });
                  }}
                  opts={monthOpts}
                  placeHolder="Select Month..."
                />
              </div>
              <div className="ac-bd">
                <div className="ac-bd-bs bss-white pd-20">
                  <div className="ac-bd-item">
                    <h3>Total:</h3>
                    <h5 style={{ color: proptions.colors[0] }}>
                      ${costSummary.totalCosts}
                    </h5>
                  </div>
                  <div className="ac-bd-item">
                    <h3 style={{ color: proptions.colors[1] }}>Cups:</h3>
                    <h5 style={{ color: proptions.colors[1] }}>
                      ${costSummary.cupCosts}
                    </h5>
                  </div>
                  <div className="ac-bd-item">
                    <h3 style={{ color: proptions.colors[2] }}>Events:</h3>
                    <h5 style={{ color: proptions.colors[2] }}>
                      ${costSummary.eventCosts}
                    </h5>
                  </div>
                  <div className="ac-bd-item">
                    <h3 style={{ color: proptions.colors[3] }}>Leagues:</h3>
                    <h5 style={{ color: proptions.colors[3] }}>
                      ${costSummary.leagueCosts}
                    </h5>
                  </div>
                  <div className="ac-bd-item">
                    <h3 style={{ color: proptions.colors[4] }}>Tournaments:</h3>
                    <h5 style={{ color: proptions.colors[4] }}>
                      ${costSummary.mptCosts}
                    </h5>
                  </div>

                  <div className="ac-bd-item">
                    <h3 style={{ color: proptions.colors[5] }}>Premium:</h3>
                    <h5 style={{ color: proptions.colors[5] }}>
                      ${costSummary.premiumCost}
                    </h5>
                  </div>
                  <div className="ac-bd-item">
                    <h3 style={{ color: proptions.colors[6] }}>Members:</h3>
                    <h5 style={{ color: proptions.colors[6] }}>
                      ${costSummary.memberCosts}
                    </h5>
                  </div>
                  <div className="ac-bd-item">
                    <h3 style={{ color: proptions.colors[7] }}>ProStats:</h3>
                    <h5 style={{ color: proptions.colors[7] }}>
                      ${costSummary.proStatsCosts}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selTab === "graphs" && (
            <>
              <div className="selector-group sg-small ml-20 mt-20">
                {graphOpts.map((opt, i) => {
                  return (
                    <button
                      key={opt}
                      className={selGraph === opt ? "sg-active" : ""}
                      onClick={() => {
                        setSelGraph(opt);
                        setUpdate((c) => c + 1);
                      }}
                    >
                      {opt}
                    </button>
                  );
                })}
              </div>
              <div className="acg-box">
                <div className="ac-graph bss-white">
                  {/* {series.length > 0 && (
              <LineChart
                width={800}
                height={300}
                series={series}
                xAxis={[{ data: dateSeries, scaleType: "linear" }]}
              />
            )} */}

                  {selGraph === "Line" && (
                    <Chart
                      ref={chartRef}
                      options={proptions}
                      series={series}
                      type={"line"}
                      height={320}
                    />
                  )}

                  {selGraph === "Pie" && (
                    <Chart
                      options={pieProp.options}
                      series={pieBreakdown}
                      type="pie"
                      height={320}
                    />
                  )}

                  {false && (
                    <LineChart
                      width={500}
                      height={300}
                      series={[
                        { data: pData, label: "pv" },
                        { data: uData, label: "uv" },
                      ]}
                      xAxis={[{ scaleType: "point", data: xLabels }]}
                    />
                  )}
                </div>{" "}
              </div>
            </>
          )}
          {selTab === "breakdown" && (
            <div className="acc-bd">
              <div className=" bs-subtle bss-white pd-20">
                <div className="acc-bd-opts">
                  {bdOpts.map((opt, i) => {
                    return (
                      <div
                        key={opt}
                        onClick={() => {
                          const item = bds[opt];
                          setSelBDItem(item);
                          setSelBD(opt);
                        }}
                        style={{
                          backgroundColor:
                            selBD === opt ? proptions.colors[i + 1] : "white",
                        }}
                        className={classNames("abo-item")}
                      >
                        <p
                          style={{
                            color:
                              selBD === opt ? "white" : proptions.colors[i + 1],
                          }}
                        >
                          {opt}
                        </p>
                      </div>
                    );
                  })}
                </div>
                {selBDItem && (
                  <div className="acc-bd-ctt">
                    <div className="abc-head mt-20">
                      <h3>Total</h3>
                      <p>
                        $
                        {selBD === "Total"
                          ? costSummary.totalCosts
                          : selBD === "Leagues"
                          ? costSummary.leagueCosts
                          : selBD === "Cups"
                          ? costSummary.cupCosts
                          : selBD === "Premium"
                          ? costSummary.premiumCost
                          : selBD === "Members"
                          ? costSummary.memberCosts
                          : selBD === "ProStats"
                          ? costSummary.proStatsCosts
                          : costSummary.eventCosts}{" "}
                      </p>
                    </div>
                    {selBD !== "ProStats" &&
                      Object.entries(selBDItem).map((item, i) => {
                        //   const leagueID = item[0];
                        const details = item[1];
                        const costItem = details.costs.at(-1);
                        const { costMap } = costItem;
                        return (
                          <div key={`${i}bd-sum`} className="acc-bd-sum">
                            {selBD === "Leagues" && (
                              <>
                                <div className="abs-head">
                                  <h3>{details.name}</h3>
                                </div>
                                <div className="abs-item abs-total">
                                  <h3>Total</h3>
                                  <p>${(costMap.total / 100).toFixed(2)}</p>
                                </div>
                                <div className="abs-item">
                                  <h3>League Fee</h3>
                                  <p>${(costMap.league / 100).toFixed(2)}</p>
                                </div>
                                <div className="abs-item">
                                  <h3>Player Fee</h3>
                                  <p>${(costMap.players / 100).toFixed(2)}</p>
                                </div>
                              </>
                            )}
                            {selBD === "Events" && (
                              <>
                                <div className="abs-head">
                                  <h3>{details.name}</h3>
                                  <h5>{costItem.dateString}</h5>
                                </div>
                                <div className="abs-item abs-total">
                                  <h3>Total</h3>
                                  <p>${(costMap.total / 100).toFixed(2)}</p>
                                </div>
                                <div className="abs-item">
                                  <h3>Event Fee</h3>
                                  <p>${(costMap.event / 100).toFixed(2)}</p>
                                </div>
                                <div className="abs-item">
                                  <h3>Player Fee</h3>
                                  <p>${(costMap.players / 100).toFixed(2)}</p>
                                </div>
                                {costMap.rounds && (
                                  <div className="abs-item">
                                    <h3>Rounds Fee</h3>
                                    <p>${(costMap.rounds / 100).toFixed(2)}</p>
                                  </div>
                                )}
                                {costMap.divisions !== undefined && (
                                  <div className="abs-item">
                                    <h3>Division Fee</h3>
                                    <p>
                                      ${(costMap.divisions / 100).toFixed(2)}
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                            {selBD === "Premium" && (
                              <>
                                <div className="abs-head">
                                  <h3>{premMapDefs[details.name]}</h3>
                                </div>
                                <div className="abs-item abs-total">
                                  <h3>Total</h3>
                                  <p>${(costItem.cost / 100).toFixed(2)}</p>
                                </div>
                                {/* <div className="abs-item">
                                <h3>League Fee</h3>
                                <p>${(costMap.league / 100).toFixed(2)}</p>
                              </div>
                              <div className="abs-item">
                                <h3>Player Fee</h3>
                                <p>${(costMap.players / 100).toFixed(2)}</p>
                              </div> */}
                              </>
                            )}
                          </div>
                        );
                      })}
                    {selBD === "ProStats" && (
                      <>
                        <div className="abs-head mt-10">
                          <div className="ac-ps-player app-head b-10">
                            <p>Player</p>
                            <h3 className="ac-ps-status">Status</h3>
                            <h3>Cost</h3>
                          </div>
                          {proStatsArr.map((player, i) => {
                            return (
                              <div
                                key={player.playerID}
                                className="ac-ps-player"
                              >
                                <p>{player.name}</p>
                                <h3 className="ac-ps-status">
                                  {player.active ? "Active" : "Dormant"}
                                </h3>
                                <h3>${player.active ? "5.00" : "2.00"}</h3>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {selTab === "history" && (
            <>
              <div className="acg-box">
                <div className="ac-graph bss-white">
                  <Chart
                    // ref={chartRef}

                    options={proptions}
                    series={totalSeries}
                    type={"line"}
                    height={320}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AccountCosts;
