import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
// Icons

// Components
import FixturePreview from "../../display/FixturePreview/FixturePreview";
import Switch from "react-ios-switch";
import EventHome from "../../Events/EventCreator/EventHome";
import GroupSelector from "../../Events/GroupSelector/GroupSelector";
import InviteModal from "../../Events/InviteModal/InviteModal";
import EventRounds from "../../Events/EventCreator/EventRounds";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import TeamLeagueEventHome from "../../ClubLeagues/TeamLeagueEventHome";
import FixtureRow from "../../FixtureRow/FixtureRow";
import FixtureRowHeader from "../../FixtureRow/FixtureRowHeader";
import FixtureList from "../../FixtureRow/FixtureList";
import { isMobile } from "react-device-detect";

function LeagueEvents({
  pastEvents = [],
  futureEvents = [],
  loading = true,
  setToShow = () => {},
  setShow = () => {},
  teamLeague = false,
}) {
  const { userDetails } = useAuth();

  // State
  const [checked, setChecked] = useState("upcoming");
  const [listType, setListType] = useState("items");

  return (
    <div className="league-events">
      <>
        <div className="switch-row">
          <RadioGroupTwo
            active={checked}
            buttons={[
              {
                text: "Past",
                value: "past",
              },
              {
                text: "Upcoming",
                value: "upcoming",
              },
            ]}
            setActive={(e) => {
              setChecked(e);
            }}
          />
          {userDetails && userDetails.test && !isMobile && (
            <RadioGroupTwo
              active={listType}
              buttons={[
                {
                  text: "Items",
                  value: "items",
                },
                {
                  text: "List",
                  value: "list",
                },
              ]}
              setActive={(e) => {
                setListType(e);
              }}
            />
          )}
        </div>
        {checked === "upcoming" ? (
          <>
            <div className="sub-header">
              <h3>Upcoming Events</h3>
            </div>
            {listType === "list" ? (
              <FixtureList eventArr={futureEvents} />
            ) : (
              <div className="fp-cont">
                {futureEvents.map((event, index) => (
                  <FixturePreview
                    showLive={event.roundIDs.length > 0}
                    key={event.eventID}
                    past={false}
                    hidePerson={event.roundIDs.length > 0 || teamLeague}
                    hideRounds={event.roundIDs.length > 0 || teamLeague}
                    event={event}
                    showDetails={() => {}}
                    editAction={() => {
                      if (teamLeague) {
                        setToShow(
                          <TeamLeagueEventHome
                            goBack={() => setShow(false)}
                            eventID={event.eventID}
                          />
                        );
                      } else {
                        setToShow(
                          <EventHome
                            eventID={event.eventID}
                            goBack={() => setShow(false)}
                          />
                        );
                      }
                      setShow(true);
                    }}
                    personAction={() => {
                      setToShow(
                        <InviteModal
                          eventID={event.eventID}
                          hide={() => setShow(false)}
                        />
                      );
                      setShow(true);
                    }}
                    timeAction={() => {
                      setToShow(
                        <EventRounds
                          eventID={event.eventID}
                          defaultRounds={event.rounds}
                          goBack={() => setShow(false)}
                        />
                      );
                      setShow(true);
                    }}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="sub-header">
              <h3>Past Events</h3>
            </div>
            {listType === "poop" ? (
              <div className="fplb-box mt-20 mb-20">
                <div className="fp-list-box bs-subtle bss-white">
                  <FixtureRowHeader />
                  {pastEvents.map((event, i) => {
                    let courseString = event.courseName;

                    if (event.courses !== undefined) {
                      for (let j = 0; j < event.courses.length; j++) {
                        if (j === event.courses.length - 1) {
                          courseString += event.courses[j].courseName;
                        } else {
                          courseString += `${event.courses[j].courseName}, `;
                        }
                      }
                    }
                    return (
                      <FixtureRow
                        imageLink={event.imageLink}
                        eventID={event.eventID}
                        players={event.playerIDs.length}
                        date={event.dateString}
                        title={event.eventName}
                        courses={courseString}
                        winner={event.winnerName}
                        season={event.season}
                        league={event.leagueName}
                      />
                    );
                  })}
                </div>
              </div>
            ) : listType === "list" ? (
              <FixtureList eventArr={pastEvents} />
            ) : (
              <div className="fp-cont">
                {pastEvents.map((event, index) => (
                  <FixturePreview
                    showLive={event.roundIDs.length > 0}
                    hidePerson={event.roundIDs.length > 0}
                    hideRounds={event.roundIDs.length > 0}
                    key={event.eventID}
                    past
                    event={event}
                    editAction={() => {
                      if (teamLeague) {
                        setToShow(
                          <TeamLeagueEventHome
                            goBack={() => setShow(false)}
                            eventID={event.eventID}
                          />
                        );
                      } else {
                        setToShow(
                          <EventHome
                            eventID={event.eventID}
                            goBack={() => setShow(false)}
                          />
                        );
                      }
                      setShow(true);
                    }}
                    showDetails={() => {}}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default LeagueEvents;
