import React, { useEffect, useState } from "react";
import "./SubscriptionCreator.css";
import { db, admin } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import BackRow from "../display/Rows/BackRow";
import classNames from "classnames";
import BackdropLoader from "../Loaders/BackdropLoader";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";
import PaystackPop from "@paystack/inline-js";

// Icons

// Comps

function SubscriptionCreator({ goBack = () => {}, showBack = false }) {
  // Admin
  const { userDetails, portalSettings, currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const generatePFSub = admin.functions().httpsCallable("payfastSubSignature");
  const generatePSSub = admin.functions().httpsCallable("paystackSig");
  const activateClub = admin.functions().httpsCallable("activateClubCall");

  // State
  const [formHTML, setFormHTML] = useState();

  // UE
  useEffect(() => {
    // callPayfastSub();
  }, []);

  // Funcs

  async function setActive() {
    setLoading(true);
    await activateClub({
      clubID: portalSettings.clubID,
      userID: currentUser.uid,
      trial: true,
    });
    toast.success("Successfully activated", {
      icon: successIco,
    });
    setLoading(false);
    goBack();
  }

  async function exchangeRate(amount) {
    try {
      const rate = await fetch(
        `https://api.exchangerate.host/convert?from=USD&to=ZAR&amount=${amount}`
      );
      const jsRate = await rate.json();
      console.log(jsRate);
      const rounded = Math.round(jsRate.result);
      return rounded;
      // return Math.round(jsRate.result);
    } catch (err) {
      console.log(err);
    }
  }

  async function callPayfastSub() {
    const now = new Date();
    const value = "50";
    // const exchtotal = await exchangeRate();
    // return;
    // const total = exchtotal.toString();
    // const total = "5";
    const { firstName, lastName, emailAddress } = userDetails;
    const formattedDate = now.toISOString().split("T")[0];
    let html = ``;

    const htmlData = await generatePFSub({
      total: "10.00",
      firstName,
      lastName,
      emailAddress,
      type: "Subscription",
      initialAmount: "0",
      frequency: "3",
      custom_str1: portalSettings.clubID,
    });
    html += htmlData.data;
    console.log(html);
    setFormHTML(html);
  }

  async function callPaystack() {
    setLoading(true);
    const { firstName, lastName, emailAddress } = userDetails;

    const res = await generatePSSub({
      emailAddress,
      clubID: portalSettings.clubID,
    });
    const { data } = res;
    console.log(data);
    const access_code = data.data.access_code;
    console.log(access_code);
    const popup = new PaystackPop();
    popup.resumeTransaction(access_code);
    setLoading(false);
  }

  async function addTrigger() {
    await db.collection("triggers").add({
      type: "club-charge",
      clubID: "PCJQziWXh61eiyGqzLo6",
      amount: "1000",
      usageDocID: "tzZHB2dWLatSpsahJDbk",
    });
    alert("added");
  }

  const temp = `<form action="https://sandbox.payfast.co.za/eng/process" method="post">
<input type="hidden" name="merchant_id" value="10027035">
<input type="hidden" name="merchant_key" value="loeu9mukcphfi">
<input type="hidden" name="return_url" value="https://www.theugatour.com">
<input type="hidden" name="cancel_url" value="https://www.theugatour.com">
<input type="hidden" name="notify_url" value="https://us-central1-uga-tour.cloudfunctions.net/payfastWebhookReceiver">
<input type="hidden" name="name_first" value="UGA">
<input type="hidden" name="name_last" value="Admin">
<input type="hidden" name="email_address" value="admin@theugatour.com">
<input type="hidden" name="amount" value="10.00">
<input type="hidden" name="item_name" value="Subscription">
<input type="hidden" name="item_description" value="Subscription2">
<input type="hidden" name="subscription_type" value="2"> 
<input type="hidden" name="signature" value="30e3c4b2b1ee1d76b88be58341043252"> 
<input type="submit">
</form> 
`;

  return (
    <div
      className={classNames("subs-creator", {
        "su-ce-full": showBack,
      })}
    >
      {loading && <BackdropLoader />}
      {showBack && <BackRow action={goBack} />}
      <button onClick={addTrigger} className="default-button">
        Call
      </button>
      {false && (
        <div
          className="payfast-form"
          dangerouslySetInnerHTML={{ __html: temp }}
        ></div>
      )}
      <div
        className="payfast-form"
        dangerouslySetInnerHTML={{ __html: formHTML }}
      ></div>

      {showBack && (
        <>
          <div className="flex-center">
            <button onClick={setActive} className="default-button">
              Activate
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default SubscriptionCreator;
