import React, { useState, useEffect, startTransition } from "react";
import { db, admin } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import PayHistoryHeader from "./PayHistoryHeader";
import PayHistoryRow from "./PayHistoryRow";
import { Oval } from "react-loader-spinner";
import PayDispute from "../PayDispute/PayDispute";
import ErrorModal from "../ErrorModal/ErrorModal";
import { errDefault } from "../HelperFunctions";
import BackdropProgLoader from "../Loaders/BackdropProgLoader";
import BackdropProgress from "../Loaders/BackdropProgress";

// Icons

// Components

function PaymentHistory({ clubID = "" }) {
  // Adin
  const invoiceCall = admin.functions().httpsCallable("invoiceCreator");
  const { currentUser } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [toShow, setToShow] = useState();
  const [show, setShow] = useState(false);
  const [showProg, setShowProg] = useState(false);

  const [error, setError] = useState("");

  // UE
  useEffect(() => {
    loadTransactions();
  }, []);

  // Funcs
  async function loadTransactions() {
    setLoading(true);
    const payDocs = await db
      .collection("clubs")
      .doc(clubID)
      .collection("paystackTransactions")
      .orderBy("date", "desc")
      .get();

    const arr = [];

    for (let i = 0; i < payDocs.docs.length; i++) {
      const payDoc = payDocs.docs[i];
      const payData = payDoc.data();
      const { date, data } = payData;
      if (payData.status === "failed") {
        continue;
      }
      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "short",
      });
      const { authorization, reference, status } = data;
      const amount = payData.amount ?? data.amount;
      const { last4, card_type } = authorization;
      arr.push({
        dateString,
        dateObject,
        reference,
        status,
        last4,
        amount: (amount / 100).toFixed(2),
        card_type,
        docID: payDoc.id,
        disputed: payData.disputed ?? false,
        disputeStatus: payData.disputeStatus ?? "",
      });
    }

    setTransactions(arr);
    setLoading(false);

    // const history = await historyCall({ clubID });
    // console.log(history);
  }

  async function generateInvoice({ month, year }) {
    setShowProg(true);
    try {
      const link = await invoiceCall({
        month,
        year,
        clubID,
        userID: currentUser.uid,
      });
      const url = link.data;
      window.open(url, "_blank");
    } catch (err) {
      console.log(err);
      setError(errDefault);
    }
    setShowProg(false);
  }

  return (
    <div className="pay-history">
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <div className="sub-header">
        <h3>Payment History</h3>
      </div>
      {showProg && (
        <BackdropProgress val={false} text="Generating Invoice..." />
      )}
      <div className="acg-box">
        <div className="ac-graph bss-white">
          {loading ? (
            <>
              {" "}
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : show ? (
            toShow
          ) : (
            <>
              <PayHistoryHeader />
              {transactions.map((tr, i) => {
                const year = tr.year ?? 2024;
                const month = tr.month ?? 7;

                return (
                  <PayHistoryRow
                    key={tr.reference}
                    row={tr}
                    invoiceAct={() => {
                      generateInvoice({ month, year });
                    }}
                    disputeAct={() => {
                      setToShow(
                        <PayDispute
                          close={() => setShow(false)}
                          clubID={clubID}
                          docID={tr.docID}
                          reload={loadTransactions}
                          reference={tr.reference}
                        />
                      );
                      setShow(true);
                    }}
                  />
                );
              })}
              {!loading && transactions.length === 0 && (
                <div>
                  {" "}
                  <div className="no-items mt-20">
                    <p>No items to be displayed</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentHistory;
