import React from "react";
import "./PremiumFeatures.css";
import { featureDetails } from "../HelperFunctions";

// Components
import { Dialog } from "@mui/material";
import PageHeader from "../display/Rows/PageHeader";
// Icons

function PremiumFeatures({ close = () => {} }) {
  return (
    <div className="prem-feats">
      <Dialog open onClose={close} className="pf-dia">
        <div className="pf-dia-ctt">
          <PageHeader text="Premium Features" showClose close={close} />

          <div className="pdc-feats">
            {featureDetails.map((feature, i) => {
              return (
                <div className="pdc-feat" key={`${i}feature`}>
                  <h3>{feature.title}</h3>
                  <>{feature.desc}</>
                  <h5>${feature.cost} / month</h5>
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default PremiumFeatures;
