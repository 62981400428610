import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import "./PayDispute.css";
import { useAuth } from "../../contexts/AuthContext";

// Icos

// Comps
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import PageHeader from "../display/Rows/PageHeader";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";

function PayDispute({
  close = () => {},
  reference = "",
  docID = "",
  clubID = "",
  reload = () => {},
}) {
  // Admin
  const { currentUser } = useAuth();

  // State
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState("");

  const [text, setText] = useState("");

  // Functions
  async function saveDispute() {
    setLoading(true);
    try {
      await db.collection("payDisputes").doc(reference).set({
        date: new Date(),
        reference,
        docID,
        clubID,
        userID: currentUser.uid,
        text,
        status: "pending",
      });
      await db
        .collection("clubs")
        .doc(clubID)
        .collection("paystackTransactions")
        .doc(docID)
        .update({
          disputed: true,
          disputeStatus: "pending",
        });
      toast.success("Your dispute was successfully logged.", {
        icon: successIco,
      });
      setComplete(true);
      reload();
      close();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  return (
    <div className="pay-dispute">
      <Dialog className="pd-dia" open onClose={close}>
        <div className="pd-dia-ctt">
          <PageHeader text="Dispute Transaction" showClose close={close} />

          {loading ? (
            <>
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : complete ? (
            <>
              <div className="erp-finished">
                <div className="erpc-msg pd-20">
                  <p style={{ textAlign: "center" }}>
                    Your dispute has been logged - one of our agents will be in
                    touch with you as soon as possible.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="input-group pl-20 mb-20">
                <p>
                  {" "}
                  Please let us know more details with regards to your issue:
                </p>
                <textarea
                  className="default-input"
                  value={text}
                  placeholder="Start Typing..."
                  rows="10"
                  onChange={(e) => setText(e.target.value)}
                />
              </div>

              <div className="flex-center mb-40">
                <button
                  onClick={saveDispute}
                  disabled={text.length < 0}
                  className="default-button"
                >
                  Save Dispute
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default PayDispute;
