import React, { useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Person } from "@mui/icons-material";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Link } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import classNames from "classnames";
import ConfirmModal from "../ConfirmModal";

function DrillItem({
  drill = {},
  viewAct = () => {},
  academyID = "",
  showAdd = false,
  custom = false,
  addAct = () => {},
  removeAct = () => {},
  editAct = () => {},
  showRemove = false,
  showAdded = false,
  hideVert = false,
  cursor = false,
  handleClick = () => {},
  selected = false,
  showAddScore = false,
  addScore = () => {},
  showAttempts = false,
  viewAttempts = () => {},
  scenario = false,
}) {
  const [show, setShow] = useState(false);

  return (
    <div
      onClick={handleClick}
      className={classNames("drill-item aca-coach-card", {
        "cur-poi": cursor,
        "di-sel": selected,
        "drill-sce": scenario,
      })}
    >
      {show && (
        <ConfirmModal
          close={() => setShow(false)}
          confirm={() => {
            removeAct();
            setShow(false);
          }}
          text="Are you sure you want to remove this drill from your library? This action cannot be undone."
        />
      )}
      <div className="acc-top-bg">
        {custom && <Person className="di-own" />}
        {showAdded && <BookmarkIcon className="di-own" />}
        {!hideVert && (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <button className="acc-horiz">
                <MoreHorizIcon />
              </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="DropdownMenuContent"
                sideOffset={5}
              >
                {/* <Link to={`/academies/${academyID}/drills/${drill.drillID}`}> */}
                <DropdownMenu.Item
                  onClick={viewAct}
                  className="DropdownMenuItem"
                >
                  View Details
                </DropdownMenu.Item>
                {/* </Link> */}

                {showAdd && (
                  <DropdownMenu.Item
                    onClick={addAct}
                    className="DropdownMenuItem"
                  >
                    Add to library
                  </DropdownMenu.Item>
                )}
                {showAddScore && (
                  <DropdownMenu.Item
                    onClick={addScore}
                    className="DropdownMenuItem"
                  >
                    Add Score
                  </DropdownMenu.Item>
                )}
                {showAttempts && (
                  <DropdownMenu.Item
                    onClick={viewAttempts}
                    className="DropdownMenuItem"
                  >
                    View Scores
                  </DropdownMenu.Item>
                )}

                {custom && (
                  <DropdownMenu.Item
                    onClick={editAct}
                    className="DropdownMenuItem"
                  >
                    Edit
                  </DropdownMenu.Item>
                )}
                {showRemove && (
                  <DropdownMenu.Item
                    onClick={() => setShow(true)}
                    className="DropdownMenuItem"
                  >
                    Remove from library
                  </DropdownMenu.Item>
                )}
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        )}
        <div className="acc-bg-box">
          <img alt={drill.title} src={drill.mainImage ?? ""} />
        </div>
      </div>
      <div className="acc-deets mt-20">
        <h2>{drill.title}</h2>
        <div className="flex flex-align-center mt-10">
          <h3>{drill.category}</h3>
          <div className={`di-diff di-${drill.difficulty}`}>
            <p>{drill.difficulty}</p>
          </div>
        </div>
      </div>

      <div className="acc-stats">
        <div className="accs-left">
          <h4>{drill.attempts.length ?? 0}</h4>
          <h2>Attempts</h2>
        </div>
        <div className="accs-div"></div>
        <div className="accs-right">
          <h4>{drill.highScore ?? 0}</h4>
          <h2>High Score</h2>
        </div>
      </div>
      <div className="di-desc">
        <p>{drill.description}</p>
      </div>
    </div>
  );
}

export default DrillItem;
