import React from "react";

function RefundRow({ refund = {} }) {
  return (
    <div className="refund-row">
      <div className="rr-date">
        <p>{refund.dateString}</p>
      </div>
      <div className="rr-date">
        <p>${refund.amount}</p>
      </div>
      <div className="rr-date">
        <p>{refund.reference}</p>
      </div>
    </div>
  );
}

export default RefundRow;
