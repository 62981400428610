import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { featuresProp, featureVals, premiumFeatures } from "../HelperFunctions";

// Icons

// Components
import { Oval } from "react-loader-spinner";
import Switch from "react-ios-switch/lib/Switch";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";
import { Cancel, CheckCircle } from "@mui/icons-material";
import PremiumFeatures from "../PremiumFeatures/PremiumFeatures";

function FeaturePicker({ clubID = "" }) {
  // Admin
  const sgOpts = ["Enabled", "Changes"];

  // State
  const [loading, setLoading] = useState(true);
  const [currentFeatures, setCurrentFeatures] = useState(featuresProp);
  const [changes, setChanges] = useState([]);
  const [update, setUpdate] = useState(0);
  const [selTab, setSelTab] = useState("Enabled");

  const [showFeatures, setShowFeatures] = useState(false);

  // UE
  useEffect(() => {
    loadFeatures();
  }, []);

  // Funcs
  async function loadFeatures() {
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const features = club.features ?? featuresProp;
    for (const [key, value] of Object.entries(featuresProp)) {
      if (features[key] === undefined) {
        features[key] = false;
      }
    }
    setCurrentFeatures(features);
    const featureChangeDocs = await clubDoc.ref
      .collection("featureChanges")
      .orderBy("date", "desc")
      .get();
    const arr = [];

    for (let i = 0; i < featureChangeDocs.docs.length; i++) {
      const fcDoc = featureChangeDocs.docs[i];
      const fcData = fcDoc.data();
      const { features, date } = fcData;
      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "medium",
      });
      arr.push({
        features,
        dateObject,
        dateString,
      });
    }

    setChanges(arr);
    setLoading(false);
  }

  async function saveFeatures() {
    setLoading(true);
    await db.collection("clubs").doc(clubID).update({
      features: currentFeatures,
    });
    await db.collection("clubs").doc(clubID).collection("featureChanges").add({
      date: new Date(),
      features: currentFeatures,
    });
    setChanges((c) => {
      c.unshift({
        dateObject: new Date(),
        dateString: new Date().toLocaleDateString("default", {
          dateStyle: "medium",
        }),
        features: currentFeatures,
      });
      return c;
    });
    toast.success("Features successfully saved", {
      icon: successIco,
    });
    setLoading(false);
  }

  return (
    <div className="feature-picker">
      {showFeatures && <PremiumFeatures close={() => setShowFeatures(false)} />}
      <div className="feat-pic-box acg-box">
        <div className="ac-graph bss-white">
          {loading ? (
            <>
              {" "}
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : (
            <>
              <div className="ph-msg  pd-10">
                <p>
                  Any activated Premium Features will be pro-rated for the
                  month. for more information on The Tour's Premium Features{" "}
                  <span
                    onClick={() => setShowFeatures(true)}
                    className="span-link"
                  >
                    click here
                  </span>
                </p>
              </div>
              <div className="selector-group sg-small mt-20">
                {sgOpts.map((opt, i) => {
                  return (
                    <button
                      key={opt}
                      className={selTab === opt ? "sg-active" : ""}
                      onClick={() => {
                        setSelTab(opt);
                        setUpdate((c) => c + 1);
                      }}
                    >
                      {opt}
                    </button>
                  );
                })}
              </div>
              {selTab === "Enabled" && (
                <>
                  <div className="sub-header">
                    <h3>Enabled Features</h3>
                  </div>
                  <div className="fpb-items pd-20 sp-group">
                    {Object.entries(currentFeatures).map((entry, i) => {
                      const key = entry[0];
                      const val = entry[1];

                      if (!premiumFeatures.includes(key)) {
                        return null;
                      }

                      return (
                        <div key={i} className="fpb-item sp-item">
                          <p>{featureVals[key]}</p>
                          <Switch
                            onColor="#1e7a69"
                            checked={val}
                            className={
                              val
                                ? "scoring-switch switch-on"
                                : "scoring-switch"
                            }
                            onChange={() => {
                              setCurrentFeatures((current) => {
                                // console.log(current);
                                current[key] = !current[key];
                                // console.log(current);

                                return current;
                              });
                              setUpdate((current) => current + 1);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex-center">
                    <button onClick={saveFeatures} className="default-button">
                      Save Changes
                    </button>
                  </div>
                </>
              )}
              {selTab === "Changes" && (
                <>
                  <div className="sub-header">
                    <h3>Feature Changes</h3>
                  </div>
                  <div className="fp-chgs pd-20">
                    {changes.map((change, i) => {
                      const { features } = change;
                      return (
                        <div key={change.dateString} className="fp-change">
                          <h3>{change.dateString}</h3>
                          <div className="fpc-abled">
                            {Object.entries(features).map((feat, j) => {
                              if (!premiumFeatures.includes(feat[0])) {
                                return null;
                              }
                              const featureLabel = featureVals[feat[0]];
                              return (
                                <div
                                  key={`${i}able${j}`}
                                  className="abled-feat"
                                >
                                  <p>{featureLabel}</p>
                                  {feat[1] ? (
                                    <>
                                      <CheckCircle className="dg-icon" />
                                    </>
                                  ) : (
                                    <>
                                      <Cancel className="red-icon" />
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FeaturePicker;
