import React from "react";

import { Dialog } from "@mui/material";
import PageHeader from "../../../../display/Rows/PageHeader";

function ApproachMissDisplay({ details = {}, close = () => {} }) {
  return (
    <Dialog className="amd-dia" onClose={close} open>
      <div className="amd-dia-ctt">
        <PageHeader text="Attempt Details" showClose close={close} />

        <div className="adc-ctt pd-20">
          <div className="sub-header">
            <h3>Details</h3>
          </div>
          <div className="details-item mt-10">
            <h3>Date:</h3>
            <p>{details.dateString}</p>
          </div>
          <div className="details-item mt-10">
            <h3>Total Percentage:</h3>
            <p>{details.totalPerc}%</p>
          </div>
          <div className="sub-header mb-10 mt-20">
            <h3>Attempts</h3>
          </div>
          <div className="adc-atts">
            <div className="amd-dia-row adr-header">
              <div className="adr-ite">
                <p>Club</p>
              </div>
              <div className="adr-ite">
                <p>Distance</p>
              </div>
              <div className="adr-ite">
                <p>Miss</p>
              </div>
              <div className="adr-ite">
                <p>Percentage</p>
              </div>
            </div>
            {details.attempts.map((att, i) => {
              return (
                <div key={`attdis${i}`} className="amd-dia-row">
                  <div className="adr-ite">
                    <p>{att.club}</p>
                  </div>
                  <div className="adr-ite">
                    <p>{att.distance}</p>
                  </div>
                  <div className="adr-ite">
                    <p>{att.miss}</p>
                  </div>
                  <div className="adr-ite adr-perc">
                    <p>{att.missPercentage}%</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ApproachMissDisplay;
