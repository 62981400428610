import React from "react";
import "./PlayerRow.css";

import { ArrowDropDown, Delete } from "@mui/icons-material";
import { ArrowDropUp } from "@mui/icons-material";

function PlayerRowHeader({
  currentSorter = "",
  dir = "",
  sortByHeader = () => {},
  showTeam = false,
  showRemove = false,
}) {
  return (
    <div className="player-row player-row-header row-header">
      <div className="pr-item">
        <p>Image</p>
      </div>
      <div
        onClick={() => sortByHeader("lastName")}
        className="pr-player cursor-ico"
      >
        <p>Player</p>
        {currentSorter === "lastName" && (
          <>
            {dir === "up" ? (
              <ArrowDropUp className="header-arrow" />
            ) : (
              <ArrowDropDown className="header-arrow" />
            )}
          </>
        )}
      </div>
      <div
        onClick={() => sortByHeader("handicap")}
        className="pr-item cursor-ico pr-hcp"
      >
        <p>Hcp</p>
        {currentSorter === "handicap" && (
          <>
            {dir === "up" ? (
              <ArrowDropUp className="header-arrow" />
            ) : (
              <ArrowDropDown className="header-arrow" />
            )}
          </>
        )}
      </div>
      {showTeam && (
        <div
          onClick={() => sortByHeader("team")}
          className="pr-item cursor-ico"
        >
          <p>Team</p>
          {currentSorter === "team" && (
            <>
              {dir === "up" ? (
                <ArrowDropUp className="header-arrow" />
              ) : (
                <ArrowDropDown className="header-arrow" />
              )}
            </>
          )}
        </div>
      )}
      {showRemove && (
        <div
          onClick={() => sortByHeader("team")}
          className="pr-item cursor-ico"
        >
          <p>Remove</p>
        </div>
      )}
      {/* <div
        className="pr-item cursor-ico pr-city"
        onClick={() => sortByHeader("city")}
      >
        <p>City</p>
        {currentSorter === "city" && (
          <>
            {dir === "up" ? (
              <ArrowDropUp className="header-arrow" />
            ) : (
              <ArrowDropDown className="header-arrow" />
            )}
          </>
        )}
      </div>
      <div
        className="pr-item cursor-ico pr-cou"
        onClick={() => sortByHeader("country")}
      >
        <p>Country</p>
        {currentSorter === "country" && (
          <>
            {dir === "up" ? (
              <ArrowDropUp className="header-arrow" />
            ) : (
              <ArrowDropDown className="header-arrow" />
            )}
          </>
        )}
      </div>
      <div
        className="pr-item cursor-ico pr-joi"
        onClick={() => sortByHeader("dateJoined")}
      >
        <p>Joined</p>
        {currentSorter === "dateJoined" && (
          <>
            {dir === "up" ? (
              <ArrowDropUp className="header-arrow" />
            ) : (
              <ArrowDropDown className="header-arrow" />
            )}
          </>
        )}
      </div> */}
    </div>
  );
}

export default PlayerRowHeader;
