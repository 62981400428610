import React, { useState, useEffect, lazy, Suspense } from "react";
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "./media.css";
import "./styles/variables.css";

// Components
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

import Menu from "./components/Menu/Menu";
import { MenuProvider } from "./contexts/MenuContext";
import { HelperProvider } from "./contexts/HelperContext";

// CSS
import "./items/text.css";
import "./items/animations.css";
import "./components/Buttons/Buttons.css";
import "@radix-ui/themes/styles.css";
import "react-calendar/dist/Calendar.css";

// Components
import BackdropLoader from "./components/Loaders/BackdropLoader";
import LandingPage from "./components/LandingPage/LandingPage";
import Footer from "./components/Footer/Footer";
import TourLanding from "./components/TourLanding/TourLanding";

// Pages
import Login from "./pages/Login/Login";
import EventCreator from "./components/Events/EventCreator/EventCreator";
import TeamLeagueEventEditor from "./components/ClubLeagues/TeamLeagueEventEditor";
import Home from "./pages/Home/Home";
import FairUse from "./pages/FairUse";
import CalendarPage from "./pages/Calendar/CalendarPage";
import Leagues from "./pages/Leagues/Leagues";
import Settings from "./pages/Settings/Settings";
import Support from "./pages/Support/Support";
import SignUp from "./components/SignUp/SignUp";
import Players from "./pages/Players/Players";
import Onboarding from "./components/SignUp/Onboarding";
import PrivacyPolicy from "./pages/TermsConditions/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import Contests from "./pages/Contests/Contests";
import ContestBoard from "./pages/Contests/ContestBoard";
import Cups from "./pages/Cups/Cups";
import Tournaments from "./pages/MatchplayTournaments/Tournaments";
import Clubs from "./pages/Clubs/Clubs";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import { auth, db } from "./firebase";
import ClubPlayers from "./pages/Clubs/ClubPlayers";
import ClubEvents from "./pages/Clubs/ClubEvents";
import ClubLeagues from "./pages/Clubs/ClubLeagues";
import ClubTournaments from "./pages/Clubs/ClubTournaments";
import ClubPlayerStats from "./pages/Clubs/ClubPlayerStats";
import EventHome from "./components/Events/EventCreator/EventHome";
import TeamLeagueEventHome from "./components/ClubLeagues/TeamLeagueEventHome";
import LeagueEditHome from "./components/Leagues/LeagueEdit/LeagueEditHome";
import LeagueEdit from "./components/Leagues/LeagueEdit/LeagueEdit";
import TeamLeagueHome from "./components/ClubLeagues/TeamLeagueHome";
import EventDashboard from "./components/Events/EventDashboard/EventDashboard";
import TeamEventDashboard from "./components/Events/EventDashboard/TeamEventDashboard";
import CupDashboard from "./components/Events/EventDashboard/CupDashboard";
import CupHome from "./components/CupCreator/CupHome";
import MatchplayTournamentHome from "./pages/MatchplayTournaments/MatchplayTournamentHome";
import LeagueCreator from "./components/Leagues/LeagueCreator/LeagueCreator";
import TeamLeagueEditor from "./components/ClubLeagues/TeamLeagueEditor";
import ContestPage from "./pages/Contests/ContestPage";
import Account from "./components/Account/Account";
import Helper from "./components/Helper/Helper";
import MessageBoard from "./pages/Clubs/MessageBoard/MessageBoard";
import ClubSponsors from "./pages/Clubs/Sponsors/ClubSponsors";
import ClubStats from "./pages/Clubs/ClubStats";
import Features from "./pages/Features/Features";
import Pricing from "./pages/Pricing/Pricing";
import ClientStats from "./components/ClientStats/ClientStats";
import PLStatsHome from "./components/ClientStats/PLStatsHome/PLStatsHome";
import HomeLanding from "./components/HomeLanding/HomeLanding";
import ClubAnalytics from "./pages/Clubs/ClubAnalytics";
import CourseStats from "./components/CourseStats/CourseStats";
import AdminDrillCreator from "./components/ClientStats/Drills/AdminDrillCreator";
import AdminDrills from "./components/ClientStats/Drills/AdminDrills";
import VerifyPage from "./pages/VerifyPage/VerifyPage";
import BulkPlayerCreator from "./components/BulkPlayerCreator/BulkPlayerCreator";
import OutstandingPayments from "./components/Account/OutstandingPayments";
import Arrears from "./components/Arrears/Arrears";
import InactivePage from "./components/InactivePage/InactivePage";
import TourLogin from "./components/TourLanding/TourLogin";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [valid, setValid] = useState(true);

  const { currentUser, userDetails, portalSettings, inactive } = useAuth();

  const [sdClick, setSDClick] = useState(0);
  const [showArrears, setShowArrears] = useState(false);

  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === undefined && userDetails === undefined) {
      return;
    }
    if (currentUser === null) {
      // console.log(1);
      setLoggedIn(false);
      setLoaded(true);
    }
    if (currentUser && currentUser.email.includes("guest")) {
      auth.signOut();
    }
    if (currentUser && userDetails) {
      console.log(userDetails.accountType);
      if (
        userDetails.accountType === "club" ||
        userDetails.accountType === "admin" ||
        userDetails.accountType === "society" ||
        (userDetails.accountType === "subscription" &&
          userDetails.portal === true)
      ) {
        setValid(true);
        setLoggedIn(true);
        setLoaded(true);
      } else {
        setValid(false);
        setLoggedIn(true);
        setLoaded(true);
      }
    }
  }, [currentUser, userDetails]);

  useEffect(() => {
    console.log(inactive);
    if (inactive !== undefined && inactive !== null) {
      if (inactive === true) {
        setIsInactive(true);
      }
    }
  }, [inactive]);

  useEffect(() => {
    if (isInactive) {
      navigate("/"); // Redirect to home page
    }
  }, [isInactive, navigate]);

  // useEffect(() => {
  //   if (portalSettings && portalSettings.clubID) {
  //     console.log(portalSettings.clubID);

  //     checkOPs(portalSettings.clubID);
  //   }
  // }, [portalSettings]);

  async function checkOPs(clubID) {
    const clubOpDocs = await db
      .collection("clubs")
      .doc(clubID)
      .collection("outstandingPayments")
      .where("status", "==", "outstanding")
      .get();

    if (clubOpDocs.docs.length > 0) {
      setShowArrears(true);
    } else {
      setShowArrears(false);
    }
  }

  return (
    <MenuProvider>
      <HelperProvider>
        <div className="App">
          {!loaded && <BackdropLoader />}
          <CookieConsent>
            This website uses cookies to enhance the user experience.
          </CookieConsent>
          <ToastContainer
            // icon={}
            position="bottom-left"
            // autoClose={false}
            // hideProgressBar={true}
            closeOnClick
            pauseOnHover
            draggable
          />
          {loaded && (
            <>
              <Menu sdClick={sdClick} />
              {/* {showArrears && <Arrears />} */}
              {false &&
                portalSettings &&
                portalSettings.showHelper === true && <Helper />}
              <Routes>
                <Route
                  path="/"
                  element={
                    valid && loggedIn && !isInactive ? (
                      <Home />
                    ) : isInactive ? (
                      <>
                        <InactivePage />
                      </>
                    ) : loggedIn ? (
                      <LandingPage />
                    ) : (
                      <LandingPage />
                    )
                  }
                />
                {/* <Route path="/login" element={<TourLogin />} /> */}
                <Route path="/pl-stats">
                  <Route path=":id" element={<ClientStats />} />
                </Route>
                <Route path="course-stats">
                  <Route path=":id" element={<CourseStats />} />
                </Route>
                <Route path="/club-analytics">
                  <Route path=":id" element={<ClubAnalytics />} />
                </Route>
                <Route path="/pl-stats-home">
                  <Route path=":id" element={<PLStatsHome />} />
                </Route>
                <Route path="features" element={<Features />} />
                <Route path="pricing" element={<Pricing />} />
                <Route
                  path="/event-creator"
                  element={<EventCreator hideBack edit={false} />}
                />
                <Route path="/account" element={<Account />} />
                <Route path="/outstanding">
                  <Route path=":id" element={<OutstandingPayments />} />
                </Route>
                <Route
                  path="/team-event-creator"
                  element={<TeamLeagueEventEditor hideBack edit={false} />}
                />
                <Route
                  path="/league-creator"
                  element={<LeagueCreator hideBack edit={false} />}
                />
                <Route
                  path="/team-league-creator"
                  element={<TeamLeagueEditor hideBack edit={false} />}
                />
                <Route path="/members">
                  <Route path=":id" element={<ClubPlayers />} />
                </Route>
                <Route path="/club-stats">
                  <Route path=":id" element={<ClubStats />} />
                </Route>
                <Route path="/message-board">
                  <Route path=":id" element={<MessageBoard />} />
                </Route>
                <Route path="/player-stats">
                  <Route path=":id" element={<ClubPlayerStats />} />
                </Route>
                <Route path="/club-board">
                  <Route path=":id" element={<MessageBoard />} />
                </Route>
                <Route path="/club-sponsors">
                  <Route path=":id" element={<ClubSponsors />} />
                </Route>
                <Route path="/evt-dash">
                  <Route path=":id" element={<EventDashboard />} />
                </Route>
                <Route path="/cup-dash">
                  <Route path=":id" element={<CupDashboard />} />
                </Route>
                <Route path="/cup-home">
                  <Route path=":id" element={<CupHome />} />
                </Route>
                <Route path="/team-evt-dash">
                  <Route path=":id" element={<TeamEventDashboard />} />
                </Route>
                <Route path="/clubleagues">
                  <Route path=":id" element={<ClubLeagues />} />
                </Route>
                <Route path="/events">
                  <Route path=":id" element={<ClubEvents />} />
                </Route>{" "}
                <Route path={"event-home"}>
                  <Route path=":id" element={<EventHome />} />
                </Route>
                <Route path={"team-event-home"}>
                  <Route path=":id" element={<TeamLeagueEventHome />} />
                </Route>
                <Route path="/clubtournaments">
                  <Route path=":id" element={<ClubTournaments />} />
                </Route>
                <Route path="/calendar" element={<CalendarPage />} />
                <Route path="/fair-use" element={<FairUse />} />
                <Route path="/cups" element={<Cups />} />
                <Route path="/tournaments">
                  <Route path="" element={<Tournaments />} />
                  <Route path=":id" element={<MatchplayTournamentHome />} />
                </Route>
                <Route path="/contests">
                  <Route path="" element={<Contests />} />
                  <Route path=":contestID" element={<ContestPage />} />
                </Route>
                <Route path="/contestboards">
                  <Route path=":contestID" element={<ContestBoard />} />
                </Route>
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/clubs" element={<Clubs />} />
                <Route path="/players" element={<Players />} />
                <Route path="/leagues">
                  <Route path="" element={<Leagues />} />
                  <Route path=":id" element={<LeagueEdit />} />
                </Route>
                <Route path="team-leagues">
                  <Route path=":id" element={<TeamLeagueHome />} />
                </Route>
                <Route path="player-creator">
                  <Route path=":id" element={<BulkPlayerCreator stdl />} />
                </Route>
                <Route path="/settings" element={<Settings />} />
                <Route path="/support" element={<Support />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/profile-edit" element={<Onboarding edit />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-conditions" element={<TermsConditions />} />
                <Route path="/admin-drills" element={<AdminDrills />} />
                <Route path="/verify" element={<VerifyPage />} />
              </Routes>
              <Footer />
            </>
          )}
        </div>
      </HelperProvider>
    </MenuProvider>
  );
}

export default App;
