import React, { useState, useEffect } from "react";

// Icons

// Components
import CustomDrop from "../../../../CustomDrop/CustomDrop";
import { DeleteForever } from "@mui/icons-material";

const numberStrings = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

function ShotRandomizerRow({
  row = {},
  saveRow = () => {},
  deleteRow = () => {},
  index = 1,
}) {
  const [rating, setRating] = useState("");

  //   useEffect(() => {
  //     saveRow(rating);
  //   }, [rating]);

  return (
    <div className="sr-row acc-drill-row">
      <div className="adr-idx">
        <p>Shot {index}:</p>
      </div>
      <div className="flex-as">
        <div className="adr-item input-group">
          <p>Shape</p>
          <h3>{row.shape}</h3>
        </div>
        <div className="adr-item input-group">
          <p>Trajectory</p>
          <h3>{row.trajectory}</h3>
        </div>
        <div className="adr-item input-group">
          <p>Distance</p>
          <h3>{row.distance}</h3>
        </div>
        <div className="adr-item input-group">
          <p>Rating</p>
          <CustomDrop
            onChange={(e) => {
              setRating(e.value);
              saveRow(e.value);
            }}
            options={numberStrings}
          />
        </div>
        <div className="adr-del adr-ico">
          <DeleteForever
            onClick={deleteRow}
            className="red-icon-bg icon-cursor"
          />
        </div>{" "}
      </div>
    </div>
  );
}

export default ShotRandomizerRow;
