import React, { useEffect, useState } from "react";
import { clubArray } from "../../../HelperFunctions";
import CustomDrop from "../../../../CustomDrop/CustomDrop";
import { DeleteForever, Save } from "@mui/icons-material";

function AccuracyDrillRow({
  row = {},
  saveRow = () => {},
  removeRow = () => {},
  index = 1,
}) {
  const [selClub, setSelClub] = useState(row.club ?? "");
  const [distance, setDistance] = useState(row.distance ?? 0);
  const [miss, setMiss] = useState(0);
  const [missPerc, setMissPerc] = useState(0);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    let missPercentage = ((miss / distance) * 100).toFixed(2);

    if (isNaN(missPercentage)) {
      setMissPerc(0);
      missPercentage = 0;
    } else {
      setMissPerc(missPercentage);
    }

    const att = { club: selClub, distance, miss, missPercentage };

    saveRow(att);
    // console.log("saved");
  }, [selClub, distance, miss]);

  useEffect(() => {
    console.log("TRIGGED");
    setUpdate((c) => c + 1);
  }, [index]);

  return (
    <div className="acc-drill-row">
      <div className="adr-idx">
        <p>Shot {index}:</p>
      </div>
      <div className="flex-as">
        <div className="adr-item input-group">
          <p>Club</p>
          <CustomDrop
            value={selClub}
            onChange={(e) => setSelClub(e.value)}
            options={clubArray}
          />
        </div>
        <div className="adr-item input-group">
          <p>Distance</p>
          <input
            className="default-input"
            type="number"
            value={distance}
            onChange={(e) => setDistance(e.target.valueAsNumber)}
          />
        </div>
        <div className="adr-item input-group">
          <p>Miss</p>
          <input
            className="default-input"
            type="number"
            value={miss}
            onChange={(e) => setMiss(e.target.valueAsNumber)}
          />
        </div>
        <div className="adr-item input-group">
          <p>Percentage</p>
          <h3>{missPerc}%</h3>
        </div>
        {false && (
          <div className="adr-save adr-ico mr-20">
            <Save className="icon-cursor dg-icon-bg" />
          </div>
        )}
        <div className="adr-del adr-ico">
          <DeleteForever
            onClick={removeRow}
            className="red-icon-bg icon-cursor"
          />
        </div>
      </div>
    </div>
  );
}

export default AccuracyDrillRow;
