import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
// Icons
import Close from "@mui/icons-material/Close";

// Components
import BackRow from "../../components/display/Rows/BackRow";
import PageHeader from "../../components/display/Rows/PageHeader";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import Pill from "../../components/Buttons/Pill";
import FixturePreview from "../../components/display/FixturePreview/FixturePreview";
import EventRounds from "../../components/Events/EventCreator/EventRounds";
import EventHome from "../../components/Events/EventCreator/EventHome";
import InviteModal from "../../components/Events/InviteModal/InviteModal";
import EventCreator from "../../components/Events/EventCreator/EventCreator";
import ClubCourse from "./ClubCourse";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Dialog } from "@mui/material";
import TeamLeagueEventHome from "../../components/ClubLeagues/TeamLeagueEventHome";
import FixtureList from "../../components/FixtureRow/FixtureList";
import { isMobile } from "react-device-detect";

function ClubEvents({ goBack = () => {}, courses = [] }) {
  const navigate = useNavigate();
  const { userDetails } = useAuth();

  // Statea
  const [allEvents, setAllEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState("upcoming");

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [searchInput, setSearchInput] = useState("");

  const [leagueOptions, setLeagueOptions] = useState([]);
  const [selLeague, setSelLeague] = useState("");

  const [showCourseSelect, setShowCourseSelect] = useState(false);
  const [clubID, setClubID] = useState("");
  const [selCourse, setSelCourse] = useState({
    courseID: "",
    name: "",
  });

  const [listType, setListType] = useState("items");

  const { id } = useParams();

  // UE
  useEffect(() => {
    setClubID(id);
    loadEvents({ clubID: id });
    // loadLeagues({ clubID: id });
  }, []);

  async function loadLeagues({ clubID = "" }) {
    const arr = [
      {
        label: "None",
        value: "",
      },
    ];
    const leagueDocs = await db
      .collection("leagues")
      .where("clubID", "==", clubID)
      .get();
    for (let i = 0; i < leagueDocs.docs.length; i++) {
      const leagueDoc = leagueDocs.docs[i];
      const league = leagueDoc.data();
      const leagueID = leagueDoc.id;
      const { leagueName } = league;
      arr.push({
        leagueName,
        leagueID,
        label: leagueName,
        value: leagueID,
      });
    }
    setLeagueOptions(arr);
  }

  async function loadEvents({ clubID = "" }) {
    setLoading(true);
    const larr = [
      {
        label: "None",
        value: "",
      },
    ];
    const leagueDocs = await db
      .collection("leagues")
      .where("clubID", "==", clubID)
      .get();
    for (let i = 0; i < leagueDocs.docs.length; i++) {
      const leagueDoc = leagueDocs.docs[i];
      const league = leagueDoc.data();
      const leagueID = leagueDoc.id;
      const { leagueName } = league;
      larr.push({
        leagueName,
        leagueID,
        label: leagueName,
        value: leagueID,
      });
    }
    setLeagueOptions(larr);

    const leagueIDs = leagueDocs.docs.map((league) => league.id);

    const seasonDocs = await db
      .collection("seasons")
      .where("leagueID", "in", leagueIDs)
      .get();
    const seasonMap = {};

    for (let i = 0; i < seasonDocs.docs.length; i++) {
      const seasonDoc = seasonDocs.docs[i];
      const seasonData = seasonDoc.data();
      const seasonID = seasonDoc.id;
      const { seasonName } = seasonData;
      seasonMap[seasonID] = seasonName;
    }

    const eventDocs = await db
      .collection("events")
      .where("clubID", "==", clubID)
      .get();

    const teamEventDocs = await db
      .collection("teamEvents")
      .where("clubID", "==", clubID)
      // .orderBy("eve")
      .get();

    const arr = [];
    const parr = [];
    const farr = [];

    const now = new Date();

    for (let i = 0; i < eventDocs.docs.length; i++) {
      // console.log(i);
      const eventDoc = eventDocs.docs[i];
      const event = eventDoc.data();
      if (event.cancelled) {
        continue;
      }
      const eventID = eventDoc.id;
      const dateObj = new Date(event.eventDate.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      const seasonID = event.seasonID ?? "";
      const season = seasonMap[seasonID] ?? "";
      event.season = season;
      event.eventID = eventID;
      event.dateObj = dateObj;
      event.dateString = dateString;
      arr.push(event);
      if (now < dateObj) {
        farr.push(event);
      } else {
        parr.push(event);
      }
    }
    for (let i = 0; i < teamEventDocs.docs.length; i++) {
      // console.log(i);
      const eventDoc = teamEventDocs.docs[i];
      const event = eventDoc.data();
      if (event.cancelled) {
        continue;
      }
      const eventID = eventDoc.id;
      event.teamEvent = true;
      const dateObj = new Date(event.eventDate.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      event.eventID = eventID;
      event.dateObj = dateObj;
      event.dateString = dateString;
      event.team = true;

      arr.push(event);
      if (now < dateObj) {
        farr.push(event);
      } else {
        parr.push(event);
      }
    }

    parr.sort((a, b) => b.dateObj - a.dateObj);
    farr.sort((a, b) => a.dateObj - b.dateObj);
    setAllEvents(arr);
    setPastEvents(parr);
    setFutureEvents(farr);
    setLoading(false);
  }

  return (
    <div className="club-events">
      {show ? (
        toShow
      ) : (
        <>
          <BackRow
            action={() => {
              navigate(-1);
            }}
          />
          <PageHeader text="Club Events" />
          {loading && <BackdropLoader />}
          <Dialog
            open={showCourseSelect}
            onClose={() => setShowCourseSelect(false)}
          >
            <div className="ch-cs pd-20">
              <div className="page-header">
                <h2>Course Selection</h2>
                <Close
                  className="icon-cursor"
                  onClick={() => setShowCourseSelect(false)}
                />
              </div>
              <div className="ph-msg">
                <p>Select a course for this round</p>
              </div>
              <div className="pd-20 flex-wrapper">
                {courses.map((course, index) => {
                  return (
                    <ClubCourse
                      select
                      setSelected={() => {
                        setSelCourse(course);
                      }}
                      course={course}
                      key={course.courseID}
                      hideRight
                      selected={selCourse.courseID === course.courseID}
                    />
                  );
                })}
              </div>
              <div className="flex-center mb-20">
                <button
                  onClick={() => {
                    setToShow(
                      <EventCreator
                        edit={false}
                        defaultClubID={clubID}
                        defaultCourseData={selCourse}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShowCourseSelect(false);
                    setShow(true);
                  }}
                  className="default-button"
                >
                  Select
                </button>
              </div>
            </div>
          </Dialog>
          {false && (
            <div className="quick-links">
              <Pill
                onClick={() => {
                  if (courses.length === 0) {
                    setToShow(
                      <EventCreator
                        edit={false}
                        defaultClubID={clubID}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  } else if (courses.length === 1) {
                    setToShow(
                      <EventCreator
                        edit={false}
                        defaultCourseData={courses[0]}
                        defaultCourseID={courses[0].courseID}
                        defaultClubID={clubID}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  } else {
                    setShowCourseSelect(true);
                  }
                }}
                text={"New Event"}
              />
            </div>
          )}

          <div className="ce-filters">
            <RadioGroupTwo
              active={selected}
              setActive={setSelected}
              buttons={[
                {
                  text: "Past",
                  value: "past",
                },
                {
                  value: "upcoming",
                  text: "Upcoming",
                },
              ]}
            />
            {userDetails && userDetails.test && !isMobile && (
              <RadioGroupTwo
                active={listType}
                buttons={[
                  {
                    text: "Items",
                    value: "items",
                  },
                  {
                    text: "List",
                    value: "list",
                  },
                ]}
                setActive={(e) => {
                  setListType(e);
                }}
              />
            )}
            <Dropdown
              placeholder={"Select League"}
              options={leagueOptions}
              onChange={(e) => setSelLeague(e.value)}
              value={selLeague}
            />
            <input
              type="text"
              value={searchInput}
              placeholder="Search for event"
              onChange={(e) => setSearchInput(e.target.value)}
              className="default-input ml-5v mb-10"
            />
          </div>

          {listType === "list" ? (
            <>
              {selected === "upcoming" && (
                <FixtureList
                  leagueID={selLeague}
                  search={searchInput}
                  eventArr={futureEvents}
                />
              )}
              {selected === "past" && (
                <FixtureList
                  leagueID={selLeague}
                  search={searchInput}
                  eventArr={pastEvents}
                />
              )}
            </>
          ) : (
            <>
              {selected === "upcoming" && futureEvents.length > 0 && (
                <div className="ce-events">
                  {futureEvents.map((event, index) => {
                    if (selLeague !== "") {
                      if (selLeague !== event.leagueID) {
                        return null;
                      }
                    }
                    if (searchInput !== "") {
                      const sl = searchInput.toLowerCase();
                      const el = event.eventName.toLowerCase();
                      if (!el.includes(sl)) {
                        return null;
                      }
                    }
                    return (
                      <FixturePreview
                        hideRounds={
                          event.teamEvent || event.roundIDs.length > 0
                        }
                        hidePerson={
                          event.teamEvent || event.roundIDs.length > 0
                        }
                        showLive={event.roundIDs.length > 0}
                        key={event.eventID}
                        event={event}
                        timeAction={() => {
                          setToShow(
                            <EventRounds
                              eventID={event.eventID}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }}
                        personAction={() => {
                          setToShow(
                            <InviteModal
                              reload={() => loadEvents({ clubID })}
                              hide={() => setShow(false)}
                              eventID={event.eventID}
                            />
                          );
                          setShow(true);
                        }}
                        editAction={() => {
                          if (event.team) {
                            setToShow(
                              <TeamLeagueEventHome
                                goBack={() => setShow(false)}
                                eventID={event.eventID}
                              />
                            );
                          } else {
                            setToShow(
                              <EventHome
                                eventID={event.eventID}
                                goBack={() => setShow(false)}
                                reload={() => loadEvents({ clubID })}
                              />
                            );
                          }
                          setShow(true);
                        }}
                      />
                    );
                  })}
                </div>
              )}

              {selected === "upcoming" && futureEvents.length === 0 && (
                <div className="no-msg">
                  <p>No items to display</p>
                </div>
              )}

              {selected === "past" && pastEvents.length > 0 && (
                <div className="ce-events">
                  {pastEvents.map((event, index) => {
                    if (selLeague !== "") {
                      if (selLeague !== event.leagueID) {
                        return null;
                      }
                    }
                    if (searchInput !== "") {
                      const sl = searchInput.toLowerCase();
                      const el = event.eventName.toLowerCase();
                      if (!el.includes(sl)) {
                        return null;
                      }
                    }

                    if (event.team) {
                    }

                    return (
                      <FixturePreview
                        showLive={event.roundIDs.length > 0}
                        key={event.eventID}
                        event={event}
                        hidePerson
                        hideRounds
                        timeAction={() => {
                          setToShow(
                            <EventRounds
                              eventID={event.eventID}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }}
                        personAction={() => {
                          setToShow(
                            <InviteModal
                              reload={() => loadEvents({ clubID })}
                              hide={() => setShow(false)}
                              eventID={event.eventID}
                            />
                          );
                          setShow(true);
                        }}
                        editAction={() => {
                          if (event.team) {
                            setToShow(
                              <TeamLeagueEventHome
                                goBack={() => setShow(false)}
                                eventID={event.eventID}
                              />
                            );
                          } else {
                            setToShow(
                              <EventHome
                                eventID={event.eventID}
                                goBack={() => setShow(false)}
                                reload={() => loadEvents({ clubID })}
                              />
                            );
                          }
                          setShow(true);
                        }}
                      />
                    );
                  })}
                </div>
              )}

              {selected === "past" && pastEvents.length === 0 && (
                <div className="no-msg">
                  <p>No items to display</p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ClubEvents;
