import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { makeID, successIco } from "../HelperFunctions";
import "./ClientDrills.css";
// Icons

// Components
import PageHeader from "../../display/Rows/PageHeader";
import CPLoader from "../CPLoader";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";

function DrillScoreInput({
  drill = {},
  close = () => {},
  clubID = "",
  clientID = "",
  edit = false,
  defaults = {},
  clientName = "",
  reload = () => {},
}) {
  // Admin

  // State
  const [loading, setLoading] = useState();
  const [score, setScore] = useState(0);
  const [saved, setSaved] = useState(false);
  const [dateString, setDateString] = useState("");

  // UE

  useEffect(() => {
    console.log(clubID);
    if (edit) {
      setScore(defaults.score);
      const ds = defaults.dateObject.toLocaleDateString("en-us", {
        dateStyle: "medium",
      });
      setDateString(ds);
    }
  }, []);

  // Funcs
  async function saveScore() {
    setLoading(true);
    try {
      const attempt = {
        date: new Date(),
        clubID,
        clientID,
        score,
        title: drill.title,
        perfectScore: drill.perfectScore,
        category: drill.category,
        difficulty: drill.difficulty,
        attemptID: makeID(10),
        drillID: drill.drillID,
        clientName,
      };

      const acDrillDoc = await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drill.drillID)
        .get();
      const acDrill = acDrillDoc.data();

      const { attempts } = acDrill;

      attempts.push(attempt);

      attempts.sort((a, b) => b.score - a.score);

      await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drill.drillID)
        .update({
          attempts,
          highScore: attempts[0].score,
        });

      if (!drill.custom) {
        const drillDoc = await db
          .collection("admin")
          .doc("academy")
          .collection("drills")
          .doc(drill.drillID)
          .get();
        const drillData = drillDoc.data();

        const { attempts } = drillData;

        attempts.push(attempt);
        attempts.sort((a, b) => b.score - a.score);

        await db
          .collection("admin")
          .doc("academy")
          .collection("drills")
          .doc(drill.drillID)
          .update({
            attempts,
            highScore: attempts[0].score,
          });
      }
      toast.success("Score Input successfully captured", {
        icon: successIco,
      });
      setSaved(true);
      setLoading(false);
      close();
      reload();
    } catch (err) {
      console.log(err);
    }
  }

  async function saveChanges() {
    setLoading(true);
    console.log(drill);
    try {
      const acDrillDoc = await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drill.drillID)
        .get();
      const acDrill = acDrillDoc.data();

      const { attempts } = acDrill;

      for (let i = 0; i < attempts.length; i++) {
        const att = attempts[i];
        if (att.attemptID === defaults.attemptID) {
          attempts[i].score = parseInt(score);
        }
      }

      attempts.sort((a, b) => b.score - a.score);

      await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drill.drillID)
        .update({
          attempts,
          highScore: attempts[0].score,
        });

      if (!drill.custom) {
        const drillDoc = await db
          .collection("admin")
          .doc("academy")
          .collection("drills")
          .doc(drill.drillID)
          .get();
        const drillData = drillDoc.data();

        const { attempts } = drillData;

        for (let i = 0; i < attempts.length; i++) {
          const att = attempts[i];
          if (att.attemptID === defaults.attemptID) {
            attempts[i].score = parseInt(score);
          }
        }

        attempts.sort((a, b) => b.score - a.score);
        attempts.sort((a, b) => b.score - a.score);

        await db
          .collection("admin")
          .doc("academy")
          .collection("drills")
          .doc(drill.drillID)
          .update({
            attempts,
            highScore: attempts[0].score,
          });
      }
      toast.success("Score Input successfully captured", {
        icon: successIco,
      });
      setSaved(true);
      reload();
      close();
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="ds-input">
      <Dialog open onClose={close}>
        <div className="dsi-ctt pd-20">
          <PageHeader text="Drill Score Input" showClose close={close} />

          {loading ? (
            <CPLoader />
          ) : saved ? (
            <>
              <div className="ri-submitted">
                <div className="neh-box">
                  <h2 className="ne-header">Successfully saved</h2>
                </div>
                <div className="ri-sub-text">
                  <p>
                    This drill score was succesfully added to your client's
                    profile
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="pd-20">
              {edit && (
                <div className="input-group">
                  <p>Date</p>
                  <h3>{dateString}</h3>
                </div>
              )}

              <div className="input-group">
                <p>
                  Score Input
                  {drill.perfectScore && ` (max ${drill.perfectScore})`}
                </p>
                <input
                  value={score}
                  onChange={(e) => {
                    // console.log(drill.perfectScore);
                    if (drill.perfectScore !== "") {
                      if (e.target.value > drill.perfectScore) {
                        setScore(drill.perfectScore);
                      } else {
                        setScore(e.target.value);
                      }
                    } else {
                      setScore(e.target.value);
                    }
                  }}
                  min={0}
                  className="default-input"
                  type="number"
                  max={drill.perfectScore ?? 1000}
                />
              </div>
              <div className="flex-center mt-40 mb-20">
                <button
                  onClick={edit ? saveChanges : saveScore}
                  className="default-button med-btn shadow-md"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default DrillScoreInput;
