import React, { useEffect, useState } from "react";

import { Avatar } from "@mui/material";
import DeblurIcon from "@mui/icons-material/Deblur";

import { useAuth } from "../../../contexts/AuthContext";

const GradientBlurIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <DeblurIcon className="dg-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);

function PTSPlayer({
  player,
  setSelected = () => {},
  tees = [],
  lengths = {},
  yards = false,
  selected = "",
}) {
  const bgc = {
    black: "black",
    white: "white",
    red: "#A9363F",
    blue: "#379FDC",
    yellow: "yellow",
    gold: "#d4af37",
    Red: "#A9363F",
    green: "#1e7a69",
    Black: "black",
    Blue: "blue",
    purple: "purple",
  };

  const bc = {
    black: "black",
    white: "#C6C7C8",
    red: "#E24955",
    Red: "#e24955",
    blue: "#379FDC",
    yellow: "yellow",
    gold: "#d4af37",
    green: "#1e7a69",
  };

  // console.log(tees);

  return (
    <div className="pts-player">
      <div className="ptsp-left">
        <div className="pts-ava">
          <Avatar alt={player.name} src={player.profileImage} />
        </div>
        <div className="pts-name">
          <p>{player.name ?? `${player.firstName} ${player.lastName}`}</p>
          <div className="pts-hcp">
            <GradientBlurIcon />
            <h5>
              {player.handicap < 0 ? `+${-player.handicap}` : player.handicap}
            </h5>
          </div>
        </div>
      </div>
      <div className="pts-tees">
        {tees.map((tee) => {
          if (tee === "None") {
            return null;
          }

          return (
            <div
              key={tee}
              onClick={() => setSelected(tee)}
              className={selected === tee ? "pts-tee ptst-active" : "pts-tee"}
            >
              <div
                style={{
                  backgroundColor: bgc[tee],
                  borderColor: bc[tee],
                }}
                className="pts-circle"
              >
                <p
                  style={{
                    color: [
                      "white",
                      "yellow",
                      "back",
                      "front",
                      "front ",
                    ].includes(tee.toLowerCase())
                      ? "#232c2e"
                      : "white",
                  }}
                >
                  {tee}
                </p>
              </div>
              <h5>
                {lengths[tee]}
                {yards ? "yds" : "m"}
              </h5>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PTSPlayer;
