import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import "./BulkPlayerCreator.css";
import { auth, db } from "../../firebase";
import { useParams, useNavigate } from "react-router-dom";

// Icons

// Comps
import BackdropLoader from "../Loaders/BackdropLoader";
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import Pill from "../Buttons/Pill";
import BPCRow from "./BPCRow";
import BPCHeader from "./BPCHeader";
import ErrorModal from "../ErrorModal/ErrorModal";
import { errDefault, validateEmail } from "../HelperFunctions";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import { successIco } from "../ClientStats/HelperFunctions";
import classNames from "classnames";

function BulkPlayerCreator({
  goBack = () => {},
  defaultClubID = "",
  showBack = false,
  stdl = false,
}) {
  // Admin
  const rowProp = {
    firstName: "",
    lastName: "",
    handicap: 0,
    emailAddress: "",
    password: "",
    gender: "male",
    dob: "",
  };
  const { currentUser, userDetails } = useAuth();
  const navigate = useNavigate();
  // State
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState([rowProp]);
  const [update, setUpdate] = useState(0);
  const [error, setError] = useState("");
  const [complete, setComplete] = useState(false);
  const [rowError, setRowError] = useState();
  const [errorType, setErrorType] = useState("");
  const [clubID, setClubID] = useState(defaultClubID);

  const [showDialog, setShowDialog] = useState(false);

  const [existingAddresses, setExistingAddresses] = useState([]);

  const id = useParams().id;

  // UE
  useEffect(() => {
    console.log(defaultClubID);
    if (stdl) {
      setShowDialog(true);
      setClubID(id);
    }
  }, []);

  // Funcs
  async function validate() {
    setExistingAddresses([]);
    setRowError("");
    setErrorType("");

    for (let i = 0; i < players.length; i++) {
      const player = players[i];

      if (player.firstName === "") {
        setError("Please enter a first name value for row " + (i + 1));
        setRowError(i);
        setErrorType("firstName");
        return false;
      }

      if (player.lastName === "") {
        setError("Please enter a last name value for row " + (i + 1));
        setRowError(i);
        setErrorType("lastName");
        return false;
      }

      if (player.emailAddress === "") {
        setError("Please enter an email address for row " + (i + 1));
        setRowError(i);
        setErrorType("emailAddress");
        return false;
      }

      if (validateEmail(player.emailAddress) === false) {
        setError(
          "Please ensure the email address is formatted correctly for row " +
            (i + 1)
        );
        setRowError(i);
        setErrorType("emailAddress");
        return false;
      }

      if (player.password === "") {
        setError("Please enter a password for row " + (i + 1));
        setRowError(i);
        setErrorType("password");
        return false;
      }
      if (player.dob === "") {
        setError("Please enter a date of birth for row " + (i + 1));
        setRowError(i);
        setErrorType("dob");
        return false;
      }
    }

    const existArr = await checkEmails(players);

    if (existArr.length > 0) {
      for (let i = 0; i < existArr.length; i++) {
        const row = existArr[i];
        if (row.exists) {
          setError(
            "The email address for row " + (i + 1) + " is already in use."
          );
          setRowError(i);
          setErrorType("emailAddress");
          return false;
        }
      }
      setExistingAddresses(existArr);
    }

    console.log("valid");

    return true;
  }

  async function checkEmails(players = []) {
    const promises = players.map(async (player) => {
      const signInMethods = await auth.fetchSignInMethodsForEmail(
        player.emailAddress
      );
      return { email: player.emailAddress, exists: signInMethods.length > 0 };
    });

    const trig = await Promise.all(promises);

    for (let i = 0; i < trig.length; i++) {
      const res = trig[i];
      if (res.exists === true) {
        players[i].exists = true;
      } else {
        players[i].exists = false;
      }
    }
    // console.log(players);

    // const existArr = players.filter((pl) => pl.exists);
    return players;
  }

  async function savePlayers() {
    setLoading(true);

    if ((await validate()) === false) {
      return setLoading(false);
    }

    console.log("got past");

    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();

      const { city, state, country } = club;

      for (let i = 0; i < players.length; i++) {
        players[i].city = city;
        players[i].country = country;
        players[i].state = state;
        players[i].clubID = clubID;
        players[i].handicap = parseFloat(players[i].handicap);
        players[i].addedBy = currentUser.uid;
        players[i].lowHI = players[i].handicap;
        players[i].playerClubName = "";
        players[i].platform = "";
        players[i].platformID = "";
        players[i].hnaID = "";
      }
      console.log(players);
      await db.collection("admin").doc("items").collection("imports").add({
        dateAdded: new Date(),
        imports: players,
        userID: currentUser.uid,
      });
      toast.success("Successfully uploaded", {
        icon: successIco,
      });
      setComplete(true);
    } catch (err) {
      console.log(err);
      setError(errDefault);
    }
    setLoading(false);
  }

  function addRow() {
    setPlayers((c) => {
      c.push(rowProp);
      return c;
    });
    setUpdate((c) => c + 1);
  }

  return (
    <div
      className={classNames("bulk-pc", {
        "bpc-stdl": stdl,
      })}
    >
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        className="bpc-dia"
      >
        <div className="bd-ctt">
          <PageHeader
            text="Keep it going!"
            showClose
            close={() => setShowDialog(false)}
          />

          <div className="bdc-txt pd-20">
            <p>
              Add a few of your players to your club to finish your setup - you
              can always do this at a later stage.
            </p>
          </div>

          <div className="flex-center mt-40 mb-40">
            <button
              className="default-button"
              onClick={() => setShowDialog(false)}
            >
              Let's Go!
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}
      {showBack && <BackRow action={goBack} />}
      <PageHeader text="Bulk Player Creator" />
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}

      <div className="quick-links">
        <Pill text={"Add Row"} onClick={addRow} />
      </div>

      <div className="bpc-players">
        <div className="bs-subtle bss-white bpcb-box pd-20">
          {complete ? (
            <>
              <div className="bi-complete flex-center-col">
                <h1 className="big-green-header mt-40">
                  Successfully Uploaded
                </h1>
                <p className="mt-20 mb-40">
                  You will receive a notification once the process is complete
                </p>
              </div>
            </>
          ) : (
            <div className="bpc-box">
              <BPCHeader />
              {players.map((player, i) => {
                return (
                  <BPCRow
                    key={i}
                    player={player}
                    rowIndex={i}
                    setRow={setPlayers}
                    upd={() => setUpdate((c) => c + 1)}
                    errorRow={rowError}
                    errorType={errorType}
                  />
                );
              })}{" "}
            </div>
          )}
        </div>{" "}
      </div>

      <div className="flex-center mt-40">
        <button onClick={savePlayers} className="default-button">
          Save Players
        </button>
      </div>

      <div className="flex-center mt-20">
        <button
          onClick={() => {
            navigate("/");
          }}
          className="black-button default-button"
        >
          Skip
        </button>
      </div>
    </div>
  );
}

export default BulkPlayerCreator;
