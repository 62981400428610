import React from "react";

function CreditRow({ row = {} }) {
  return (
    <div className="credit-row">
      <div className="cr-item">
        <p>{row.dateString}</p>
      </div>
      <div className={`cr-item cri-${row.type}`}>
        <p style={{ textTransform: "capitalize" }}>{row.type}</p>
      </div>
      <div className="cr-item">
        <p>${row.amount}</p>
      </div>
      <div className="cr-item">
        <p>{row.balance}</p>
      </div>
      <div className="cr-item">
        <p>{row.details}</p>
      </div>
    </div>
  );
}

export default CreditRow;
