import React from "react";

function DisputeRowHeader() {
  return (
    <div className="dispute-row dr-header">
      <div className="dr-item">
        <p>Date</p>
      </div>
      <div className="dr-item">
        <p>Ref #</p>
      </div>
      <div className="dr-item">
        <p>Status</p>
      </div>
    </div>
  );
}

export default DisputeRowHeader;
