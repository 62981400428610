import React from "react";
import "./PlayerRow.css";
import { Avatar } from "@mui/material";
import { returnHandicap } from "../HelperFunctions";
import { RemoveCircle } from "@mui/icons-material";

function PlayerRow({
  name = "",
  profileImage = "",
  handicap = "",
  city = "",
  country = "",
  joined = "",
  team = "",
  showRemove = false,
  removeAction = () => {},
}) {
  return (
    <div className="player-row">
      <div className="pr-item">
        <Avatar src={profileImage} />
      </div>
      <div className="pr-player">
        <p>{name}</p>
      </div>
      <div className="pr-item pr-hcp">
        <p>{returnHandicap(handicap)}</p>
      </div>
      {team !== "" && (
        <div className="pr-item">
          {" "}
          <p>{team}</p>
        </div>
      )}
      {showRemove && (
        <div className="pr-item">
          {" "}
          <RemoveCircle
            onClick={removeAction}
            className="red-icon icon-cursor hgb-ico"
          />
        </div>
      )}
      {/* <div className="pr-item pr-city">
        <p>{city}</p>
      </div>
      <div className="pr-item pr-cou">
        <p>{country}</p>
      </div>
      <div className="pr-item pr-joi">
        <p>{joined}</p>
      </div> */}
    </div>
  );
}

export default PlayerRow;
