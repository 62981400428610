import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { useHelperClickTheme } from "../../contexts/HelperContext";

// Icons
import { KeyboardArrowDown } from "@mui/icons-material";

// Components
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useCookies } from "react-cookie";
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackdropLoader from "../Loaders/BackdropLoader";
import { Dialog, Tab, Tabs } from "@mui/material";
import Pill from "../Buttons/Pill";
import TeamLeagueEditor from "./TeamLeagueEditor";
import LeagueAddPlayer from "../Leagues/LeagueEdit/LeagueAddPlayer";
import LeagueEvents from "../Leagues/LeagueEdit/LeagueEvents";
import LeagueMembers from "../Leagues/LeagueMembers";
import LeagueTeamManager from "./LeagueTeamManager";
import TeamSeasonEditor from "./TeamSeasonEditor";
import TeamLeagueLog from "./TeamLeagueLog";
import LeagueSeasons from "../Leagues/LeagueEdit/LeagueSeasons";
import TeamLeagueEventEditor from "./TeamLeagueEventEditor";
import LogHeader from "../display/LeagueLogs/LogItem/LogHeader";
import LogItem from "../display/LeagueLogs/LogItem/LogItem";
import TeamLeagueStats from "./TeamLeagueStats";
import { Oval } from "react-loader-spinner";
import MenuSwitcher from "../MenuSwitcher/MenuSwitcher";
import TeamLeagueTableEditor from "./TeamLeagueTableEditor";

function TeamLeagueHome({}) {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }
  const showHelperClick = useHelperClickTheme();
  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  const leagueID = useParams().id;
  const [loading, setLoading] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingSeasons, setLoadingSeasons] = useState(true);

  const [active, setActive] = useState("overview");
  const [details, setDetails] = useState({});

  const [loadingPlayers, setLoadinPlayers] = useState(true);

  const { currentUser, userDetails } = useAuth();

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [showAdd, setShowAdd] = useState(false);

  const [seasons, setSeasons] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [shortBoard, setShortBoard] = useState([]);
  const [seasonMap, setSeasonMap] = useState({});
  const [seasonOpts, setSeasonOpts] = useState([]);
  const [selSeason, setSelSeason] = useState("");
  const [pointsTable, setPointsTable] = useState([]);
  const [teams, setTeams] = useState([]);

  const [toAdd, setToAdd] = useState([]);
  const [players, setPlayers] = useState([]);
  const [bbs, setBBS] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    if (userDetails) {
      loadDetails();
    }
  }, [userDetails]);

  async function loadDetails() {
    setLoading(true);
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    setBBS(league.betterballStableford ?? false);
    const currentLeagueSeason = league.seasonID;

    const events = await db
      .collection("teamEvents")
      .where("leagueID", "==", leagueID)
      .orderBy("eventDate")
      .get();
    const seasons = await db
      .collection("seasons")
      .where("leagueID", "==", leagueID)
      .orderBy("dateCreated")
      .get();

    const seasonMap = {};

    for (let i = 0; i < seasons.docs.length; i++) {
      const seasonDoc = seasons.docs[i];
      const seasonData = seasonDoc.data();
      const seasonID = seasonDoc.id;
      const { seasonName } = seasonData;
      seasonMap[seasonID] = seasonName;
    }

    const {
      leagueName,
      shortName,
      description,
      imageLink,
      country,
      city,
      playerIDs,
      state,
      clubID,
      seasonID,
      pointsTable,
      teamTable,
      seasonIDs,
      teams,
    } = league;
    for (let i = 0; i < teams.length; i++) {
      if (teams[i].leaguePoints === undefined) {
        teams[i].leaguePoints = 0;
      }
    }

    // console.log(teams);

    teams.sort((a, b) => {
      if (b.leaguePoints === a.leaguePoints && b.played !== a.played) {
        return b.played - a.played;
      } else if (b.leaguePoints === a.leaguePoints && b.played === a.played) {
        return b.won - a.won;
      } else {
        return b.leaguePoints - a.leaguePoints;
      }
    });
    setDetails({
      leagueName,
      imageLink,
      country,
      city,
      clubID,
      seasonID,
      pointsTable,
      teamTable,
      seasonIDs,
    });

    const addArr = [];
    const sarr = [];
    const playArr = [];

    const arr = [];
    const now = new Date();
    const farr = [];
    const parr = [];

    const ids = [...playerIDs];

    const { friends } = userDetails;

    for (let i = 0; i < friends.length; i++) {
      if (!ids.includes(friends[i])) {
        ids.push(friends[i]);
      }
    }

    if (clubID) {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();
      const { memberIDs } = club;
      for (let x = 0; x < memberIDs.length; x++) {
        const id = memberIDs[x];
        if (!ids.includes(id)) {
          ids.push(id);
        }
      }
    }

    ids.reverse();
    const teamEvtMap = {};

    // Events
    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      if (event.rounds.length === 0) {
        continue;
      }
      event.teamEvent = true;
      event.eventID = eventID;
      const dateObject = new Date(event.eventDate.toDate());
      const dateObj = new Date(event.eventDate.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      const seasonID = event.seasonID ?? "";
      const season = seasonMap[seasonID] ?? "";
      event.season = season;
      event.dateObject = dateObject;
      event.dateString = dateString;

      if (event.completed && event.rounds.length > 0) {
        const matchups = event.matchups ?? [];
        for (let j = 0; j < matchups.length; j++) {
          const matchup = matchups[j];
          // console.log(matchup);
          const { teamOne, teamTwo } = matchup;
          const { conceded, winner } = matchup;
          if (conceded) {
            // console.log("CEEEDEEEED");
          }

          const format = event.rounds[0].format;

          let t1Score = teamOne.score;
          let t2Score = teamTwo.score;
          if (conceded) {
            if (event.betterballStableford) {
              if (winner === teamOne.teamID) {
                t1Score = 2.5;
                t2Score = 1.5;
              } else {
                t1Score = 1.5;
                t2Score = 2.5;
              }
            } else {
              if (format === "Matchplay") {
                if (winner === teamOne.teamID) {
                  t1Score = 3.5;
                  t2Score = 2.5;
                } else {
                  t1Score = 2.5;
                  t2Score = 3.5;
                }
              } else {
                if (winner === teamOne.teamID) {
                  t1Score = 1.5;
                  t2Score = 0.5;
                } else {
                  t1Score = 0.5;
                  t2Score = 1.5;
                }
              }
            }
          }

          if (conceded) {
            let loserID = "";
            if (winner === teamOne.teamID) {
              loserID = teamTwo.teamID;
            } else {
              loserID = teamOne.teamID;
            }
          }

          if (event.seasonID === currentLeagueSeason) {
            if (teamEvtMap[teamOne.teamID]) {
              teamEvtMap[teamOne.teamID].push({
                dateObj,
                dateString,
                oppo: teamTwo.name,
                score: t1Score,
                oppoScore: t2Score,
              });
            } else {
              teamEvtMap[teamOne.teamID] = [
                {
                  dateObj,
                  dateString,
                  oppo: teamTwo.name,
                  score: t1Score,
                  oppoScore: t2Score,
                },
              ];
            }

            if (teamEvtMap[teamTwo.teamID]) {
              teamEvtMap[teamTwo.teamID].push({
                dateObj,
                dateString,
                oppo: teamOne.name,
                score: teamTwo.score,
                oppoScore: teamOne.score,
              });
            } else {
              teamEvtMap[teamTwo.teamID] = [
                {
                  dateObj,
                  dateString,
                  oppo: teamOne.name,
                  score: teamTwo.score,
                  oppoScore: teamOne.score,
                },
              ];
            }
          }
        }
      }

      arr.push(event);

      if (dateObject > now) {
        farr.push(event);
      } else {
        parr.push(event);
      }
    }
    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const { teamID } = team;
      const results = teamEvtMap[teamID] ?? [];
      teams[i].results = results;
    }

    // console.log(teams);

    setTeams(teams ?? []);
    setPointsTable(pointsTable ?? []);

    const sortedParr = parr.sort((a, b) => b.dateObject - a.dateObject);
    setPastEvents(sortedParr);
    setFutureEvents(farr);
    setLoadingEvents(false);

    const smep = {};
    const seasOpts = [];

    // Seasons
    for (let i = 0; i < seasons.docs.length; i++) {
      const teamEvtMap = {};
      const seasonDoc = seasons.docs[i];
      const seasonID = seasonDoc.id;

      const season = seasonDoc.data();
      const teams = season.teams ?? [];
      const dcObject = new Date(season.dateCreated.toDate());
      const dcString = dcObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      season.dcObject = dcObject;
      season.dcString = dcString;
      season.seasonID = seasonID;
      seasOpts.push({
        label: season.seasonName,
        value: seasonID,
      });
      if (seasonID === league.seasonID) {
        const board = season.pointsTable ?? [];
        const sorted = board.sort((a, b) => (a.points > b.points ? -1 : 1));
        const shortboard = sorted.slice(0, 8);
        setShortBoard(shortboard);
      }

      for (let i = 0; i < events.docs.length; i++) {
        const eventDoc = events.docs[i];
        const eventID = eventDoc.id;
        const event = eventDoc.data();
        if (event.rounds.length === 0) {
          continue;
        }
        event.teamEvent = true;
        event.eventID = eventID;
        const dateObject = new Date(event.eventDate.toDate());
        const dateObj = new Date(event.eventDate.toDate());
        const dateString = dateObject.toLocaleDateString("en-us", {
          dateStyle: "full",
        });
        event.dateObject = dateObject;
        event.dateString = dateString;

        if (event.completed && event.rounds.length > 0) {
          const matchups = event.matchups ?? [];
          for (let j = 0; j < matchups.length; j++) {
            const matchup = matchups[j];
            // console.log(matchup);
            const { teamOne, teamTwo } = matchup;
            const { conceded, winner } = matchup;
            if (conceded) {
              // console.log("CEEEDEEEED");
            }

            const format = event.rounds[0].format;

            let t1Score = teamOne.score;
            let t2Score = teamTwo.score;
            if (conceded) {
              if (event.betterballStableford) {
                if (winner === teamOne.teamID) {
                  t1Score = 2.5;
                  t2Score = 1.5;
                } else {
                  t1Score = 1.5;
                  t2Score = 2.5;
                }
              } else {
                if (format === "Matchplay") {
                  if (winner === teamOne.teamID) {
                    t1Score = 3.5;
                    t2Score = 2.5;
                  } else {
                    t1Score = 2.5;
                    t2Score = 3.5;
                  }
                } else {
                  if (winner === teamOne.teamID) {
                    t1Score = 1.5;
                    t2Score = 0.5;
                  } else {
                    t1Score = 0.5;
                    t2Score = 1.5;
                  }
                }
              }
            }

            if (conceded) {
              let loserID = "";
              if (winner === teamOne.teamID) {
                loserID = teamTwo.teamID;
              } else {
                loserID = teamOne.teamID;
              }
            }

            if (event.seasonID === seasonID) {
              if (teamEvtMap[teamOne.teamID]) {
                teamEvtMap[teamOne.teamID].push({
                  dateObj,
                  dateString,
                  oppo: teamTwo.name,
                  score: t1Score,
                  oppoScore: t2Score,
                });
              } else {
                teamEvtMap[teamOne.teamID] = [
                  {
                    dateObj,
                    dateString,
                    oppo: teamTwo.name,
                    score: t1Score,
                    oppoScore: t2Score,
                  },
                ];
              }

              if (teamEvtMap[teamTwo.teamID]) {
                teamEvtMap[teamTwo.teamID].push({
                  dateObj,
                  dateString,
                  oppo: teamOne.name,
                  score: teamTwo.score,
                  oppoScore: teamOne.score,
                });
              } else {
                teamEvtMap[teamTwo.teamID] = [
                  {
                    dateObj,
                    dateString,
                    oppo: teamOne.name,
                    score: teamTwo.score,
                    oppoScore: teamOne.score,
                  },
                ];
              }
            }
          }
        }
      }
      console.log(teamEvtMap);
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        const { teamID } = team;
        const results = teamEvtMap[teamID] ?? [];
        teams[i].results = results;
      }

      season.teams = teams;

      smep[seasonID] = season;
      sarr.push(season);
    }
    console.log(sarr);
    setSeasonMap(smep);
    setSeasonOpts(seasOpts);

    setSeasons(sarr);
    setLoadingSeasons(false);
    setSelSeason(seasOpts[seasOpts.length - 1]);

    setLoading(false);

    const refs = ids.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );

    const playerDocs = await Promise.all(refs);

    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const playerID = playerDoc.id;
      if (!playerDoc.exists) {
        continue;
      }
      const player = playerDoc.data();
      const { firstName, lastName, profileImage, handicap } = player;
      if (playerIDs.includes(playerID)) {
        playArr.push({
          name: `${firstName} ${lastName}`,
          handicap,
          playerID,
          profileImage,
          firstName,
          lastName,
        });
      } else {
        addArr.push({
          name: `${firstName} ${lastName}`,
          handicap,
          playerID,
          profileImage,
          firstName,
          lastName,
        });
      }
    }

    setToAdd(addArr);
    setPlayers(playArr);
    setLoadinPlayers(false);
  }
  return (
    <div className="team-league-home league-edit">
      {showAdd && (
        <LeagueAddPlayer
          hide={() => setShowAdd(false)}
          leagueID={leagueID}
          teamLeague
          players={toAdd}
          reload={loadDetails}
        />
      )}
      {show ? (
        toShow
      ) : (
        <>
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          {loading && <BackdropLoader />}
          <BackRow action={goBack} />

          <div className="le-top">
            <div className="let-left">
              <div className="ph-me-opt">
                <PageHeader text={details.leagueName} />

                <MenuSwitcher />
              </div>

              <div className="ph-msg">
                <p>
                  {details.city}, {details.country}
                </p>
                {/* <p>{details.type} League</p> */}
              </div>
              <div className="tabs-container mt-20">
                <Tabs
                  value={active}
                  onChange={(e, newValue) => {
                    setActive(newValue);
                  }}
                >
                  <Tab label="Overview" value={"overview"} />
                  <Tab label="Events" value={"events"} />
                  <Tab label="Seasons" value={"seasons"} />
                  <Tab label="Members" value={"members"} />
                  <Tab label="Logs" value={"logs"} />
                  <Tab label="Stats" value={"stats"} />
                </Tabs>
              </div>
            </div>
            <div className="let-right">
              {cookies["menu-type"] === "drop" && (
                <>
                  <div className="flex flex-align-center mb-20 mt-20">
                    <div className="ddm-box pl-20">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                          <button className="ddm-but">
                            <span>General</span>
                            <KeyboardArrowDown className="ddm-ico" />
                          </button>
                        </DropdownMenu.Trigger>

                        <DropdownMenu.Portal>
                          <DropdownMenu.Content
                            className="DropdownMenuContent"
                            sideOffset={5}
                          >
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <TeamLeagueEditor
                                    defaultClubID={details.clubID}
                                    edit
                                    goBack={() => setShow(false)}
                                    reload={loadDetails}
                                    teamLeagueID={leagueID}
                                  />
                                );
                                setShow(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Edit Details
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                              onClick={() => {
                                window.open(
                                  `https://www.theugatour.com/leagues/${leagueID}`,
                                  "_blank"
                                );
                              }}
                              className="DropdownMenuItem"
                            >
                              Public Page
                            </DropdownMenu.Item>

                            {details.seasonIDs && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setToShow(
                                    <TeamLeagueEventEditor
                                      leagueID={leagueID}
                                      leagueName={details.leagueName}
                                      goBack={() => setShow(false)}
                                    />
                                  );
                                  setShow(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                New Event
                              </DropdownMenu.Item>
                            )}
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <TeamSeasonEditor
                                    defaultSeasonIndex={seasons.length + 1}
                                    defaultLeagueName={details.leagueName}
                                    edit={false}
                                    goBack={() => setShow(false)}
                                    leagueID={leagueID}
                                    reload={loadDetails}
                                    seasons={seasons}
                                  />
                                );
                                setShow(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              New Season
                            </DropdownMenu.Item>
                            {true && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setToShow(
                                    <TeamLeagueTableEditor
                                      goBack={() => setShow(false)}
                                      leagueID={leagueID}
                                      reload={loadDetails}
                                    />
                                  );
                                  setShow(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Edit Table
                              </DropdownMenu.Item>
                            )}
                          </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                      </DropdownMenu.Root>
                    </div>
                    <div className="ddm-box pl-10">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                          <button className="ddm-but">
                            <span>Players</span>
                            <KeyboardArrowDown className="ddm-ico" />
                          </button>
                        </DropdownMenu.Trigger>

                        <DropdownMenu.Portal>
                          <DropdownMenu.Content
                            className="DropdownMenuContent"
                            sideOffset={5}
                          >
                            <DropdownMenu.Item
                              onClick={() => {
                                setShowAdd(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Add Players
                            </DropdownMenu.Item>

                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <LeagueTeamManager
                                    goBack={() => {
                                      setShow(false);
                                      showHelperClick("team-league-home");
                                    }}
                                    leagueID={leagueID}
                                  />
                                );
                                setShow(true);
                                showHelperClick("team-league-teams");
                              }}
                              className="DropdownMenuItem"
                            >
                              Manage Teams
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                      </DropdownMenu.Root>
                    </div>
                  </div>
                </>
              )}
              {cookies["menu-type"] !== "drop" && (
                <>
                  <div className="sub-header">
                    <h5>Quick Links</h5>
                  </div>
                  <div className="quick-links">
                    <Pill
                      text={"Edit Details"}
                      onClick={() => {
                        setToShow(
                          <TeamLeagueEditor
                            defaultClubID={details.clubID}
                            edit
                            goBack={() => setShow(false)}
                            reload={loadDetails}
                            teamLeagueID={leagueID}
                          />
                        );
                        setShow(true);
                      }}
                    />
                    <Pill
                      text={"Add Players"}
                      onClick={() => setShowAdd(true)}
                    />
                    <Pill
                      text="Manage Teams"
                      onClick={() => {
                        setToShow(
                          <LeagueTeamManager
                            goBack={() => {
                              setShow(false);
                              showHelperClick("");
                            }}
                            leagueID={leagueID}
                          />
                        );
                        setShow(true);
                      }}
                    />
                    {details.seasonIDs && (
                      <Pill
                        text="New Event"
                        onClick={() => {
                          setToShow(
                            <TeamLeagueEventEditor
                              leagueID={leagueID}
                              leagueName={details.leagueName}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                    <Pill
                      text={"New Season"}
                      onClick={() => {
                        setToShow(
                          <TeamSeasonEditor
                            defaultSeasonIndex={seasons.length + 1}
                            defaultLeagueName={details.leagueName}
                            edit={false}
                            goBack={() => setShow(false)}
                            leagueID={leagueID}
                            reload={loadDetails}
                            seasons={seasons}
                          />
                        );
                        setShow(true);
                      }}
                    />
                    <Pill
                      text={"Public Page"}
                      onClick={() =>
                        window.open(
                          `https://www.theugatour.com/leagues/${leagueID}`,
                          "_blank"
                        )
                      }
                    />
                    {true && (
                      <Pill
                        text={"Edit Table"}
                        onClick={() => {
                          setToShow(
                            <TeamLeagueTableEditor
                              goBack={() => setShow(false)}
                              leagueID={leagueID}
                              reload={loadDetails}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {active === "overview" && (
            <div className="pd-20">
              <div className="hd-item">
                <div className="tlh-log">
                  <LogHeader showTies bbs={bbs} />
                  {teams.map((team, index) => {
                    const lps = team.leaguePoints ?? 0;

                    return (
                      <LogItem
                        ties={bbs ? team.won : team.tied}
                        key={team.teamID}
                        name={team.teamName}
                        played={team.played}
                        wins={
                          bbs && isNaN(team.stableFor)
                            ? 0
                            : bbs
                            ? team.stableFor
                            : team.won
                        }
                        points={team.leaguePoints ?? 0}
                        ave={
                          bbs && isNaN(team.stableAg)
                            ? 0
                            : bbs
                            ? team.stableAg
                            : team.played === 0
                            ? 0
                            : Math.round((lps / team.played) * 100) / 100
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {active === "events" && (
            <LeagueEvents
              pastEvents={pastEvents}
              loading={loading}
              setShow={setShow}
              setToShow={setToShow}
              futureEvents={futureEvents}
              teamLeague
            />
          )}
          {active === "members" && (
            <>
              {loadingPlayers ? (
                <>
                  <div className="flex-center mb-20 sis-loading">
                    <Oval
                      color="#1e7a69"
                      secondaryColor="#ffffff"
                      height={40}
                      width={40}
                    />
                  </div>
                </>
              ) : (
                <LeagueMembers
                  teamLeague
                  leagueID={leagueID}
                  reload={loadDetails}
                  players={players}
                />
              )}
            </>
          )}

          {active === "logs" && (
            <>
              {seasonOpts.length > 1 && (
                <div className="input-group pl-20">
                  <Dropdown
                    value={selSeason}
                    options={seasonOpts}
                    onChange={(e) => {
                      // console.log(e);
                      const seasonID = e.value;
                      console.log(seasonMap[seasonID]);
                      const season = seasonMap[seasonID];
                      setTeams(season.teams);
                      setPointsTable(season.pointsTable);
                    }}
                  />
                </div>
              )}
              <TeamLeagueLog
                leagueTitle={details.leagueName}
                pointsTable={pointsTable}
                teamTable={teams}
                bbs={bbs}
              />
            </>
          )}

          {active === "stats" && (
            <TeamLeagueStats
              leagueTitle={details.leagueName}
              leagueID={leagueID}
              seasonID={details.seasonID}
            />
          )}

          {active === "seasons" && (
            <LeagueSeasons
              setShow={setShow}
              setToShow={setToShow}
              reload={loadDetails}
              seasons={seasons}
              teamLeague
              activeSeasonID={details.seasonID}
            />
          )}
        </>
      )}
    </div>
  );
}

export default TeamLeagueHome;
