import React, { useState } from "react";
import PaystackPop from "@paystack/inline-js";

import ttl from "../../assets/images/ttlogowide.png";
import HomeLandingItem from "../HomeLanding/HomeLandingItem";
import ConfirmModal from "../ClientStats/ConfirmModal";
import { AccountCircle } from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";
import { admin } from "../../firebase";
import BackdropLoader from "../Loaders/BackdropLoader";
import BackdropProgLoader from "../Loaders/BackdropProgLoader";

function InactivePage() {
  // Admin
  const item = {
    title: "Re-activate",
    subtitle: "Reactivate your account",
    text: <p>Click here to get your account up and running again</p>,
    ico: (
      <>
        <AccountCircle className="hl-ico" />
      </>
    ),
    action: () => {
      setShowConfirm(true);
    },
  };
  const { currentUser, userDetails, portalSettings } = useAuth();
  const generatePSSub = admin.functions().httpsCallable("paystackSig");

  // State
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  // Funcs
  async function callPaystack() {
    setShowConfirm(false);
    setLoading(true);
    const { firstName, lastName, emailAddress } = userDetails;

    const res = await generatePSSub({
      emailAddress,
      clubID: portalSettings.clubID,
    });
    const { data } = res;
    const access_code = data.data.access_code;
    // const popup = new Paystack();
    // popup.
    const popup = new PaystackPop();
    popup.resumeTransaction({
      access_code,
    });
    // popup.resumeTransaction(access_code,);
    setLoading(false);
  }

  return (
    <div className="inactive-page home-landing">
      {showConfirm && (
        <ConfirmModal
          confirm={callPaystack}
          close={() => setShowConfirm(false)}
          text="To re-activate your account you will need to submit your credit card details again. Click Confirm to start the process"
        />
      )}
      {loading && <BackdropProgLoader />}
      <div className="hl-ctt">
        <div className="hl-logo">
          <img alt="logo" src={ttl} />
        </div>
        <div className="hl-header">
          <h1>Account Inactive</h1>
          <div className="aboh-border"></div>
        </div>
        <div className="hl-items">
          <HomeLandingItem item={item} />
        </div>
      </div>
    </div>
  );
}

export default InactivePage;
