import React, { useState, useEffect } from "react";
import "./StatsRoundInput.css";

import { db, admin } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import algoliasearch from "algoliasearch";
import { Country } from "country-state-city";
import { useAuth } from "../../../contexts/AuthContext";
import Geohash from "latlon-geohash";
import GeohashDistance from "geohash-distance";
import Geocode from "react-geocode";
import { geoFirestore } from "../../../firebase";

// Icons
import { IoGolfSharp } from "react-icons/io5";
import { VscError } from "react-icons/vsc";
import { TbArrowBearLeft, TbArrowBearRight } from "react-icons/tb";
import { FaRegCheckCircle } from "react-icons/fa";
import { CheckIcon } from "@radix-ui/react-icons";
import { SiTarget } from "react-icons/si";

// Components
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import BackdropLoader from "../../Loaders/BackdropLoader";
import SearchInput from "../SearchInput";
import CourseItem from "../../display/CourseItem/CourseItem";
import { ClipLoader } from "react-spinners";
import { Dialog } from "@mui/material";
import * as Checkbox from "@radix-ui/react-checkbox";
import BackRow from "../../display/Rows/BackRow";

const deetsProp = {
  backNineFIR: 0,
  backNineGIR: 0,
  frontNineFIR: 0,
  frontNineGIR: 0,
  totalFIR: 0,
  totalGIR: 0,
  frontNineGross: 0,
  backNineGross: 0,
  frontNineNet: 0,
  backNineNet: 0,
  backNinePar: 0,
  coursePar: 72,
  frontNinePar: 0,
  frontNinePutts: 0,
  backNinePutts: 0,
  totalPutts: 0,
  totalGrossStrokes: 0,
  totalNetStrokes: 0,
  // format: 0,
  // courseName: name,
  backNinePoints: 0,
  frontNinePoints: 0,
  currentScore: 0,
  currentGrossScore: 0,
  totalPoints: 0,
  totalModPoints: 0,
  startingPoints: 0,
  startingScore: 0,
};

const esOpts = [
  {
    title: "Distance to Pin",
    description:
      "Capture your distance to the pin when landing on the green in regulation.",
  },
  {
    title: "Driving Distance",
    description:
      "Capture your driving distances on all Par 4s & 5s. Whether you hit the fairway or not. So you may as well put your back out trying.",
  },
  {
    title: "Putt Length",
    description: "Capture your total distance in terms of putts made",
  },
  {
    title: "Greenside Bunkers",
    description:
      "Capture greenside bunkers hit to keep track of those sandies!",
  },
  {
    title: "Lost Balls",
    description:
      "Keep track of how many balls get lost during the course of the round",
  },
  // {
  //   title: "",
  //   description: "",
  // },
];

function StatsRoundInput({ goBack = () => {}, id = "" }) {
  // Admin
  const navigate = useNavigate();
  const searchClient = algoliasearch(
    "QF50LJZ12F",
    "729ebd0baad19471b95fc34f74b98202"
  );
  const index = searchClient.initIndex("uga_tour_courses");
  Geocode.setApiKey("AIzaSyB7IUK8lNigjp_8wBSRJYpKQX6ueT8H7D8");
  Geocode.setLocationType("ROOFTOP");

  const { userDetails } = useAuth();

  const [loading, setLoading] = useState(false);
  const [loadingClose, setLoadingClose] = useState(true);

  const [teeOpts, setTeeOpts] = useState([]);
  const [courseDetails, setCourseDetails] = useState({});
  const [search, setSearch] = useState("");
  const [choseCourse, setChoseCourse] = useState(false);
  const [courses, setCourses] = useState([]);
  const [closeCourses, setCloseCourses] = useState([]);
  const [selCourse, setSelCourse] = useState("");

  const [showDia, setShowDia] = useState(false);

  const [details, setDetails] = useState(deetsProp);
  const [error, setError] = useState("");

  const [upd, setUpd] = useState(0);

  const [selTee, setSelTee] = useState();
  const [hcp, setHcp] = useState(10);
  const [playHcp, setPlayHcp] = useState();

  const [courseHoles, setCourseHoles] = useState([]);
  const [holes, setHoles] = useState([]);

  const [academyID, setAcademyID] = useState("");

  const [enabledStats, setEnabledStats] = useState([]);

  const [submitted, setSubmitted] = useState(false);
  const [user, setUser] = useState("");
  const [eventRound, setEventRound] = useState(false);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    loadCourses();
    loadUser();
  }, []);

  async function algoliCourse() {
    const arr = [];
    setLoading(true);
    index
      .search(search, {
        hitsPerPage: 100,
      })
      .then(async ({ hits }) => {
        const algoArr = [];
        const linkMap = hits.map((hit) =>
          db.collection("courses").doc(hit.objectID).get()
        );
        const courseDocs = await Promise.all(linkMap);
        for (let n = 0; n < hits.length; n++) {
          const algoCourse = hits[n];
          // console.log(algoCourse);
          const { objectID, name, imageLink, rating, city, country, state } =
            algoCourse;
          const courseDoc = courseDocs[n];
          const courseData = courseDoc.data();
          if (!courseDoc.exists) {
            continue;
          }
          const {
            roundArray,
            tees,
            length,
            par,
            teeArray,
            address,
            location,
            firstNine,
            lastNine,
          } = courseData;

          algoArr.push({
            courseID: objectID,
            name,
            imageLink,
            rating,
            city,
            country,
            state,
            roundArray,
            tees,
            length,
            par,
            teeArray,
            address,
            location,
            firstNine,
            lastNine,
          });
        }
        const userCountry = Country.getCountryByCode(userDetails.country);
        const uc = userCountry.name;
        algoArr.sort((a, b) => {
          if (uc === b.country) {
            return 1;
          } else {
            return -1;
          }
        });
        console.log(uc, algoArr);
        setCourses(algoArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function compareDistance(a, b) {
    // console.log(a,b)
    if (a.distance < b.distance) {
      return -1;
    }
    if (a.distance > b.distance) {
      return 1;
    }
    return 0;
  }

  async function loadUser() {
    const userDoc = await db.collection("users").doc(id).get();
    const user = userDoc.data();
    const { firstName, lastName } = user;
    setUser(`${firstName} ${lastName}`);
    setHcp(Math.round(user.handicap));
  }

  async function loadCourses() {
    if (navigator.geolocation) {
      // console.log("here");

      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          // console.log(pos);
          const userHash = Geohash.encode(
            pos.coords.latitude,
            pos.coords.longitude,
            10
          );

          const center = new admin.firestore.GeoPoint(
            pos.coords.latitude,
            pos.coords.longitude
          );

          let city, state, country;
          const res = await Geocode.fromLatLng(
            pos.coords.latitude,
            pos.coords.longitude
          ).catch((err) => console.log(err));

          for (let i = 0; i < res.results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < res.results[0].address_components[i].types.length;
              j++
            ) {
              switch (res.results[0].address_components[i].types[j]) {
                case "locality":
                  city = res.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = res.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = res.results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }

          const geoColl = geoFirestore.collection("courses");
          const query = geoColl
            .near({
              center,
              radius: 100,
            })
            .limit(30);
          const courses = await query.get();

          const arr = [];
          const sarr = [];

          for (let i = 0; i < courses.docs.length; i++) {
            const courseID = courses.docs[i].id;
            const course = courses.docs[i].data();
            if (i === 0) {
              // console.log(course);
            }
            const {
              address,
              city,
              country,
              location,
              name,
              imageLink,
              teeArray,
              tees,
              par,
              length,
              firstNine,
              lastNine,
            } = course;
            const roundArray = course.roundArray ?? [];
            const totalReviews = course.totalReviews ?? 0;
            const rating = course.rating ?? 5;

            const hash = Geohash.encode(location._lat, location._long, 10);
            const distance =
              Math.round(GeohashDistance.inKm(userHash, hash) * 10) / 10;

            arr.push({
              address,
              city,
              country,
              rating,
              location,
              name,
              courseID,
              distance,
              imageLink,
              roundArray,
              teeArray,
              tees,
              par,
              totalReviews,
              length,
              firstNine,
              lastNine,
            });
          }
          arr.sort(compareDistance);
          setCloseCourses(arr);
          setLoadingClose(false);
        },
        (err) => console.log(err),
        { timeout: 5000, enableHighAccuracy: false }
      );
    }
  }

  function createPlayerScoreCard({ scoreCard, handicap, plusHoles = [] }) {
    console.log("creating normal scorecard");
    const holes = [];
    for (let i = 0; i < 18; i++) {
      const hole = scoreCard[i];
      const { par, stroke } = hole;
      let strokes;
      if (handicap >= 0) {
        if (handicap < stroke) {
          strokes = 0;
        } else {
          const strokeDiff = handicap - stroke;
          const ratio = Math.floor(strokeDiff / 18);
          strokes = ratio + 1;
        }
      } else {
        if (plusHoles.length > 0) {
          if (plusHoles.includes(i + 1)) {
            strokes = -1;
          } else {
            strokes = 0;
          }
        } else if (stroke - 18 > handicap) {
          strokes = -1;
        } else {
          strokes = 0;
        }
      }

      holes.push({
        strokes,
        puttLength: 0,
        grossScore: 0,
        netScore: 0,
        toPar: 0,
        lostBalls: 0,
        par: par,
        stroke,
        putts: 0,
        fir: false,
        netGir: false,
        gir: false,
        points: 0,
        modPoints: 0,
        bunker: false,
        drd: 0,
        dtp: 0,
      });
    }
    return holes;
  }

  function calcDetails() {}

  function totals(arr) {
    let fnGross = 0;
    let bnGross = 0;
    let fnNet = 0;
    let bnNet = 0;
    let tg = 0;
    let tn = 0;
    let fgir = 0;
    let bgir = 0;
    let tgir = 0;
    let fP = 0;
    let bP = 0;
    let tp = 0;
    let tpu = 0;
    let fpu = 0;
    let bpu = 0;

    let bfir = 0;
    let ffir = 0;

    let thru = 0;

    for (let i = 0; i < arr.length; i++) {
      const hole = arr[i];
      const { netScore, gir } = hole;
      let grossScore = isNaN(hole.grossScore) ? 0 : hole.grossScore;

      if (grossScore !== 0) {
        thru += 1;
      }

      if (i < 9) {
        fnGross += grossScore;
        fnNet += netScore;
        fP += hole.points;
        fpu += hole.putts;
        if (hole.fir) {
          ffir += 1;
        }
        if (gir) {
          fgir += 1;
        }
      } else {
        bnGross += grossScore;
        bnNet += netScore;
        bP += hole.points;
        bpu += hole.putts;
        if (hole.fir) {
          bfir += 1;
        }
        if (gir) {
          bgir += 1;
        }
      }

      if (gir) {
        tgir += 1;
      }

      tg += grossScore;
      tn += netScore;
      tp += hole.points;
      tpu += hole.putts;
    }

    console.log(bfir);

    setDetails((current) => {
      current.frontNineGross = fnGross;
      current.backNineGross = bnGross;
      current.frontNineNet = fnNet;
      current.backNineNet = bnNet;
      current.totalGrossStrokes = tg;
      current.totalNetStrokes = tn;
      current.backNineGIR = bgir;
      current.frontNineGIR = fgir;
      current.totalGIR = tgir;
      current.totalPoints = tp;
      current.backNinePoints = bP;
      current.frontNinePoints = fP;
      current.frontNinePutts = fpu;
      current.backNinePutts = bpu;
      current.totalPutts = tpu;
      current.backNineFIR = bfir;
      current.frontNineFIR = ffir;
      current.thru = thru;
      // console.log(current);
      return current;
    });

    setUpd((current) => current + 1);
  }

  function handleGrossChange(e, hole) {
    console.log(hole);
    // console.log(e.target.valueAsNumber, hole);
    // console.log("hurr");
    let bs = false;
    let score = e.target.valueAsNumber;
    console.log(score);
    if (isNaN(score)) {
      // score = 0;
      bs = true;
    }

    setHoles((current) => {
      const temp = [...current];
      const holeToChange = temp[hole];
      const strokesOnHole = holeToChange.strokes;
      const netScore = score === 0 || isNaN(score) ? 0 : score - strokesOnHole;
      const holePar = holeToChange.par;
      let puttsOnHole = holeToChange.putts;
      const toPar = score === 0 ? 0 : netScore - holePar;

      temp[hole].grossScore = score;
      temp[hole].netScore = netScore;
      temp[hole].toPar = toPar;

      if (temp[hole].putts === 0 && score !== 0) {
        temp[hole].putts = 2;
        puttsOnHole = 2;
      }

      if (score === 0 || isNaN(score)) {
        temp[hole].putts = 0;
      }

      if (score !== 0 && !isNaN(score)) {
        let points = 0;

        switch (toPar) {
          case toPar > 2:
            points = 0;
            break;
          case 2:
            points = 0;
            break;
          case 1:
            points = 1;
            break;
          case 0:
            points = 2;
            break;
          case -1:
            points = 3;
            break;
          case -2:
            points = 4;
            break;
          case toPar < -2:
            points = 5;
            break;
          default:
            break;
        }

        points = 2 - toPar;
        if (points < 0) {
          points = 0;
        }

        temp[hole].points = points;
      } else {
        temp[hole].points = 0;
      }

      // GIR
      console.log(puttsOnHole, holePar, score);
      if (score - puttsOnHole <= holePar - 2 && score !== 0) {
        temp[hole].gir = true;
      } else {
        temp[hole].gir = false;
      }
      totals(temp);
      return temp;
    });
    // setUpdate(current => current + 1);
  }

  async function createRound() {
    setLoading(true);
    setShowDia(false);
    try {
      const rdDoc = await db.collection("rounds").add({
        adjusted: false,
        admin: id,
        completed: false,
        courseID: courseDetails.courseID,
        courseImage: courseDetails.imageLink,
        courseName: courseDetails.name,
        customHandicaps: true,
        customRound: true,
        date: new Date(),
        enabledStats,
        feedItems: [],
        format: "medal",
        handicaps: { [`${id}`]: hcp },
        lastNine: courseDetails.lastNine,
        leaderboard: [
          {
            playerID: id,
            score: details.currentGrossScore,
            thru: 18,
            // name: userDetails.name,
            handicap: hcp,
          },
        ],
        par: courseDetails.par,
        playerIDs: [id],
        rules: [],
        scratchMode: false,
        submittedIDs: [],
        teeMap: {
          [`${id}`]: courseDetails.teeArray[0],
        },
        tees: courseDetails.teeArray[0],
        eventRound,
      });

      await rdDoc.collection("scores").doc(id).set({
        holes,
        notes,
        holesCompleted: 18,
        totalModPoints: 0,
        frontNinePar: details.frontNinePar,
        backNinePar: details.backNinePar,
        frontNineModPoints: 0,
        backNineModPoints: 0,
        totalPoints: details.totalPoints,
        frontNinePoints: details.frontNinePoints,
        backNinePoints: details.backNinePoints,
        frontNineNet: details.frontNineNet,
        backNineNet: details.backNineNet,
        frontNineGross: details.frontNineGross,
        backNineGross: details.backNineGross,
        frontNineGIR: details.frontNineGIR,
        backNineGIR: details.backNineGIR,
        frontNineFIR: details.frontNineFIR,
        backNineFIR: details.backNineFIR,
        frontNinePutts: details.frontNinePutts,
        backNinePutts: details.backNinePutts,
        totalNetStrokes: details.totalNetStrokes,
        totalGrossStrokes: details.totalGrossStrokes,
        eventPoints: 0,
        currentScore: details.currentScore,
        currentGrossScore: details.currentGrossScore,
        coursePar: details.coursePar,
        handicap: hcp,
        totalPutts: 0,
        stats: {},
        format: "medal",
        rankingsTracker: [],
        netTracker: [],
        grossTracker: [],
      });

      await rdDoc.update({
        submittedIDs: [id],
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there. Please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="rd-ipt sig-page">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <Dialog open={showDia} onClose={() => setShowDia(false)}>
        <div className="rd-ip-cof pd-20">
          <PageHeader
            showClose
            close={() => setShowDia(false)}
            text="Confirm Submission"
          />
          <div className="ri-sub-text">
            <p>
              Please confirm that you would like to submit this round. Please
              note that due to stats calculations scores cannot be changed once
              a round is submitted.
            </p>
          </div>

          <div className="flex-center">
            <button
              onClick={createRound}
              className="default-button med-btn shadow-md"
            >
              <p>Confirm</p>
            </button>
          </div>
        </div>
      </Dialog>
      <BackRow action={goBack} />
      <PageHeader
        showBack={choseCourse}
        back={() => setChoseCourse(false)}
        text="Round Input"
      />

      {submitted ? (
        <>
          <div className="ri-submitted">
            <div className="neh-box">
              <h2 className="ne-header">Successfully submitted</h2>
            </div>
            <div className="ri-sub-text">
              <p>
                Our statisticians are hard at work performing calculations, your
                this round will show up in the client's profile very shortly.
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          {!choseCourse ? (
            <div className="rdi-cc">
              {selCourse !== "" && (
                <div className=" flex flex-align-center rdi-search">
                  <div className="input-group mt-0 mr-20 pl-20">
                    <h2 className="ne-header">Handicap</h2>
                    <input
                      className="default-input"
                      value={hcp}
                      type="number"
                      onChange={(e) => setHcp(e.target.value)}
                    />
                  </div>

                  <button
                    onClick={() => {
                      setCourseDetails(selCourse);
                      const { teeArray, tees, firstNine, lastNine, par } =
                        selCourse;
                      const tee = teeArray[0];
                      const f = firstNine[tee];
                      const b = lastNine[tee];
                      const p = par[tee];
                      setDetails((c) => {
                        c.coursePar = p;
                        c.frontNinePar = f;
                        c.backNinePar = b;
                        return c;
                      });
                      const hls = createPlayerScoreCard({
                        scoreCard: tees[teeArray[0]],
                        plusHoles: selCourse.plusHoles ?? [],
                        handicap: hcp,
                      });
                      setHoles(hls);

                      setCourseHoles(tees[teeArray[0]]);
                      setChoseCourse(true);
                    }}
                    className="default-button small-but shadow-md"
                  >
                    Confirm
                  </button>
                </div>
              )}
              <div className="neh-box">
                <h2 className="ne-header">Search for course</h2>
              </div>
              <div className="flex flex-align-center rdi-search">
                <SearchInput
                  val={search}
                  setVal={setSearch}
                  keyAction={() => {
                    if (search.length > 2) {
                      algoliCourse();
                    }
                  }}
                />
                <button
                  onClick={algoliCourse}
                  disabled={search.length < 3}
                  className="default-button small-but ml-20"
                >
                  Search
                </button>
              </div>
              <div className="rdi-cc-res flex flex-wrap flex-align-center mt-20">
                {courses.map((course, i) => (
                  <CourseItem
                    image={course.imageLink}
                    course={course}
                    city={course.city}
                    country={course.country}
                    hideStar
                    courseName={course.name}
                    key={`search${course.courseID}`}
                    showBG={selCourse.courseID === course.courseID}
                    showCourse={() => setSelCourse(course)}
                  />
                ))}
              </div>
              <div className="pl-20 mt-20">
                <h2 className="ne-header">Nearby courses</h2>
              </div>
              <div className="rdi-cc-res flex flex-wrap flex-align-center">
                {closeCourses.map((course, i) => (
                  <CourseItem
                    image={course.imageLink}
                    course={course}
                    hideStar
                    city={course.city}
                    country={course.country}
                    courseName={course.name}
                    key={course.courseID}
                    showBG={selCourse.courseID === course.courseID}
                    showCourse={() => {
                      // alert("click");
                      setSelCourse(course);
                      setUpd((c) => c + 1);
                    }}
                  />
                ))}
              </div>
              {loadingClose && (
                <div className="cp-loader">
                  <ClipLoader color="#6ba390" size={40} className="btn-ico" />
                </div>
              )}{" "}
            </div>
          ) : (
            <div className="rsb-box">
              <div className="ri-sc-box">
                <div className="rs-ip-box">
                  <div className="input-group">
                    <p className="ne-header">Event Round</p>
                    <div className="cao-item">
                      <div
                        onClick={() => {
                          // alert("clack");
                          setEventRound((c) => !c);
                          setUpd((c) => c + 1);
                        }}
                        className="cao-item"
                      >
                        <Checkbox.Root
                          className="CheckboxRoot"
                          checked={eventRound}
                          // onCheckedChange={() => {
                          //   setSelected((c) => {
                          //     if (c.includes(att)) {
                          //       const filt = selected.filter((it) => it !== att);
                          //       return filt;
                          //     } else {
                          //       c.push(att);
                          //       return c;
                          //     }
                          //   });
                          //   update();
                          // }}
                        >
                          {" "}
                          <Checkbox.Indicator className="CheckboxIndicator">
                            {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                            {eventRound === true && <CheckIcon />}
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <p> Count this round towards your event stats</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="neh-box">
                  <h2 className="ne-header">Enabled Stats</h2>
                </div>
                <div className="es-box flex flex-wrap">
                  {esOpts.map((stat, i) => {
                    const checked = enabledStats.includes(stat.title);

                    return (
                      <div
                        onClick={() => {
                          // alert("clack");
                          setEnabledStats((c) => {
                            if (c.includes(stat.title)) {
                              const filt = c.filter((it) => it !== stat.title);
                              return filt;
                            } else {
                              c.push(stat.title);
                              return c;
                            }
                          });
                          setUpd((c) => c + 1);
                        }}
                        className="cao-item"
                        key={stat.title}
                      >
                        <Checkbox.Root
                          className="CheckboxRoot"
                          checked={enabledStats.includes(stat.title)}
                          // onCheckedChange={() => {
                          //   setSelected((c) => {
                          //     if (c.includes(att)) {
                          //       const filt = selected.filter((it) => it !== att);
                          //       return filt;
                          //     } else {
                          //       c.push(att);
                          //       return c;
                          //     }
                          //   });
                          //   update();
                          // }}
                        >
                          {" "}
                          <Checkbox.Indicator className="CheckboxIndicator">
                            {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                            {checked === true && <CheckIcon />}
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <p>{stat.title}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="rc-sc ri-sc">
                  <table>
                    <thead>
                      <tr>
                        <th className="rc-head-ico">
                          <IoGolfSharp />
                        </th>{" "}
                        <th>Par</th>
                        <th>Stroke</th>
                        <th>Strokes</th>
                        <th>Gross</th>
                        <th>Net</th>
                        <th>Points</th>
                        <th>Putts</th>
                        <th>GIR</th>
                        <th></th>
                        <th>Drive</th>
                        <th></th>
                        {enabledStats.includes("Greenside Bunkers") && (
                          <th>Bunker</th>
                        )}
                        {enabledStats.includes("Driving Distance") && (
                          <th>Driving Distance</th>
                        )}
                        {enabledStats.includes("Distance to Pin") && (
                          <th>Distance To Pin</th>
                        )}
                        {enabledStats.includes("Putt Length") && (
                          <th>Putt Length</th>
                        )}
                        {enabledStats.includes("Lost Balls") && (
                          <th>Lost Balls</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {courseHoles.map((courseHole, i) => {
                        const hole = holes[i];

                        return (
                          <React.Fragment key={`${i}hole`}>
                            {i === 9 && (
                              <tr
                                key={"first-out"}
                                className="header-row out-row sum-tr"
                              >
                                <td className="hole-td">OUT</td>
                                <td className="par-td">
                                  {details.frontNinePar}
                                </td>
                                <td></td>
                                <td></td>
                                <td className="normal-td bold-td">
                                  {details.frontNineGross}
                                </td>
                                <td className="normal-td bold-td">
                                  {details.frontNineNet}
                                </td>
                                <td className="normal-td">
                                  {details.frontNinePoints}
                                </td>
                                <td className="normal-td">
                                  {details.frontNinePutts}
                                </td>

                                <td className="normal-td">
                                  {details.frontNineGIR}
                                </td>
                                <td></td>
                                <td className="normal-td">
                                  {details.frontNineFIR}
                                </td>
                                <td></td>

                                {enabledStats.map((s, i2) => (
                                  <td key={`out ${i2}`}></td>
                                ))}
                              </tr>
                            )}

                            <tr className="rc-row">
                              <td>{i + 1}</td>
                              <td>{courseHole.par}</td>
                              <td>{courseHole.stroke}</td>
                              <td>{hole.strokes}</td>
                              <td>
                                <input
                                  onChange={(e) => handleGrossChange(e, i)}
                                  min={0}
                                  type="number"
                                  value={holes[i].grossScore}
                                  className="ri-ip"
                                />
                              </td>
                              <td
                                className={
                                  hole.netScore - hole.par > 0
                                    ? "score-td over-par"
                                    : hole.netScore - hole.par < 0 &&
                                      hole.grossScore > 0
                                    ? "score-td ud-par"
                                    : "score-td"
                                }
                              >
                                {hole.netScore}
                              </td>
                              <td
                                className={
                                  hole.netScore - hole.par > 0
                                    ? "score-td over-par"
                                    : hole.netScore - hole.par < 0 &&
                                      hole.grossScore > 0
                                    ? "score-td ud-par"
                                    : "score-td"
                                }
                              >
                                {hole.points}
                              </td>
                              <td>
                                <input
                                  min={0}
                                  value={holes[i].putts}
                                  className="ri-ip"
                                  type={"number"}
                                  onChange={(e) => {
                                    setHoles((current) => {
                                      current[i].putts = e.target.valueAsNumber;
                                      const hole = current[i];
                                      const score = hole.grossScore;
                                      const puttsOnHole = hole.putts;
                                      const holePar = hole.par;
                                      // GIR
                                      if (
                                        score - puttsOnHole <= holePar - 2 &&
                                        hole.grossScore !== 0
                                      ) {
                                        current[i].gir = true;
                                      } else {
                                        current[i].gir = false;
                                      }
                                      totals(current);

                                      return current;
                                    });
                                    setUpd((current) => current + 1);
                                  }}
                                />
                              </td>
                              <td>
                                <div className="ico-td">
                                  {hole.gir ? (
                                    <SiTarget className="succ-ico" />
                                  ) : (
                                    <VscError className="fail-ico" />
                                  )}
                                </div>
                              </td>
                              <td>
                                {hole.par !== 3 && (
                                  <div className="fit-box">
                                    <div
                                      onClick={() => {
                                        setHoles((c) => {
                                          c[i].firMiss = "left";
                                          c[i].fir = false;
                                          console.log(c);
                                          totals(c);

                                          return c;
                                        });
                                        setUpd((c) => c + 1);
                                      }}
                                      className={
                                        hole.firMiss === "left"
                                          ? "ico-td fir-ico-td fit-sel"
                                          : "ico-td fir-ico-td"
                                      }
                                    >
                                      {hole.firMiss === "left" ? (
                                        <TbArrowBearLeft className="sel-fir-ico fir-ico" />
                                      ) : (
                                        <TbArrowBearLeft className="fir-ico" />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>
                                {hole.par !== 3 && (
                                  <div className="fit-box">
                                    <div
                                      onClick={() => {
                                        setHoles((c) => {
                                          c[i].firMiss = "fir";
                                          c[i].fir = true;
                                          // console.log(c);
                                          totals(c);

                                          return c;
                                        });
                                        setUpd((c) => c + 1);
                                      }}
                                      className={
                                        hole.firMiss === "fir"
                                          ? "ico-td fir-ico-td fit-sel"
                                          : "ico-td fir-ico-td"
                                      }
                                    >
                                      {hole.firMiss === "fir" ? (
                                        <FaRegCheckCircle className="sel-fir-ico fir-ico" />
                                      ) : (
                                        <FaRegCheckCircle className="fir-ico" />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td>
                                {hole.par !== 3 && (
                                  <div className="fit-box">
                                    <div
                                      onClick={() => {
                                        setHoles((c) => {
                                          c[i].firMiss = "right";
                                          c[i].fir = false;
                                          console.log(c);
                                          totals(c);
                                          return c;
                                        });

                                        setUpd((c) => c + 1);
                                      }}
                                      className={
                                        hole.firMiss === "right"
                                          ? "ico-td fir-ico-td fit-sel"
                                          : "ico-td fir-ico-td"
                                      }
                                    >
                                      {hole.firMiss === "right" ? (
                                        <TbArrowBearRight className="sel-fir-ico fir-ico" />
                                      ) : (
                                        <TbArrowBearRight className="fir-ico" />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </td>
                              {enabledStats.includes("Greenside Bunkers") && (
                                <td>
                                  <div className="fit-box">
                                    <div
                                      onClick={() => {
                                        setHoles((c) => {
                                          // c[i].firMiss = "fir";
                                          c[i].bunker = !c[i].bunker;
                                          console.log(c);
                                          return c;
                                        });
                                        setUpd((c) => c + 1);
                                      }}
                                      className={
                                        hole.bunker
                                          ? "ico-td fir-ico-td fit-sel"
                                          : "ico-td fir-ico-td"
                                      }
                                    >
                                      {hole.bunker ? (
                                        <FaRegCheckCircle className="sel-fir-ico fir-ico" />
                                      ) : (
                                        <FaRegCheckCircle className="fir-ico" />
                                      )}
                                    </div>
                                  </div>
                                </td>
                              )}
                              {enabledStats.includes("Driving Distance") && (
                                <td>
                                  {hole.par !== 3 && (
                                    <input
                                      min={0}
                                      value={holes[i].drd}
                                      className="ri-ip"
                                      type={"number"}
                                      onChange={(e) => {
                                        setHoles((current) => {
                                          current[i].drd =
                                            e.target.valueAsNumber;

                                          return current;
                                        });
                                        setUpd((current) => current + 1);
                                      }}
                                    />
                                  )}
                                </td>
                              )}
                              {enabledStats.includes("Distance to Pin") && (
                                <td>
                                  {hole.gir && (
                                    <input
                                      min={0}
                                      value={holes[i].dtp}
                                      className="ri-ip"
                                      type={"number"}
                                      onChange={(e) => {
                                        setHoles((current) => {
                                          current[i].dtp =
                                            e.target.valueAsNumber;

                                          return current;
                                        });
                                        setUpd((current) => current + 1);
                                      }}
                                    />
                                  )}
                                </td>
                              )}
                              {enabledStats.includes("Putt Length") && (
                                <td>
                                  <input
                                    min={0}
                                    value={holes[i].puttLength}
                                    className="ri-ip"
                                    type={"number"}
                                    onChange={(e) => {
                                      setHoles((current) => {
                                        current[i].puttLength =
                                          e.target.valueAsNumber;

                                        return current;
                                      });
                                      setUpd((current) => current + 1);
                                    }}
                                  />
                                </td>
                              )}
                              {enabledStats.includes("Lost Balls") && (
                                <td>
                                  <input
                                    min={0}
                                    value={holes[i].lostBalls}
                                    className="ri-ip"
                                    type={"number"}
                                    onChange={(e) => {
                                      setHoles((current) => {
                                        current[i].lostBalls =
                                          e.target.valueAsNumber;

                                        return current;
                                      });
                                      setUpd((current) => current + 1);
                                    }}
                                  />
                                </td>
                              )}
                            </tr>

                            {i === 17 && (
                              <tr
                                key={"in-row"}
                                className="header-row in-row sum-tr"
                              >
                                <td className="hole-td">IN</td>
                                <td className="par-td">
                                  {details.backNinePar ?? 36}
                                </td>
                                <td></td>
                                <td></td>

                                <td className="normal-td">
                                  {details.backNineGross}
                                </td>
                                <td className="normal-td">
                                  {details.backNineNet}
                                </td>
                                <td className="normal-td">
                                  {details.backNinePoints}
                                </td>
                                <td className="normal-td">
                                  {details.backNinePutts}
                                </td>

                                <td className="normal-td">
                                  {details.backNineGIR}
                                </td>
                                <td></td>
                                <td className="normal-td">
                                  {details.backNineFIR}
                                </td>
                                <td></td>

                                {enabledStats.map((s, i2) => (
                                  <td key={`i ${i2}`}></td>
                                ))}
                              </tr>
                            )}
                            {i === 17 && (
                              <tr
                                key={"out-second"}
                                className="header-row out-row sum-tr"
                              >
                                <td className="hole-td">OUT</td>
                                <td className="par-td">
                                  {details.frontNinePar ?? 36}
                                </td>

                                <td></td>
                                <td></td>
                                <td className="normal-td">
                                  {details.frontNineGross}
                                </td>
                                <td className="normal-td">
                                  {details.frontNineNet}
                                </td>
                                <td className="normal-td">
                                  {details.frontNinePoints}
                                </td>
                                <td className="normal-td">
                                  {details.frontNinePutts}
                                </td>
                                <td className="normal-td">
                                  {details.frontNineGIR}
                                </td>
                                <td></td>
                                <td className="normal-td">
                                  {details.frontNineFIR}
                                </td>
                                <td></td>

                                {enabledStats.map((s, i2) => (
                                  <td key={`out2 ${i2}`}></td>
                                ))}
                              </tr>
                            )}
                            {i === 17 && (
                              <tr
                                key={"total"}
                                className="header-row sum-tr total-row"
                              >
                                <td className="hole-td">TOT</td>
                                <td className="par-td">{details.coursePar}</td>
                                <td></td>
                                <td></td>

                                <td className="player-td bold-td">
                                  {details.totalGrossStrokes}
                                </td>
                                <td className="player-td bold-td">
                                  {details.totalNetStrokes}
                                </td>
                                <td className="normal-td">
                                  {details.totalPoints}
                                </td>
                                <td className="normal-td">
                                  {details.totalPutts}
                                </td>
                                {/* {true && (
                              <>
                                <td className="normal-td"></td>
                                <td className="normal-td"></td>
                              </>
                            )} */}

                                <td className="normal-td">
                                  {details.frontNineGIR + details.backNineGIR}
                                </td>
                                <td className="normal-td"></td>
                                <td className="normal-td">
                                  {details.frontNineFIR + details.backNineFIR}
                                </td>
                                <td className="normal-td"></td>

                                {enabledStats.map((s, i2) => (
                                  <td key={`tot ${i2}`}></td>
                                ))}
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="sri-ta">
                  <div className="input-group">
                    <p>Round Notes</p>
                    <textarea
                      rows={10}
                      placeholder="Enter notes here"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      className="default-input"
                    />
                  </div>
                </div>
                {details.thru === 18 && (
                  <div className="flex-center mt-20 mb-20">
                    <button
                      onClick={() => setShowDia(true)}
                      className="default-button shadow-md"
                    >
                      <p>Save Round</p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default StatsRoundInput;
