import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import "./ClubCredits.css";

// Icons

// Components
import { Oval } from "react-loader-spinner";
import CreditRowHeader from "./CreditRowHeader";
import CreditRow from "./CreditRow";

function ClubCredits({ clubID = "" }) {
  // Admin

  // State
  const [loading, setLoading] = useState(true);
  const [credits, setCredits] = useState([]);
  const [toShow, setToShow] = useState();
  const [show, setShow] = useState(false);

  // UE
  useEffect(() => {
    loadCredits();
  }, []);

  // Functions
  async function loadCredits() {
    const clubCreditDocs = await db
      .collection("clubs")
      .doc(clubID)
      .collection("credits")
      .orderBy("date", "desc")
      .get();

    const arr = [];

    for (let i = 0; i < clubCreditDocs.docs.length; i++) {
      const ccDoc = clubCreditDocs.docs[i];
      const ccData = ccDoc.data();
      const dateObject = new Date(ccData.date.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "short",
      });

      ccData.amount = (ccData.amount / 100).toFixed(2);
      ccData.balance = (ccData.balance / 100).toFixed(2);

      ccData.dateString = dateString;
      ccData.dateObject = dateObject;
      arr.push(ccData);
    }
    setCredits(arr);
    setLoading(false);
  }

  return (
    <div className="club-credits">
      <div className="sub-header">
        <h3>Credit Transactions</h3>
      </div>
      <div className="acg-box">
        <div className="ac-graph bss-white">
          {loading ? (
            <>
              {" "}
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : show ? (
            toShow
          ) : (
            <>
              {<CreditRowHeader />}
              {credits.map((cred, i) => {
                return <CreditRow row={cred} key={`${i}cred`} />;
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClubCredits;
