import React, { useEffect, useState } from "react";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Country } from "country-state-city";
import { useHelperClickTheme } from "../../contexts/HelperContext";

// Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useCookies } from "react-cookie";

// Components
import BackdropProgress from "../../components/Loaders/BackdropProgress";
import PageHeader from "../../components/display/Rows/PageHeader";
import BackRow from "../../components/display/Rows/BackRow";
import Pill from "../../components/Buttons/Pill";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import ManualPlayerAdd from "../../components/Events/EventCreator/ManualPlayerAdd";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import { Close, KeyboardArrowDown, MoreHoriz } from "@mui/icons-material";
import PlayerIDs from "./PlayerIDs";
import BulkImport from "../../components/UserImport/BulkImport";
import UserImport from "../../components/UserImport/UserImport";
import algoliasearch from "algoliasearch/lite";
import RadioGroup from "../../components/Buttons/RadioGroup/RadioGroup";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import { Oval } from "react-loader-spinner";
import ClubListPlayer from "./ClubListPlayer";
import PlayerGroups from "../../components/PlayerGroups/PlayerGroups";
import HandicapUpload from "./HandicapUpload";
import BulkPlayerCreator from "../../components/BulkPlayerCreator/BulkPlayerCreator";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import MenuSwitcher from "../../components/MenuSwitcher/MenuSwitcher";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { successIco } from "../../components/ClientStats/HelperFunctions";
import { featuresProp } from "../../components/HelperFunctions";

function ClubPlayers({ goBack = () => {}, clubID = "", reload = () => {} }) {
  const navigate = useNavigate();
  const location = useLocation();
  const showHelperClick = useHelperClickTheme();

  const searchClient = algoliasearch(
    "QF50LJZ12F",
    "729ebd0baad19471b95fc34f74b98202"
  );
  const playerIndex = searchClient.initIndex("uga_tour_players");

  // State
  const [members, setMembers] = useState([]);
  const [memberIDs, setMemberIDs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [playerMap, setPlayerMap] = useState();
  const [searchedPlayers, setSearchedPlayers] = useState([]);

  const [menuType, setMenuType] = useState("pill");

  const [active, setActive] = useState("members");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [notFound, setNotFound] = useState("");
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }
  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState({
    name: "",
  });
  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  const [sorter, setSorter] = useState("lastName");
  const [sortDir, setSortDir] = useState("up");

  const [error, setError] = useState("");

  const [showManual, setShowManual] = useState(false);
  const [friends, setFriends] = useState([]);

  const { currentUser, userDetails, clubFeatures } = useAuth();
  const [features, setFeatures] = useState(featuresProp);

  const [searchInput, setSearchInput] = useState("");

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [prog, setProg] = useState(0);

  const [cid, setCid] = useState("");
  const { id } = useParams();

  const [csv, setCSV] = useState([]);

  const [showBulk, setShowBulk] = useState(true);
  const [showHCPUpload, setShowHCPUpload] = useState(true);

  useEffect(() => {
    if (clubFeatures) {
      console.log(clubFeatures);
      setFeatures(clubFeatures);
      // setShowBulk(clubFeatures.playerUploads ?? false);
      // setShowHCPUpload(clubFeatures.hcpUploads);
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      if (id) {
        loadMembers({ clubID: id });
        setCid(id);
      } else {
        loadMembers({ clubID });
        setCid(clubID);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (location.state) {
      if (location.state.showCreate) {
        setToShow(<UserImport clubID={id} goBack={() => setShow(false)} />);
        setShow(true);
      }
    }
  }, []);

  async function loadFriends({ playerIDs = [] }) {
    let { friends } = userDetails;
    friends = friends.filter((playerID) => !playerIDs.includes(playerID));
    // console.log(friends);
    const links = friends.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );
    const docs = await Promise.all(links);

    const arr = [];

    const mep = {};

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      if (!playerDoc.exists || player === undefined) {
        continue;
      }

      const { firstName, lastName, profileImage, handicap } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        playerID,
        profileImage,
        handicap,
        platformIDs: [],
        firstName,
        lastName,
      });
      mep[playerID] = {
        name: `${firstName} ${lastName}`,
        playerID,
        profileImage,
        handicap,
        platformIDs: [],
        firstName,
        lastName,
      };
    }
    setPlayerMap(mep);
    setFriends(arr);
  }

  async function loadMembers({ clubID = "" }) {
    setLoading(true);
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const { memberIDs } = club;
    loadFriends({ playerIDs: memberIDs });
    const datesJoined = club.datesJoined ?? {};
    setProg(25);

    const refs = memberIDs.map((uid) => db.collection("users").doc(uid).get());
    const docs = await Promise.all(refs);
    const arr = [];

    const csvArr = [];

    csvArr.push([
      "First Name",
      "Last Name",
      "Gender",
      "Handicap",
      "hnaID",
      "Club ID",
      "Low HI",
      "Email",
      "Date Joined",
      "DOB",
      "City",
      "Country",
      "State",
      "Club",
    ]);

    for (let i = 0; i < docs.length; i++) {
      setProg((i / docs.length) * 100);
      // console.log((i / docs.length) * 100);
      const playerDoc = docs[i];
      if (!playerDoc.exists) {
        continue;
      }
      const playerID = docs[i].id;

      const player = playerDoc.data();

      const {
        firstName,
        lastName,
        handicap,
        profileImage,
        emailAddress,
        city,
        country,
        state,
      } = player;
      const playerClubName = player.playerClubName ?? "";
      const platformIDs = player.platformIDs ?? [];
      const clubHNAID = player.clubHNAID ?? "";
      let dateJoined = "";
      let djObject = "";
      if (datesJoined[playerID]) {
        djObject = new Date(datesJoined[playerID].toDate());
        dateJoined = djObject.toLocaleDateString("en-us", {
          dateStyle: "medium",
        });
      }
      let lowHI = player.lowHI ?? "";
      if (isNaN(lowHI)) {
        lowHI = handicap;
      }
      // platformIDs.push({
      //   platform: "hnaID",
      //   platformID: player.hnaID ?? "",
      // });

      const dobObj = new Date(player.dob.toDate());
      const dobString = dobObj.toLocaleDateString("en-us", {
        dateStyle: "medium",
      });

      // console.log(country, playerID);

      csvArr.push([
        firstName,
        lastName,
        player.gender,
        handicap,
        player.hnaID ?? "",
        clubHNAID,
        lowHI,
        player.emailAddress,
        dateJoined,
        dobString,
        city,
        country && country.length === 2
          ? Country.getCountryByCode(player.country).name
          : country
          ? country
          : "",
        state,
        playerClubName,
      ]);

      arr.push({
        playerClubName,
        playerID,
        name: `${firstName} ${lastName}`,
        handicap,
        hnaID: player.hnaID ?? "",
        clubHNAID,
        profileImage,
        platformIDs,
        emailAddress,
        lowHI,
        firstName,
        dobObj,
        dobString,
        lastName,
        gender: player.gender ?? "Male",
        addedBy: player.addedBy ?? "",
        dateJoined,
        djObject,
        city,
        country:
          country && country.length === 2
            ? Country.getCountryByCode(player.country).name
            : country
            ? country
            : "",
        state,
      });
    }
    arr.sort((a, b) => a.lastName.localeCompare(b.lastName));

    const memIDs = arr.map((pl) => pl.playerID);
    setMemberIDs(memIDs);
    setCSV(csvArr);
    setMembers(arr);

    setLoading(false);
  }

  async function addMembers(playerIDs = []) {
    setLoading(true);
    try {
      await db
        .collection("clubs")
        .doc(cid)
        .update({
          memberIDs: admin.firestore.FieldValue.arrayUnion(...playerIDs),
        });
      setMembers((current) => {
        for (let i = 0; i < playerIDs.length; i++) {
          current.push(playerMap[playerIDs[i]]);
        }
        return current;
      });
      setMemberIDs((current) => {
        for (let i = 0; i < playerIDs.length; i++) {
          current.push(playerMap[playerIDs[i].playerID]);
        }
        return current;
      });
      setFriends((current) => {
        const filtered = current.filter(
          (player) => !playerIDs.includes(player.playerID)
        );
        return filtered;
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }
    setLoading(false);
  }

  async function removeMember(playerID) {
    setLoading(true);
    setShowDelete(false);
    try {
      await db
        .collection("clubs")
        .doc(cid)
        .update({
          memberIDs: admin.firestore.FieldValue.arrayRemove(playerID),
        });
      setMembers((current) =>
        current.filter((member) => member.playerID !== playerID)
      );
      setMemberIDs((current) =>
        current.filter((member) => member !== playerID)
      );
      toast.success("Player successfully removed", {
        icon: successIco,
      });
      reload();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }
    setLoading(false);
  }

  async function directSearch() {
    setLoadingSearch(true);
    setNotFound(false);
    playerIndex
      .search(searchInput)
      .then(({ hits }) => {
        const algoArr = [];

        for (let n = 0; n < hits.length; n++) {
          const algoCourse = hits[n];
          // console.log(algoCourse);
          const {
            objectID,
            handicap,
            profileImage,
            firstName,
            lastName,
            city,
            country,
            state,
          } = algoCourse;
          algoArr.push({
            playerID: objectID,
            name: `${firstName} ${lastName}`,
            profileImage,
            handicap,
            city,
            country,
            state,
          });
        }
        setSearchedPlayers(algoArr);
        if (algoArr.length === 0) {
          setNotFound(true);
        }
        setLoadingSearch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function addPlayerToClub(playerID) {
    // return console.log(cid, playerID);
    setLoading(true);
    try {
      await db
        .collection("clubs")
        .doc(cid)
        .update({
          memberIDs: admin.firestore.FieldValue.arrayUnion(playerID),
        });
      await db
        .collection("users")
        .doc(currentUser.uid)
        .update({
          friends: admin.firestore.FieldValue.arrayUnion(playerID),
        });
      // setSearchedPlayers((c) => {
      //   const filt = c.filter((player) => player.playerID !== playerID);
      //   return filt;
      // });
      setMemberIDs((c) => {
        c.push(playerID);
        return c;
      });
      toast.success("Player successfully added to your club", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  function sortPlayers(item = "") {
    const textItems = ["firstName", "lastName", "gender", "emailAddress"];
    if (item === sorter) {
      // console.log("eq");
      if (sortDir === "up") {
        setMembers((c) => {
          if (textItems.includes(item)) {
            c.sort((a, b) => a[item].localeCompare(b[item]));
          } else {
            c.sort((a, b) => a[item] - b[item]);
          }
          return c;
        });
        setSortDir("down");
      } else {
        setMembers((c) => {
          if (textItems.includes(item)) {
            c.sort((a, b) => b[item].localeCompare(a[item]));
          } else {
            c.sort((a, b) => b[item] - a[item]);
          }
          return c;
        });
        setSortDir("up");
      }
    } else {
      setSorter(item);
      setMembers((c) => {
        if (textItems.includes(item)) {
          c.sort((a, b) => a[item].localeCompare(b[item]));
        } else {
          c.sort((a, b) => a[item] - b[item]);
        }
        return c;
      });
      setSortDir("down");
    }
    update();
  }

  return (
    <div className="club-players">
      {show ? (
        toShow
      ) : (
        <>
          <BackRow
            action={() => {
              if (clubID !== "") {
                goBack();
              } else {
                navigate(-1);
              }
            }}
          />
          <div className="ph-me-opt">
            <PageHeader text="Club Members" />

            <MenuSwitcher
              value={menuType}
              handleChange={(e) => {
                setMenuType((c) => {
                  if (c === "pill") {
                    return "drop";
                  } else {
                    return "pill";
                  }
                });
                update();
              }}
            />
          </div>
          {/* {loading && <BackdropProgress prog={prog} />} */}
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}

          {showManual && (
            <ManualPlayerAdd
              custom
              players={friends}
              customAction={(e) => {
                console.log(e);
                addMembers(e);
              }}
              hide={() => setShowManual(false)}
              hideFilters
            />
          )}

          <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
            <div className="cs-delete">
              <div className="page-header">
                <h2>Remove Player</h2>
                <Close
                  className="icon-cursor"
                  onClick={() => setShowDelete(false)}
                />
              </div>
              <p>
                Are you sure you want to remove <span>{toDelete.name}</span>{" "}
                from your club?
              </p>
              <div className="flex-center mb-20">
                <button
                  onClick={() => removeMember(toDelete.playerID)}
                  className="delete-button"
                >
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>

          {cookies["menu-type"] === "drop" && (
            <div className="flex flex-align-center mb-20 mt-10">
              <div className="ddm-box pl-20">
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <button className="ddm-but">
                      <span>Add</span>
                      <KeyboardArrowDown className="ddm-ico" />
                    </button>
                  </DropdownMenu.Trigger>

                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className="DropdownMenuContent"
                      sideOffset={5}
                    >
                      <DropdownMenu.Item
                        onClick={() => {
                          setShowManual(true);
                        }}
                        className="DropdownMenuItem"
                      >
                        Add Players
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onClick={() => {
                          setToShow(
                            <UserImport
                              clubID={cid}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }}
                        className="DropdownMenuItem"
                      >
                        Create User
                      </DropdownMenu.Item>
                      {userDetails && userDetails.test && (
                        <DropdownMenu.Item
                          onClick={() => {
                            setToShow(
                              <BulkPlayerCreator
                                defaultClubID={cid}
                                goBack={() => setShow(false)}
                                showBack
                              />
                            );
                            setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          Bulk Creator
                        </DropdownMenu.Item>
                      )}

                      {!isMobile && showBulk && (
                        <DropdownMenu.Item
                          onClick={() => {
                            setToShow(
                              <BulkImport
                                clubID={cid}
                                goBack={() => setShow(false)}
                              />
                            );
                            setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          Bulk Import{" "}
                        </DropdownMenu.Item>
                      )}
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              </div>
              <div className="ddm-box pl-10">
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <button className="ddm-but">
                      <span>Manage</span>
                      <KeyboardArrowDown className="ddm-ico" />
                    </button>
                  </DropdownMenu.Trigger>

                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className="DropdownMenuContent"
                      sideOffset={5}
                    >
                      <DropdownMenu.Item
                        onClick={() => {
                          setToShow(
                            <PlayerIDs
                              clubID={cid}
                              goBack={() => {
                                setShow(false);
                                showHelperClick("members");
                              }}
                              players={members}
                            />
                          );
                          setShow(true);
                          showHelperClick("player-ids");
                        }}
                        className="DropdownMenuItem"
                      >
                        Manage Player IDs
                      </DropdownMenu.Item>

                      <DropdownMenu.Item
                        onClick={() => {
                          setToShow(
                            <PlayerGroups
                              clubID={cid}
                              goBack={() => {
                                setShow(false);
                                showHelperClick("members");
                              }}
                              members={members}
                            />
                          );
                          setShow(true);
                          showHelperClick("member-groups");
                        }}
                        className="DropdownMenuItem"
                      >
                        Member Groups
                      </DropdownMenu.Item>
                      {!isMobile && showHCPUpload && (
                        <DropdownMenu.Item
                          onClick={() => {
                            setToShow(
                              <HandicapUpload
                                clubID={cid}
                                goBack={() => setShow(false)}
                                players={members}
                              />
                            );
                            setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          Upload Handicaps
                        </DropdownMenu.Item>
                      )}
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              </div>
            </div>
          )}

          {cookies["menu-type"] !== "drop" && (
            <div className="quick-links">
              <Pill text={"Add Players"} onClick={() => setShowManual(true)} />
              <Pill
                text={"Create User"}
                onClick={() => {
                  setToShow(
                    <UserImport clubID={cid} goBack={() => setShow(false)} />
                  );
                  setShow(true);
                }}
              />
              <Pill
                text={"Manage Player IDs"}
                onClick={() => {
                  setToShow(
                    <PlayerIDs
                      clubID={cid}
                      goBack={() => {
                        setShow(false);
                        showHelperClick("members");
                      }}
                      players={members}
                    />
                  );
                  setShow(true);
                  showHelperClick("player-ids");
                }}
              />

              {!isMobile && showBulk && (
                <Pill
                  text={"Bulk Import"}
                  onClick={() => {
                    setToShow(
                      <BulkImport clubID={cid} goBack={() => setShow(false)} />
                    );
                    setShow(true);
                  }}
                />
              )}
              {!isMobile && userDetails && userDetails.test && (
                <Pill
                  text={"Bulk Creator"}
                  onClick={() => {
                    setToShow(
                      <BulkPlayerCreator
                        defaultClubID={cid}
                        goBack={() => setShow(false)}
                        showBack
                      />
                    );
                    setShow(true);
                  }}
                />
              )}
              {!loading && (
                <Pill
                  text={"Member Groups"}
                  onClick={() => {
                    setToShow(
                      <PlayerGroups
                        clubID={cid}
                        goBack={() => {
                          setShow(false);
                          showHelperClick("members");
                        }}
                        members={members}
                      />
                    );
                    setShow(true);
                    showHelperClick("member-groups");
                  }}
                />
              )}

              {!loading && !isMobile && showHCPUpload && (
                <Pill
                  text={"Upload Handicaps"}
                  onClick={() => {
                    setToShow(
                      <HandicapUpload
                        clubID={cid}
                        goBack={() => setShow(false)}
                        players={members}
                      />
                    );
                    setShow(true);
                  }}
                />
              )}
            </div>
          )}

          <RadioGroup
            active={active}
            buttons={[
              { text: "Profile", value: "members" },
              { text: "List", value: "list" },
              { text: "Search", value: "search" },
            ]}
            setActive={(e) => {
              setActive(e);
              setSearchInput("");
            }}
          />

          {active === "search" ? (
            <>
              {loadingSearch ? (
                <>
                  <div className="flex-center mb-20 sis-loading">
                    <Oval
                      color="#1e7a69"
                      secondaryColor="#ffffff"
                      height={40}
                      width={40}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="sub-header">
                    <h3>Search for players on The UGA Platform</h3>
                  </div>
                  <div className="input-group mb-20 si-search">
                    <p>Search</p>

                    <div className="sis-ig">
                      <input
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            directSearch();
                          }
                        }}
                        type="text"
                        placeholder="Search for player (min 3 characters)"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="default-input"
                      />
                      <button
                        disabled={searchInput.length < 3}
                        onClick={directSearch}
                        className="default-button"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="cpb-box">
                    <div className="pp-friends cp-box">
                      {searchedPlayers.map((player, index) => {
                        // console.log(
                        //   userDetails.friends.includes(player.playerID)
                        // );
                        return (
                          <PlayerItem
                            hideRemove
                            key={player.playerID}
                            hcp={player.handicap}
                            hideEdit
                            addIsFriend={true}
                            name={player.name}
                            showAdd={memberIDs.includes(player.playerID)}
                            playerID={player.playerID}
                            img={player.profileImage}
                            addAction={() => {
                              addPlayerToClub(player.playerID);
                            }}
                          />
                        );
                      })}
                      {notFound && (
                        <div className="no-msg">
                          <p>No players found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : active === "list" ? (
            <>
              <div className="flex flex-align-ceter">
                <div className="ce-filters">
                  <input
                    type="text"
                    value={searchInput}
                    placeholder="Search for player"
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="default-input ml-20 mb-10"
                  />
                </div>

                {members.length > 0 && (
                  <div className="ml-20 mt-10">
                    <CSVLink className="default-button small-but" data={csv}>
                      Export
                    </CSVLink>
                  </div>
                )}
              </div>

              {loading ? (
                <>
                  {" "}
                  <>
                    <div className="flex-center mb-20 sis-loading">
                      <Oval
                        color="#1e7a69"
                        secondaryColor="#ffffff"
                        height={40}
                        width={40}
                      />
                    </div>
                  </>
                </>
              ) : (
                <div className="clp-list pd-20">
                  <div className="bs-subtle clps-tb">
                    <table>
                      <thead>
                        <tr className="cl-player clp-header">
                          <th onClick={() => sortPlayers("firstName")}>
                            <div>
                              <p>First Name</p>
                              {sorter === "firstName" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("lastName")}>
                            <div>
                              <p> Last Name</p>
                              {sorter === "lastName" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          {
                            <th onClick={() => sortPlayers("playerClubName")}>
                              <div>
                                <p> Club Name</p>
                                {sorter === "playerClubName" && (
                                  <>
                                    {sortDir === "down" ? (
                                      <ArrowDropUpIcon />
                                    ) : (
                                      <ArrowDropDownIcon />
                                    )}
                                  </>
                                )}
                              </div>
                            </th>
                          }
                          <th onClick={() => sortPlayers("gender")}>
                            <div>
                              <p>Gender</p>
                              {sorter === "gender" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("handicap")}>
                            <div>
                              <p>Handicap</p>
                              {sorter === "handicap" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("hnaID")}>
                            <div>
                              <p>Handicap ID </p>
                              {sorter === "hnaID" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("clubHNAID")}>
                            <div>
                              <p>Club ID </p>
                              {sorter === "clubHNAID" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("lowHI")}>
                            <div>
                              <p>Low HI</p>
                              {sorter === "lowHI" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("emailAddress")}>
                            <div>
                              <p>Email Address</p>
                              {sorter === "emailAddress" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("djObject")}>
                            <div>
                              <p>Date Joined</p>
                              {sorter === "djObject" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("dobObj")}>
                            <div>
                              <p>Date of Birth</p>
                              {sorter === "dobObj" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("city")}>
                            <div>
                              <p>City</p>
                              {sorter === "city" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("state")}>
                            <div>
                              <p>State</p>
                              {sorter === "state" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                          <th onClick={() => sortPlayers("country")}>
                            <div>
                              <p>Country</p>
                              {sorter === "country" && (
                                <>
                                  {sortDir === "down" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </>
                              )}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {members.map((player, i) => {
                          if (searchInput !== "") {
                            const sl = searchInput.toLowerCase();
                            const nl = player.name.toLowerCase();
                            if (!nl.includes(sl)) {
                              return null;
                            }
                          }

                          return (
                            <ClubListPlayer
                              key={player.playerID}
                              player={player}
                              editAct={() => {
                                setToShow(
                                  <UserImport
                                    goBack={() => setShow(false)}
                                    clubID={cid}
                                    edit
                                    added={player.addedBy !== ""}
                                    userID={player.playerID}
                                  />
                                );
                                setShow(true);
                              }}
                            />
                          );
                        })}
                      </tbody>
                    </table>{" "}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="ce-filters">
                <input
                  type="text"
                  value={searchInput}
                  placeholder="Search for player"
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input ml-20 mb-10"
                />
              </div>

              {loading ? (
                <>
                  <div className="flex-center mb-20 sis-loading">
                    <Oval
                      color="#1e7a69"
                      secondaryColor="#ffffff"
                      height={40}
                      width={40}
                    />
                  </div>
                </>
              ) : (
                <div className="cpb-box">
                  <div className="cp-box flex-wrapper">
                    {members.map((player, index) => {
                      if (searchInput !== "") {
                        const sl = searchInput.toLowerCase();
                        const sn = player.name.toLowerCase();
                        if (!sn.includes(sl)) {
                          return null;
                        }
                      }

                      return (
                        <PlayerItem
                          hcp={player.handicap}
                          // hideEdit={player.addedBy === ""}
                          showAdd
                          removeAction={() => {
                            setToDelete(player);
                            setShowDelete(true);
                          }}
                          // noLink
                          hideMessage={
                            currentUser && currentUser.uid === player.playerID
                              ? true
                              : false
                          }
                          hideProfile
                          key={player.playerID}
                          playerID={player.playerID}
                          img={player.profileImage}
                          name={player.name}
                          editAction={() => {
                            setToShow(
                              <UserImport
                                goBack={() => setShow(false)}
                                clubID={clubID}
                                edit
                                added={player.addedBy !== ""}
                                userID={player.playerID}
                              />
                            );
                            setShow(true);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ClubPlayers;
