import React from "react";

/**
 * @typedef {'red' | 'green' | 'black'} ButtonColor
 */

/**
 * Button component
 * @param {Object} props - The props for the Button component.
 * @param {ButtonColor} props.colour - The color of the button.
 * @param {string} [props.text=""] - The text inside the button.
 * @param {Function} [props.onClick] - The function to call when the button is clicked.
 * @param {React.ReactNode} [props.ico=<></>] - The icon or additional element to display inside the button.
 */

function IconButton({
  text = "",
  onClick = () => {},
  ico = <></>,
  colour = "green",
}) {
  return (
    <button onClick={onClick} className={`icon-button icon-button-${colour}`}>
      <div className="ib-ico">{ico}</div>
      <span>{text}</span>
    </button>
  );
}

export default IconButton;
