import React, { useState } from "react";
import "./TourLanding.css";

// Components
import Login from "../../pages/Login/Login";
import { NavLink } from "react-router-dom";

function TourLanding() {
  // State
  const [showLogin, setShowLogin] = useState(false);

  return (
    <div className="tour-landing">
      {showLogin && <Login hide={() => setShowLogin(false)} />}
      <div className="tlp-top">
        <div className="tlp-left">
          <h1 className="big-header">The Tour Portal</h1>
          <p>
            A hybrid approach to golf management and scoring software, designed
            to transform your social golf and take your golfing expierences to
            the next level. Ideal for clubs, tours, leagues & societies.
          </p>
          <div className="flex-ac mt-20 hlp-buts">
            <NavLink to={"/login"}>
              <button
                onClick={() => setShowLogin(true)}
                className="medium-button default-button black-button "
              >
                Login
              </button>
            </NavLink>
            <div className="ml-20 txt-lk-box">
              <NavLink to={"/onboarding"}>
                <h3 className="txt-lk">Create Account</h3>
              </NavLink>
            </div>
          </div>
          <div className="tl-more mt-40">
            <p>
              For more information on The Tour visit{" "}
              <a
                href="https://tour-site-f09fe.web.app/"
                target="_blank"
                rel="noreferrer"
              >
                our home page
              </a>
            </p>
          </div>
        </div>
        <div className="tlp-right"></div>
      </div>
    </div>
  );
}

export default TourLanding;
