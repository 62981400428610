import React, { useState, useEffect } from "react";
import "./LogPage.css";

import FullLog from "./FullLog";

import { Tabs, Tab } from "@mui/material";

import { admin, db, storage } from "../../../firebase";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PlayerLoader from "../../../components/Loaders/PlayerLoader";
import LeagueRankTracker from "../../../components/display/RankTracker/LeagueRankTracker";
import { useAuth } from "../../../contexts/AuthContext";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import EclecticLog from "../../Eclectic/EclecticLog";
import ErrorModal from "../../ErrorModal/ErrorModal";
import Pill from "../../Buttons/Pill";
import LeagueTeamLog from "../../Leagues/LeagueEdit/LeagueTeamLog";
import { CSVLink } from "react-csv";

function LogPage({ leagueID, leagueName, goBack, goFoward, teams = [] }) {
  const statsProp = {
    rounds: 0,
    averageNet: 0,
    averageGross: 0,
    averagePutts: 0,
    fewestPutts: 0,
    lowestGross: 0,
    lowestNet: 0,
    highestGross: 0,
    highestNet: 0,
    frontNineGrossAverage: 0,
    frontNineNetAverage: 0,
    backNineGrossAverage: 0,
    backNineNetAverage: 0,
    frontNineLowestGross: 0,
    backNineLowestGross: 0,
    frontNineLowestNet: 0,
    backNineLowestNet: 0,
    frontNineHighestGross: 0,
    backNineHighestGross: 0,
    frontNineHighestNet: 0,
    backNineHighestNet: 0,
    girAve: 0,
    netGirAve: 0,
    firAve: 0,
    totalThreePutts: 0,
  };

  const playerStatsProp = {
    majorWins: 0,
    averageBirdies: 0,
    averageBogies: 0,
    averageDoubles: 0,
    averageEagles: 0,
    averageFIR: 0,
    averageGIR: 0,
    averageNetGIR: 0,
    averageGross: 0,
    averageNet: 0,
    averageParFives: 0,
    averageParFours: 0,
    averageParThrees: 0,
    averagePars: 0,
    averagePutts: 0,
    totalRounds: 0,
    totalHoles: 0,
    totalBirdies: 0,
    totalBogies: 0,
    totalDoubles: 0,
    totalEagles: 0,
    totalFIR: 0,
    totalGIR: 0,
    totalParFives: 0,
    totalParFours: 0,
    totalParThrees: 0,
    totalPars: 0,
    totalPutts: 0,
    netGIRPutts: 0,
    grossGIRPutts: 0,
    parThreeNetGIRs: 0,
    parFourNetGIRs: 0,
    parFiveNetGIRs: 0,
    parThreeGrossGIRs: 0,
    parFourGrossGIRs: 0,
    parFiveGrossGIRs: 0,
    wins: 0,
    topTwentyFinishes: 0,
    topTenFinishes: 0,
    topThreeFinishes: 0,
    parThreeGrossGIRScoringAverage: 0,
    parFourGrossGIRScoringAverage: 0,
    parFiveGrossGIRScoringAverage: 0,
    parThreeGrossFIRScoringAverage: 0,
    parFourGrossFIRScoringAverage: 0,
    parFiveGrossFIRScoringAverage: 0,
    girGrossAverageToPar: 0,
    firGrossAverageToPar: 0,
    parThreeNetGIRScoringAverage: 0,
    parFourNetGIRScoringAverage: 0,
    parFiveNetGIRScoringAverage: 0,
    parThreeNetFIRScoringAverage: 0,
    parFourNetFIRScoringAverage: 0,
    parFiveNetFIRScoringAverage: 0,
    girNetAverageToPar: 0,
    firNetAverageToPar: 0,
    lowestGrossNineHoleScore: 0,
    lowestNetNineHoleScore: 0,
    totalGrossScrambleAttempts: 0,
    totalNetScrambleAttempts: 0,
    totalGrossScrambles: 0,
    totalNetScrambles: 0,
    holeOuts: 0,
    onePutts: 0,
    threePutts: 0,
    grossBounceBackBirdies: 0,
    netBounceBackBirdies: 0,
    percentagePointsWon: 0,
    grossBirdieStreak: 0,
    netBirdieStreak: 0,
    highestGirStreak: 0,
    highestFirStreak: 0,
    highestNetGirStreak: 0,
    currentNoThreePuttStreak: 0,
    currentOnePuttStreak: 0,
    highestNoThreePuttStreak: 0,
    highestOnePuttStreak: 0,
    currentGrossSubParRoundsStreak: 0,
    currentNetSubParRoundsStreak: 0,
    highestGrossSubParRoundsStreak: 0,
    highestNetSubParRoundsStreak: 0,
    grossSubParHolesStreak: 0,
    netSubParHolesStreak: 0,
    averagePuttLength: 0,
    totalPuttLength: 0,
    netBirdieAttempts: 0,
    grossBirdieAttempts: 0,
  };

  const callBoard = admin.functions().httpsCallable("ptCall");

  const { userSettings } = useAuth();

  const [meters, setMeters] = useState(false);

  const [error, setError] = useState("");
  const [active, setActive] = useState("singles");

  const [selected, setSelected] = useState("log");
  const [showProfile, setShowProfile] = useState(false);
  const [profile, setProfile] = useState();
  const [logs, setLogs] = useState([]);
  const [grossStats, setGrossStats] = useState([]);
  const [netStats, setNetStats] = useState([]);
  const [loading, setLoading] = useState(true);

  const [leagueStats, setLeagueStats] = useState();
  const [seasonStats, setSeasonStats] = useState([]);

  const [final, setFinal] = useState(false);

  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState({ value: "" });

  const [records, setRecords] = useState([]);

  const [cutoff, setCutoff] = useState(0);

  const [eclectic, setEclectic] = useState(false);

  const [statSlider, setStatSlider] = useState("slide-in-left");
  const [recSlider, setRecSlider] = useState("slide-in-left");

  const [csv, setCSV] = useState([]);

  useEffect(() => {
    loadLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userSettings) {
      setMeters(userSettings.units === "meters");
    }
  }, [userSettings]);

  function compare(a, b) {
    if (a.points > b.points) {
      return -1;
    }
    if (a.points < b.points) {
      return 1;
    }
    return 0;
  }

  async function loadLogs() {
    // setLoading(true)

    const statArr = [];

    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { stats } = league;
    const leagueType = league.leagueType ?? "normal";
    const eclectic = leagueType === "eclectic";
    const records = league.records ?? [];
    setEclectic(eclectic);

    for (let i = 0; i < records.length; i++) {}
    setRecords(records);
    const leagueLeaderboard = league.pointsTable;
    const currentSeasonID = league.seasonID;
    // console.log(leagueLeaderboard);
    setLeagueStats(stats);

    // Seasons
    const seasonDocs = await db
      .collection("seasons")
      .where("leagueID", "==", leagueID)
      .orderBy("seasonIndex", "asc")
      .get();
    // if (leagueLeaderboard[0].finalRank === undefined) {
    //   leagueLeaderboard.sort(compare);
    // } else {
    leagueLeaderboard.sort((a, b) => b.points - a.points);
    // }
    const seasonArr = [
      // {
      //   label: "Overall",
      //   value: "overall",
      //   leaderboard: leagueLeaderboard,
      //   final: false,
      // },
    ];
    if (eclectic) {
      leagueLeaderboard.sort((a, b) => {
        // if (b.net === 0) {
        //   return -1;
        // }
        // if (a.net === 0) {
        //   return 1;
        // }
        return b.stableford - a.stableford;
      });

      let currentTies = 0;

      for (let i = 0; i < leagueLeaderboard.length; i++) {
        let ranking = i + 1;

        if (i > 0) {
          if (leagueLeaderboard[i - 1].net === leagueLeaderboard[i].net) {
            // console.log('ello')
            ranking = i - currentTies;
            currentTies += 1;
          } else {
            currentTies = 0;
          }
        }
        leagueLeaderboard[i].ranking = ranking;
      }
    }

    if (
      leagueLeaderboard.length > 0 &&
      leagueLeaderboard[0].finalRank !== undefined
    ) {
      leagueLeaderboard.sort((a, b) => a.finalRank - b.finalRank);
    }
    sortCSV(leagueLeaderboard);
    setLogs(leagueLeaderboard);
    for (let i = 0; i < seasonDocs.docs.length; i++) {
      const seasonDoc = seasonDocs.docs[i];
      const seasonID = seasonDoc.id;
      const lastSnapDocs = await seasonDoc.ref
        .collection("snapshots")
        .orderBy("date", "desc")
        .limit(2)
        .get();
      let snapTable = [];
      if (lastSnapDocs.docs.length > 1) {
        const snapDoc = lastSnapDocs.docs[1].data();
        snapTable = snapDoc.pointsTable;
        snapTable.sort(compare);
      }
      // console.log(snapTable);
      const seasonData = seasonDoc.data();
      if (seasonData.stats === undefined) {
        console.log("continuing");
        // continue;
      }
      const { seasonName, pointsTable } = seasonData;
      const stats = seasonData.stas ?? statsProp;
      const rankCutoff = seasonData.rankCutoff ?? 0;
      if (seasonData.finalRoundID !== undefined) {
        pointsTable.sort((a, b) => a.finalRank - b.finalRank);
        setFinal(true);
      } else {
        pointsTable.sort(compare);
      }
      // console.log(snapTable);
      for (let j = 0; j < pointsTable.length; j++) {
        const row = pointsTable[j];
        for (let n = 0; n < snapTable.length; n++) {
          const snapRow = snapTable[n];
          if (row.playerID === snapRow.playerID) {
            // console.log("found");
            if (n > j) {
              pointsTable[j].move = "up";
            } else if (n < j) {
              pointsTable[j].move = "down";
            } else {
              pointsTable[j].move = "same";
            }
          }
        }
        if (row.move === undefined) {
          pointsTable[j].move = "up";
        }
      }
      seasonArr.push({
        label: seasonName,
        value: seasonID,
        seasonID,
        leaderboard: pointsTable,
        snapTable,
        final: seasonData.finalRoundID !== undefined,
        rankCutoff,
      });
      setCutoff(rankCutoff);
      stats.seasonName = seasonName;
      statArr.push(stats);
      if (seasonID === currentSeasonID) {
        // console.log(seasonArr[i + 1]);
        setSelectedSeason(seasonArr[i + 1]);
        setLogs(pointsTable);
      }
    }
    setSeasonStats(statArr);
    setSeasons(seasonArr);
    if (seasonArr.length > 0) {
      setSelectedSeason(seasonArr[0]);
    }

    // setLogs(leagueLeaderboard);
    setLoading(false);
  }

  async function getPDF() {
    setLoading(true);
    const link = await callBoard({
      seasonID: selectedSeason.value,
      active,
    }).catch((err) => {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there. Please try again."
      );
    });
    const url = link.data;
    console.log(url);
    const ref = storage.refFromURL(url);
    window.open(url, "_blank");
    console.log(ref);
    setLoading(false);
  }

  function sortCSV(pointsTable = []) {
    const arr = [["name", "played", "points", "wins"]];
    for (let i = 0; i < pointsTable.length; i++) {
      const row = pointsTable[i];
      arr.push([
        row.userName,
        row.totalRounds,
        Math.round(row.points),
        row.wins,
      ]);
    }
    setCSV(arr);
  }

  return (
    <div className="log-page">
      <div className="flex-align-center">
        <RadioGroupTwo
          buttons={[
            { text: "Log", value: "log" },
            { text: "Tracker", value: "tracker" },
          ]}
          active={selected}
          setActive={setSelected}
        />
        <div className="ml-40"></div>
        <Pill text={"Export PDF"} onClick={getPDF} />
        {csv.length > 0 && (
          <CSVLink
            filename={`${leagueName} Logs ${new Date().toLocaleDateString()}`}
            data={csv}
            className="pill-button"
          >
            <p>Export CSV</p>
          </CSVLink>
        )}
      </div>

      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      {loading ? (
        <div className="player-loaders pt-20">
          <PlayerLoader />
          <PlayerLoader />
          <PlayerLoader />
          <PlayerLoader />
          <PlayerLoader />
          <PlayerLoader />
          <PlayerLoader />
          <PlayerLoader />
          <PlayerLoader />
          <PlayerLoader />
        </div>
      ) : showProfile ? (
        profile
      ) : (
        <>
          <div className="log-page-league-name ">{/* {leagueName} */}</div>

          {selected === "log" && (
            <div className="slide-in-left">
              <div className="sub-header">
                <h3 className="">Points Table</h3>
              </div>
              <div className="input-group">
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={seasons}
                  value={selectedSeason.value}
                  onChange={(e) => {
                    const seasonID = e.value;
                    setSelectedSeason(e);
                    let toStore;
                    for (let i = 0; i < seasons.length; i++) {
                      if (seasons[i].value === seasonID) {
                        // setLogs(seasons[i].leaderboard);
                        setFinal(seasons[i].final);
                        toStore = seasons[i].leaderboard;
                        setCutoff(seasons[i].cutoff ?? 0);
                      }
                    }
                    // if (e.value !== "overall" || true) {
                    sortCSV(toStore);
                    setLogs(toStore);
                    // }
                  }}
                />
              </div>
              {teams.length > 0 && (
                <div className="pl-20 mt-20">
                  <div className="selector-group">
                    <button
                      onClick={() => setActive("singles")}
                      className={
                        active === "singles" ? "sg-active sg-right" : "sg-right"
                      }
                    >
                      Singles
                    </button>
                    <button
                      onClick={() => setActive("teams")}
                      className={
                        active === "teams" ? "sg-middle sg-active" : "sg-middle"
                      }
                    >
                      Teams
                    </button>
                  </div>
                </div>
              )}
              <div className="lc-box">
                <div className="log-container">
                  {active === "teams" ? (
                    <LeagueTeamLog
                      teams={teams}
                      final={
                        teams.length > 0 && teams[0].finalRank !== undefined
                      }
                    />
                  ) : (
                    <>
                      {!eclectic && (
                        <FullLog
                          cutoff={cutoff}
                          goBack={() => setShowProfile(false)}
                          showProfile={(e) => {
                            setProfile(e);
                            setShowProfile(true);
                          }}
                          leagueLog={logs}
                          leagueID={leagueID}
                          setLoading={setLoading}
                          final={final}
                        />
                      )}
                      {eclectic && (
                        <EclecticLog courseHoles={[]} leagueLog={logs} />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {selected === "tracker" && (
            <div className="slide-in-right">
              <LeagueRankTracker leagueID={leagueID} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default LogPage;
