import { MoreVert } from "@mui/icons-material";
import classNames from "classnames";
import React from "react";
import Menu from "@mui/material/Menu";

function PayHistoryRow({
  row = {},
  disputeAct = () => {},
  invoiceAct = () => {},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="pay-history-row">
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {" "}
        <div
          onClick={() => {
            invoiceAct();
            handleClose();
          }}
          className="rp-mi"
        >
          <p>Generate Invoice</p>
        </div>
        {!row.disputed && row.status !== "failed" && (
          <div
            onClick={() => {
              disputeAct();
              handleClose();
            }}
            className="rp-mi"
          >
            <p>Dispute</p>
          </div>
        )}
      </Menu>
      <div className="phr-item">
        <p>{row.dateString}</p>
      </div>
      <div className="phr-item">
        <p>${row.amount}</p>
      </div>
      <div className="phr-item">
        <p style={{ textTransform: "capitalize" }}>{row.card_type}</p>
      </div>
      <div className="phr-item">
        <p>{row.last4}</p>
      </div>
      <div className="phr-item">
        <p>{row.reference}</p>
      </div>
      <div
        className={classNames("phr-item", {
          "phr-suc": row.status === "success",
          "phr-fail": row.status === "failure",
        })}
      >
        <p style={{ textTransform: "capitalize" }}>{row.status}</p>
      </div>
      <div className="phr-item phr-opt">
        <p>
          <MoreVert
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="icon-cursor hgb-ico"
          />
        </p>
      </div>
      <div className="phr-item">
        <p style={{ textTransform: "capitalize" }}>
          {row.disputed ? row.disputeStatus : "-"}
        </p>
      </div>
    </div>
  );
}

export default PayHistoryRow;
